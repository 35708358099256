import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  DsmButton,
  DsmCheckbox,
  DsmGrid,
  DsmIcon,
  DsmSelect,
  DsmModal,
} from "@dsm-dcs/design-system-react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubheader } from "../../../../_metronic/layout";
import { templateStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import LayingHensStage from "../../components/FarmFlow/Baseline/LayingHens/LayingHensStages";
import getValidationSchemaSustell from "../../../modules/Farms/Baseline/validationSchema/validationSchemaAssembler";
import { UserProfilePrefs } from "../../../modules/Helpers/UserProfilePrefs";
import { defaultValues } from "../../components/FarmFlow/baselineDefaultValues";
import BroilerStages from "../../components/FarmFlow/Baseline/Broiler/BroilerStages";
import DairyStages from "../../components/FarmFlow/Baseline/Dairy/DairyStages";
import PigStages from "../../components/FarmFlow/Baseline/PigFattening/PigStages2";
import PoultryStages from "../../components/FarmFlow/Baseline/Poultry/PoultryStages";
import ShrimpStages from "../../components/FarmFlow/Baseline/Shrimp/ShrimpStages";
import ReactHookDsmInput from "../../../modules/Helpers/ReactHookDsmInput2";
import {
  getMasterData,
  listFacilitiesBrief,
  listFeedsV2,
  listFeedsDairyV2,
  listFarms,
  listOriginsAndSingleIngredients,
} from "../../../../graphql/queries";
import {
  listEntryDataSorter,
  masterDataSorter,
} from "../../helpers/sortingFunctions";
import MarineFishStages from "../../components/FarmFlow/Baseline/MarineFish/MarineFishStages_v2";
import {
  mapProductionDataOutSustell,
  mapProductionDataInSustell,
} from "../../../modules/Farms/Baseline/mapper/productionDataMapper";
import {
  saveBaseline,
  fetchPPDatasetV2,
  getFarmBaseData,
} from "../../apicalls/farmFunctions";
import DeleteConfirmationDialog from "../../../sustell_15/components/helpers/DeleteConfirmationDialog";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import {
  clearBaselineDraftAction,
  setBaselineDraftAction,
} from "../../../state/drafts/baselineDraftsSlice";
import getBaselineDraftByKey from "../../../state/drafts/baselineGetFromDraft";
import {
  NotificationDialog,
  NotificationDialogType,
} from "../../components/helpers/NotificationDialog";
import useAdobeDataLayer from "../../analytics/adobeDataLayer";
import SustellFarmSteps from "./SustellFarmSteps_v2";
import BaselineProfile from "../../components/FarmFlow/CommonDataParts/v2.0/BaselineProfile";
import {
  FormType,
  isReadOnlyAnimalSystem,
} from "../../components/FarmFlow/common";
import StageComponent from "../../components/FarmFlow/CommonDataParts/v2.0/StageComponent";
import StagesListComponent from "../../components/FarmFlow/StagesListComponent";
import StageForm from "../../components/FarmFlow/StageForm_v2";
import StepperButtons from "../../components/FarmFlow/CommonDataParts/v2.0/StepperButtons";
import { convertDateToAWS } from "../../utils/string-utils";
import { enumToOptionsArrayWithTranslatedStrings } from "../../utils/obj-utils";
import { AnimalType, FacilityType, StageType } from "../../../../graphql/types";
import ReactHookDsmSelect from "../../../modules/Helpers/ReactHookDsmSelect2";
import Can from "../../../modules/Auth/Can";
import lookupValues from "../../helpers/lookupValues";
import {
  isMarineFish,
  isPoultry,
  filterStageTypesByAnimalType,
  isShrimp,
  isBeef,
  isDairy,
  isPig,
  formatTargetSpecies,
} from "../../helpers/animals";
import SlaughterhouseStages from "../../components/FarmFlow/Baseline/Slaughterhouse_v2/SlaughterhouseStages";
import DsmButtonControlGroup from "../../components/helpers/DsmButtonControlGroup";
import { DialogContainer } from "../../components/FarmFlow/CommonDataParts/DialogContainer2";
import {
  DsmButtonV2,
  useButtonStyles,
} from "../../../sustell_15/components/helpers/DsmButton";
import PigFatteningStages from "../../components/FarmFlow/Baseline/PigFattening/PigFatteningStages";
import SalmonStages from "../../components/FarmFlow/Baseline/Salmon/SalmonStages";
import {
  filterFacilitiesByType,
  mountFacilityOptions,
  isFacilitySuitableForAnimalType,
  isProcessingStage,
  isSlaugteringAndCuttingFacility,
} from "../../helpers/facilities";
import StageSelectionDefinitionsDialog from "./StageSelectionDefinitionsDialog";
import DefinitionsDialog from "../../components/FarmFlow/CommonDataParts/v2.0/DefinitionsDialog";
import BeefStages from "../../components/FarmFlow/Baseline/Beef/BeefStages";
import { organiseBaselines } from "../../../modules/Helpers/organise-baselines";
import WarningDialog from "../../../sustell_15/components/helpers/WarningDialog";
import DairyV2Stages from "../../components/FarmFlow/Baseline/DairyV2/DairyV2Stages";
import paginateQuery from "../../../sustell_15/helpers/paginate";
import { translateIngredients } from "../../utils/translation-utils";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import { capitalize } from "lodash";
import { addMappedIngredientAddons } from "./Common";

const SustellDatasetMainPage_v2 = (props) => {
  const language = useLang();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const subheader = useSubheader();
  const buttonClasses = useButtonStyles();
  const classes = templateStyles();
  const {
    match: {
      params: { customerID, farmID, processID, datasetID },
    },
  } = props;
  const { formSubmissionEvent, formErrorsEvent } = useAdobeDataLayer();
  // const [initProcessingStageType, setInitProcessingStageType] = useState(false);
  const [inlineStageEditorOpened, setInlineStageEditorOpened] = useState(-1);
  const validationSchema = useRef({});
  // would be good to keep this stored in Context
  const farmName = props.location.state?.farmName || "Farm";
  const processName = props.location.state?.processName || "Process";
  const duplicateDatasetID = props.location.state?.duplicateDatasetID;
  const animalType = props.location.state?.animalType || AnimalType.Pig;
  const newStageData = JSON.parse(
    JSON.stringify(defaultValues[animalType]?.stages[0]?.stageData || {})
  );

  let formType =
    props.location.state?.formType ||
    (datasetID === "NEW" ? FormType.Add : FormType.View);

  const targetSpecieForSingleIngredients = () => {
    switch (animalType) {
      case AnimalType.Pig:
        return "pig";
      case AnimalType.BroilerV2:
      case AnimalType.LayingHensV2:
      case AnimalType.Turkey:
        return "poultry";
      case AnimalType.LitopenaeusVannamei:
      case AnimalType.PenaeusMonodon:
        return "shrimp";
      case AnimalType.Beef:
        return "beef";
      case AnimalType.DairyV2:
        return "dairy";
      default:
        return "";
    }
  };

  const headingTitle = {
    fontSize: "20px",
    color: "black",
    fontWeight: "500",
    marginTop: "10px",
    marginBottom: "10px",
  };

  // if animalType is missing force user to go to proper
  // higher level and proceed from there
  if (!animalType) {
    let path = "/";
    if (customerID) {
      path = `/customers/${customerID}`;
      if (farmID) {
        path += `/farm/${farmID}`;
        if (processID) {
          path += `/pp/${processID}`;
        }
      }
    }
    history.push(path);
  }

  const [openDraftDialog, setOpenDraftDialog] = useState(false);
  const [baseline, setBaseline] = useState({});
  const [compoundFeeds, setCompoundFeeds] = useState([]);
  const [singleIngredients, setSingleIngredients] = useState([]);
  const [manureManagementSystems, setManureManagementSystems] = useState([]);
  const [masterDataEnums, setMasterDataEnums] = useState({});
  const [origins, setOrigins] = useState([]);
  const [poultryFarms, setPoultryFarms] = useState([]);
  const [shrimpFarms, setShrimpFarms] = useState([]);
  const [beefFarms, setBeefFarms] = useState([]);
  const [dairyFarms, setDairyFarms] = useState([]);
  const [pigFarms, setPigFarms] = useState([]);

  const [selectedStageToDelete, setSelectedStageToDelete] = useState(null);
  const [notification, setNotification] = useState(null);
  const [stageFormOpen, setStageFormOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState({});
  const [stageFormType, setStageFormType] = useState("");
  const [firstStageType, setFirstStageType] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [benchmark, setBenchMark] = useState(false);
  const [selectedFarmName, setSelectedFarmName] = useState(farmName);
  const [openSaveChangesDialog, setOpenSaveChangesDialog] = useState(false);
  const [duplicateBaseline, setDuplicateBaseline] = useState("No");
  const [submittedData, setSubmittedData] = useState();
  const [facilities, setFacilities] = useState([]);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [hasWarningDialogOpened, setHasWarningDialogOpened] = useState(false);
  const [warningStageName, setWarningStageName] = useState(null);
  const [restoreDraftErrorMessage, setRestoreDraftErrorMessage] = useState("");

  const minValueOutput = UserProfilePrefs.getInstance().getMinOutputMass();
  const tempRange = UserProfilePrefs.getInstance().getTempRange();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const userDatabase = UserProfilePrefs.getInstance().getUserDatabasePrefs().databaseType
  const isMarineFishFarm = isMarineFish(animalType);
  const isPoultryFarm = isPoultry(animalType);
  const isShrimpFarm = isShrimp(animalType);
  const isBeefFarm = isBeef(animalType);
  const isDairyFarm = isDairy(animalType);
  const isPigFarm = isPig(animalType);
  const SAVE_CHANGES_OPTIONSS = [
    {
      label: intl.formatMessage({
        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.SAVE_CHANGES.OPTION_ONE",
      }),
      value: "No",
    },
    {
      label: intl.formatMessage({
        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.SAVE_CHANGES.OPTION_TWO",
      }),
      value: "Yes",
    },
  ];

  async function loadFarms() {
    API.graphql({
      query: listFarms,
      variables: {
        customerId: customerID,
      },
    }).then((response) => {
      const resultList = [];
      (response.data?.listFarms || []).forEach((farm) => {
        farm.animalSystems.items.forEach((animalSystem) => {
          const prodSystem = {
            baselines: [...animalSystem.baselines.items],
          };
          const [activeBaseline, _] = organiseBaselines(prodSystem);
          animalSystem.baselines.items.forEach((bas) => {
            // Ignore current opened baseline
            if (
              (farm.id !== farmID ||
                animalSystem.id !== processID ||
                bas.reference !== datasetID) &&
              bas.reference === activeBaseline.reference
            ) {
              bas.stages.forEach((stage) => {
                let stageData = JSON.parse(stage.stageData);
                let outputNames = [];

                try {
                  // shrimp has output as list with names
                  if (isShrimp(animalSystem.animalType))
                    stageData?.output?.forEach((output) => {
                      outputNames.push(output.name);
                    });
                } catch (e) {
                  console.log(e);
                }
                resultList.push({
                  farmId: farm.id,
                  farmName: farm.name,
                  productionProcessId: animalSystem.id,
                  productionProcessName: animalSystem.name,
                  stageId: stage.id,
                  stageName: stage.name,
                  reference: bas.reference,
                  stageType: stage.type,
                  averageWeight: stageData?.output?.averageWeight,
                  averageWeightPiglets:
                    stageData?.animalsOutput?.averageWeightPiglets,
                  averageWeightPigs:
                    stageData?.animalsOutput?.averageWeightPigs,
                  averageAgePigs: stageData?.animalsOutput?.averageAgePigs,
                  averageAgePiglets: stageData?.animalsOutput?.averageAgePiglets,
                  animalType: animalSystem.animalType,
                  databaseFoundation: bas.databaseFoundation,
                  outputs: outputNames,
                  benchmark: farm.benchmark,
                });
              });
            }
          });
        });
      });
      setPoultryFarms(
        resultList.filter(
          (item) => isPoultry(item.animalType) && item.animalType === animalType
        )
      );
      setShrimpFarms(
        resultList.filter(
          (item) => isShrimp(item.animalType) && item.animalType === animalType
        )
      );
      if (isBeefFarm) {
        setBeefFarms(
          resultList.filter(
            (item) => (isBeef(item.animalType) || isDairy(item.animalType))
          )
        );
      }
      setDairyFarms(
        resultList.filter(
          (item) => isDairy(item.animalType) && item.animalType === animalType
        )
      );
      setPigFarms(
        resultList.filter(
          (item) => isPig(item.animalType) && item.animalType === animalType
        )
      );
    });
  }

  async function loadDairyMasterData() {
    API.graphql({
      query: getMasterData,
      variables: {
        customerId: customerID,
        animalType,
      },
    }).then(
      (response) => {
        if (response.data?.getMasterData?.compound_feed_uses)
          setCompoundFeeds(
            response.data.getMasterData.compound_feed_uses.sort(
              masterDataSorter
            )
          );
        if (response.data?.getMasterData?.single_ingredient_uses)
          setSingleIngredients(
            response.data?.getMasterData.single_ingredient_uses.sort(
              masterDataSorter
            )
          );
        if (response.data?.getMasterData?.manure_management_system)
          setManureManagementSystems(
            response.data.getMasterData.manure_management_system.sort(
              masterDataSorter
            )
          );
      },
      (reason) => {
        console.log("Error fetching master data: ", reason);
      }
    );
  }

  async function loadMasterData() {
    const vars = {
      customerId: customerID,
      animalType,
      databaseFoundation: userDatabase
    };

    API.graphql({
      query: listOriginsAndSingleIngredients,
      variables: vars,
    }).then(
      (response) => {
        if (!isMarineFishFarm || isPoultryFarm || isShrimpFarm) {
          const originsList =
            response.data.getOrigins?.map((item) => ({
              value: item.isoCode,
              text: intl.formatMessage({
                id: `SUSTELL.GEOGRAPHY.${item.isoCode.toUpperCase()}`,
              }),
            })) || [];
          const targetSpecie = targetSpecieForSingleIngredients();
          let ingredients =
            response.data.getSingleIngredientNames
              ?.filter((item) => item.targetSpecies.includes(targetSpecie))
              .map((item) => ({
                value: item.name,
                text: item.name,
                isMilk: item?.isMilk || false,
                isSilage: item?.isSilage || false,
              })) || [];

          ingredients = addMappedIngredientAddons(
            response.data?.getCompoundFeedV2CustomerAddons,
            ingredients
          );

          setOrigins(originsList.sort(listEntryDataSorter));
          translateIngredients(ingredients, language);
          setSingleIngredients(ingredients?.sort(listEntryDataSorter));
        }
        if (isMarineFishFarm) {
          if (response.data?.getMasterData?.enumerations) {
            const enumsObject = {};
            response.data.getMasterData.enumerations.forEach((item) => {
              enumsObject[item.enumName] = [...item.enumValues];
            });
            setMasterDataEnums(enumsObject);
          }
        }
      },
      (reason) => {
        console.log("Error fetching master data: ", reason);
      }
    );
  }

  const loadCompoundFeeds = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await paginateQuery(
      {
        query: listFeedsV2,
        variables: {
          customerId: customerID,
          animalType: animalType,
        },
      },
      "listCompoundFeedsV2"
    ).then(
      (response) => {
        setCompoundFeeds(
          // use only feeds for selected animal type
          response?.filter((feed) =>
            feed.targetSpecies?.includes(
              lookupValues.animalTypeFeedAnimalSystem[animalType]
            ) ||
            feed.targetSpecies?.includes(
              formatTargetSpecies(lookupValues.animalTypeFeedAnimalSystem[animalType])
            )
          )
            ?.sort(masterDataSorter)
        );
      },
      (error) => {
        console.log("Error fetching compound feeds: ", error);
      }
    );
  };

  const loadDairyV2CompoundFeeds = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await paginateQuery(
      {
        query: listFeedsDairyV2,
        variables: {
          customerId: customerID,
          animalType: animalType,
        },
      },
      "listCompoundFeedsV2"
    ).then(
      (response) => {
        setCompoundFeeds(
          // use only feeds for selected animal type
          response?.filter((feed) =>
            feed.targetSpecies?.includes(
              lookupValues.animalTypeFeedAnimalSystem[animalType]
            ) ||
            feed.targetSpecies?.includes(
              formatTargetSpecies(lookupValues.animalTypeFeedAnimalSystem[animalType])
            )
          )
            ?.sort(masterDataSorter)
        );
      },
      (error) => {
        console.log("Error fetching compound feeds: ", error);
      }
    );
  };

  async function loadVersionOneMasterData() {
    API.graphql({
      query: getMasterData,
      variables: {
        customerId: customerID,
        animalType, // animalTypeToEnum(animalType),
      },
    }).then(
      (response) => {
        if (response.data?.getMasterData?.single_ingredient_uses)
          setSingleIngredients(
            response.data?.getMasterData.single_ingredient_uses.sort(
              masterDataSorter
            )
          );
        if (response.data?.getMasterData?.manure_management_system)
          setManureManagementSystems(
            response.data.getMasterData.manure_management_system.sort(
              masterDataSorter
            )
          );
        // setDsmFeedsVisible(response.data?.getCustomer?.feedsVisible || false);
        if (animalType === "SALMON") {
          if (response.data?.getMasterData?.enumerations) {
            const enumsObject = {};
            response.data.getMasterData.enumerations.forEach((item) => {
              enumsObject[item.enumName] = [...item.enumValues];
            });
            setMasterDataEnums(enumsObject);
          }
          if (response.data?.getCompoundFeedList?.items) {
            // needs to be filtered for animalType
            const result = response.data.getCompoundFeedList.items;
            const availableCompoundFeeds = result
              .filter(
                (item) =>
                  item.target_species.includes("Salmon system") ||
                  item.target_species.includes("Marine fish system") ||
                  item.target_species.includes(
                    "All animal production system types"
                  )
              )
              .sort(masterDataSorter);
            setCompoundFeeds(availableCompoundFeeds);
          }
        }
      },
      (reason) => {
        console.log("Error fetching master data: ", reason);
      }
    );
  }

  const filterFacilitiesByAnimalType = (facilitiesToFilter) =>
    facilitiesToFilter.filter((facility) =>
      facility.targetSpecies.find((species) =>
        isFacilitySuitableForAnimalType(species, animalType)
      )
    );

  const loadFacilities = () => {
    API.graphql({
      query: listFacilitiesBrief,
      variables: {
        customerId: customerID,
      },
    }).then(
      (response) => {
        const fetchedFacilities = response?.data?.listFacilities?.items;
        if (!fetchedFacilities || !fetchedFacilities.length) return;

        const filteredFacilities =
          filterFacilitiesByAnimalType(fetchedFacilities);
        setFacilities(filteredFacilities);
      },
      (reason) => {
        console.log("err, ", reason);
      }
    );
  };

  async function loadShrimpMasterDataEnums() {
    const vars = {
      customerId: customerID,
      animalType,
    };

    API.graphql({
      query: getMasterData,
      variables: vars,
    }).then(
      (response) => {
        if (isShrimpFarm) {
          if (response.data?.getMasterData?.enumerations) {
            const enumsObject = {};
            response.data.getMasterData.enumerations.forEach((item) => {
              enumsObject[item.enumName] = [...item.enumValues];
            });
            setMasterDataEnums(enumsObject);
          }
        }
      },
      (reason) => {
        console.log("Error fetching master data enums: ", reason);
      }
    );
  }

  useEffect(() => {
    if (
      animalType !== AnimalType.Dairy &&
      !isReadOnlyAnimalSystem(animalType)
    ) {
      if (animalType === AnimalType.DairyV2) {
        loadDairyV2CompoundFeeds();
      } else {
        loadCompoundFeeds();
      }
      loadMasterData();
    } else if (
      !isReadOnlyAnimalSystem(animalType) &&
      animalType === AnimalType.Dairy
    ) {
      loadDairyMasterData();
    } else if (isReadOnlyAnimalSystem(animalType)) {
      loadVersionOneMasterData();
    }
    if (isShrimp(animalType)) {
      loadShrimpMasterDataEnums();
    }
    if (isPoultryFarm || isShrimpFarm || isBeefFarm || isDairyFarm || isPigFarm)
      loadFarms();
    loadFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createDraftKey = () => {
    let key = null;
    // console.log("keyParts: ", datasetID, formType);
    if (datasetID && (formType === "edit" || formType === "add")) {
      key = `BASELINE_${processID}_${datasetID}`;
    } else if (formType === "add" && duplicateDatasetID) {
      key = `BASELINE__NEW_${processID}_${duplicateDatasetID}`;
    }
    return key;
  };

  const handleTypeChange = (event) => {
    setDuplicateBaseline(event.target.value);
  };

  const saveSuccessHandler = () => {
    setNotification({
      message: intl.formatMessage({ id: "BASELINE.SAVE.SUCCESS" }),
      type: NotificationDialogType.INFO,
    });
  };

  const saveFailedHandler = (errorMessage) => {
    setNotification({
      message: errorMessage,
      type: NotificationDialogType.ERROR,
    });
  };

  const switchToEdit = () => {
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${processID}/nds/${datasetID}`,
      {
        farmName: selectedFarmName,
        processName,
        animalType,
        formType: "edit",
      }
    );
  };
  // Dialogs suggesting adding facility for cutting if only slaughterhouse exist
  const [showAddCuttingFacility, setShowAddCuttingFacility] = useState(false);
  const [showChoseCuttingFacility, setShowChoseCuttingFacility] =
    useState(false);
  const [selectedAdditionalFacilityId, setSelectedAdditionalFacilityId] =
    useState(null);
  const [addSecondFacility, setAddSecondFacility] = useState(true);

  const processingSlaughteringOnly = (submitData) =>
    submitData?.stages?.some((s) => s.facilityType === "SLAUGHTERING") &&
    submitData?.stages?.every((s) => s.facilityType !== "CUTTING");

  const hideDialogs = () => {
    setShowAddCuttingFacility(false);
    setAddSecondFacility(false);
  };

  const updateOrDuplicateBaseline = () => {
    if (duplicateBaseline === "Yes") {
      formType = FormType.Add;
      const dublicateBaseline = submittedData;
      dublicateBaseline.info.name = `${submittedData.info.name} - Copy`;
      saveBaselineData(dublicateBaseline);
    } else {
      saveBaselineData(submittedData);
    }
  };

  const saveBaselineData = (submitData) => {
    setOpenSaveChangesDialog(false);
    //check feeds validation
    if (submitData.info?.databaseFoundation && submitData.stages.length > 0) {
      submitData.stages.map((stage, i) => {
        if (
          stage.stageData?.feed?.compoundFeeds &&
          stage.stageData?.feed?.compoundFeeds?.length > 0
        ) {
          const compoundFeeds = stage.stageData.feed.compoundFeeds;
          const compoundFeedsErrors = compoundFeeds.filter(
            (item) => !item.feedType && item.kgPerAnimal
          );
          if (compoundFeedsErrors.length > 0) {
            setFeedErrors({
              [`stages${i}.stageData.feed`]: {
                message: intl.formatMessage({
                  id: "COMPOUND_FEED.REQUIRED",
                }),
              },
            });
            return false;
          }
        }
      });
    }
    submitData.info.validFrom = convertDateToAWS(
      new Date(submitData.info.validFrom)
    );
    submitData.info.validTo = convertDateToAWS(
      new Date(submitData.info.validTo)
    );

    submitData.stages?.forEach((item) => {
      if (!isShrimpFarm && item.stageData?.input?.startDate) {
        item.stageData.input.startDate = convertDateToAWS(
          new Date(item.stageData.input.startDate)
        );
      }
      if (!isShrimpFarm && item.stageData?.input?.endDate) {
        item.stageData.input.endDate = convertDateToAWS(
          new Date(item.stageData.input.endDate)
        );
      }
    });

    const payloadObj = mapProductionDataOutSustell(submitData, animalType);

    saveBaseline(
      formType,
      customerID,
      farmID,
      processID,
      payloadObj,
      animalType,
      saveSuccessHandler,
      saveFailedHandler,
      formSubmissionEvent
    );
  };

  const hasStageAsInternalSource = (stage, currentStage) => {
    if (stage?.id !== currentStage?.id) {
      if (stage?.type !== StageType.Processing) {
        const internalSources = stage?.stageData?.input?.internalSources;
        if (currentStage?.type === StageType.Breeding && currentStage?.stageData?.output?.pigsToStage > 0) {
          const originStageId = stage?.stageData?.processingInput?.originStageId;
          return originStageId === currentStage.id;
        }
        return internalSources?.some(
          (sources) => sources?.originStageId === currentStage.id
        );
      } else {
        const originStageId = stage?.stageData?.processingInput?.originStageId;
        return originStageId === currentStage.id;
      }
    }

    return false;
  };

  const verifyOutputUsage = (stages, currentStage) => {
    const isStageUsedAsInternalSource = stages?.some((otherStage) =>
      hasStageAsInternalSource(otherStage, currentStage)
    );
    if (!isStageUsedAsInternalSource) {
      setHasWarningDialogOpened(true);
      showWarningDialog();

      return false;
    }

    return true;
  };

  const verifyWarningDisplay = (stages) => {
    let proceedSaving = true;

    if (stages) {
      for (const stage of stages) {
        if (
          stage?.type === StageType.Breeding ||
          stage?.type === StageType.DairyCow ||
          stage?.type === StageType.Fattening
        ) {
          if (
            stage?.stageData?.output?.cowsToAnotherStage > 0 ||
            stage?.stageData?.output?.weanedCalvesToAnotherStage > 0 ||
            stage?.stageData?.output?.pigsToStage > 0 ||
            stage?.stageData?.output?.pigletsToStage > 0
          ) {
            proceedSaving = verifyOutputUsage(stages, stage);
            if (!proceedSaving) {
              setWarningStageName(stage?.name);
              break;
            }
          }
        }
        if (
          stage?.type === StageType.Growing ||
          stage?.type === StageType.ContinuousGrowing
        ) {
          if (stage?.stageData?.output?.cowsToAnotherStage > 0) {
            proceedSaving = verifyOutputUsage(stages, stage);
            if (!proceedSaving) {
              setWarningStageName(stage?.name);
              break;
            }
          }
        }
      }
    }

    return proceedSaving;
  };

  const onSubmit = (submitData) => {
    try {
      hideDialogs();
      if (processingSlaughteringOnly(submitData) && addSecondFacility) {
        setShowAddCuttingFacility(true);
        return;
      }

      let proceedSaving = true;

      if ((isBeefFarm || isDairyFarm || isPigFarm) && !hasWarningDialogOpened) {
        const stages = submitData?.stages;
        proceedSaving = verifyWarningDisplay(stages);
      }

      if (proceedSaving) {
        if (formType === FormType.Edit) {
          setSubmittedData(submitData);
          setOpenSaveChangesDialog(true);
        } else {
          saveBaselineData(submitData);
        }
      }
    } catch (err) {
      console.error(`Error sumbiting data: ${err?.message}`);
    }
  };

  const onErrors = () => {
    const formErrors = Object.keys(methods.formState.errors).length;
    console.error("Form validation error: ", methods.formState.errors);
    formErrorsEvent(
      formType === "add" ? "Add Baseline" : "Edit Baseline",
      formType,
      formErrors
    );
  };

  const methods = useForm({
    context: { animalContext: animalType || "" }, // must be passed as parameter, or available in component
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: (data, context) => {
      const { animalContext } = context;
      validationSchema.current = getValidationSchemaSustell({
        animalContext,
        intl,
        minValueOutput,
        ...tempRange,
        userUOM,
      });
      return yupResolver(validationSchema.current)(data);
    },
    shouldUnregister: false,
  });

  const closeNotificationDialog = (stayOnPage) => {
    setNotification(null);
    if (!stayOnPage) {
      // Making a delay for potential cold starts
      // to reduce the situations where the status not updates when the redirection not appears
      setTimeout(() => {
        methods.reset(methods.getValues());
        const key = createDraftKey();
        dispatch(clearBaselineDraftAction({ key }));
        history.push(`/customers/${customerID}/farm/${farmID}/pp/${processID}`);
      }, 700);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "stages", // unique name for your Field Array
    keyName: "keyId", // useField would mess with our id field if not adjusted to use other field
  });

  useEffect(() => {
    if (fields.length) {
      setFirstStageType(fields[0]?.type);
    } else setFirstStageType("");
  }, [fields]);

  const initializeStageTypeCounters = () => {
    const counters = {
      [StageType.Breeding]: 0,
      [StageType.Fattening]: 0,
      [StageType.Growing]: 0,
      [StageType.Hatching]: 0,
      [StageType.Laying]: 0,
      [StageType.Processing]: 0,
      [StageType.DairyCow]: 1,
      [StageType.ContinuousGrowing]: 0,
      [StageType.Nursery]: 0,
    };

    return counters;
  };

  const [stageTypeCounters, setStageTypeCounters] = useState(
    initializeStageTypeCounters()
  );

  const getFacilityType = (facilityId) =>
    facilities.find((f) => f.id === facilityId)?.type;

  const draftKey = createDraftKey();
  const discardDraftFlag = useRef(false);

  const saveDraft = (key) => {
    const formValues = methods.getValues();
    if (key) {
      const payloadObj = mapProductionDataOutSustell(
        formValues,
        animalType,
        true
      ); // last parameter is isDraft - to skip adding default values
      dispatch(setBaselineDraftAction({ key, newBaselineValue: payloadObj }));
    }
  };

  React.useEffect(() => {
    if (
      Object.keys(methods.formState.touched).length > 0 &&
      !discardDraftFlag.current
    ) {
      saveDraft(draftKey);
    }
    if (discardDraftFlag.current) discardDraftFlag.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  const setBreadCrumbs = (selBaseline) => {
    // need to set id for
    subheader?.setBreadcrumbs([
      {
        pathname: `/customers/${customerID}/myfarms`,
        title: intl.formatMessage({ id: "SUSTELL.MY_FARMS" }),
        state: { animalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}`,
        title: selectedFarmName,
        state: { animalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}`,
        title: processName,
        state: { animalType },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}/nds/${selBaseline?.id ? selBaseline.id : "NEW"
          }`,
        title:
          selBaseline?.info?.name ||
          intl.formatMessage({ id: "GENERAL.NEW.BASELINE" }),
        state: { animalType },
      },
    ]);
  };

  const setFarmData = (formData) => {
    if (formData && formData.name) {
      setSelectedFarmName(formData.name);
    }
    if (formData && formData.benchmark) {
      setBenchMark(formData.benchmark);
    }
  };

  const setBaselineData = (fetchedBaseline) => {
    let selBaseline = {};
    try {
      selBaseline = mapProductionDataInSustell(fetchedBaseline, animalType);
    } catch (err) {
      console.error("Error while mapping productionDataInSustell: ", err);
    }
    if (duplicateDatasetID) {
      // Remove oldName and ID (to call create instead update), set new name originalName" - COPY"
      // Generate new ID's for loaded stages, clear impact summary also
      selBaseline.id = undefined;
      selBaseline.info.oldName = undefined;
      selBaseline.impactSummary = undefined;
      selBaseline.info.name += " - COPY";
      selBaseline.stages.forEach((stage) => {
        const currStage = stage;
        const oldStageId = String(stage.id); // to really copy the old string
        currStage.id = uuidv4();

        const referencedProcessingStage = selBaseline.stages.find(
          (stageItem) =>
            stageItem.stageData?.processingInput?.originStageId === oldStageId
        );
        if (referencedProcessingStage) {
          referencedProcessingStage.stageData.processingInput.originStageId =
            currStage.id;
        }

        // Same goes for internal sources - coming from stage
        const referencedInternalSourcesStages = selBaseline.stages.filter(
          (stageItem) => {
            if (
              stageItem.type === StageType.Hatching ||
              stageItem.type === StageType.Laying
            ) {
              return (
                stageItem.stageData?.input?.internalSource?.originStageId ===
                oldStageId
              );
            }
            if (
              stageItem.type === StageType.Breeding &&
              animalType !== AnimalType.Pig
            ) {
              return (
                stageItem.stageData?.input?.henInternalSource?.originStageId ===
                oldStageId ||
                stageItem.stageData?.input?.roosterInternalSource
                  ?.originStageId === oldStageId
              );
            }
            return (
              stageItem.stageData?.input?.internalSources?.findIndex(
                (is) => is?.originStageId === oldStageId
              ) > -1
            );
          }
        );

        (referencedInternalSourcesStages || []).forEach((stageItem) => {
          if (
            stageItem.type === StageType.Hatching ||
            stageItem.type === StageType.Laying
          ) {
            const currentStageInternal =
              stageItem.stageData?.input?.internalSource;
            if (currentStageInternal?.originStageId === stage.id) {
              currentStageInternal.originStageId = currStage.id;
            }
          } else if (
            stageItem.type === StageType.Breeding &&
            animalType !== AnimalType.Pig
          ) {
            const currentStageInternalForHen =
              stageItem.stageData?.input?.henInternalSource;
            if (currentStageInternalForHen?.originStageId === stage.id) {
              currentStageInternalForHen.originStageId = currStage.id;
            }
            const currentStageInternalForRooster =
              stageItem.stageData?.input?.roosterInternalSource;
            if (currentStageInternalForRooster?.originStageId === stage.id) {
              currentStageInternalForRooster.originStageId = currStage.id;
            }
          } else {
            (stageItem.stageData?.input?.internalSources || []).forEach(
              (internalSource) => {
                if (internalSource?.originStageId === stage.id) {
                  // eslint-disable-next-line no-param-reassign
                  internalSource.originStageId = currStage.id;
                }
              }
            );
          }
        });

        // find stages that uses old stage as input
        const otherStagesWithIncomingsFromMyStage = selBaseline.stages.filter(
          (item) => {
            if (
              item.type === StageType.Hatching ||
              item.type === StageType.Laying
            ) {
              return (
                item.stageData?.input?.internalSource?.originStageId ===
                oldStageId
              );
            }
            if (
              item.type === StageType.Breeding &&
              animalType !== AnimalType.Pig
            ) {
              return (
                item.stageData?.input?.henInternalSource?.originStageId ===
                oldStageId ||
                item.stageData?.input?.roosterInternalSource?.originStageId ===
                oldStageId
              );
            }
            return item.stageData?.input?.internalSources?.some(
              (oneSource) => oneSource.originStageId === oldStageId
            );
          }
        );
        otherStagesWithIncomingsFromMyStage.forEach((otherStage) => {
          if (
            otherStage.type === StageType.Hatching ||
            otherStage.type === StageType.Laying
          ) {
            const currentStageInternal =
              otherStage.stageData?.input?.internalSource;
            if (currentStageInternal?.originStageId === oldStageId) {
              currentStageInternal.originStageId = currStage.id;
            }
          } else if (
            otherStage.type === StageType.Breeding &&
            animalType !== AnimalType.Pig
          ) {
            const currentStageInternalForHen =
              otherStage.stageData?.input?.henInternalSource;
            if (currentStageInternalForHen?.originStageId === oldStageId) {
              currentStageInternalForHen.originStageId = currStage.id;
            }
            const currentStageInternalForRooster =
              otherStage.stageData?.input?.roosterInternalSource;
            if (currentStageInternalForRooster?.originStageId === oldStageId) {
              currentStageInternalForRooster.originStageId = currStage.id;
            }
          } else {
            const currentStageInternals =
              otherStage.stageData?.input?.internalSources || [];
            currentStageInternals.forEach((incomingInput) => {
              const currentInputElement = incomingInput;
              if (currentInputElement.originStageId === oldStageId)
                currentInputElement.originStageId = currStage.id;
            });
          }
        });
      });
    }
    setBaseline(selBaseline);
    setBreadCrumbs(selBaseline);
  };

  const restoreDraft = () => {
    try {
      if (draftKey) {
        const draft = getBaselineDraftByKey(draftKey);
        if (!draft) {
          setOpenDraftDialog(false);
          return;
        }
        const selBaseline = mapProductionDataInSustell(draft, animalType, true); // last parametar set to true skips loading default values fro animal type when draft is loaded
        setOpenDraftDialog(false);
        setBaseline(selBaseline);
        setBreadCrumbs(selBaseline);
      }
    } catch (err) {
      setRestoreDraftErrorMessage(
        `${intl.formatMessage({ id: "SUSTELL.ERROR.RESTORING_DRAFT" })}${err?.message
        }`
      );
      console.error(err);
    }
  };

  useEffect(() => {
    // Set facility type on processing stages after baseline and facilities are loaded
    if (baseline && baseline.stages && facilities) {
      baseline.stages.forEach((stage) => {
        if (isProcessingStage(stage?.type)) {
          // eslint-disable-next-line no-param-reassign
          stage.facilityType = getFacilityType(stage.facilityId);
        }
      });
    }

    methods.reset(baseline);
  }, [baseline, facilities]);

  const showStageForm = (stageData, index) => {
    if (stageData) {
      setStageFormType(FormType.Edit);
      setSelectedStage({
        stageIndex: index,
        id: stageData.id,
        name: stageData.name,
        type: stageData.type,
        facilityType: stageData.facilityType,
        facilityId: stageData.facilityId,
      });
    } else {
      setStageFormType(FormType.Add);
      setSelectedStage({
        id: uuidv4(),
        stageIndex: index,
        name: "",
        type: isShrimp(animalType) ? firstStageType || StageType.Hatching : "",
        facilityType: "",
        facilityId: "",
      });
    }
    setStageFormOpen(true);
  };

  const [newFacilityInfo, setNewFacilityInfo] = useState();

  useEffect(() => {
    // Show stage form with loaded data from new facility creation
    if (facilities.length > 0 && newFacilityInfo && fields.length > 0) {
      const stageIndexNext = fields?.length || 0;
      showStageForm(
        {
          stageIndex: stageIndexNext,
          id: newFacilityInfo.id,
          name: newFacilityInfo.name,
          type: "PROCESSING",
          facilityType: getFacilityType(newFacilityInfo.facilityId),
          facilityId: newFacilityInfo.facilityId,
        },
        stageIndexNext
      );
      setNewFacilityInfo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFacilityInfo, facilities]);

  useEffect(() => {
    const existingDraft = getBaselineDraftByKey(draftKey);
    if (farmID) {
      getFarmBaseData(farmID, setFarmData, (error) => console.log(error));
    }
    if (existingDraft) {
      // If just redirected from facility creation page, auto-restore the draft
      // eslint-disable-next-line react/destructuring-assignment
      if (props?.location?.state?.autoRestoreDraft) {
        restoreDraft();
        const newFacility = props?.location?.state?.newFacilityInfo;
        if (newFacility) {
          const stageIndexNext = fields?.length || 0;
          const newStageData = {
            stageIndex: stageIndexNext,
            id: uuidv4(),
            name: `${newFacility.name} - #${stageIndexNext}`,
            type: newFacility.type,
            facilityId: newFacility.id,
          };
          setNewFacilityInfo(newStageData);
        }
        const step = props?.location?.state?.currentStep || 4;
        setActiveStep(step);
      } else {
        // opens restore drafts dialog, depending on result it will  load data from draft storage or skip it
        setOpenDraftDialog(true);
      }
    } else if (farmID && datasetID && datasetID !== "NEW") {
      fetchPPDatasetV2(farmID, datasetID, setBaselineData, (error) =>
        console.log(error)
      );
    } else if (farmID && datasetID === "NEW" && !duplicateDatasetID) {
      setBreadCrumbs(undefined);
    } else if (farmID && datasetID === "NEW" && duplicateDatasetID) {
      fetchPPDatasetV2(farmID, duplicateDatasetID, setBaselineData, (error) =>
        console.log(error)
      );
      // setBreadCrumbs(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetID, processID]);

  // if formType changed from view to edit, show dialog if draft exits
  useEffect(() => {
    if (formType !== "view") {
      const existingDraft = getBaselineDraftByKey(createDraftKey());
      if (existingDraft) {
        // If not redirectred from facility page open draft dialog
        // eslint-disable-next-line react/destructuring-assignment
        if (!props?.location?.state?.autoRestoreDraft) {
          setOpenDraftDialog(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  const calculateCompleteness = (validationFunction, prefix) => {
    try {
      validationFunction({ intl, userUOM }).validateSyncAt(
        "stages",
        methods.getValues(),
        { abortEarly: false }
      );
      return 100;
    } catch (e) {
      const errorMessages = e?.inner?.map((err) => err.path);
      if (errorMessages?.some((str) => str.includes(prefix))) return 0;
      return 100;
    }
  };

  // excludeFromPath should ignore fields in this path for calculating completeness (used for 3NOP)
  const getCompleteness = (
    getCurrentValues,
    fieldGroup,
    onlyLiveweight = false
  ) => {
    // target object will be read from defaultValues
    let groupData = getCurrentValues(fieldGroup);

    let targetObject = defaultValues[animalType]
      ? defaultValues[animalType][fieldGroup]
      : {};

    if (fieldGroup.indexOf("stageData") !== -1) {
      const groupPath = fieldGroup.substring(
        fieldGroup.indexOf("stageData") + 10
      );
      targetObject =
        defaultValues[animalType]?.stages[0]?.stageData[groupPath] || {};
      groupData = { ...groupData };
      targetObject = { ...targetObject };

      // special exception for DAIRY if production system is not for Dairy cows
      if (animalType === "DAIRY" && groupPath === "output" && onlyLiveweight) {
        targetObject = { liveWeight: "" };
      }
      // If 3NOP is unchecked remove all 3nop fields from counting
      else if (
        animalType === "DAIRY" &&
        groupPath === "feed" &&
        groupData &&
        !groupData.is3NOPSelected
      ) {
        delete groupData.supplement3NOP;
        delete groupData.is3NOPSelected;
        delete targetObject.supplement3NOP;
        delete targetObject.is3NOPSelected;
      }

      // if compoundFeeds or singleIngredients is not set -> remove it from object (total feed intake will be blank if not selected)
      // if there is any empty compoundFeeds or singleIngredients field, remove it
      if (groupPath === "feed" && groupData?.compoundFeeds?.length === 0) {
        delete groupData.compoundFeeds;
        delete targetObject.compoundFeeds;
      } else if (groupPath === "feed") {
        const filteredCompoundFeeds = groupData?.compoundFeeds?.filter(
          (compoundFeed) =>
            !(compoundFeed.feedType === "" && compoundFeed.kgPerAnimal === "")
        );
        groupData.compoundFeeds = filteredCompoundFeeds;
        targetObject.compoundFeeds = filteredCompoundFeeds;
      }

      if (groupPath === "feed" && groupData?.singleIngredients?.length === 0) {
        delete groupData.singleIngredients;
        delete targetObject.singleIngredients;
      } else if (groupPath === "feed") {
        const filteredSingleIngredients = groupData?.singleIngredients?.filter(
          (singleIngredient) =>
            !(
              singleIngredient.feedType === "" &&
              singleIngredient.kgPerAnimal === ""
            )
        );
        groupData.singleIngredients = filteredSingleIngredients;
        targetObject.singleIngredients = filteredSingleIngredients;
      }
    }
    if (
      typeof groupData === "object" &&
      Object.keys(groupData).length > 0 &&
      typeof targetObject === "object" &&
      Object.keys(targetObject).length > 0
    ) {
      const keys = Object.keys(targetObject);
      let totalFields = keys.length;
      let completed = 0;
      keys.forEach((key) => {
        // count filled in the lists - dynamic items
        if (Array.isArray(targetObject[key]) && targetObject[key].length > 0) {
          // decrease number of total fields, as it will be fixed with exact numebr of subfields
          totalFields -= 1;
          const defaultCount = Object.keys(targetObject[key][0]).length;
          // if any data for the list entered, count it
          if (groupData[key] && groupData[key].length > 0) {
            groupData[key].forEach((item) => {
              // for each list item, inceraase number of total fields
              totalFields += defaultCount;
              // count filled fields
              const subkeys = Object.keys(item);
              subkeys.forEach((subkey) => {
                if (item[subkey] || item[subkey] === 0) completed += 1;
              });
            });
          }
          // non of items is inserted, just increase number of total fields
          else totalFields += defaultCount;
        }
        // if nested object, for now one level supported
        else if (
          typeof targetObject[key] === "object" &&
          Object.keys(targetObject[key]).length > 0 &&
          typeof groupData[key] === "object" &&
          Object.keys(groupData[key]).length > 0
        ) {
          // decrease number of total fields, as it will be fixed with exact numebr of subfields
          totalFields -= 1;
          Object.keys(targetObject[key]).forEach((subkey) => {
            totalFields += 1;
            if (groupData[key][subkey] || groupData[key][subkey] === 0)
              completed += 1;
          });
        }
        // single value
        else if (groupData[key] || groupData[key] === 0) completed += 1;
      });
      return Math.round((completed / totalFields) * 100);
    }
    return 0;
  };

  const validateStageCreation = (type, facilityType) => {
    // Check only if add mode and on last step
    // not sure why we had this validation, its not doing belove validation in the edit mode
    // if (formType !== FormType.Add || activeStep !== 5 || type !== 'PROCESSING') {
    //   return true;
    // }

    // skip if not processing stage
    if (type !== "PROCESSING") {
      return true;
    }

    if (
      (facilityType === FacilityType.Slaughtering ||
        facilityType === FacilityType.Cutting ||
        facilityType === FacilityType.SlaughteringAndCutting) &&
      (fields.length === 0 ||
        fields.every((stage) => stage.type === "PROCESSING"))
    ) {
      setNotification({
        message: intl.formatMessage({
          id: "SUSTELL.STAGES.PROCESSING.ERROR.ADD_ANIMAL_STAGE",
        }),
        type: NotificationDialogType.HELP_CENTER,
      });
      return false;
    }

    if (
      facilityType === FacilityType.Cutting &&
      !fields.some(
        (stageItem) => stageItem.facilityType === FacilityType.Slaughtering
      )
    ) {
      setNotification({
        message: intl.formatMessage({
          id: "SUSTELL.STAGES.PROCESSING.ERROR.ADD_SL_STAGE",
        }),
        type: NotificationDialogType.HELP_CENTER,
      });
      return false;
    }

    return true;
  };

  const saveStage = (data, nestedFormHandler) => {
    if (!validateStageCreation(data?.type, data?.facilityType)) {
      return;
    }

    const currentStages = fields;
    const editedIndex = currentStages.findIndex((item) => item.id === data.id);
    const sameNamedItem = currentStages.findIndex(
      (item) => item.name?.trim() === data.name?.trim()
    );
    if (sameNamedItem > -1 && editedIndex !== sameNamedItem) {
      nestedFormHandler.setError("name", {
        message: intl.formatMessage({ id: "SUSTELL.STAGE.NAME.UNIQUE" }),
      });
      return false;
    }

    const { type } = data;

    if (editedIndex > -1) {
      // changing existing stage
      const currStage = fields[editedIndex];
      if (data.id === "") currStage.id = uuidv4();
      currStage.name = data.name?.trim();
      currStage.type = type;
      // setValue needed to correctly set data before saving
      methods.setValue(`stages.${editedIndex}.id`, currStage.id, {
        shouldDirty: true,
      }); // stage added by default on empty dataset will not have id
      methods.setValue(`stages.${editedIndex}.name`, currStage.name, {
        shouldDirty: true,
      });
      methods.setValue(`stages.${editedIndex}.type`, type, {
        shouldDirty: true,
      });

      // if processing stage, add additional fileds
      if (isProcessingStage(type)) {
        methods.setValue(
          `stages.${editedIndex}.facilityType`,
          currStage.facilityType,
          {
            shouldDirty: true,
          }
        );
        methods.setValue(
          `stages.${editedIndex}.facilityId`,
          currStage.facilityId,
          {
            shouldDirty: true,
          }
        );
      }
    } else {
      // adding new stage
      const newStage = {
        id: uuidv4(),
        name: data.name?.trim(),
        type,
        // animalProductivity:'HIGH',
        newStage: true,
        stageData: newStageData, // avoid shallow copying nested objects/arrays
        pondName: data.pondName,
      };

      if (isProcessingStage(type)) {
        newStage.facilityType = data.facilityType;
        newStage.facilityId = data.facilityId;
      }

      append(newStage);
      const formValues = methods.getValues();
      // Added this code because append is creating duplicate stages some times
      const addedStages = formValues.stages.filter(
        (stage) =>
          stage.name === newStage.name &&
          stage.type === newStage.type &&
          stage.id === newStage.id
      );
      if (addedStages && addedStages.length > 1) {
        methods.setValue(
          "stages",
          formValues.stages.filter((stage) => stage.stageData)
        );
      }
    }

    setStageFormOpen(false);
    saveDraft(draftKey);

    return true;
  };

  const onDeleteStageClick = (index, itemName, type) => {
    // check if the stage is being use as input in some other stage
    const formData = methods.getValues();

    const stageId = formData?.stages?.[index]?.id;

    const usedInStage = formData?.stages?.find((stage) => {
      // check is it used as input in processing stage, or internal input in some other stage
      // other UI logic should controle where each stage can be used as input
      const usedInProcessing =
        stage?.stageData?.processingInput?.originStageId === stageId;
      const usedInRegular = stage?.stageData?.input?.internalSources?.some(
        (source) => source.originStageId === stageId
      );
      return usedInProcessing || usedInRegular;
    });

    // if stage is being used in some other stage, prevet deletion
    if (usedInStage)
      setNotification({
        message: intl.formatMessage(
          { id: "SUSTELL.STAGES.DELETE_LINKED_ERROR" },
          { linkedStage: usedInStage.name, br: <br /> }
        ),
        type: NotificationDialogType.ERROR,
      });
    else setSelectedStageToDelete({ index, itemName, type });
  };

  const removeStageTypeCounters = (type) => {
    const countersCopy = { ...stageTypeCounters };
    countersCopy[type] -= 1;
    setStageTypeCounters(countersCopy);
  };

  const deleteStage = () => {
    if (selectedStageToDelete && selectedStageToDelete.index > -1) {
      removeStageTypeCounters(selectedStageToDelete.type);
      remove(selectedStageToDelete.index);
      setSelectedStageToDelete(null);
      saveDraft(draftKey);
      restoreDraft();
    }
  };

  const backwardStep = () => {
    setActiveStep(activeStep - 1);
  };

  const forwardStep = (completed) => {
    if (completed) {
      setCompletedSteps((prevCompletedSteps) => {
        if (!prevCompletedSteps.includes(`${activeStep}`)) {
          return [...prevCompletedSteps, `${activeStep}`];
        }
        return prevCompletedSteps;
      });
    }
    setActiveStep(activeStep + 1);
  };

  const closeStageForm = (completeAndContinue = false) => {
    setStageFormOpen(false);
    if (completeAndContinue) forwardStep(true);
  };

  const handleCancel = () => {
    history.push(`/customers/${customerID}/farm/${farmID}`, {
      farmName: selectedFarmName,
    });

    // TODO - To lucas check which one is correct

    // if (formType === FormType.Add)
    //   history.push(`/customers/${customerID}/farm/${farmID}/pp`, {
    //     farmName,
    //   });
    // if (formType === FormType.Edit) {
    //   history.push(`/customers/${customerID}/farm/${farmID}/pp/${processID}`);
    // }
  };

  const closeDraftDialog = () => {
    const key = createDraftKey();
    dispatch(clearBaselineDraftAction({ key }));

    setOpenDraftDialog(false);
    setRestoreDraftErrorMessage("");
    if (farmID) {
      getFarmBaseData(farmID, setFarmData, (error) => console.log(error));
    }
    if (farmID && datasetID && datasetID !== "NEW") {
      fetchPPDatasetV2(farmID, datasetID, setBaselineData, (error) =>
        console.log(error)
      );
    } else if (farmID && datasetID === "NEW" && !duplicateDatasetID) {
      setBreadCrumbs(undefined);
    } else if (farmID && datasetID === "NEW" && duplicateDatasetID) {
      fetchPPDatasetV2(farmID, duplicateDatasetID, setBaselineData, (error) =>
        console.log(error)
      );
      // setBreadCrumbs(undefined);
    }
  };

  const stepClicked = (id) => {
    setActiveStep(Number(id));
  };

  const cancelInlineEditHandler = () => {
    console.log(methods.getValues("stages"));
    if (inlineStageEditorOpened !== -1) {
      setInlineStageEditorOpened(-1);
    }
  };

  const saveInlineEditHandler = () => {
    if (inlineStageEditorOpened !== -1) {
      const newName = methods.getValues(
        `stages[${inlineStageEditorOpened}].tempName`
      );

      if (newName) {
        methods.setValue(`stages[${inlineStageEditorOpened}].name`, newName);
        fields[inlineStageEditorOpened].name = newName;
      }

      const newFacilityId = methods.getValues(
        `stages[${inlineStageEditorOpened}].tempFacilityId`
      );

      if (newFacilityId) {
        methods.setValue(
          `stages[${inlineStageEditorOpened}].facilityId`,
          newFacilityId
        );
        fields[inlineStageEditorOpened].facilityId = newFacilityId;
      }

      const newPondName = methods.getValues(
        `stages[${inlineStageEditorOpened}].tempPondName`
      );

      if (newPondName) {
        methods.setValue(
          `stages[${inlineStageEditorOpened}].pondName`,
          newPondName
        );
        fields[inlineStageEditorOpened].pondName = newPondName;
      }

      setInlineStageEditorOpened(-1);
    }
  };

  const navigateToAddNewFacility = (facilityType = undefined) => {
    const datasetParams = {
      farmID,
      customerID,
      processID,
      datasetID,
    };

    const state = {
      datasetParams,
      formType: FormType.Add,
      animalType,
      currentStep: activeStep,
    };

    if (facilityType) {
      state.facilityType = facilityType;
    }

    saveDraft(draftKey);

    history.push(`/profile/customers/${customerID}/facilities/NEW`, state);
  };

  const getFacilityOptionsByType = (type, includeAddNew = true) => {
    const filteredOptions = filterFacilitiesByType(facilities, type);
    return includeAddNew
      ? mountFacilityOptions(filteredOptions, intl, includeAddNew)
      : mountFacilityOptions(filteredOptions, intl);
  };

  const handleSelectCuttingFacilitySuggestion = (event) => {
    const isNewFacility = event.target?.value === "ADD_NEW";
    if (isNewFacility) {
      saveDraft(draftKey);
      navigateToAddNewFacility(FacilityType.Cutting);
    } else {
      const facility = facilities.find((f) => f.id === e.target.value);
      setSelectedAdditionalFacilityId(facility?.id);
    }
  };

  const getTitleStringCode = (type) => {
    switch (type) {
      case FormType.Add:
        return "SUSTELL.ADD.BASELINE";
      case FormType.Edit:
        return "SUSTELL.EDIT.BASELINE";
      default:
        return "SUSTELL.BASELINE.OVERVIEW";
    }
  };

  const canShowEditButton = () => {
    return (
      !benchmark &&
      !isReadOnlyAnimalSystem(animalType) &&
      formType === FormType.View &&
      Can("update", "Baseline")
    );
  };

  const isAllowedToAddNewStage = (selectedStages) => {
    if (stageFormOpen) {
      return false;
    }

    if (!isMarineFishFarm || (selectedStages && selectedStages.length === 0)) {
      if (isShrimpFarm) {
        return firstStageType !== StageType.Hatching;
      }
      return true;
    }

    // Temporary code to disable marine fish processing since the feature isn't ready for production yet.
    // To enable marine fish processing MUST set marineFishStagesLimit to 3
    const disableMarineFishProcessing = true;

    // Marine fish can have up to 3 stages. One growing stage and up to 2 processing stages.
    // If the facility handle Slaughtering and Cutting then
    const marineFishStagesLimit = disableMarineFishProcessing ? 1 : 3;
    const numberOfSelectedStages = selectedStages.reduce((acc, stage) => {
      if (stage.type !== StageType.Processing) return acc + 1;

      const facilityType =
        stage?.facilityType ||
        facilities.find((facility) => facility?.id === stage?.facilityId)?.type;

      return isSlaugteringAndCuttingFacility(facilityType) ? acc + 2 : acc + 1;
    }, 0);

    return numberOfSelectedStages < marineFishStagesLimit;
  };

  useEffect(() => {
    if (isDairyFarm && !fields.length) {
      const newStage = {
        id: uuidv4(),
        name: "Dairy cow 01",
        type: StageType.DairyCow,
        newStage: true,
        stageData: newStageData, // avoid shallow copying nested objects/arrays
      };

      append(newStage);
      const formValues = methods.getValues();
      // Added this code because append is creating duplicate stages some times
      const addedStages = formValues.stages.filter(
        (stage) =>
          stage.name === newStage.name &&
          stage.type === newStage.type &&
          stage.id === newStage.id
      );
      if (addedStages && addedStages.length > 1) {
        methods.setValue(
          "stages",
          formValues.stages.filter((stage) => stage.stageData)
        );
      }
    }
  }, [fields]);

  const showDescription = () => setOpenDescriptionDialog(true);
  const closeDescriptionDialog = () => setOpenDescriptionDialog(false);

  const showWarningDialog = () => setOpenWarningDialog(true);
  const closeWarningDialog = () => setOpenWarningDialog(false);

  const isMandatoryFirstStage = (index) => isDairyFarm && index === 0;

  return (
    <>
      {openDescriptionDialog && !isShrimpFarm && (
        <StageSelectionDefinitionsDialog
          open
          animalType={animalType}
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      {openDescriptionDialog && isShrimpFarm && (
        <DefinitionsDialog
          open
          prefix="SUSTELL.SHRIMP.STAGE.TYPE"
          itemHeader="SUSTELL.SHRIMP.STAGE.TYPE"
          items={lookupValues.shrimpStages}
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      <WarningDialog
        isOpen={openWarningDialog}
        closeModal={closeWarningDialog}
        title={"BASELINE.WARNING.TITLE"}
        body={"BASELINE.WARNING.BODY"}
        stageName={warningStageName}
      />
      <DialogContainer
        formVisible={openSaveChangesDialog}
        handleClose={() => setOpenSaveChangesDialog(false)}
        iconCode="general/save-01"
        iconColor="#EE7203"
        variant="normal"
      >
        <Typography style={headingTitle}>
          {intl.formatMessage({
            id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.SAVE_CHANGES",
          })}
        </Typography>
        <Typography style={{ fontSize: "14px", marginBottom: "20px" }}>
          {intl.formatMessage({
            id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.SAVE_CHANGES.DESCRIPTION",
          })}
        </Typography>
        <Box display="flex" flexDirection="column">
          <RadioGroup
            value={duplicateBaseline}
            onChange={handleTypeChange}
            aria-required
          >
            {SAVE_CHANGES_OPTIONSS.map((eachType) => (
              <FormControlLabel
                value={eachType.value}
                label={eachType.label}
                key={eachType.value}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ marginTop: "12px" }}
          >
            <DsmButtonV2
              className={buttonClasses.secondaryButton}
              onClick={() => setOpenSaveChangesDialog(false)}
              style={{ width: "170px" }}
            >
              {intl.formatMessage({ id: "GENERAL.CANCEL" })}
            </DsmButtonV2>
            <DsmButtonV2
              onClick={() => updateOrDuplicateBaseline()}
              style={{ width: "170px" }}
            >
              {intl.formatMessage({ id: "GENERAL.CONFIRM" })}
            </DsmButtonV2>
          </Box>
        </Box>
      </DialogContainer>
      <DialogContainer
        formVisible={showChoseCuttingFacility}
        handleClose={() => setShowChoseCuttingFacility(false)}
        iconCode="shapes/star-06"
        formTitle={intl.formatMessage({
          id: "SUSTELL.STAGES.PROCESSING.SELECT_CUTTING",
        })}
        width="343px"
      >
        <div>
          {intl.formatMessage({
            id: "SUSTELL.STAGES.PROCESSING.SELECT_CUTTING.DESCRIPTION",
          })}
        </div>
        <br />
        <div>
          <DsmSelect
            label={intl.formatMessage({
              id: "SUSTELL.STAGES.PROCESSING.LIST_OF_CUTTING",
            })}
            placeholder={intl.formatMessage({
              id: "SUSTELL.STAGES.PROCESSING.LIST_OF_CUTTING.PLACEHOLDER",
            })}
            options={getFacilityOptionsByType(FacilityType.Cutting)}
            onDsmChange={(e) => handleSelectCuttingFacilitySuggestion(e)}
          />
        </div>
        <DsmButtonControlGroup
          cancelHandler={() => {
            setShowChoseCuttingFacility(false);
            setAddSecondFacility(false);
          }}
          saveHandler={() => {
            const facility = facilities.find(
              (f) => f.id === selectedAdditionalFacilityId
            );
            const newStageData = {
              stageIndex: fields?.length || 0,
              id: uuidv4(),
              name: facility?.name,
              facilityId: facility?.id,
              type: StageType.Processing,
            };
            append(newStageData);
            setShowChoseCuttingFacility(false);
            setSelectedAdditionalFacilityId(null);
          }}
          saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
        />
      </DialogContainer>
      <DsmModal
        open={openDraftDialog}
        header={intl.formatMessage({ id: "GENERAL.UNSAVED_CHANGES" })}
        icon="general/info-square"
      >
        <p slot="content">
          {intl.formatMessage({ id: "GENERAL.DRAFT.INFO.MESSAGE" })}
        </p>
        {restoreDraftErrorMessage && (
          <p slot="content" style={{ color: "var(--dsm-color-error)" }}>
            {restoreDraftErrorMessage}
          </p>
        )}
        <DsmButtonV2
          variant="secondary"
          slot="actions"
          onClick={closeDraftDialog}
          className={buttonClasses.secondaryButton}
        >
          {intl.formatMessage({ id: "GENERAL.DRAFT.ACTION.DISCARD" })}
        </DsmButtonV2>
        <DsmButtonV2 variant="secondary" slot="actions" onClick={restoreDraft}>
          {intl.formatMessage({ id: "GENERAL.DRAFT.ACTION.RESTORE" })}
        </DsmButtonV2>
      </DsmModal>

      <NotificationDialog
        open={!!notification}
        message={notification?.message}
        type={notification?.type}
        closeNotificationDialog={() =>
          closeNotificationDialog(
            notification?.type !== NotificationDialogType.INFO
          )
        }
      />

      <h1 style={{ color: "var(--dsm-color-primary)" }}>
        {intl.formatMessage({ id: getTitleStringCode(formType) })}
      </h1>
      <DsmGrid
        style={{
          display: "grid",
          grid: canShowEditButton() ? "auto/auto 128px" : "auto/auto",
          padding: "0",
        }}
      >
        <p style={{ width: "100%" }}>
          {intl.formatMessage({ id: "SUSTELL.BASELINE.DESCRIPTION" })}
        </p>
        {canShowEditButton() && (
          <DsmButton variant="secondary" onClick={switchToEdit} style={{ whiteSpace: "nowrap" }}>
            {intl.formatMessage({ id: "SUSTELL.EDIT.BASELINE" })}
          </DsmButton>
        )}
      </DsmGrid>
      {formType === FormType.Add && (
        <SustellFarmSteps
          active={activeStep}
          completed={completedSteps}
          onStepChange={stepClicked}
          customerID={customerID}
          farmID={farmID}
          farmType={animalType}
          processID={processID}
          datasetID={datasetID}
        />
      )}
      <DeleteConfirmationDialog
        open={!!selectedStageToDelete} // open attribute strictly expects boolean, so null was giving a warning in runtime
        confirmHandler={deleteStage}
        cancelHandler={() => setSelectedStageToDelete(null)}
        itemName={selectedStageToDelete?.itemName}
        itemType={intl.formatMessage({
          id: "SUSTELL.PRODUCTION_PROCESS.STAGE",
        })}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
          <DialogContainer
            formVisible={showAddCuttingFacility}
            handleClose={() => setShowAddCuttingFacility(false)}
            iconCode="general/info-circle"
            formTitle={intl.formatMessage({
              id: "SUSTELL.STAGES.PROCESSING.SECOND_FACILITY",
            })}
            width="343px"
          >
            <div>
              {intl.formatMessage(
                { id: "SUSTELL.STAGES.PROCESSING.SECOND_FACILITY.DESCRIPTION" },
                { br: <br /> }
              )}
            </div>
            <br />
            <div>
              <DsmCheckbox
                label={intl.formatMessage({
                  id: "SUSTELL.STAGES.PROCESSING.DONT_SHOW_MESSAGE",
                })}
              />
            </div>
            <DsmButtonControlGroup
              cancelHandler={methods.handleSubmit(onSubmit, onErrors)}
              cancelLabel={intl.formatMessage({
                id: "SUSTELL.STAGES.PROCESSING.SKIP",
              })}
              saveHandler={() => {
                setShowAddCuttingFacility(false);
                setShowChoseCuttingFacility(true);
                setAddSecondFacility(false);
              }}
              saveLabel={intl.formatMessage({
                id: "SUSTELL.STAGES.PROCESSING.ADD_FACILITY",
              })}
            />
          </DialogContainer>

          <input
            ref={methods.register()}
            type="hidden"
            name="farmId"
            value={animalType}
          />
          <input ref={methods.register()} type="hidden" name="id" />
          <input
            ref={methods.register()}
            type="hidden"
            name="productionProcessId"
          />
          <input ref={methods.register()} type="hidden" name="animalType" />
          {activeStep === 1 && (
            <BaselineProfile
              formType={formType}
              animalType={animalType}
              forwardHandler={forwardStep}
              cancelHandler={handleCancel}
            />
          )}

          {activeStep === 2 && formType === FormType.Add && (
            <>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ marginTop: "3rem" }}
              >
                {intl.formatMessage({ id: "SUSTELL.STAGE.SELECTION" })}
              </Typography>
              <Typography style={{ marginTop: "10px", width: "100%" }}>
                {intl.formatMessage({
                  id: isShrimp(animalType)
                    ? "SUSTELL.STAGE.SELECTION.SHRIMP.DESCRIPTION"
                    : "SUSTELL.STAGE.SELECTION.DESCRIPTION",
                })}
              </Typography>
              {!isMarineFishFarm && (
                <DsmButton variant="text" onClick={showDescription}>
                  <DsmIcon slot="before" name="general/eye" />
                  {intl.formatMessage({
                    id: "DATABASE_FOUNDATION_DEFINITIONS",
                  })}
                </DsmButton>
              )}
              <StagesListComponent
                stageDeletehandler={onDeleteStageClick}
                formType={formType}
                selectedStages={fields}
                facilities={facilities}
                animalType={animalType}
                isMandatoryFirstStage={isMandatoryFirstStage}
              />

              <StageComponent
                stageFormOpen={stageFormOpen}
                showStageForm={showStageForm}
                forwardStep={forwardStep}
                backwardStep={backwardStep}
                selectedStages={fields}
                isAllowedToAddNewStage={isAllowedToAddNewStage(fields)}
              />
            </>
          )}

          {(activeStep === 3 ||
            formType === FormType.View ||
            formType === FormType.Edit) && (
              <>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  style={{ marginTop: "3rem" }}
                >
                  {intl.formatMessage({ id: "SUSTELL.STAGE.DETAILS" })}
                </Typography>
                <Typography style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {intl.formatMessage(
                    {
                      id: "SUSTELL.STAGE.DETAILS.DESCRIPTION",
                    },
                    { br: <br /> }
                  )}
                </Typography>
                {methods.errors && methods.errors.stages?.type === "min" && (
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item xs={12}>
                      <Alert severity="error">
                        <AlertTitle>
                          {intl.formatMessage({
                            id: "SUSTELL.STAGE.MIN.REQUIRED",
                          })}
                        </AlertTitle>
                      </Alert>
                    </Grid>
                  </Grid>
                )}
                {fields?.map((item, index) => {
                  const { errors } = methods;
                  const generalDataError =
                    errors &&
                    errors.stages &&
                    (errors.stages[index]?.id ||
                      errors.stages[index]?.name ||
                      errors.stages[index]?.type);
                  return (
                    <React.Fragment key={item.id}>
                      {inlineStageEditorOpened !== index && (
                        <DsmGrid
                          style={{
                            grid: "auto/1fr 0.5fr",
                            padding: "0px",
                            marginBottom: "10px",
                            alignItems: "baseline",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{ display: "flex" }}
                            className={clsx(
                              classes.lightCardSubtitle,
                              generalDataError ? "error" : ""
                            )}
                          >
                            <span
                              style={{
                                color: "#1b1b1b",
                                fontWeight: 500,
                                fontSize: "20px",
                              }}
                            >
                              {item.name ||
                                intl.formatMessage({
                                  id: "SUSTELL.DEFAULT.STAGE_NAME",
                                })}{" "}
                              {item?.type &&
                                !item.facilityType
                                ? `(${capitalize(intl
                                  .formatMessage({
                                    id: `SUSTELL.ENUMS.STAGE.TYPE.${item?.type?.toUpperCase()}`,
                                    defaultMessage: item?.type,
                                  })
                                  ?.replaceAll("_", " "))})`
                                : ""}{" "}
                              {item?.facilityType
                                ? `( ${intl
                                  .formatMessage({
                                    id: `SUSTELL.STAGE.${item.facilityType}`,
                                  })} )`
                                : ""}{" "}
                              {isShrimp(animalType) &&
                                item.pondName &&
                                ` - ${item?.pondName}`}
                            </span>
                            {!benchmark &&
                              !isReadOnlyAnimalSystem(animalType) &&
                              Can("update", "Baseline") && (
                                <DsmIcon
                                  onClick={() =>
                                    setInlineStageEditorOpened(index)
                                  }
                                  slot="before"
                                  name="general/edit-01"
                                  className={classes.iconStyles}
                                  style={{
                                    color: "#0070BA",
                                  }}
                                />
                              )}
                            {!benchmark &&
                              !isReadOnlyAnimalSystem(animalType) &&
                              Can("update", "Baseline") &&
                              !isMandatoryFirstStage(index) && (
                                <DsmIcon
                                  onClick={() => {
                                    onDeleteStageClick(
                                      index,
                                      item.name,
                                      item.type
                                    );
                                  }}
                                  slot="before"
                                  name="general/trash-01"
                                  className={classes.iconStyles}
                                  style={{
                                    color: "#E51F22",
                                  }}
                                />
                              )}
                          </Typography>
                        </DsmGrid>
                      )}
                      {inlineStageEditorOpened === index && (
                        <DsmGrid
                          style={{
                            grid: "auto/2fr 1fr",
                            padding: "0px",
                            alignItems: "center",
                          }}
                        >
                          <DsmGrid
                            style={{
                              grid: "auto/1fr 1fr 1fr",
                              padding: "0px",
                              alignItems: "start",
                            }}
                          >
                            {item.facilityId && (
                              <ReactHookDsmSelect
                                name={`stages[${index}].tempFacilityId`}
                                label={intl.formatMessage({
                                  id: "SUSTELL.STAGE.FACILITY_NAME",
                                })}
                                options={getFacilityOptionsByType(
                                  item.facilityType,
                                  false
                                )}
                                defaultValue={methods.getValues(
                                  `stages[${index}].facilityId`
                                )}
                              />
                            )}
                            {!item.facilityId && (
                              <ReactHookDsmSelect
                                name={`stages[${index}].type`}
                                label={intl.formatMessage({
                                  id: "SUSTELL.STAGE.TYPE",
                                })}
                                disabled
                                options={enumToOptionsArrayWithTranslatedStrings(
                                  filterStageTypesByAnimalType(animalType),
                                  intl,
                                  isShrimpFarm
                                    ? "SUSTELL.SHRIMP.ENUMS.STAGE.TYPE"
                                    : "SUSTELL.ENUMS.STAGE.TYPE"
                                )}
                                defaultValue={methods.getValues(
                                  `stages[${index}].type`
                                )}
                              />
                            )}
                            <ReactHookDsmInput
                              name={`stages[${index}].tempName`}
                              label={intl.formatMessage({
                                id: "SUSTELL.FIELD_STAGE.NAME",
                              })}
                              required
                              disabled={formType === FormType.View}
                              defaultValue={methods.getValues(
                                `stages[${index}].name`
                              )}
                            />
                            {isShrimp(animalType) && (
                              <ReactHookDsmInput
                                name={`stages[${index}].tempPondName`}
                                label={intl.formatMessage({
                                  id: "SUSTELL.FIELD_STAGE.POND_NAME",
                                })}
                                disabled={formType === FormType.View}
                                defaultValue={methods.getValues(
                                  `stages[${index}].pondName`
                                )}
                              />
                            )}
                          </DsmGrid>
                          <DsmGrid
                            style={{
                              grid: "auto/1fr auto",
                              padding: "0px",
                              columnGap: "var(--dsm-spacing-px-2",
                              justifyItems: "end",
                            }}
                          >
                            <DsmButton
                              variant="secondary"
                              destructive={false}
                              onClick={cancelInlineEditHandler}
                            >
                              {intl.formatMessage({ id: "GENERAL.CANCEL" })}
                            </DsmButton>
                            <DsmButton
                              variant="primary"
                              onClick={saveInlineEditHandler}
                            >
                              {intl.formatMessage({ id: "GENERAL.SAVE" })}
                              <DsmIcon slot="after" name="arrows/chevron-right" />
                            </DsmButton>
                          </DsmGrid>
                        </DsmGrid>
                      )}
                      {animalType === AnimalType.LayingHens && (
                        <LayingHensStage
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          item={item}
                          getCompleteness={getCompleteness}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          manureManagementSystems={manureManagementSystems}
                        />
                      )}
                      {animalType === AnimalType.Broiler && (
                        <BroilerStages
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          item={item}
                          getCompleteness={getCompleteness}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          manureManagementSystems={manureManagementSystems}
                        />
                      )}
                      {animalType === AnimalType.PigFattening && (
                        <PigFatteningStages
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          item={item}
                          getCompleteness={getCompleteness}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          manureManagementSystems={manureManagementSystems}
                        />
                      )}
                      {animalType === AnimalType.Salmon && (
                        <SalmonStages
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          item={item}
                          getCompleteness={getCompleteness}
                          compoundFeeds={compoundFeeds}
                          manureManagementSystems={manureManagementSystems}
                          masterDataEnums={masterDataEnums}
                        />
                      )}
                      {animalType === AnimalType.Pig &&
                        !isProcessingStage(item.type) && (
                          <PigStages
                            farms={pigFarms}
                            farmId={farmID}
                            farmName={selectedFarmName}
                            productionProcessName={processName}
                            baseline={baseline}
                            formType={formType}
                            stageIndex={index}
                            stageType={item.type}
                            item={item}
                            compoundFeeds={compoundFeeds}
                            singleIngredients={singleIngredients}
                            manureManagementSystems={manureManagementSystems}
                            origins={origins}
                            animalType={animalType}
                            calculateCompleteness={calculateCompleteness}
                          />
                        )}
                      {animalType === AnimalType.Dairy && (
                        <DairyStages
                          stageIndex={index}
                          stageType={item.type}
                          baseline={baseline}
                          getCompleteness={getCompleteness}
                          manureManagementSystems={manureManagementSystems}
                          singleIngredients={singleIngredients}
                          compoundFeeds={compoundFeeds}
                          item={item}
                          formType={formType}
                        />
                      )}
                      {isMarineFishFarm && !isProcessingStage(item.type) && (
                        <MarineFishStages
                          item={item}
                          stageIndex={index}
                          baseline={baseline}
                          compoundFeeds={compoundFeeds}
                          animalType={animalType}
                          masterDataEnums={masterDataEnums}
                          formType={formType}
                          calculateCompleteness={calculateCompleteness}
                        />
                      )}
                      {isProcessingStage(item.type) && (
                        <SlaughterhouseStages
                          baseline={baseline}
                          formType={formType}
                          facilities={facilities}
                          getCompleteness={getCompleteness}
                          item={item}
                          stageIndex={index}
                          calculateCompleteness={calculateCompleteness}
                        />
                      )}

                      {isPoultryFarm && !isProcessingStage(item.type) && (
                        <PoultryStages
                          farms={poultryFarms}
                          farmId={farmID}
                          farmName={selectedFarmName}
                          productionProcessName={processName}
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          stageType={item.type}
                          item={item}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          origins={origins}
                          manureManagementSystems={manureManagementSystems}
                          masterDataEnums={masterDataEnums}
                          calculateCompleteness={calculateCompleteness}
                          animalType={animalType}
                        />
                      )}
                      {isShrimpFarm && (
                        <ShrimpStages
                          farms={shrimpFarms}
                          farmName={farmName}
                          farmId={farmID}
                          productionProcessName={processName}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          origins={origins}
                          masterDataEnums={masterDataEnums}
                          stageIndex={index}
                          baseline={baseline}
                          item={item}
                          formType={formType}
                          stageType={item.type}
                          animalType={animalType}
                          calculateCompleteness={calculateCompleteness}
                        />
                      )}

                      {isBeefFarm && !isProcessingStage(item.type) && (
                        <BeefStages
                          farms={beefFarms}
                          farmId={farmID}
                          farmName={selectedFarmName}
                          productionProcessName={processName}
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          stageType={item.type}
                          item={item}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          origins={origins}
                          masterDataEnums={masterDataEnums}
                          calculateCompleteness={calculateCompleteness}
                          animalType={animalType}
                        />
                      )}

                      {isDairyFarm && !isProcessingStage(item.type) && (
                        <DairyV2Stages
                          farms={dairyFarms}
                          farmId={farmID}
                          farmName={selectedFarmName}
                          productionProcessName={processName}
                          baseline={baseline}
                          formType={formType}
                          stageIndex={index}
                          stageType={item.type}
                          item={item}
                          compoundFeeds={compoundFeeds}
                          singleIngredients={singleIngredients}
                          origins={origins}
                          masterDataEnums={masterDataEnums}
                          calculateCompleteness={calculateCompleteness}
                          animalType={animalType}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
                {formType !== FormType.View && isAllowedToAddNewStage(fields) && (
                  <DsmButton
                    style={{ marginTop: "var(--dsm-spacing-px-6" }}
                    onClick={() => showStageForm()}
                    variant="text"
                    color="primary"
                    id="prod-proc-add-btn"
                  >
                    <DsmIcon slot="before" name="general/plus-circle" />
                    {intl.formatMessage({ id: "SUSTELL.ADD_STAGE" })}
                  </DsmButton>
                )}
                {formType !== FormType.View && !stageFormOpen && (
                  <StepperButtons
                    continueLabel={intl.formatMessage({ id: "GENERAL.SAVE" })}
                    cancelHandler={handleCancel}
                    backButtonLabel={intl.formatMessage({ id: "GENERAL.BACK" })}
                    backButtonHandler={
                      formType === FormType.Add ? backwardStep : null
                    }
                    continueHandler={
                      formType !== FormType.View
                        ? methods.handleSubmit(onSubmit, onErrors)
                        : undefined
                    }
                  />
                )}
              </>
            )}
        </form>
      </FormProvider>
      {(activeStep === 2 || activeStep === 3 || formType === FormType.Edit) &&
        stageFormOpen && (
          <StageForm
            handleFormSave={saveStage}
            handleClose={closeStageForm}
            animalType={animalType}
            navigateToAddNewFacility={navigateToAddNewFacility}
            facilities={facilities}
            formType={stageFormType}
            baselineFormType={formType}
            activeStep={activeStep}
            stageData={selectedStage}
            selectedStages={fields}
            stageTypeCounters={stageTypeCounters}
            setStageTypeCounters={setStageTypeCounters}
          />
        )}
    </>
  );
};

export default SustellDatasetMainPage_v2;
