/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sortedUniqBy } from 'lodash';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../helpers/helperTypes';
import { FormType, ListEntry, ShrimpFarms } from '../../common';
import { GrowingSupply, ShrimpOutput, ShrimpStage } from '../../../../models/Baseline/ShrimpBaseline';
import { AnimalType, StageType, TransportMode } from '../../../../../../graphql/types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DatabaseFoundation } from '../../../../../../graphql/generated/blonk/shrimp';

interface SGrowingSupplyFormProperties {
  farms: ShrimpFarms[];
  farmId: string;
  farmName: string;
  stageIndex: number;
  itemIndex: number;
  allowedStagesForFarm: string[];
  productionProcessName: string;
  formType: FormType;
  // eslint-disable-next-line react/require-default-props
  isIntervention?: boolean;
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/ban-types
  removeHandler?: Maybe<Function>;
  sourceSelected: boolean;
  // eslint-disable-next-line react/require-default-props
  triggerSelectionError?: boolean;
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
  stageType: StageType;
  // eslint-disable-next-line @typescript-eslint/ban-types
  checkboxUpdateHandler: Function;
}

const InternalSourcesInput: FC<SGrowingSupplyFormProperties> = ({
  farms,
  farmId,
  farmName,
  stageIndex = 0,
  itemIndex = 0,
  allowedStagesForFarm,
  productionProcessName,
  formType,
  isIntervention = false,
  removeHandler,
  sourceSelected,
  triggerSelectionError=false,
  animalType,
  stageType,
  checkboxUpdateHandler,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();

  const propName = isIntervention
    ? 'internalSourcesAdditions'
    : 'internalSources';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`;
  const internalSelect = `stages.${stageIndex}.stageData.input.selection.internalSelected`;
  const externalSelect = `stages.${stageIndex}.stageData.input.selection.externalSelected`;
  const intl = useIntl();
  const userProfile = UserProfilePrefs.getInstance();
  const calculateInitialDatabaseValue = () => {
    let initValue: DatabaseFoundation = fc.getValues(`info.databaseFoundation`) as DatabaseFoundation;
    if (!initValue) initValue =  userProfile.getUserDatabasePrefs().databaseType as DatabaseFoundation;
    return initValue;
  }

  let stageTypeCode = ''
  switch(stageType) {
    case StageType.Hatching:
      stageTypeCode =  'BROODSTOCK';
      break;
    case StageType.Nursery:
      stageTypeCode = 'POST_LARVAE';
      break;
    case StageType.Growing:
    default:
      stageTypeCode = 'JUVENILES';
      break;
  } 

  let selectedFarmId = '';
  useWatch({ name: `${fieldItemPrefix}` });

  const getAnimalSystemTypeLabel = (at: string) => {
    switch(at) {
      case 'LITOPENAEUS_VANNAMEI': return 'Litopenaeus Vannamei'
      case 'PENAEUS_MONODON': return 'Penaeus Monodon'
      default: return '';
    }
  }

  const invalidateStageSelection = (e: ReactChangedType) => {
    fc.setValue(`${fieldItemPrefix}.farmName`, e.target.name);
    const newSelection: string = e.target.value as string;
    if (newSelection !== selectedFarmId) { 
      fc.setValue(`${fieldItemPrefix}.comingFromStage`, '');
      fc.setValue(`${fieldItemPrefix}.outputName`, '');
      selectedFarmId = newSelection;
    };
  }

  const addCurrentFarm = (resultFarms: ListEntry[]) => {
    const currentStage: ShrimpStage = fc.getValues(`stages.${stageIndex}`) as ShrimpStage;
    const currentFarm = resultFarms.find((farm) => farm.value === farmId);
    const stages = fc.getValues('stages').filter((stage: ShrimpStage) => allowedStagesForFarm.includes(stage.type ?? ''))
                                         .filter((stage: ShrimpStage) => currentStage?.id !== stage.id);
    // If there are more then one stage on current opened baseline only then add the current farm and db
    if (stages?.length > 0 && currentFarm === undefined) resultFarms.push({text: farmName, value: farmId});
  }

  const farmOptions = () => {
    // Filter out the Farm that are not relevant
    const resultFarms = farms.filter((farm) => farm.databaseFoundation === calculateInitialDatabaseValue() || !farm.databaseFoundation)
                             .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
                             .filter((farm) => !farm.benchmark)
                             .map((farm) => ({text: farm.farmName, value: farm.farmId}));
    // Add farm for currently opened stages
    addCurrentFarm(resultFarms)
    // Because a farm can have multiple processes and baselines, its entry can be duplicated
    return sortedUniqBy(resultFarms, 'value');
  };

  const generateSelectedIds = (): string[] => {
    const sources: GrowingSupply[] = fc.getValues(`stages.${stageIndex}.stageData.input.${propName}`) as GrowingSupply[];
    return sources?.map((internalSources: GrowingSupply) => (internalSources.id));
  }

  const stageOptions = () => {
    const currentlySelectedFarm: string = fc.getValues(`${fieldItemPrefix}.comingFromFarm`) as string;
    const currentlySelectedStage: string = fc.getValues(`${fieldItemPrefix}.comingFromStage`) as string;
    const selectedStageIds = generateSelectedIds();
    const currentStage: ShrimpStage = fc.getValues(`stages.${stageIndex}`) as ShrimpStage;
    const currentBaselineValues = fc.getValues('stages').map((stage: ShrimpStage) => ({
      databaseFoundation: calculateInitialDatabaseValue(),
      stageId: stage.id,
      stageType: stage.type ?? '',
      stageName: stage.name,
      farmId,
      productionProcessName
    }));
    return [...farms, ...currentBaselineValues].filter((farm) => farm.databaseFoundation === calculateInitialDatabaseValue() || !farm.databaseFoundation)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                .filter((farm) => !selectedStageIds.includes(farm.stageId) || currentlySelectedStage === farm.stageId)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
                .filter((farm) => farm.farmId === currentlySelectedFarm)
                .filter((farm) => farm.stageId !== currentStage?.id)
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                .map((farm) => ({text: `${farm.productionProcessName} (${getAnimalSystemTypeLabel(animalType)}) ${farm.stageName}`, value: farm.stageId}));
  }

  const outputOptions = () => {
    const outputOptions: ListEntry[] = [];
    const currentlySelectedStage: string = fc.getValues(`${fieldItemPrefix}.comingFromStage`) as string;
    const farm: ShrimpFarms | undefined = farms.find((f: ShrimpFarms) => f.stageId === currentlySelectedStage);
    const selectedStage = fc.getValues('stages').find((stage: ShrimpStage) => stage?.id === currentlySelectedStage);

    if (farm) {
      farm.outputs.map((output) => outputOptions.push({text: output, value: output}));
    }
    else
    {
      selectedStage?.stageData?.output?.outputs?.map((output: ShrimpOutput) => 
      outputOptions.push({text: output.name as string, value: output.name as string}));
    }

    return outputOptions;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSupplyStageType = (farm: ShrimpFarms | undefined, selectedStage: any) => {
    if (farm) {
      fc.setValue(`${fieldItemPrefix}.stageType`, farm.stageType);
      checkboxUpdateHandler(farm.stageType);
    }
    else if (selectedStage?.type) {
      fc.setValue(`${fieldItemPrefix}.stageType`, selectedStage.type);
      checkboxUpdateHandler(selectedStage.type);
    }
  }

  const stageSelection = async (e: ReactChangedType) => {
    const selectedStageId: string = e.target.value as string;
    const farm: ShrimpFarms | undefined = farms.find((f: ShrimpFarms) => f.stageId === selectedStageId);
    const selectedStage = fc.getValues('stages').find((stage: ShrimpStage) => stage?.id === selectedStageId)

    if (farm) {
      fc.setValue(`${fieldItemPrefix}.originBaselineId`, farm.reference);
    }
    else
    {
      fc.setValue(`${fieldItemPrefix}.originBaselineId`, '');
    }

    if (farm && farm.outputs?.length === 1) {
      fc.setValue(`${fieldItemPrefix}.outputName`, farm.outputs[0]);
    }
    else if (selectedStage?.stageData?.output?.outputs?.lenght === 1)
    {
      fc.setValue(`${fieldItemPrefix}.outputName`, selectedStage.stageData.output.outputs[0].name);
    }

    updateSupplyStageType(farm, selectedStage);
  }

  useEffect(() => {
    const selectedStageId: string = fc.getValues(`${fieldItemPrefix}.comingFromStage`) as string;
    const farm: ShrimpFarms | undefined = farms.find((f: ShrimpFarms) => f.stageId === selectedStageId);
    const selectedStage = fc.getValues('stages').find((stage: ShrimpStage) => stage?.id === selectedStageId);

    updateSupplyStageType(farm, selectedStage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalSelect, externalSelect]);

  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg";

  const selectedTransportMode = useWatch({name: `${fieldItemPrefix}.transportMode`})
  const [distanceUnit, setDistanceUnit] = useState(userUOM.unitTransportDistanceTerrestrial)
  useEffect(() => {
    switch (selectedTransportMode) {
      case TransportMode.InlandShip:
        setDistanceUnit(userUOM.unitTransportDistanceInlandWaterways)        
        break;
      case TransportMode.SeaShip:
        setDistanceUnit(userUOM.unitTransportDistanceSea)        
        break;
      case TransportMode.Train:
      case TransportMode.Truck:
        setDistanceUnit(userUOM.unitTransportDistanceTerrestrial)
        break;
      default:
        break
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTransportMode])

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.originBaselineId`}
        value={fc.getValues(`${fieldItemPrefix}.originBaselineId`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={fc.getValues(`${fieldItemPrefix}.stageType`)}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && 
        itemIndex !== undefined && itemIndex !== null && 
        itemIndex !== 0  && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            onClick={() => removeHandler(itemIndex)}
            disabled={!sourceSelected}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%', marginTop: "10px" }}
            name={`${fieldItemPrefix}.comingFromFarm`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_FARM',
            })}`}
            disabled={formType === FormType.View || !sourceSelected}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_FARM.PLACEHOLDER',
            })}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.COMING_FROM_FARM.TOOLTIP`,
            })}
            options={farmOptions()}
            changeHandler={invalidateStageSelection}
            required
            defaultValue={
              fc.getValues(`${fieldItemPrefix}.comingFromFarm`) as string
            }
          />
        </div>
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.comingFromStage`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_STAGE',
            })}`}
            disabled={formType === FormType.View || !sourceSelected}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.COMING_FROM_STAGE.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_STAGE.PLACEHOLDER',
            })}
            options={stageOptions()}
            changeHandler={async (e: ReactChangedType) => {
              await stageSelection(e);
              if (triggerSelectionError) await fc.trigger(`stages.${stageIndex}.stageData.input.selection`);
            }}
            required
            defaultValue={
              fc.getValues(
                `${fieldItemPrefix}.comingFromStage`
              ) as string
            }
          />
        </div>
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.outputName`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.OUTPUT_NAME',
            })}`}
            disabled={formType === FormType.View || !sourceSelected}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.OUTPUT_NAME.PLACEHOLDER',
            })}
            options={outputOptions()}
            required
            defaultValue={
              fc.getValues(`${fieldItemPrefix}.outputName`) as string
            }
          />
        </div>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalWeight`}
          label={`${intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT`,
          })}`}
          disabled={formType === FormType.View || !sourceSelected}
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT.TOOLTIP`,
          })}
          adornment={barnOutputMassUnit}
          required
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.totalWeight`) as string
          }
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTraveled`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.JUVENILES.SHRIMP.DISTANCE_TRAVELED',
          })}`}
          disabled={formType === FormType.View || !sourceSelected}
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.SUPPLY.DISTANCE_TRAVELED.TOOLTIP`,
          })}
          adornment={distanceUnit}
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.distanceTraveled`) as string
          }
        />
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.transportMode`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE',
            })}`}
            disabled={formType === FormType.View || !sourceSelected}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TRANSPORT_MODE.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE.PLACEHOLDER',
            })}
            options={Object.keys(TransportMode).map((key) => ({
              value: TransportMode[key as keyof typeof TransportMode],
              text: TransportMode[key as keyof typeof TransportMode]
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            }))}
            defaultValue={
              fc.getValues(`${fieldItemPrefix}.transportMode`) as string
            }
          />
        </div>
      </DsmGrid>
    </>
  );
};

export default InternalSourcesInput;
