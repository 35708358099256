import { FC, useEffect, useRef, useState } from "react";
import { BaselineDialogProps, FormType } from "../../common";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { useFormContext } from "react-hook-form";
import { DairyIntervention, DairyOutput } from "../../../../models/Intervention";
import { DairyV2Baseline, DairyV2DairyCowStageData } from "../../../../models/Baseline/DairyV2Baseline";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import { cloneDeep } from "lodash";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import DairyV2OutputCowMilkComponent from "./DairyV2OutputCowMilkComponent";
import DairyV2OutputCowNewbornCalvesComponent from "./DairyV2OutputCowNewbornCalvesComponent";
import DairyV2OutputCowCowsComponent from "./DairyV2OutputCowCowsComponent";

interface DairyV2OutputCowDialogProps extends BaselineDialogProps {
  formType: FormType;
  formVisible: boolean;
  stageIndex: number;
  baseline: DairyV2Baseline;
  handleCancel: (param?: string | null) => void;
}

const DairyV2OutputCowDialog: FC<DairyV2OutputCowDialogProps> = ({
  formType = FormType.Add,
  formVisible,
  stageIndex = 0,
  baseline,
  handleCancel,
}) => {
  const classes: CSSClassesList = processAndStageStylesV2();
  const intl = useIntl();
  const currResetValue = useRef<DairyOutput>();
  const fc = useFormContext<DairyIntervention>();

  const [isMilkEdit, setIsMilkEdit] = useState(false);
  const [isCowsEdit, setIsCowsEdit] = useState(false);
  const [isNewbornCalvesEdit, setIsNewbornCalvesEdit] = useState(false);

  const formTitle = intl.formatMessage({ id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE', });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;

  const currentStageData = baseline.stages[stageIndex].stageData;
  const dairyV2CowOutputData = (currentStageData as DairyV2DairyCowStageData).output;

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  }, [formVisible]);

  const isOutputEdit = () => isMilkEdit || isCowsEdit || isNewbornCalvesEdit;

  const handleDialogCancelClick = () => {
    if (currResetValue.current) {
      for (let [propertyName, key] of Object.entries(currResetValue.current)) {
        fc.setValue(`${fieldItemPrefix}.${propertyName}`, key);
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <DialogContainer
      formVisible={true}
      handleClose={handleDialogCancelClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      datasetType="intervention"
      width={isOutputEdit() ? "1168px" : "812px"}
    >

      <p className={classes.headingTitleVariant}>
        {intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.OUTPUT.MILK" })}
      </p>
      <DairyV2OutputCowMilkComponent
        formType={formType}
        fieldItemPrefix={fieldItemPrefix}
        currentStageData={dairyV2CowOutputData}
        isMilkEdit={isMilkEdit}
        setIsMilkEdit={setIsMilkEdit}
      />

      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <p className={classes.headingTitleVariant}>
            {intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS" })}
          </p>
          <DairyV2OutputCowCowsComponent
            formType={formType}
            fieldItemPrefix={fieldItemPrefix}
            currentStageData={dairyV2CowOutputData}
            isCowsEdit={isCowsEdit}
            setIsCowsEdit={setIsCowsEdit}
          />
        </div>

        <div>
          <p className={classes.headingTitleVariant}>
            {intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.OUTPUT.CALVES" })}
          </p>
          <DairyV2OutputCowNewbornCalvesComponent
            formType={formType}
            fieldItemPrefix={fieldItemPrefix}
            currentStageData={dairyV2CowOutputData}
            isNewbornCalvesEdit={isNewbornCalvesEdit}
            setIsNewbornCalvesEdit={setIsNewbornCalvesEdit}
          />
        </div>
      </DsmGrid>

      <DsmButtonControlGroup
        cancelHandler={handleDialogCancelClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  )
}

export default DairyV2OutputCowDialog;
