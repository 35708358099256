/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { DsmIcon } from '@dsm-dcs/design-system-react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';
import {
  dialogUltraWide,
  dialogsStyle2,
  dialogsStyleIntervention2,
  dialogsStyleFootprint2,
  dialogsStyleDouble2,
  dialogsStyleDoubleIntervention2,
  dialogsStyleDescription,
  dialogsStyleDemiWide2,
  dialogsStyleDoubleIntervention2v3,
} from '../../../../../_metronic/layout/_core/MaterialThemeProvider';

export const DialogContainer = (props) => {
  const {
    formVisible,
    variant = 'normal', // 'normal', 'wide', 'ultrawide' or 'descriptionbox'
    formTitle = '',
    titlePlacement = 'normal', // 'normal', 'with-icon'
    iconCode = 'default',
    introText = '',
    introTextStyle,
    outroText,
    dialogContentStyles = {
      marginTop: '20px',
      paddingBottom: '1.25rem',
      overflowY: 'unset',
    },
    handleClose,
    datasetType = 'baseline',
    withIcon = true,
    width,
  } = props;

  const classes = processAndStageStylesV2();

  const singleStyle =
    datasetType === 'baseline'
      ? dialogsStyle2
      : datasetType === 'footprint'
        ? dialogsStyleFootprint2
        : dialogsStyleIntervention2;

  const doubleStyle =
    datasetType === 'baseline'
      ? dialogsStyleDouble2
      : dialogsStyleDoubleIntervention2;

  const demiWideStyle =
    datasetType === 'baseline'
      ? dialogsStyleDemiWide2
      : dialogsStyleIntervention2;

  const interventionWideStyle = dialogsStyleDoubleIntervention2v3;

  const getDialogVariantStyle = () => {
    if (variant === 'ultrawide') return dialogUltraWide;

    if (variant === 'wide') return doubleStyle;

    if (variant === 'descriptionbox') return dialogsStyleDescription;

    if (variant === 'demiWide') return demiWideStyle;

    if (variant === 'interventionWide') return interventionWideStyle;

    // if nothing from above
    return {
      style: {
        ...singleStyle.style,
        width: width || singleStyle.style.width,
      },
    };
  };

  return (
    <Dialog
      open={formVisible}
      maxWidth={false}
      PaperProps={
        // eslint-disable-next-line no-nested-ternary
        getDialogVariantStyle()
      }
    >
      <IconButton
        aria-label="close"
        className={classes.dialogCloseBtn}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" disableTypography>
        {withIcon && (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box className={clsx(classes.iconHolder, 'completed')}>
              <DsmIcon
                name={iconCode}
                className={clsx(classes.iconInTheBox, 'completed')}
              />
            </Box>
            {titlePlacement === 'with-icon' && (
              <Box style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}>
                {formTitle}
              </Box>
            )}
          </Box>
        )}
        {titlePlacement === 'normal' && (
          <Box
            style={{ whiteSpace: 'pre-line', marginTop: 16 }}
          >
            {formTitle}
          </Box>
        )}
      </DialogTitle>
      <DialogContent style={dialogContentStyles}>
        {introText && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={classes.dialogDescriptionText}
              style={{ ...introTextStyle }}
            >
              {introText}
            </Typography>
          </Grid>
        )}
        {props.children}
        {outroText && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={classes.dialogDescriptionText}
              style={{ ...introTextStyle }}
            >
              {outroText}
            </Typography>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
