import { DsmGrid } from "@dsm-dcs/design-system-react";
import { cloneDeep } from "lodash";
import { useEffect, useRef } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { AnimalType } from "../../../../../../graphql/generated/blonk/pigs";
import { StageType } from "../../../../../../graphql/types";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import {
  PigBreedingStageData,
  PigStage as PigBaselineStage,
} from "../../../../models/Baseline/PigBaseline";
import {
  PigInputBreeding,
  PigIntervention,
  PigStage,
} from "../../../../models/Intervention";
import { unitLong } from "../../../../utils/unit-utils";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import ExternalSourcesInput from "../../Baseline/PigFattening/v2.0/ExternalSourcesInput";
import InternalSourcesInput from "../../Baseline/PigFattening/v2.0/InternalSourcesInput";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { FormType } from "../../common";
import InterventionExternalSourcesInput from "./InterventionExternalSourcesInput";
import InterventionInternalSourcesInput from "./InterventionInternalSourcesInput";
import { PFInterventionInputFormDialogProps } from "./PFInterventionInputFormDialog";

const PBInterventionInputFormDialog = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PFInterventionInputFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : "kg";

  const currResetValue = useRef<PigInputBreeding>();

  const formTitle = intl.formatMessage({
    id: "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE",
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;
  const fc = useFormContext<PigIntervention>();

  const {
    fields: internalSourcesAdditions,
    // append: appendInternalAdditions,
    remove: removeInternalAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSourcesAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const {
    fields: externalSourcesAdditions,
    // append: appendExternalAdditions,
    remove: removeExternalAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSourcesAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  // const addInternalSourceAdditions = () => {
  //   appendInternalAdditions({
  //     numberPigs: "",
  //     originStageId: "",
  //     distanceTransport: "",
  //     farmId: "",
  //     averageAgeOfPigs: "",
  //     averageWeightOfPigs: "",
  //     originAnimalType: "",
  //   });
  // };

  const removeInternalSourceAdditions = (index: number) => {
    removeInternalAdditions(index);
  };

  // const addExternalSourceAdditions = () => {
  //   appendExternalAdditions({
  //     numberPigs: "",
  //     averageAgeOfPigs: "",
  //     averageWeightOfPigs: "",
  //     distanceTransport: "",
  //   });
  // };

  const removeExternalSourceAdditions = (index: number) => {
    removeExternalAdditions(index);
  };

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PigIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData;
      if (stageData?.input) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        stageData.input = { ...currResetValue.current };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }

    handleCancel();
  };

  const input = (
    baseline?.stages?.[stageIndex]?.stageData as PigBreedingStageData
  )?.input;

  // remove baseline used input stages and current stage from options
  const usedStages =
    input?.internalSources?.map((item) => item.originStageId) || [];

  const currentStage: PigStage | undefined =
    fc.getValues()?.stages?.[stageIndex];
  if (currentStage) usedStages.push(currentStage.id);

  // available stages as input source for intervention
  // are the ones not used in the baseline
  let stages =
    baseline?.stages
      .filter((item) => item.type !== StageType.Processing)
      .filter((item) => !usedStages.includes(item.id))
      .map((item) => ({ text: item.name, value: item.id })) || [];

  const baselineStageOriginAnimalType = (
    baseline?.stages[stageIndex]?.stageData as PigBreedingStageData
  )?.input?.originAnimalType;
  // console.log("baseline animal type",stageIndex, baselineStageOriginAnimalType);
  const initValueForAnimalType =
    baselineStageOriginAnimalType ||
    fc.getValues(`stages.${stageIndex}.stageData.input.originAnimalType`) ||
    "";

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const stageOriginAnimalType = useWatch({
    name: `stages.${stageIndex}.stageData.input.originAnimalType`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue: initValueForAnimalType,
  }) as AnimalType;

  const allStages = baseline?.stages || [];
  const breedingStagesList = allStages
    .filter((item) => item.type === StageType.Breeding)
    .map((item) => item.id);
  const fatteningStagesList = allStages
    .filter((item) => item.type === StageType.Fattening)
    .map((item) => item.id);
  if (stageOriginAnimalType === AnimalType.Piglet) {
    // no fattening stages should be allowed - so further filtering should be done
    stages = stages.filter((item) => !fatteningStagesList.includes(item.value));
  } else {
    // after change return to full list
    stages = allStages.map((item) => ({ text: item.name, value: item.id }));
  }

  useEffect(() => {
    if (formVisible && input) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {
          startDate: input.startDate as string,
          endDate: input.endDate as string,
          pigsPresentAtStart: "",
          pigsPresentAtEnd: "",
        };
      } else {
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, input]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="wide"
      iconCode="general/check-heart"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        className={classes.dsmGridTwoColumnIntervention}
      >
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({ id: "BASELINE.FORM.FIELD.START_DATE" })}
          disabled
          required
          defaultValue={input.startDate as string}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({ id: "BASELINE.FORM.FIELD.END_DATE" })}
          disabled
          required
          defaultValue={input.endDate as string}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.pigsPresentAtStart`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_START",
          })}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_START.INTERVENTION.TOOLTIP",
          })}
          type="number"
          metricUnit=""
          baseline={input?.pigsPresentAtStart}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.pigsPresentAtEnd`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_END",
          })}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_END.INTERVENTION.TOOLTIP",
          })}
          type="number"
          metricUnit=""
          baseline={input?.pigsPresentAtEnd}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        className={`${classes.dsmGridTwoColumnIntervention} ${classes.topVerticalSpacer}`}
      >
        <div>
          {input?.internalSources?.map((item, i) => (
            <InterventionInternalSourcesInput
              key={item.id}
              stageIndex={stageIndex}
              stages={baseline.stages as PigBaselineStage[]}
              itemIndex={i}
              formType={formType}
              item={item}
              breedingStagesList={breedingStagesList}
              baselineStageOriginAnimalType={baselineStageOriginAnimalType}
            />
          ))}
          {internalSourcesAdditions &&
            internalSourcesAdditions?.map((item, index) => (
              <InternalSourcesInput
                farms={farms}
                farmId={farmId}
                farmName={farmName}
                allowedStagesForFarm={[StageType.Fattening]}
                productionProcessName={productionProcessName}
                key={item.id}
                stageIndex={stageIndex}
                itemIndex={index}
                stages={stages}
                breedingStagesList={breedingStagesList}
                formType={formType}
                stageType={StageType.Breeding}
                stageOriginAnimalType={stageOriginAnimalType}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeInternalSourceAdditions}
                isIntervention
                barnOutputMassUnit={barnOutputMassUnit}
              />
            ))}

          {/* <DsmButton
            variant="text"
            onClick={addInternalSourceAdditions}
            disabled={
              formType === FormType.View ||
              internalSourcesAdditions.length >= stages.length-1
            }
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_INTERNAL',
            })}
          </DsmButton> */}
        </div>
        <div>
          {input?.externalSources?.map((item, i) => (
            <InterventionExternalSourcesInput
              key={item.id}
              stageIndex={stageIndex}
              itemIndex={i}
              formType={formType}
              item={item}
            />
          ))}

          {externalSourcesAdditions &&
            externalSourcesAdditions?.map((item, index) => (
              <ExternalSourcesInput
                key={item.id}
                stageIndex={stageIndex}
                itemIndex={index}
                formType={formType}
                stageType={StageType.Breeding}
                barnOutputMassUnit={barnOutputMassUnit}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeExternalSourceAdditions}
                isIntervention
              />
            ))}

          {/* <DsmButton
            variant="text"
            onClick={addExternalSourceAdditions}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_EXTERNAL',
            })}
          </DsmButton> */}
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default PBInterventionInputFormDialog;
