import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { cloneDeep, get } from 'lodash';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType, ShrimpFarms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import SGrowingSupplyForm from './SGrowingSupplyForm';
import SGrowingExternalSourceForm from './SGrowingExternalSourceForm';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import {
  GrowingExternalSource,
  GrowingSupply,
  ShrimpBaseline,
  ShrimpGrowingInput,
} from '../../../../models/Baseline/ShrimpBaseline';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import DefinitionsDialog from '../../CommonDataParts/v2.0/DefinitionsDialog';
import lookupValues from '../../../../helpers/lookupValues';

export interface ShrimpInputDialogProps extends BaselineDialogProps {
  farms: ShrimpFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
  stageType: StageType;
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
}

const SInputGrowingFormDialog: FC<ShrimpInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  stageType,
  animalType,
  handleCancel,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  let stageTypeCode = ''
    switch(stageType) {
      case StageType.Hatching:
        stageTypeCode =  'BROODSTOCK';
        break;
      case StageType.Nursery:
        stageTypeCode = 'POST_LARVAE';
        break;
      case StageType.Growing:
      default:
        stageTypeCode = 'JUVENILES';
        break;
    } 
  const formTitleId = `BASELINE.FORM.${stageTypeCode}.SHRIMP.${stageTypeCode}`;

  const formTitle = intl.formatMessage({
    id: formTitleId,
  });
  const introText = stageType === StageType.Hatching 
  ? '' 
  : intl.formatMessage({
    id: 'BASELINE.FORM.JUVENILES.SHRIMP.DESCRIPTION',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);

  const currResetValue = useRef<ShrimpGrowingInput>();
  const fc = useFormContext<ShrimpBaseline>();
  const [internalSourceSelected, setInternalSourceSelected] = useState<boolean>(true);
  const [externalSourceSelected, setExternalSourceSelected] = useState<boolean>(true);
  const initialInternalSourceSelected = useRef<boolean>(true);
  const initialExternalSourceSelected = useRef<boolean>(true);
  const { control } = useFormContext<ShrimpBaseline>();
  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control,
    keyName: 'keyId',
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control,
    keyName: 'keyId',
  });

  const addInternalSource = () => {
    appendInternal({
      id: uuidv4(),
      comingFromFarm: '',
      comingFromStage: '',
      distanceTraveled: '',
      transportMode: '',
    });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fc.trigger(`stages.${itemIndex}.stageData.input`);
  };

  const addExternalSource = () => {
    appendExternal({
      id: uuidv4(),
      totalWeight: '',
      distanceTraveled: '',
      transportMode: '',
    });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fc.trigger(`stages.${itemIndex}.stageData.input`);
  };

  useEffect(() => {
    if (formVisible) {
      const intSources: GrowingSupply[] = fc.getValues(`${fieldItemPrefix}.internalSources`);
      const extSources: GrowingExternalSource[] = fc.getValues(`${fieldItemPrefix}.externalSources`);

      if (intSources?.length === 0) {
        setInternalSourceSelected(false);
        initialInternalSourceSelected.current = false;
        addInternalSource();
      } 
      if (extSources?.length === 0) {
        setExternalSourceSelected(false);
        initialExternalSourceSelected.current = false;
        addExternalSource();
      } 

      currResetValue.current = cloneDeep<ShrimpGrowingInput>(
        fc.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as ShrimpBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.input) {
        resetObject.stages[itemIndex].stageData.input = currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }

    if (!initialInternalSourceSelected.current) fc.setValue(`${fieldItemPrefix}.internalSources`, []);
    if (!initialExternalSourceSelected.current) fc.setValue(`${fieldItemPrefix}.externalSources`, []);

    if (handleCancel) handleCancel('reset');
  };

  useEffect(() => {
    if (internalSources.length === 0 && externalSources.length === 0) {
      addInternalSource();
      addExternalSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerSelectionValidation = () => {
    // eslint-disable-next-line no-void
    void fc.trigger(`${fieldItemPrefix}.selection`);
  }

  const allowedStagesForFarm = () => {
    let allowedStages;
    
    switch(stageType) {
      case StageType.Nursery:
        allowedStages = [StageType.Hatching, StageType.Nursery];
        break;
      case StageType.Growing:
      default:
        allowedStages = [StageType.Hatching, StageType.Nursery, StageType.Growing];
        break;
    }

    return allowedStages;
  }

  const updateInternalCheckbox = (selectedOption: boolean) => {
    setInternalSourceSelected(selectedOption); 
    fc.setValue(`${fieldItemPrefix}.selection.internalSelected`, selectedOption);
    triggerSelectionValidation();
    if (!selectedOption)
      fc.clearErrors(`${fieldItemPrefix}.internalSources`);
  }

  const updateExternalCheckbox = (selectedOption: boolean) => {
    setExternalSourceSelected(selectedOption); 
    fc.setValue(`${fieldItemPrefix}.selection.externalSelected`, selectedOption);
    triggerSelectionValidation();
    if (!selectedOption)
      fc.clearErrors(`${fieldItemPrefix}.externalSources`);
  }

  const uncheckExternalCheckbox = (selectedStageType: StageType) => {
    if (selectedStageType === StageType.Nursery || selectedStageType === StageType.Growing)
      updateExternalCheckbox(false);
  }

  const stageDefinition = stageType as
  | StageType.Growing
  | StageType.Nursery;

  return (
    <div>
      {openDescriptionDialog && (
        <DefinitionsDialog
          open
          prefix='BASELINE.FORM.INPUT.SHRIMP.SOURCE'
          itemHeader='BASELINE.FORM.INPUT.SHRIMP.SOURCE'
          items={lookupValues.shrimpInputSourceDefinitions[stageDefinition]}
          handleClose={() => setOpenDescriptionDialog(false)}
        />
      )}
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleResetClick}
        iconCode="general/check-heart"
        variant="wide"
        formTitle={formTitle}
        introText={introText}
      >
        {stageType !== StageType.Hatching && (
          <DsmButton variant="text" className="mb-5" onClick={() => setOpenDescriptionDialog(true)}>
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({ id: 'DATABASE_FOUNDATION_DEFINITIONS' })}
          </DsmButton>
        )}
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.stageDuration`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION',
            })}`}
            disabled={formType === FormType.View}
            type="number"
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION.ADORNMENT',
            })}
            required
            defaultValue={fc.getValues(`${fieldItemPrefix}.stageDuration`)}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION.TOOLTIP',
            })}
          />
          <div/>
        </DsmGrid>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacingWithBorder}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.totalPondArea`}
            label={intl.formatMessage({
              id: stageType === StageType.Hatching ? 
              'BASELINE.FORM.BROODSTOCK.SHRIMP.TOTAL_TANK_AREA' : 
              'BASELINE.FORM.JUVENILES.SHRIMP.TOTAL_POND_AREA',
            })}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            adornment={userUOM.unitFarmSize}
            disabled={formType === FormType.View}
            type="number"
            tooltip={intl.formatMessage({
              id: stageType === StageType.Hatching ? 
              'BASELINE.FORM.BROODSTOCK.SHRIMP.TOTAL_TANK_AREA.TOOLTIP' : 
              'BASELINE.FORM.JUVENILES.SHRIMP.TOTAL_POND_AREA.TOOLTIP',
            })}
            required
            defaultValue={fc.getValues(`${fieldItemPrefix}.totalPondArea`)}
            style={{marginTop: '10px'}}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.emptyPeriod`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.EMPTY_PERIOD',
            })}`}
            disabled={formType === FormType.View}
            type="number"
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.EMPTY_PERIOD.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.EMPTY_PERIOD.ADORNMENT',
            })}
            required
            defaultValue={fc.getValues(`${fieldItemPrefix}.emptyPeriod`)}
            style={{marginTop: '10px'}}
          />
        </DsmGrid>
        <p className={classes.headingTitleVariant}>
          {intl.formatMessage({ id: 'BASELINE.FORM.INPUT.SHRIMP.STOCK' })}
        </p>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacingWithBorder}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.totalWeightAtStartDate`}
            label={`${intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_START`,
            })}`}
            type="number"
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_START.TOOLTIP`,
            })}
            disabled={formType === FormType.View}
            adornment={barnOutputMassUnit}
            defaultValue={fc.getValues(
              `${fieldItemPrefix}.totalWeightAtStartDate`
            )}
            style={{marginTop: '10px'}}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.totalWeightAtEndDate`}
            label={`${intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_END`,
            })}`}
            type="number"
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_END.TOOLTIP`,
            })}
            disabled={formType === FormType.View}
            adornment={barnOutputMassUnit}
            defaultValue={fc.getValues(`${fieldItemPrefix}.totalWeightAtEndDate`)}
            style={{marginTop: '10px'}}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.priceAtStartDate`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.PRICE_START',
            })}`}
            type="number"
            disabled={formType === FormType.View}
            adornment={intl.formatMessage(
              {
                id: 'BASELINE.FORM.JUVENILES.SHRIMP.PRICE_START.PLACEHOLDER',
              },
              {
                unit: barnOutputMassUnit,
              }
            )}
            defaultValue={fc.getValues(`${fieldItemPrefix}.priceAtStartDate`)}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.priceAtEndDate`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.PRICE_END',
            })}`}
            type="number"
            disabled={formType === FormType.View}
            adornment={intl.formatMessage(
              {
                id: 'BASELINE.FORM.JUVENILES.SHRIMP.PRICE_END.PLACEHOLDER',
              },
              {
                unit: barnOutputMassUnit,
              }
            )}
            defaultValue={fc.getValues(`${fieldItemPrefix}.priceAtEndDate`)}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
          { get(fc.errors, `stages.${itemIndex}.stageData.input.selection`) && 
            (
            <div style={{'color': '#E51F22', 'height': '14px', 'marginBottom': '25px'}}>  
              <span>{ get(fc.errors, `stages.${itemIndex}.stageData.input.selection.message`) }</span>
            </div>
            )
          }
        </DsmGrid>
        {stageType !== StageType.Hatching && (
          <div>
            <p className={classes.headingTitleVariant}>
              {intl.formatMessage({ id: "BASELINE.FORM.INPUT.SHRIMP.NEW_ANIMALS" })}
            </p>
            <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
              <div>
                <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
                  <input 
                    type='checkbox'
                    name={`${fieldItemPrefix}.selection.internalSelected`}
                    defaultChecked={internalSourceSelected}
                    checked={internalSourceSelected}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                    onChange={(e) => { 
                      updateInternalCheckbox(e.target?.checked);
                    }}
                    disabled={formType === FormType.View}
                  />
                  <span className={classes.shrimpInputSourcesText}>
                    {intl.formatMessage({
                      id: 'BASELINE.FORM.JUVENILES.SHRIMP.SUPPLY',
                    })}
                  </span>
                </div>
                {internalSources &&
                  internalSources?.map((item, index) => (
                    <SGrowingSupplyForm
                      farms={farms}
                      farmId={farmId}
                      farmName={farmName}
                      productionProcessName={productionProcessName}
                      key={item.keyId}
                      stageIndex={itemIndex}
                      itemIndex={index}
                      allowedStagesForFarm={allowedStagesForFarm()}
                      formType={formType}
                      removeHandler={removeInternalSource}
                      sourceSelected={internalSourceSelected}
                      triggerSelectionError
                      animalType={animalType}
                      stageType={stageType}
                      checkboxUpdateHandler={uncheckExternalCheckbox}
                    />
                  ))}
                <DsmButton
                  variant="text"
                  onClick={addInternalSource}
                  disabled={formType === FormType.View || !internalSourceSelected}
                >
                  <DsmIcon slot="before" name="general/plus-circle" />
                  {intl.formatMessage({
                    id: 'BASELINE.FORM.JUVENILES.SHRIMP.ADD_INTERNAL_SOURCE',
                  })}
                </DsmButton>
              </div>
              <div>
                <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
                  <input
                    type='checkbox'
                    name={`${fieldItemPrefix}.selection.externalSelected`}
                    defaultChecked={externalSourceSelected}
                    checked={externalSourceSelected}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                    onChange={(e) => { 
                      updateExternalCheckbox(e.target?.checked);
                    }}
                    disabled={formType === FormType.View}
                  />
                  <span className={classes.shrimpInputSourcesText}>
                    {intl.formatMessage({
                      id: 'BASELINE.FORM.JUVENILES.SHRIMP.EXTERNAL_SOURCE',
                    })}
                  </span>
                </div>
                {externalSources &&
                  externalSources?.map((item, index) => (
                    <SGrowingExternalSourceForm
                      key={item.keyId}
                      stageIndex={itemIndex}
                      itemIndex={index}
                      formType={formType}
                      removeHandler={removeExternalSource}
                      stageType={stageType}
                      sourceSelected={externalSourceSelected}
                    />
                  ))}
                <DsmButton
                  variant="text"
                  onClick={addExternalSource}
                  disabled={formType === FormType.View || !externalSourceSelected}
                >
                  <DsmIcon slot="before" name="general/plus-circle" />
                  {intl.formatMessage({
                    id: 'BASELINE.FORM.JUVENILES.SHRIMP.ADD_EXTERNAL_SOURCE',
                  })}
                </DsmButton>
              </div>
            </DsmGrid>
          </div>
        )}
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => {
            if (!internalSourceSelected) fc.setValue(`${fieldItemPrefix}.internalSources`, []);
            if (!externalSourceSelected) fc.setValue(`${fieldItemPrefix}.externalSources`, []);
            await fc.trigger(`stages.${itemIndex}.stageData.input`);
            handleCancel('confirm');
          }}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </DialogContainer>
    </div>
  );
};

export default SInputGrowingFormDialog;
