import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { SlaughterHouseInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import {
  FacilityType,
  StageType,
  TransportMode,
} from '../../../../../../graphql/types';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import RowSelectWithMetricsV2 from '../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { PigIntervention } from '../../../../models/Intervention';
import {
  ProcessingInput,
  ProcessingStageData,
} from '../../../../models/Intervention/Processing';
import { ProcessingStageData as ProcessingStageDataBaseline } from '../../../../models/Facility/Processing';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';

interface SHInterventionAnimalsFormDialogProps
  extends SlaughterHouseInterventionFormDialogProps {
  // eslint-disable-next-line react/require-default-props
  facilityType?: FacilityType | undefined;
}

const SHInterventionAnimalsFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  facilityType,
  baseline,
}: SHInterventionAnimalsFormDialogProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<ProcessingInput>();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.processingInput`;
  const formContext = useFormContext();

  const isCutting = facilityType === FacilityType.Cutting;

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...formContext.getValues(fieldItemPrefix),
      } as ProcessingInput;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = JSON.parse(
        JSON.stringify(formContext.getValues())
      ) as PigIntervention;
      if (
        resetObject?.stages &&
        (resetObject?.stages[stageIndex]?.stageData as ProcessingStageData)
          ?.processingInput
      ) {
        (
          resetObject?.stages[stageIndex].stageData as ProcessingStageData
        ).processingInput = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const processingInputBaseline = (
    baseline?.stages?.at(stageIndex)?.stageData as ProcessingStageDataBaseline
  )?.processingInput;

  const {
    fields: transportAdditions,
    append: appendTransportAdditions,
    remove: removeTransportAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.transportAdditions`,
    control: formContext.control,
    keyName: 'keyId',
  });

  // If facility type is cutting, then only SLAUGHTERING stages can be input stages
  // for slaughtering stages only fatteinng stages can be input
  const getAvailableStages = () => {
    let stages =
      baseline.stages?.filter(
        (st) => st.type !== StageType.Breeding && st.type !== StageType.Hatching
      ) || [];
    if (
      facilityType === FacilityType.Slaughtering ||
      facilityType === FacilityType.SlaughteringAndCutting
    ) {
      stages = stages.filter((st) => st.type !== StageType.Processing);
    } else if (facilityType === FacilityType.Cutting) {
      stages = stages.filter(
        (st) =>
          'facilityType' in st && st?.facilityType === FacilityType.Slaughtering
      );
    }
    return (
      stages?.map(
        (item: { name: string; id: string; facilityType?: string }) => ({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          text: `${item.name} ${
            item.facilityType
              ? ` (${intl.formatMessage({
                  id: `SUSTELL.SLAUGHTERHOUSE.ENUMS.STAGE.TYPE.${item.facilityType}`,
                })})`
              : ''
          }`,
          value: item.id,
        })
      ) || []
    );
  };

  const getTransportTypeOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      TransportMode,
      intl,
      'SUSTELL.TRANSPORT_MODE.'
    );

  const revaliadatePigsToAnotherStage = () => {
    const formData = formContext.getValues() as PigIntervention;

    // if input stage is chagend, do a revalidation of pigsToStage field for all stages
    formData?.stages?.forEach((stage, index) => {
      if (stage.type !== StageType.Processing)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        formContext.trigger(`stages.${index}.stageData.output.pigsToStage`);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const transportModeUnitsMap = new Map<TransportMode, string>([
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.InlandShip, userUOM.unitTransportDistanceInlandWaterways],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.SeaShip, userUOM.unitTransportDistanceSea],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.Train, userUOM.unitTransportDistanceTerrestrial],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.Truck, userUOM.unitTransportDistanceTerrestrial],
  ]);

  const getUnit = (type: string | undefined): string | undefined => {
    if (!type) return undefined;
    if (Object.values<string>(TransportMode).includes(type)) {
      return transportModeUnitsMap.get(type as TransportMode);
    }
    return undefined;
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      variant="wide"
      formTitle={intl.formatMessage({
        id: !isCutting
          ? 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE'
          : 'SUSTELL.PROCESS.DIALOG.STAGE.CARCASSES.TITLE',
      })}
      datasetType="intervention"
      introText={
        !isCutting
          ? intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.ANIMALS.DESCRIPTION',
            })
          : intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.ANIMALS_CARCASES.DESCRIPTION',
            })
      }
    >
      <DsmGrid className={classes.dsmGridThreeColumn}>
        <RowSelectWithMetricsV2
          name={`${fieldItemPrefix}.originStageId`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE',
          })}
          disabled={formType === FormType.View}
          options={getAvailableStages()}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          baseline={processingInputBaseline?.originStageId}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.originStageId`
          )}
          changeHandler={revaliadatePigsToAnotherStage}
        />
      </DsmGrid>

      {!isCutting && (
        <DsmGrid className={classes.dsmGridThreeColumn}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.quantity`}
            label={`${intl.formatMessage({
              id: 'COMPOUND_FEED.BASE.FEED_PLACEHOLDER',
            })}*`}
            type="number"
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.BASE.FEED_PLACEHOLDER',
            })}
            metricUnit=""
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            baseline={processingInputBaseline?.quantity}
            disabled={formType === FormType.View}
            clearDefaultStyle
          />
        </DsmGrid>
      )}

      {(processingInputBaseline?.transport || []).map((transport, index) => (
        <DsmGrid
          className={classes.dsmGridThreeColumn}
          style={{ marginTop: '12px' }}
        >
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.transport[${index}].transportMode`}
            label={intl.formatMessage({ id: 'SUSTELL.TRANSPORT_MODE' })}
            placeholder={intl.formatMessage({
              id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
            })}
            disabled
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
            })}
            options={getTransportTypeOptions()}
            required
            defaultValue={transport?.transportMode}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.transport[${index}].distance`}
            label={`${intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.PROCESSING.DISTANCE_TRAVELED',
            })}*`}
            type="number"
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.PROCESSING.DISTANCE_TRAVELED',
            })}
            metricUnit={intl.formatMessage(
              { id: 'SUSTELL.INPUT.TYPE_AMOUNT.UNIT' },
              {
                unit: 'km',
              }
            )}
            baseline={transport?.distance}
            disabled={formType === FormType.View}
            clearDefaultStyle
          />
        </DsmGrid>
      ))}
      <br />

      {transportAdditions &&
        transportAdditions?.map((item, index) => (
          <DsmGrid
            className={classes.dsmGridThreeColumn}
            key={item.keyId}
            style={{
              marginTop: '12px',
              // border: '1px solid var(--dsm-color-neutral)',
              borderWidth: '70%',
              paddingLeft: '4px',
              paddingTop: '10px',
            }}
          >
            <ReactHookDsmSelect
              name={`${fieldItemPrefix}.transportAdditions[${index}].transportMode`}
              label={intl.formatMessage({ id: 'SUSTELL.TRANSPORT_MODE' })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
              })}
              options={getTransportTypeOptions()}
              required
              defaultValue={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                formContext.getValues(
                  `${fieldItemPrefix}.transportAdditions[${index}].transportMode`
                )
              }
            />
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.transportAdditions[${index}].distance`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.PROCESSING.DISTANCE_TRAVELED',
              })}
              placeholder="km"
              disabled={formType === FormType.View}
              tooltip=""
              adornment={intl.formatMessage(
                { id: 'SUSTELL.INPUT.TYPE_AMOUNT.UNIT_PER_YEAR' },
                {
                  unit:
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    getUnit(formContext.getValues(`${fieldItemPrefix}.transportAdditions[${index}].distance`)) ?? 'km',
                }
              )}
              type="number"
              defaultValue={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                formContext.getValues(
                  `${fieldItemPrefix}.transportAdditions[${index}].distance`
                )
              }
            />
            {formType !== FormType.View && (
              <DsmButton
                variant="text"
                style={{ position: 'relative', width: '4%' }}
                onClick={() => removeTransportAdditions(index)}
              >
                <DsmIcon
                  name="general/x-close"
                  style={{
                    position: 'absolute',
                    color: 'var(--dsm-color-neutral-darker',
                    right: '0',
                  }}
                />
              </DsmButton>
            )}
          </DsmGrid>
        ))}

      <DsmButton
        variant="text"
        onClick={() =>
          appendTransportAdditions({ transportMode: '', distance: '' })
        }
        disabled={formType === FormType.View}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        Add another transport mode
      </DsmButton>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SHInterventionAnimalsFormDialog;
