import React, { FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { cloneDeep } from "lodash";
import { Box, Tooltip } from "@material-ui/core";
import {
  DsmButton,
  DsmCheckbox,
  DsmGrid,
  DsmIcon,
} from "@dsm-dcs/design-system-react";
import { BaselineDialogProps, FormType } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
// eslint-disable-next-line import/no-named-as-default
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import {
  FacilityOutputs,
  ProcessingOutput,
  ProcessingStageData,
} from "../../../../models/Facility/Processing";
import { PigBaseline } from "../../../../models/Baseline/PigBaseline";
import {
  FacilitySpecies,
  FacilityTableItem,
} from "../../../../models/Facility/FacilityTypes";
import lookupValues from "../../../../helpers/lookupValues";
import OutputDefinitionsDialog from "./OutputDefinitionsDialog";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import {
  isBeefFacility,
  isMarineFishFacility,
  isPoultryFacility,
} from "../../../../helpers/facilities";

const inlineTooltipClass = {
  color: "var(--dsm-color-neutral-darker)",
  height: "16px",
  width: "16px",
  display: "inline-block",
};

interface SlaughterhouseOutputFormDialogProps extends BaselineDialogProps {
  formTitle: string;
  facility: FacilityTableItem | undefined;
  iconCode?: string;
}

const SlaughterhouseOutputFormDialog: FC<
  SlaughterhouseOutputFormDialogProps
> = ({
  handleCancel,
  facility,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  formTitle = "Output",
  iconCode = "arrows/arrow-circle-broken-right",
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : "kg";

  const fieldItemPrefix = `stages.${itemIndex}.stageData.processingOutput`;
  // TODO: create model for Slaughterhouse
  const currResetValue = useRef<ProcessingOutput>();
  const formContext = useFormContext<PigBaseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const [openPdfDialog, setOpenPdfDialog] = useState<boolean>(false);
  const facilityTargetSpecies = facility?.targetSpecies.at(
    0
  ) as FacilitySpecies;
  const [pricesDefault, setPricesDefault] = useState(false);

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep<ProcessingOutput>(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleCloseDialog = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() };
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as ProcessingStageData;
      if (stageData?.processingOutput) {
        stageData.processingOutput = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel();
  };

  const checkIfPricesAreDefault = () => {
    let isDefault = true;
    Object.entries(FacilityOutputs).forEach(([key]) => {
      const defaultPrice =
        lookupValues.processingOutputDefaultPrices.PIG[
          key as keyof typeof lookupValues.processingOutputDefaultPrices.PIG
        ];
      const formVal = formContext.getValues(`${fieldItemPrefix}.${key}.price`);
      if (!formVal) {
        isDefault = false;
      } else if (Number(formVal) !== defaultPrice) {
        isDefault = false;
      }
    });
    return isDefault;
  };

  const shouldRenderResetButton = (targetSpecies: FacilitySpecies) =>
    !(isMarineFishFacility(targetSpecies) || isPoultryFacility(targetSpecies));

  const getDefaultPriceValue = (
    targetSpecies: FacilitySpecies,
    key: string
  ): number =>
    isBeefFacility(targetSpecies)
      ? lookupValues.processingOutputDefaultPrices.BEEF[
          key as keyof typeof lookupValues.processingOutputDefaultPrices.BEEF
        ]
      : lookupValues.processingOutputDefaultPrices.PIG[
          key as keyof typeof lookupValues.processingOutputDefaultPrices.PIG
        ];

  const setDefaultPrices = (targetSpecies: FacilitySpecies) => {
    Object.entries(FacilityOutputs).forEach(([key]) => {
      const defaultPrice = getDefaultPriceValue(targetSpecies, key);
      formContext.setValue(`${fieldItemPrefix}.${key}.price`, defaultPrice);
    });
  };

  const getPricesOnLoad = (
    key: keyof typeof FacilityOutputs,
    targetSpecies: FacilitySpecies
  ): number => {
    if (
      !(isMarineFishFacility(targetSpecies) || isPoultryFacility(targetSpecies))
    ) {
      const userDefinedPrice = formContext.getValues(
        `${fieldItemPrefix}.${key}.price`
      );
      const defaultPrice = getDefaultPriceValue(targetSpecies, key);
      return userDefinedPrice ? Number(userDefinedPrice) : Number(defaultPrice);
    } else {
      return formContext.getValues(`${fieldItemPrefix}.${key}.price`);
    }
  };

  const handleResetDefaults = (targetSpecies: FacilitySpecies) => {
    if (
      isMarineFishFacility(targetSpecies) ||
      isPoultryFacility(targetSpecies)
    ) {
      return;
    }

    setDefaultPrices(targetSpecies);
    setPricesDefault(true);
  };

  const handleChangePrice = () => {
    if (pricesDefault && !checkIfPricesAreDefault()) {
      setPricesDefault(false);
    }
  };

  const handleOpenDefinitionsDialog = () => {
    setOpenPdfDialog(true);
  };

  const handleCloseDefinitionsDialog = () => {
    setOpenPdfDialog(false);
  };

  const getWeightAndPriceInputLabel = (val: FacilityOutputs) => {
    if (
      val === FacilityOutputs.cat3HidesAndSkins &&
      isPoultryFacility(facilityTargetSpecies)
    ) {
      return intl.formatMessage({
        id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.CAT3_FEATHERS_AND_SKINS`,
      });
    }
    return intl.formatMessage({
      id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.${val}`,
    });
  };

  return (
    <div>
      {openPdfDialog ? (
        <OutputDefinitionsDialog
          open
          handleClose={handleCloseDefinitionsDialog}
          complement={{ index: 6, column: 1, idComplement: "POULTRY" }}
        />
      ) : (
        <DialogContainer
          formVisible={formVisible}
          handleClose={handleCloseDialog}
          iconCode={iconCode}
          variant="wide"
          formTitle={`${formTitle} - ${intl.formatMessage({
            id: `SUSTELL.SLAUGHTERHOUSE.ENUMS.STAGE.TYPE.${
              facility?.type || "unknown"
            }`,
          })}`}
          introText={intl.formatMessage({
            id: "SUSTELL.STAGES.PROCESSING.OUTPUT.DESCRIPTION",
          })}
        >
          <DsmButton
            variant="text"
            onClick={handleOpenDefinitionsDialog}
            style={{ marginBottom: "16px" }}
          >
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({
              id: "DATABASE_FOUNDATION_DEFINITIONS",
            })}
          </DsmButton>
          <DsmGrid className={classes.dsmGridThreeColumnLastSmall}>
            <h4>{intl.formatMessage({ id: "GENERAL.WEIGHT" })}</h4>
            <h4>{intl.formatMessage({ id: "GENERAL.PRICE" })}</h4>
            <div>
              <b>
                {intl.formatMessage(
                  {
                    id: "SUSTELL.STAGES.PROCESSING.ANIMALS.SELECT_FOOTPRINT_TO_DISPLAY",
                  },
                  {
                    tooltip: (
                      <Tooltip
                        title={intl.formatMessage({
                          id: "SUSTELL.STAGES.PROCESSING.ANIMALS.SELECT_FOOTPRINT_TO_DISPLAY.TOOLTIP",
                        })}
                      >
                        <DsmIcon
                          name="general/help-circle"
                          style={inlineTooltipClass}
                        />
                      </Tooltip>
                    ),
                  }
                )}
              </b>
            </div>
            {Object.entries(FacilityOutputs).map(([key, val]) => (
              <React.Fragment key={`output_key_${key}`}>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.${key}.weight`}
                  label={getWeightAndPriceInputLabel(val)}
                  disabled={formType === FormType.View}
                  tooltip={intl.formatMessage({
                    id: "SUSTELL.STAGES.PROCESSING.OUTPUT.WEIGHT.TT",
                  })}
                  required={key === "freshMeatAndEdibleOffal" ? true : false}
                  type="number"
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.${key}.weight`
                  )}
                  adornment={barnOutputMassUnit}
                />

                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.${key}.price`}
                  label={getWeightAndPriceInputLabel(val)}
                  disabled={formType === FormType.View}
                  tooltip={intl.formatMessage({
                    id: "SUSTELL.STAGES.PROCESSING.OUTPUT.PRICE.TT",
                  })}
                  required={key === "freshMeatAndEdibleOffal" ? true : false}
                  defaultValue={getPricesOnLoad(
                    key as keyof typeof FacilityOutputs,
                    facilityTargetSpecies
                  )}
                  type="number"
                  adornment={`${
                    facility?.currency || ""
                  }/${barnOutputMassUnit}`}
                  changeHandler={handleChangePrice}
                />
                <DsmCheckbox
                  style={{ margin: "auto" }}
                  name={`${fieldItemPrefix}.${key}.calculate`}
                  defaultValue={
                    key === "freshMeatAndEdibleOffal"
                      ? "true"
                      : formContext.getValues(
                          `${fieldItemPrefix}.${key}.calculate`
                        )
                  }
                  checked={
                    key === "freshMeatAndEdibleOffal" ||
                    formContext.getValues(`${fieldItemPrefix}.${key}.calculate`)
                  }
                  disabled={
                    formType === FormType.View ||
                    key === "freshMeatAndEdibleOffal"
                  }
                  onDsmChange={(e) => {
                    formContext.setValue(
                      `${fieldItemPrefix}.${key}.calculate`,
                      e?.target?.checked
                    );
                  }}
                />
              </React.Fragment>
            ))}
          </DsmGrid>

          {shouldRenderResetButton(facilityTargetSpecies) && (
            <DsmGrid className={classes.dsmGridThreeColumnLastSmall}>
              <Box />
              <div className={classes.resetButtonContainer}>
                <DsmButton
                  widthFull
                  variant="text"
                  onClick={() => handleResetDefaults(facilityTargetSpecies)}
                  className="mt-3"
                >
                  <DsmIcon slot="before" name="arrows/refresh-cw-01" />
                  <u>
                    {intl.formatMessage({
                      id: "SUSTELL.STAGES.PROCESSING.RESET_DEFAULT_PRICES",
                    })}
                  </u>
                </DsmButton>
                <Tooltip
                  title={intl.formatMessage({
                    id: "SUSTELL.RESET_DEFAULT.TOOLTIP",
                  })}
                  placement="left"
                  className="ml-3"
                  style={{ transform: "translateY(85%)" }}
                >
                  <DsmIcon
                    name="general/help-circle"
                    className={classes.inlineTooltipClass}
                    title=""
                  />
                </Tooltip>
              </div>
            </DsmGrid>
          )}

          <DsmButtonControlGroup
            cancelHandler={handleCloseDialog}
            saveHandler={async () => {
              await formContext.trigger(
                `stages.${itemIndex}.stageData.processingOutput`
              );
              handleCancel("confirm");
            }}
            saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
          />
        </DialogContainer>
      )}
    </div>
  );
};

export default SlaughterhouseOutputFormDialog;
