import { FC, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { cloneDeep } from "lodash";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { UserProfilePrefs } from "../../../../../../modules/Helpers/UserProfilePrefs";

import { useIntl } from "../../../../../../../_metronic/i18n/customUseIntl";
import {
  PigFatteningBaseline,
  PigFatteningOutput,
} from "../../../../../models/Baseline";
import { DialogContainer } from "../../../CommonDataParts/DialogContainer2";
import { BaselineDialogProps, FormType } from "../../../common";

import { processAndStageStylesV2 } from "../../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../../modules/Helpers/ReactHookDsmInput2";
import DsmButtonControlGroup from "../../../../helpers/DsmButtonControlGroup";
import { BREEDING_PIGLET_AVG_AGE_END_DEFAULT } from "../../../../../../modules/Farms/Baseline/mapper/outPigMapperSustellV2";

const PBOutputFormDialog: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  handleSave = handleCancel,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const classes = processAndStageStylesV2() as any;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PigFatteningOutput>();

  const formTitle = intl.formatMessage({
    id: "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE",
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  // const { getValues, reset } = useFormContext();
  const formContext = useFormContext();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = {
        ...formContext.getValues(),
      } as PigFatteningBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel("reset");
  };

  const resetToDefaultValues = () => {
    // reset averageAgePiglets
    formContext.setValue(
      `${fieldItemPrefix}.averageAgePiglets`,
      BREEDING_PIGLET_AVG_AGE_END_DEFAULT
    );
  };

  useEffect(() => {
    if (formVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="wide"
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightPigs`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS",
            })}
            adornment={userUOM.unitBarnOutputMass}
            type="number"
            tooltip=""
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightPigs`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.mortalityPigs`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.SOWS",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.PLACEHOLDER",
            })}
            disabled={formType === FormType.View}
            type="number"
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.mortalityPigs`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.pigsToStage`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS.TO_ANOTHER_STAGE",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.TRANSFER_TO_STAGE.PLACEHOLDER",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS.TO_ANOTHER_STAGE.TOOLTIP",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.pigsToStage`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.soldPigs`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.SOLD",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOLD.PLACEHOLDER",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(`${fieldItemPrefix}.soldPigs`)}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.priceSoldPigs`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.PRICE_SOLD",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PRICE_SOLD.PLACEHOLDER",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.priceSoldPigs`
            )}
          />
          <br/>
          <br/>
          <DsmButton
            widthFull
            variant="text"
            onClick={() => resetToDefaultValues()}
            disabled={formType === FormType.View}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES",
              })}
            </u>
          </DsmButton>
        </div>
        <div>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageAgePiglets`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.AGE.PIGLETS",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.AGE.PLACEHOLDER",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.AGE.PIGLETS.TOOLTIP",
            })}
            type="number"
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageAgePiglets`
            ) ?? BREEDING_PIGLET_AVG_AGE_END_DEFAULT}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightPiglets`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS",
            })}
            adornment={userUOM.unitBarnOutputMass}
            tooltip=""
            type="number"
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightPiglets`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.mortalityPiglets`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.PIGLETS",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.PLACEHOLDER",
            })}
            disabled={formType === FormType.View}
            type="number"
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.mortalityPiglets`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.pigletsToStage`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS.TO_ANOTHER_STAGE",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.TRANSFER_TO_STAGE.PLACEHOLDER",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS.TO_ANOTHER_STAGE.TOOLTIP",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.pigletsToStage`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.soldPiglets`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.SOLD",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOLD.PLACEHOLDER",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.soldPiglets`
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.priceSoldPiglets`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.PRICE_SOLD",
            })}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PRICE_SOLD.PLACEHOLDER",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.priceSoldPiglets`
            )}
          />
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.output`);
          handleCancel("confirm");
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default PBOutputFormDialog;
