import { FC, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Maybe } from "graphql/jsutils/Maybe";

import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { FormType } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { InputAnimalOrigin } from "../../../../../../graphql/generated/blonk/pigs";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import ReactHookDsmRadioGroup, {
  RadioGroupOption,
} from "../../../../../modules/Helpers/ReactHookDsmRadioGroup";
import { AnimalType } from "../../../../../../graphql/types";
import { ShrimpOutput } from "../../../../models/Baseline/ShrimpBaseline";
import lookupValues from "../../../../helpers/lookupValues";

interface SGrowingOutputFormProps {
  stageIndex?: number;
  itemIndex?: number;
  formType: FormType;
  isIntervention?: boolean;
  removeHandler?: Maybe<Function>;
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
}

const SGrowingOutputForm: FC<SGrowingOutputFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  isIntervention,
  removeHandler,
  animalType,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const propName = isIntervention ? "outputAdditions" : "outputs";
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output.${propName}.${itemIndex}`;

  const outputsLengthWatch = (
    useWatch({
      control: formContext.control,
      name: `stages.${stageIndex}.stageData.output.${propName}`,
    }) as ShrimpOutput[]
  )?.length;
  const outputsLength = useMemo(
    () =>
      (
        formContext.getValues(
          `stages.${stageIndex}.stageData.output.${propName}`
        ) as ShrimpOutput[]
      )?.length,
    [outputsLengthWatch]
  );

  const inputTotalPondArea = formContext.getValues(
    `stages.${stageIndex}.stageData.input.totalPondArea`
  ) as number;
  const totalWeightWatch = useWatch({
    name: `${fieldItemPrefix}.totalWeight`,
  });
  const totalWeight = useMemo(
    () => Number(formContext.getValues(`${fieldItemPrefix}.totalWeight`) || 0),
    [totalWeightWatch]
  );
  const weightPerHectare = useMemo(
    () =>
      inputTotalPondArea ? (totalWeight / inputTotalPondArea).toFixed(2) : 0,
    [totalWeight]
  );

  const soldExternallyOptions: RadioGroupOption[] = [
    {
      value: "Yes",
      label: intl.formatMessage({
        id: "GENERAL.YES",
      }),
    },
    {
      value: "No",
      label: intl.formatMessage({
        id: "GENERAL.NO",
      }),
    },
  ];
  const proteinContentDefault =
    lookupValues.shrimpProteinContentDefault[animalType].value;

  const [, forceUpdate] = useState<object>({});

  const updateSoldExternallyRadioButton = (value: string) => {
    formContext.setValue(`${fieldItemPrefix}.soldExternally`, value);

    forceUpdate({});
  };

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid
        className={
          itemIndex !== 0 || isIntervention
            ? classes.additionalEntriesBlockHolder
            : classes.additionalEntriesBlockHolderWithoutBorder
        }
      >
        {formType !== FormType.View &&
          removeHandler &&
          (itemIndex !== 0 || isIntervention) && (
            <DsmButton
              variant="text"
              style={{ position: "relative", width: "100%" }}
              onClick={() => removeHandler(itemIndex)}
            >
              <DsmIcon
                name="general/x-close"
                style={{
                  position: "absolute",
                  color: "var(--dsm-color-neutral-darker",
                  right: "0",
                }}
              />
            </DsmButton>
          )}
        <DsmGrid className={classes.dsmGridTwoColumn}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.name`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.NAME",
            })}`}
            adornment=" "
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.NAME.TOOLTIP",
            })}
            type="text"
            disabled={formType === FormType.View}
            required
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.name`) as string
            }
          />
          <DsmGrid className={classes.dsmGridTwoColumn}>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.price`}
              label={`${intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PRICE",
              })}`}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PRICE.TOOLTIP",
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PRICE.PLACEHOLDER",
              })}
              type="number"
              disabled={formType === FormType.View}
              required={outputsLength > 1}
              defaultValue={formContext.getValues(`${fieldItemPrefix}.price`)}
            />
            <ReactHookDsmRadioGroup
              name={`${fieldItemPrefix}.soldExternally`}
              label={`${intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.SOLD_EXTERNALLY",
              })}`}
              options={soldExternallyOptions}
              disabled={formType === FormType.View}
              required
              changeHandler={updateSoldExternallyRadioButton}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.soldExternally`
                ) as string
              }
            />
          </DsmGrid>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.totalWeight`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT",
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.TOOLTIP",
            })}
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.PLACEHOLDER",
            })}
            type="number"
            disabled={formType === FormType.View}
            required
            helperText={intl.formatMessage(
              { id: "BASELINE.FORM.OUTPUT.SHRIMP.AVERAGE_WEIGHT_HECTARE" },
              {
                weight: weightPerHectare,
              }
            )}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.totalWeight`) as string
            }
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.proteinContent`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT",
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.TOOLTIP",
            })}
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.PLACEHOLDER",
            })}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={
              (formContext.getValues(
                `${fieldItemPrefix}.proteinContent`
              ) as string) || proteinContentDefault
            }
          />
        </DsmGrid>
      </DsmGrid>
    </>
  );
};

export default SGrowingOutputForm;
