import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';

import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { SlaughterHouseInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import SHInterventionPackagingForOutputCategory from './SHInterventionPackagingForOutputCategory';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { FacilityOutputs, ProcessingStageData as ProcessingStageDataBaseline } from '../../../../models/Facility/Processing';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { ProcessingPackaging, ProcessingStageData } from '../../../../models/Intervention/Processing';

const SHInterventionPackagingFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: SlaughterHouseInterventionFormDialogProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<ProcessingPackaging>();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const formContext = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  useEffect(() => {

    if (formVisible) {
      currResetValue.current = { ...formContext.getValues(fieldItemPrefix) } as ProcessingPackaging;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (resetObject?.stages && (resetObject?.stages[stageIndex]?.stageData as ProcessingStageData)?.processingPackaging) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        resetObject.stages[stageIndex].stageData.input = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel();
  };

  const baselinePackaging = (baseline?.stages?.at(stageIndex)?.stageData as ProcessingStageDataBaseline)?.processingPackaging;
  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      variant="wide"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.PACKAGING.TITLE',
      })}
      datasetType="intervention"
      introText={intl.formatMessage({ id: "SUSTELL.STAGES.PROCESSING.PACKAGING.DESCRIPTION" })}
    >
      {
        Object.entries(baselinePackaging).map(([key, val]) => {

          const intlVal = Object.entries(FacilityOutputs).find(([k]) => k === key)?.at(1);
          if (!intlVal || !val || JSON.stringify(val) === '{}') {
            return '';
          }

          return (
            <DsmGrid className={classes.additionalEntriesBlockHolder}>
              <SHInterventionPackagingForOutputCategory
                stageIndex={stageIndex}
                outputCategory={key}
                formType={formType}
                barnOutputMassUnit={barnOutputMassUnit}
                baseline={baseline}
                intlPart={intlVal}
              />
            </DsmGrid>
          )
        })

      }
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SHInterventionPackagingFormDialog;
