/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-template-curly-in-string */

import * as Yup from 'yup';
import { IntlShape } from 'react-intl';
import {
  interventionFieldWithMin,
  interventionFieldWithMoreThanMin,
} from './interventionValidationObjectBuilderFunctions';
import { PigBaseline } from '../../../../sustell_15/models/Baseline/PigBaseline';
import { numericRequiredWithGreaterThanMin } from '../../Baseline/validationSchema/validationObjectBuilderFunctions';
import {
  PrimaryPackagingType,
  SecondaryPackagingType,
  TertiaryPackagingType,
} from '../../../../../graphql/types';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../sustell_15/utils/obj-utils';
import { ProcessingStageData } from '../../../../sustell_15/models/Facility/Processing';
import { packagingBaselineItem } from '../../Baseline/validationSchema/processingBaselineValidation';

const processingInput = (intl: IntlShape) =>
  Yup.object({
    quantity: interventionFieldWithMoreThanMin(intl, 'quantity', 0),
    transport: Yup.array().of(
      Yup.object({
        transportMode: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
        distance: interventionFieldWithMin(intl, 'distance', 0),
      })
    ),
    transportAdditions: Yup.array().of(
      Yup.object({
        transportMode: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
        distance: numericRequiredWithGreaterThanMin(intl, 0),
      })
    ),
  });

const getErrorMessageForEnum = (
  intl: IntlShape,
  enumObj: any,
  messageKey: string
) => {
  const enumsIntl = enumToOptionsArrayWithTranslatedStrings(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    enumObj,
    intl,
    'SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.'
  );
  return intl.formatMessage(
    { id: messageKey },
    { values: enumsIntl.map((item) => item.text).join(', ') }
  );
};

const packagingIntItem = (intl: IntlShape, packagingType: any) =>
  Yup.object({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    type: Yup.string().when('amount_newValue', {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      is: (val: any) => val,
      then: Yup.string().required(
        getErrorMessageForEnum(
          intl,
          packagingType,
          'SUSTELL.STAGES.PROCESSING.PACKAGING.ERROR.INVALID_TYPE'
        )
      ),
      otherwise: Yup.string(),
    }),
    amount: interventionFieldWithMin(intl, 'amount', 0),
  }).default({});

const processingPackagingInt = (intl: IntlShape) =>
  Yup.object({
    primary: Yup.array().of(packagingIntItem(intl, PrimaryPackagingType)),
    primaryAdditions: Yup.array().of(
      packagingBaselineItem(intl, PrimaryPackagingType)
    ),
    secondary: packagingIntItem(intl, SecondaryPackagingType),
    secondaryAdditions: Yup.array().of(
      packagingBaselineItem(intl, SecondaryPackagingType)
    ),
    tertiary: Yup.array().of(packagingIntItem(intl, TertiaryPackagingType)),
    tertiaryAdditions: Yup.array().of(
      packagingBaselineItem(intl, TertiaryPackagingType)
    ),
  }).default({});

const processingPackagings = (intl: IntlShape) =>
  Yup.object({
    freshMeatAndEdibleOffal: processingPackagingInt(intl),
    foodGradeBones: processingPackagingInt(intl),
    foodGradeFat: processingPackagingInt(intl),
    cat1And2MaterialAndWaste: processingPackagingInt(intl),
    cat3SlaughterByProducts: processingPackagingInt(intl),
    cat3HidesAndSkins: processingPackagingInt(intl),
  }).default({});

const getBaselineCalculateVal = (
  stageIndex: number,
  outputCategory: string,
  baseline: PigBaseline
) => {
  if (baseline && outputCategory && (stageIndex || stageIndex === 0)) {
    const output = (
      baseline.stages[stageIndex].stageData as ProcessingStageData
    ).processingOutput;
    console.log(output);
    const categoryOutput = output[outputCategory as keyof typeof output];
    return categoryOutput?.calculate;
  }
  return false;
};

const processingOutputIntObject = (intl: IntlShape, baseline: PigBaseline) =>
  Yup.object({
    calculate: Yup.bool(),
    weight: interventionFieldWithMin(intl, 'weight', 0).test(
      'checkBaselineCalculate',
      '',
      function (value: any): Yup.ValidationError | boolean {
        // Allow no intervene value
        if (!value) {
          return true;
        }

        const { path } = this;
        // get the stage index
        let currentStageIndex;
        const matches = path.match(/stages\[(.*?)\]/);
        if (matches) {
          const indexStr = matches[1];
          if (indexStr || indexStr === '0')
            currentStageIndex = parseInt(indexStr, 10);
        }
        const outputCategory = path.split('.').at(-2);
        let calculate;
        if (currentStageIndex && outputCategory && baseline) {
          calculate = getBaselineCalculateVal(
            currentStageIndex,
            outputCategory,
            baseline
          );
        }
        if (calculate && !value) {
          return this.createError({
            path,
            message: intl.formatMessage(
              {
                id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN_CALCULATED',
              },
              {
                value: 0,
              }
            ),
          });
        }
        return true;
      }
    ),
    price: interventionFieldWithMin(intl, 'price', 0).test(
      'checkBaselineCalculate',
      '',
      function (value: any): Yup.ValidationError | boolean {
        // Allow no intervene value
        if (!value) {
          return true;
        }

        const { path } = this;
        // get the stage index
        let currentStageIndex;
        const matches = path.match(/stages\[(.*?)\]/);
        if (matches) {
          const indexStr = matches[1];
          if (indexStr || indexStr === '0')
            currentStageIndex = parseInt(indexStr, 10);
        }
        const outputCategory = path.split('.').at(-2);
        let calculate;
        if (currentStageIndex && outputCategory && baseline) {
          calculate = getBaselineCalculateVal(
            currentStageIndex,
            outputCategory,
            baseline
          );
        }

        if (calculate && !value) {
          return this.createError({
            path,
            message: intl.formatMessage(
              {
                id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN_CALCULATED',
              },
              {
                value: 0,
              }
            ),
          });
        }
        return true;
      }
    ),
  }).default({});

const processingOutput = (intl: IntlShape, baseline: PigBaseline) =>
  Yup.object({
    freshMeatAndEdibleOffal: Yup.object({
      weight: interventionFieldWithMoreThanMin(intl, 'weight', 0),
      price: interventionFieldWithMoreThanMin(intl, 'price', 0),
    }).default({}),
    foodGradeBones: processingOutputIntObject(intl, baseline),
    foodGradeFat: processingOutputIntObject(intl, baseline),
    cat1And2MaterialAndWaste: processingOutputIntObject(intl, baseline),
    cat3SlaughterByProducts: processingOutputIntObject(intl, baseline),
    cat3HidesAndSkins: processingOutputIntObject(intl, baseline),
  });

// eslint-disable-next-line import/prefer-default-export
export const processingStageData = (intl: IntlShape, baseline: PigBaseline) =>
  Yup.object({
    processingInput: processingInput(intl).default({}),
    processingOutput: processingOutput(intl, baseline).default({}),
    processingPackaging: processingPackagings(intl).default({}),
  });
