import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DsmGrid, DsmButton, DsmIcon } from "@dsm-dcs/design-system-react";
import { Box, Tooltip } from "@material-ui/core";
import { CompoundFeedFormExtended, FormType } from "../types";
import { useIntl } from "../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../_metronic/layout";
import { UserProfilePrefs } from "../../../../modules/Helpers/UserProfilePrefs";
import ReactHookDsmInput from "../../../../modules/Helpers/ReactHookDsmInput2";
import { CSSClassesList } from "../../../helpers/helperTypes";
import { defaultUnitsV2 as defaultMetric } from "../../../utils/unit-utils";
import lookupValues from "../../../helpers/lookupValues";
import { floatValueInUserUnits } from "../../../../modules/Farms/Baseline/mapper/inCommonMapperSustellV2";
import ReactHookDsmRadioGroup, {
  RadioGroupOption,
} from "../../../../modules/Helpers/ReactHookDsmRadioGroup";

interface DairyNutritionProps {
  formType: FormType;
  targetSpecies: keyof typeof lookupValues.compoundFeedNutrientsDefault;
}

const DairyNutrition: FC<DairyNutritionProps> = ({
  formType,
  targetSpecies,
}) => {
  const intl = useIntl();
  const formContext = useFormContext<CompoundFeedFormExtended>();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const classes: CSSClassesList = processAndStageStylesV2();
  const nutrientsDefaultValues =
    lookupValues.compoundFeedNutrientsDefault?.[targetSpecies];

  useEffect(() => {
    setcontainsMilk(
      formContext.getValues("nutritional_analysis_data.containsMilk")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.getValues("nutritional_analysis_data.containsMilk")]);

  const containsMilkOptions: RadioGroupOption[] = [
    {
      value: "Yes",
      label: intl.formatMessage({
        id: "GENERAL.YES",
      }),
    },
    {
      value: "No",
      label: intl.formatMessage({
        id: "GENERAL.NO",
      }),
    },
  ];

  const [containsMilk, setcontainsMilk] = useState<string>("No");

  const checkUnitChanges = (): boolean =>
    defaultMetric.unitNutritionalGrossEnergy !==
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM.unitNutritionalGrossEnergy ||
    defaultMetric.unitIngredientQuantityInputMass !==
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM.unitIngredientQuantityInputMass;

  const getEnergyUnit = (): string =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    userUOM?.unitIngredientQuantityInputMass &&
    userUOM?.unitNutritionalGrossEnergy
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `${userUOM?.unitNutritionalGrossEnergy as string}/${
          userUOM?.unitIngredientQuantityInputMass as string
        }`
      : `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`;

  const toCurrentEnergyUnits = (
    value?: number | string | null
  ): number | string | undefined => {
    if (!value) return "";

    const numVal = typeof value === "string" ? parseFloat(value) : value;

    if (checkUnitChanges())
      return floatValueInUserUnits(
        numVal,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitIngredientQuantityInputMass}`,
        `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`
      ) as number;

    return numVal;
  };

  const resetDefault = () => {
    formContext.setValue(
      "nutritional_analysis_data.dryMatterContent",
      nutrientsDefaultValues?.dryMatterContent
    );
    formContext.setValue(
      "nutritional_analysis_data.grossEnergyContent",
      toCurrentEnergyUnits(nutrientsDefaultValues?.grossEnergyContent)
    );
    formContext.setValue(
      "nutritional_analysis_data.ashContent",
      nutrientsDefaultValues?.ashContent
    );
    formContext.setValue(
      "nutritional_analysis_data.digestibleEnergyContent",
      toCurrentEnergyUnits(nutrientsDefaultValues?.digestibleEnergyContent)
    );
    formContext.setValue(
      "nutritional_analysis_data.neutralDetergentFiber",
      nutrientsDefaultValues?.neutralDetergentFiber
    );
  };

  return (
    <div>
      <DsmGrid className={classes.dsmGridThreeColumnNoRowSpacing}>
        <ReactHookDsmInput
          name="nutritional_analysis_data.crudeProteinContent"
          label={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_LABEL.CRUDE_PROTEIN_CONTENT",
          })}
          type="number"
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.PERCENTAGE_DM.ADORNMENT",
          })}
          size="medium"
          required
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.CRUDE_PROTEIN_CONTENT.DAIRY.TOOLTIP",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.CRUDE_PROTEIN_CONTENT",
          })}
          defaultValue={formContext.getValues(
            "nutritional_analysis_data.crudeProteinContent"
          )}
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.grossEnergyContent"
          label={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_LABEL.GROSS_ENERGY_CONTENT",
          })}
          type="number"
          disabled={formType === FormType.View}
          adornment={intl.formatMessage(
            { id: "COMPOUND_FEED.NUTRITION.ENERGY_UNIT_DM.ADORNMENT" },
            { energyUnit: getEnergyUnit() }
          )}
          size="medium"
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.GROSS_ENERGY_CONTENT.DAIRY.TOOLTIP",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.GROSS_ENERGY_CONTENT",
          })}
          defaultValue={
            formContext.getValues(
              "nutritional_analysis_data.grossEnergyContent"
            ) || nutrientsDefaultValues?.grossEnergyContent
          }
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.dryMatterContent"
          label={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_LABEL.DRY_MATTER_CONTENT",
          })}
          type="number"
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.PERCENTAGE.ADORNMENT",
          })}
          size="medium"
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.DRY_MATTER_CONTENT.DAIRY.TOOLTIP",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.DRY_MATTER_CONTENT",
          })}
          defaultValue={
            formContext.getValues(
              "nutritional_analysis_data.dryMatterContent"
            ) || nutrientsDefaultValues?.dryMatterContent
          }
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.digestibleEnergyContent"
          label={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITIONAL_ANALYSIS.DIGESTIBLE_ENERGY",
          })}
          type="number"
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.PERCENTAGE_GE.ADORNMENT",
          })}
          size="medium"
          required
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.DIGESTIBLE_ENERGY_CONTENT.DAIRY.TOOLTIP",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.DIGESTIBLE_ENERGY",
          })}
          defaultValue={
            formContext.getValues(
              "nutritional_analysis_data.digestibleEnergyContent"
            ) || nutrientsDefaultValues?.digestibleEnergyContent
          }
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.ashContent"
          label={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_LABEL.ASH_CONTENT",
          })}
          type="number"
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.PERCENTAGE_DM.ADORNMENT",
          })}
          size="medium"
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.ASH_CONTENT.DAIRY.TOOLTIP",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.ASH_CONTENT",
          })}
          defaultValue={
            formContext.getValues("nutritional_analysis_data.ashContent") ||
            nutrientsDefaultValues?.ashContent
          }
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.neutralDetergentFiber"
          label={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_LABEL.NEUTRAL_DETERGENT_FIBER",
          })}
          type="number"
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.PERCENTAGE_DM.ADORNMENT",
          })}
          size="medium"
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.NUTRITION.NEUTRAL_DETERGENT_FIBER.TOOLTIP",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.NEUTRAL_DETERGENT_FIBER",
          })}
          defaultValue={
            formContext.getValues(
              "nutritional_analysis_data.neutralDetergentFiber"
            ) || nutrientsDefaultValues?.neutralDetergentFiber
          }
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridOneColumn}>
        <Box style={{ marginBottom: 16 }}>
          <Box />
          <div className={classes.resetButtonContainer}>
            <DsmButton
              variant="text"
              style={{ float: "left" }}
              onClick={() => resetDefault()}
              disabled={formType === FormType.View}
              className="mt-3"
            >
              <DsmIcon slot="before" name="arrows/refresh-cw-01" />
              <u>
                {intl.formatMessage({
                  id: "COMPOUND_FEED.NUTRITIONAL_ANALYSIS.RESET_DEFAULT",
                })}
              </u>
            </DsmButton>
            <Tooltip
              title={intl.formatMessage({
                id: "SUSTELL.RESET_DEFAULT.TOOLTIP",
              })}
              placement="left"
              className="ml-3"
              style={{ transform: "translateY(85%)" }}
            >
              <DsmIcon
                name="general/help-circle"
                className={classes.inlineTooltipClass}
                title=""
              />
            </Tooltip>
          </div>
        </Box>
        <ReactHookDsmRadioGroup
          name="nutritional_analysis_data.containsMilk"
          label={intl.formatMessage({
            id: "COMPOUNDFEEDS.NUTRITION_LABEL.CONTAINS_MILK",
          })}
          options={containsMilkOptions}
          disabled={formType === FormType.View}
          defaultValue={containsMilk}
          changeHandler={(option) => setcontainsMilk(option)}
        />
        {containsMilk === "Yes" && (
          <DsmGrid className={classes.dsmGridFourColumnNoRowSpacing}>
            <ReactHookDsmInput
              name="nutritional_analysis_data.milk"
              label={intl.formatMessage({
                id: "COMPOUNDFEEDS.NUTRITION_LABEL.MILK",
              })}
              type="number"
              disabled={formType === FormType.View}
              adornment={intl.formatMessage({
                id: "COMPOUND_FEED.NUTRITION.PERCENTAGE.ADORNMENT",
              })}
              size="medium"
              required
              tooltip={intl.formatMessage({
                id: "COMPOUND_FEED.NUTRITION.MILK.TOOLTIP",
              })}
              defaultValue={formContext.getValues(
                "nutritional_analysis_data.milk"
              )}
              placeholder={intl.formatMessage({
                id: "COMPOUNDFEEDS.NUTRITION_PLACEHOLDER.MILK",
              })}
            />
          </DsmGrid>
        )}
      </DsmGrid>
    </div>
  );
};

export default DairyNutrition;
