import { DsmGrid } from "@dsm-dcs/design-system-react";
import { Maybe } from "graphql/jsutils/Maybe";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import {
  AnimalType,
  InputAnimalOrigin,
} from "../../../../../../graphql/generated/blonk/pigs";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import {
  CSSClassesList,
  UserUOMPreferences,
} from "../../../../helpers/helperTypes";
import {
  InternalSourcesInput,
  PigStage,
} from "../../../../models/Baseline/PigBaseline";
import { enumToOptionsArrayWithTranslatedStrings } from "../../../../utils/obj-utils";
import { unitLong } from "../../../../utils/unit-utils";
import { FormType } from "../../common";

interface InterventionInternalInputProperties {
  stageIndex: number;
  itemIndex: number;
  stages: PigStage[];
  formType: FormType;
  item: InternalSourcesInput;
  breedingStagesList: string[] | undefined;
  baselineStageOriginAnimalType?: Maybe<AnimalType> | string | undefined;
}

const InterventionInternalSourcesInput: FC<
  InterventionInternalInputProperties
> = ({ stageIndex = 0, itemIndex = 0, stages, item, formType }) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.internalSources[${itemIndex}]`;
  const intl = useIntl();
  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs() as UserUOMPreferences;
  const distanceUnit = unitLong(userUOM.unitTransportDistanceTerrestrial);

  const getStageName = (stageId: string): string =>
    (stages || []).find((stage) => stage.id === stageId)?.name ?? "";

  const defaultFarm = (): string | undefined => {
    const farmDeleted: boolean = formContext.getValues(
      `${fieldItemPrefix}.farmDeleted`
    );
    if (farmDeleted) return "";
    return item.farmId;
  };

  const defaultStage = (): string | undefined => {
    const farmDeleted: boolean = formContext.getValues(
      `${fieldItemPrefix}.farmDeleted`
    );
    if (farmDeleted) return "";
    return item.originStageId as string | undefined;
  };

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        type="hidden"
        value={item.id}
        ref={formContext.register()}
        name={`${fieldItemPrefix}.id`}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={`${fieldItemPrefix}.stageType`}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.farmName`}
        value={formContext.getValues(`${fieldItemPrefix}.farmName`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageName`}
        value={formContext.getValues(`${fieldItemPrefix}.stageName`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.baselineRef`}
        value={formContext.getValues(`${fieldItemPrefix}.baselineRef`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageAgePiglets`}
        value={formContext.getValues(`${fieldItemPrefix}.averageAgePiglets`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageWeightPiglets`}
        value={formContext.getValues(`${fieldItemPrefix}.averageWeightPiglets`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageWeightPigs`}
        value={formContext.getValues(`${fieldItemPrefix}.averageWeightPigs`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageAgePigs`}
        value={formContext.getValues(`${fieldItemPrefix}.averageAgePigs`)}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolderIntervention}>
        <ReactHookDsmSelect
          style={{ width: "100%" }}
          name={`${fieldItemPrefix}.farmId`}
          label={intl.formatMessage({ id: "SUSTELL.STAGE.POULTRY.INPUT.FARM" })}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.POULTRY.INPUT.FARM.ADORNMENT",
          })}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.POULTRY.INPUT.FARM.TOOLTIP",
          })}
          disabled
          options={[
            { text: item.farmName as string, value: item.farmId as string },
          ]}
          defaultValue={defaultFarm()}
        />
        <ReactHookDsmSelect
          style={{ width: "100%" }}
          name={`${fieldItemPrefix}.originStageId`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.STAGE`,
          })}
          adornment={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.STAGE.ADORNMENT`,
          })}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.POULTRY.INPUT.STAGE.TOOLTIP",
          })}
          disabled
          options={[
            {
              text: item.stageName as string,
              value: item.originStageId as string,
            },
          ]}
          defaultValue={defaultStage()}
        />
        {item.originAnimalType === AnimalType.Piglet && (
          <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
            <ReactHookDsmSelect
              style={{ width: "100%" }}
              name={`${fieldItemPrefix}.originAnimalType`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE",
              })}
              disabled
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.PLACEHOLDER",
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.TOOLTIP",
              })}
              options={enumToOptionsArrayWithTranslatedStrings(
                AnimalType,
                intl,
                "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE"
              )}
              defaultValue={AnimalType.Piglet}
            />
          </div>
        )}
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.numberPigs`}
          label={intl.formatMessage(
            {
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.NEW_ANIMALS",
            }
          )}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.NEW_ANIMALS.TOOLTIP",
          })}
          metricUnit=""
          type="number"
          baseline={item.numberPigs}
          disabled={formType === FormType.View}
        />

        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.distanceTransport`}
          label={intl.formatMessage(
            {
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.TRANSPORT_DISTANCE",
            },
            { stage: getStageName(item.originStageId as string) }
          )}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.TRANSPORT_DISTANCE.TOOLTIP",
          })}
          type="number"
          metricUnit={distanceUnit}
          baseline={item.distanceTransport}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
    </>
  );
};

export default InterventionInternalSourcesInput;
