import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  DMS_TRANSPARENT,
  DSM_DARK_GREY,
  DSM_LIGHTER_RED,
  DSM_LIME_GREEN_LIGHT,
  footprintDetailsStyles,
  tableWithProgressBarColors,
  tableWithProgressBarStyles,
} from "../../../../_metronic/layout";
import { FootprintCategoryComponentModel } from "../../models/Footprint";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { ExactNumber } from "../helpers/ExactNumber";
import StyledChip from "./common/StyledChip";
import StyledLinearProgress from "./common/StyledLinearProgress";
import { SubCategory } from "../../models/Footprint/FootprintTypes";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { useEffect, useState } from "react";
import { AnimalType } from "../../../../graphql/types";
import { isShrimp, getAdjustedLabel } from "../../helpers/animals";
import { defaultUnits, explicitConvertValue } from "../../utils/unit-utils";
import { UserProfilePrefs } from "../../../modules/Helpers/UserProfilePrefs";

type ComponentColorType = {
  label: string;
  color: string;
};

type TableWithProgressBarProps = {
  tableData: FootprintCategoryComponentModel[];
  componentsColors: ComponentColorType[];
  isComparison: boolean;
  animalType: AnimalType;
  origins: { value: string; text: string }[];
};

const getValueBuffer = (percentage?: number, comparisonPercentage?: number) => {
  let value = 0;
  if (!percentage || !comparisonPercentage) return value;
  if (percentage > 0 && comparisonPercentage > 0) {
    if (percentage < comparisonPercentage) value = comparisonPercentage;
  } else if (percentage < 0 && comparisonPercentage < 0) {
    if (percentage > comparisonPercentage) value = comparisonPercentage;
  }
  return value;
};

const getSecondBarColor = (
  percentage: number,
  comparisonPercentage?: number
) => {
  let color = DMS_TRANSPARENT as string;
  if (!percentage || !comparisonPercentage) return color;
  if (percentage > 0 && comparisonPercentage > 0) {
    if (percentage < comparisonPercentage) color = DSM_LIGHTER_RED as string;
    else color = DSM_LIME_GREEN_LIGHT as string;
  } else if (percentage < 0 && comparisonPercentage < 0) {
    if (percentage > comparisonPercentage)
      color = DSM_LIME_GREEN_LIGHT as string;
    else color = DSM_LIGHTER_RED as string;
  }
  return color;
};
const TableWithProgressBar = ({
  tableData,
  componentsColors,
  isComparison,
  animalType,
  origins,
}: TableWithProgressBarProps) => {
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const intl = useIntl();
  const classes = tableWithProgressBarStyles();
  const buttnClasses = footprintDetailsStyles();
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);

  const updateTableData = () => {
    const updatedData: FootprintCategoryComponentModel[] = [];
    tableData.forEach((td) => {
      const updatedTableData = { ...td };
      if (td.name === "Juveniles") {
        const updatedSubcatedories: SubCategory[] = [];
        let subCategoryDataArray: {
          name: string;
          label: string;
          impact: number;
          impactAbsolute: number;
        }[] = [];
        td.subCategories?.forEach((sc) => {
          subCategoryDataArray = subCategoryDataArray.concat(
            sc.subCategoryData
          );
        });
        const results = subCategoryDataArray.reduce(
          (
            r: {
              name: string;
              label: string;
              impact: number;
              impactAbsolute: number;
            }[],
            o
          ) => {
            let temp = r.find((p) => o.label === p.label);
            if (!temp) return [...r, { ...o }];
            temp.impactAbsolute += o.impactAbsolute;
            temp.impact += o.impact;

            return r;
          },
          []
        );
        results.forEach((result) => {
          const updatedCat = {
            subCategoryGroup: "Animal Inputs",
            subCategoryData: [result],
            subCategoryName: result.name,
          };
          updatedSubcatedories.push(updatedCat);
        });

        updatedTableData.subCategories = updatedSubcatedories;
        updatedData.push(updatedTableData as FootprintCategoryComponentModel);
      } else {
        updatedData.push(td);
      }
    });
    return updatedData;
  };
  const [renderedTableData, setRenderedTableData] = useState<
    FootprintCategoryComponentModel[]
  >(updateTableData());

  const accordionClicked = (index: number) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const expandAll = () => {
    const newArray: number[] = [];
    renderedTableData?.forEach((data, index) => newArray.push(index));
    setExpandedAccordions(newArray);
  };

  const getLabelName = (
    subCatGroup: string,
    subCategoryName: string,
    value: { name: string; label: string }
  ) => {
    if (subCatGroup === "Feed") {
      return getFeedName(value.name, value.label, subCategoryName);
    }
    return value.label;
  };

  const getFeedName = (
    name: string,
    label: string,
    subCategoryName: string
  ) => {
    if (subCategoryName === "Natural Feed Use") {
      return "Natural Feed(s)";
    }
    if (
      label !== "single ingredient uses" &&
      subCategoryName !== "Fertilizer Use"
    ) {
      return name;
    }

    const singleIngredientName = name?.split(",")[0];
    const singleIngredientCountryCode = name?.split(",")[1];
    const origin = origins.find(
      (org) => org.value === singleIngredientCountryCode?.trim()
    );
    if (origin) {
      return `${singleIngredientName}, ${origin.text}`;
    }
    if (subCategoryName === "Fertilizer Use") {
      return label;
    }
    return name;
  };

  useEffect(() => {
    setRenderedTableData(updateTableData());
  }, []);

  const getFacilityName = (label: string): string => {
    if (label) {
      const facility: string[] = label.split(" - ");
      if (facility) {
        const facilityName: string[] = facility[1]?.split(",");
        if (facilityName) return facilityName[0];
      }
    }

    return label;
  };

  const getFootprintConvRatioValue = (value: number) => {
    const convRatio =
      !userUOM ||
      (userUOM && userUOM.unitEnvImpactPer === defaultUnits.unitEnvImpactPer)
        ? 1
        : explicitConvertValue(
            1,
            defaultUnits.unitEnvImpactPer,
            userUOM.unitEnvImpactPer,
          ) || 1;
    if (convRatio !== 1) {
      return value * convRatio;
    }
    return value;
  };

  return (
    <TableContainer className={`${classes.tableContainer}`}>
      {(isComparison || !isShrimp(animalType as AnimalType)) && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableCell} ${classes.categoryCell}`}
              >
                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                  {intl.formatMessage({ id: "SUSTELL.CATEGORY" })}
                </Typography>
              </TableCell>
              {isComparison && (
                <TableCell
                  className={`${classes.tableCell} ${classes.datasetCell}`}
                >
                  <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                    {intl.formatMessage({ id: "DATASET" })}
                  </Typography>
                </TableCell>
              )}
              <TableCell className={`${classes.tableCell} ${classes.dataCell}`}>
                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                  {intl.formatMessage({ id: "SUSTELL.DATA" })}
                </Typography>
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.infoCell}`}
              />
              {isComparison && (
                <TableCell
                  className={`${classes.tableCell} ${classes.differenceCell}`}
                >
                  <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                    {intl.formatMessage({
                      id: "REPORT.FOOTPRINTS.DIFFERENCE",
                    })}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedTableData?.map((item: FootprintCategoryComponentModel) => {
              const adjustedName = item.label.includes("Facility")
                ? getFacilityName(item.label)
                : getAdjustedLabel(item.name, animalType, intl);
              const adjustedGroup = getAdjustedLabel(
                item.analysisGroup,
                animalType,
                intl
              );
              const percentage = item.percentage || 0;
              const comparisonPercentage = item.comparison?.percentage;
              const changePercentage = item.comparisonChangePercentage;
              const barColor =
                componentsColors.find(
                  (comp) =>
                    adjustedGroup.toLowerCase() === comp.label.toLowerCase() ||
                    adjustedName.toLowerCase() === comp.label.toLowerCase()
                )?.color || DSM_DARK_GREY;
              return (
                <TableRow key={item.name}>
                  <TableCell
                    className={`${classes.valueCell} ${classes.categoreyNameCell}`}
                  >
                    {adjustedName}
                  </TableCell>
                  {isComparison && (
                    <TableCell className={`${classes.valueCell}`}>
                      <Typography
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        {intl.formatMessage({
                          id: "SUSTELL.FOOTPRINT.TABLE.COLUMNS.DATASETA",
                        })}
                      </Typography>
                      <Typography
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        {intl.formatMessage({
                          id: "SUSTELL.FOOTPRINT.TABLE.COLUMNS.DATASETB",
                        })}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell className={`${classes.valueCell}`}>
                    <StyledLinearProgress
                      variant="buffer"
                      color="primary"
                      style={{
                        height: "8px",
                        marginBottom: isComparison ? "15px" : "0px",
                        backgroundColor:
                          tableWithProgressBarColors.linearProgressBackground,
                        borderRadius: "4px",
                        transform:
                          percentage < 0 ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                      value={Math.abs(percentage)}
                      valueBuffer={Math.abs(
                        getValueBuffer(percentage, comparisonPercentage)
                      )}
                      secondbarcolor={getSecondBarColor(
                        percentage,
                        comparisonPercentage
                      )}
                      barcolor={barColor}
                    />
                    {isComparison && (
                      <StyledLinearProgress
                        variant="buffer"
                        color="primary"
                        style={{
                          height: "8px",
                          backgroundColor:
                            tableWithProgressBarColors.linearProgressBackground,
                          borderRadius: "4px",
                          transform:
                            (comparisonPercentage || 0) < 0
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                        value={Math.abs(comparisonPercentage || 0)}
                        valueBuffer={Math.abs(
                          getValueBuffer(comparisonPercentage, percentage)
                        )}
                        secondbarcolor={getSecondBarColor(
                          percentage,
                          comparisonPercentage
                        )}
                        barcolor={barColor}
                      />
                    )}
                  </TableCell>
                  <TableCell className={`${classes.valueCell}`}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: "12px",
                        color:
                          percentage < 0
                            ? tableWithProgressBarColors.negativeInfoValue
                            : tableWithProgressBarColors.infoValue,
                      }}
                    >
                      {`${Math.round(percentage)}% (`}
                      <ExactNumber value={getFootprintConvRatioValue(item?.amount || 0)} />
                      {` ${item.category.unit})`}
                    </Typography>
                    {isComparison && (
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color:
                            percentage < 0
                              ? tableWithProgressBarColors.negativeInfoValue
                              : tableWithProgressBarColors.infoValue,
                        }}
                      >
                        {isComparison &&
                          comparisonPercentage &&
                          `${Math.round(comparisonPercentage)}% (`}
                        {isComparison && (
                          <ExactNumber value={getFootprintConvRatioValue(item.comparison?.amount || 0)} />
                        )}
                        {isComparison &&
                          comparisonPercentage &&
                          ` ${item.comparison?.category.unit})`}
                        {!isComparison && !comparisonPercentage && "-"}
                      </Typography>
                    )}
                  </TableCell>
                  {isComparison && (
                    <TableCell className={`${classes.valueCell}`}>
                      <StyledChip
                        label={
                          changePercentage > 0
                            ? `+${changePercentage}%`
                            : `${changePercentage}%`
                        }
                        variant="outlined"
                        color={changePercentage > 0 ? "secondary" : "primary"}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!isComparison && isShrimp(animalType as AnimalType) && (
        <DsmGrid
          style={{
            grid: "auto /11fr 1fr",
            padding: "18px",
            alignItems: "start",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <Typography
            variant="body1"
            style={{ fontWeight: 500, fontSize: "16px" }}
          >
            {intl.formatMessage({ id: "SUSTELL.DETAILED.CONTRIBUTION" })}
          </Typography>
          {expandedAccordions.length === 0 && (
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<DsmIcon slot="before" name="arrows/chevron-down" />}
              className={buttnClasses.buttonStyles}
              style={{ width: "145px" }}
              onClick={expandAll}
            >
              {intl.formatMessage({
                id: "SUSTELL.FOOTPRINT.BUTTONS.EXPAND_ALL",
              })}
            </Button>
          )}
          {expandedAccordions.length > 0 && (
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<DsmIcon slot="before" name="arrows/chevron-up" />}
              className={buttnClasses.buttonStyles}
              style={{ width: "145px" }}
              onClick={collapseAll}
            >
              {intl.formatMessage({
                id: "SUSTELL.FOOTPRINT.BUTTONS.COLLAPSE_ALL",
              })}
            </Button>
          )}
        </DsmGrid>
      )}
      {!isComparison && isShrimp(animalType as AnimalType) && (
        <DsmGrid
          style={{
            grid: "auto /1fr 2fr",
            padding: "18px",
            alignItems: "start",
          }}
        >
          <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
            {intl.formatMessage({ id: "SUSTELL.CATEGORY" })}
          </Typography>
          <Typography
            style={{ fontSize: "12px", fontWeight: "500", paddingLeft: "35px" }}
          >
            {intl.formatMessage({ id: "SUSTELL.DATA" })}
          </Typography>
        </DsmGrid>
      )}
      {!isComparison &&
        isShrimp(animalType as AnimalType) &&
        renderedTableData?.map(
          (item: FootprintCategoryComponentModel, index: number) => {
            const adjustedName = item.label.includes("Facility")
              ? getFacilityName(item.label)
              : getAdjustedLabel(item.name, animalType, intl);
            const adjustedGroup = getAdjustedLabel(
              item.analysisGroup,
              animalType,
              intl
            );
            const percentage = item.percentage || 0;
            const barColor =
              componentsColors.find(
                (comp) =>
                  adjustedGroup.toLowerCase() === comp.label.toLowerCase() ||
                  adjustedName.toLowerCase() === comp.label.toLowerCase()
              )?.color || DSM_DARK_GREY;
            return (
              <div key={item.name}>
                <Accordion
                  key={item.name}
                  onChange={() => accordionClicked(index)}
                  expanded={expandedAccordions.includes(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      <DsmIcon slot="before" name="arrows/chevron-down" />
                    }
                  >
                    <Typography
                      style={{
                        width: "40%",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {adjustedName}
                    </Typography>

                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        width: "20%",
                        color:
                          percentage < 0
                            ? tableWithProgressBarColors.negativeInfoValue
                            : tableWithProgressBarColors.infoValue,
                      }}
                    >
                      {`${Math.round(percentage)}% (`}
                      <ExactNumber value={getFootprintConvRatioValue(item?.amount || 0)} />
                      {` ${item.category.unit})`}
                    </Typography>

                    <StyledLinearProgress
                      variant="buffer"
                      color="primary"
                      style={{
                        height: "8px",
                        marginBottom: "0px",
                        width: "40%",
                        backgroundColor:
                          tableWithProgressBarColors.linearProgressBackground,
                        borderRadius: "4px",
                        transform:
                          percentage < 0 ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                      value={Math.abs(percentage)}
                      barcolor={barColor}
                    />
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "inline-block" }}>
                    {item.subCategories &&
                      item.subCategories.length > 0 &&
                      item.subCategories.map((subCat: SubCategory) => {
                        return (
                          subCat.subCategoryGroup === item.lookupKey && (
                            <div style={{ paddingLeft: "18px" }}>
                              {subCat.subCategoryGroup === "Feed" &&
                                subCat.subCategoryData &&
                                subCat.subCategoryData.length > 0 && (
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      width: "256px",
                                    }}
                                  >
                                    {subCat.subCategoryName}
                                  </Typography>
                                )}
                              {subCat.subCategoryData &&
                                subCat.subCategoryData.length > 0 &&
                                subCat.subCategoryData.map((val) => {
                                  return (
                                    <DsmGrid
                                      style={{
                                        grid: "auto/1fr 1fr",
                                        paddingLeft: "18px",
                                        alignItems: "start",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          textTransform: "capitalize",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          width: "256px",
                                        }}
                                      >
                                        {getLabelName(
                                          subCat.subCategoryGroup,
                                          subCat.subCategoryName,
                                          val
                                        )}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "12px",
                                          marginLeft: "100px",
                                          color:
                                            percentage < 0
                                              ? tableWithProgressBarColors.negativeInfoValue
                                              : tableWithProgressBarColors.infoValue,
                                        }}
                                      >
                                        <ExactNumber
                                          value={val.impactAbsolute}
                                        />
                                        <span
                                          style={{
                                            color: "#808080",
                                            fontWeight: "400",
                                          }}
                                        >{` ${item.category.unit}.`}</span>
                                      </Typography>
                                    </DsmGrid>
                                  );
                                })}
                            </div>
                          )
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          }
        )}
    </TableContainer>
  );
};

export default TableWithProgressBar;
