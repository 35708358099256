import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { processAndStageStylesV2 } from "../../../_metronic/layout";
import { CSSClassesList } from "../../sustell_15/helpers/helperTypes";
import { FormType } from "../../sustell_15/components/FarmFlow/common";
import ReactHookDsmSelect from "./ReactHookDsmSelect2";
import ReactHookDsmInput from "./ReactHookDsmInput2";

import {
    CattleHousingAndManure,
    CattleManureManagementSystemType,
    ManurePhase,
} from "../../sustell_15/models/Baseline/CattleBaseline";

import {
    availableMmsTypeForMonthlyStoragePeriods,
    availableMmsTypeForOverOrUnderStoragePeriod
} from "../../sustell_15/models/Baseline/DairyV2Baseline";

import {
    DsmButton,
    DsmGrid,
    DsmIcon
} from "@dsm-dcs/design-system-react";

interface SustellMMSAdditionalItemProps {
    stageIndex?: number;
    mmsOptions: Array<{ text: string; value: string }>;
    storageOptions: Array<{ text: string; value: string }>;
    fieldItemPrefix: string;
    baseline?: CattleHousingAndManure;
    formType: FormType;
    isViewMode?: boolean;
    index: number;
    isDairyCowStage: boolean;
    handleRemove: (index: number) => void;
    isIntervention?: boolean;
}

type MMSInput = {
    mmsType: string;
    storagePeriod: string;
    share: string;
    id: string;
};

const SustellMMSAdditionalItem: FC<SustellMMSAdditionalItemProps> = ({
    formType = FormType.Add,
    isViewMode = false,
    mmsOptions,
    storageOptions,
    fieldItemPrefix,
    isDairyCowStage,
    index = 0,
    isIntervention = false,
    handleRemove,
}) => {
    const [selectedMmsTypes, setSelectedMmsTypes] = useState<{
        index: number;
        mmsType: CattleManureManagementSystemType;
    }>();
    const [availableStorageOptions, setAvailableStorageOptions] = useState<{
        [key: string | number]: Array<{ text: string; value: string }>;
    }>({});

    const intl = useIntl();
    const fc = useFormContext();
    const mmsInputsStyle = { height: "60px" };
    const classes = processAndStageStylesV2() as CSSClassesList;

    const getSelectedMms = () => {
        const selectedMms: Array<MMSInput> =
            fc.getValues(fieldItemPrefix) || [];
        return selectedMms;
    };

    const isSingleMms = () => {
        const selectedMms = getSelectedMms();
        return selectedMms.length === 1;
    };

    const isMmsTypeAvailableForMonthlyRanges = (
        selectedMmsTypes: CattleManureManagementSystemType
    ) =>
        availableMmsTypeForMonthlyStoragePeriods.some(
            (mmsType) => mmsType === selectedMmsTypes
        );

    const isMmsTypeAvailableForOverOrUnder = (
        selectedMmsTypes: CattleManureManagementSystemType
    ) =>
        availableMmsTypeForOverOrUnderStoragePeriod.some(
            (mmsType) => mmsType === selectedMmsTypes
        );

    const isStorageFieldRequired = (
        seletedMmsType: CattleManureManagementSystemType
    ) =>
        isMmsTypeAvailableForMonthlyRanges(seletedMmsType) ||
        isMmsTypeAvailableForOverOrUnder(seletedMmsType);

    const filterAvailableMmsOptions = ({
        index,
        mmsType,
    }: {
        index: number;
        mmsType: CattleManureManagementSystemType;
    }) => {
        if (!isStorageFieldRequired(mmsType)) {
            fc.setValue(`${fieldItemPrefix}.${index}.storagePeriod`, "");
            setAvailableStorageOptions((previousOptions) => ({
                ...previousOptions,
                [index]: [],
            }));
            return;
        }

        if (isMmsTypeAvailableForOverOrUnder(mmsType)) {
            setAvailableStorageOptions((previousOptions) => ({
                ...previousOptions,
                [index]: storageOptions.filter(
                    (option) =>
                        option.value.includes("over") || option.value.includes("under")
                ),
            }));
            return;
        }

        setAvailableStorageOptions((previousOptions) => ({
            ...previousOptions,
            [index]: storageOptions.filter(
                (option) =>
                    !option.value.includes("over") && !option.value.includes("under")
            ),
        }));
    };

    useEffect(() => {
        if (selectedMmsTypes && Object.keys(selectedMmsTypes).length) {
            filterAvailableMmsOptions(selectedMmsTypes);
        }
    }, [selectedMmsTypes]);

    const getMmsTypeDefaulttValue = (
        index: number
    ): CattleManureManagementSystemType =>
        fc.getValues(`${fieldItemPrefix}.${index}.mmsType`);

    const updateManurePhase = (
        type: CattleManureManagementSystemType,
        index: number
    ) => {
        if (type === CattleManureManagementSystemType.DailySpread)
            fc.setValue(
                `${fieldItemPrefix}.${index}.manurePhase`,
                ManurePhase.LiquidSlurry
            );
        else
            fc.setValue(
                `${fieldItemPrefix}.${index}.manurePhase`,
                undefined
            );
    };

    return (
        <DsmGrid className={classes.dsmGridFourColumnFirstBigOthersSmall}>
            <ReactHookDsmSelect
                style={mmsInputsStyle}
                label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE',
                })}
                name={`${fieldItemPrefix}.${index}.mmsType`}
                options={mmsOptions}
                disabled={isViewMode || formType === 'view'}
                defaultValue={fc.getValues(
                    `${fieldItemPrefix}.${index}.mmsType`
                )}
                changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedMmsTypes({
                        index,
                        mmsType: e.target
                            .value as CattleManureManagementSystemType,
                    });
                    updateManurePhase(
                        e.target.value as CattleManureManagementSystemType,
                        index
                    );
                }}
                tooltip={intl.formatMessage({
                    id: isDairyCowStage
                        ? 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE.TOOLTIP'
                        : 'SUSTELL.STAGE.DAIRY.MMS.CONTINUOUS_GROWING.MMS_TYPE.TOOLTIP',
                })}
                required
            />

            {isStorageFieldRequired(getMmsTypeDefaulttValue(index)) ? (
                <ReactHookDsmSelect
                    style={mmsInputsStyle}
                    label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_STORAGE_PERIOD',
                    })}
                    name={`${fieldItemPrefix}.${index}.storagePeriod`}
                    options={
                        availableStorageOptions[index] ||
                        filterAvailableMmsOptions({
                            index,
                            mmsType: getMmsTypeDefaulttValue(index),
                        })
                    }
                    disabled={isViewMode || formType === 'view'}
                    defaultValue={fc.getValues(
                        `${fieldItemPrefix}.${index}.storagePeriod`
                    )}
                    required={isStorageFieldRequired(getMmsTypeDefaulttValue(index))}
                />
            ) : (
                <div />
            )}

            {(!isSingleMms() || isIntervention) && (
                <ReactHookDsmInput
                    style={mmsInputsStyle}
                    label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_SHARE',
                    })}
                    type="text"
                    name={`${fieldItemPrefix}.${index}.share`}
                    disabled={isViewMode || formType === 'view'}
                    defaultValue={fc.getValues(
                        `${fieldItemPrefix}.${index}.share`
                    )}
                    required
                    tooltip={intl.formatMessage({
                        id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_SHARE.TOOLTIP',
                    })}
                    adornment="%"
                    changeHandler={() => fc.trigger(fieldItemPrefix)}
                />
            )}

            {handleRemove && formType !== 'view' && !isViewMode && (
                <DsmButton
                    variant="text"
                    iconOnly
                    destructive
                    onClick={() => handleRemove(index)}
                    style={{
                        padding: "0px",
                        margin: "24px 0px 0px 0px",
                    }}
                >
                    <DsmIcon
                        slot="before"
                        name="general/trash-01"
                        style={{ margin: 0, padding: 0 }}
                    />
                </DsmButton>
            )}
        </DsmGrid>
    )
}

export default SustellMMSAdditionalItem;