/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { sortedUniqBy } from "lodash";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { FormType, ShrimpFarms } from "../../common";
import {
  ShrimpBaseline,
  ShrimpStage,
} from "../../../../models/Baseline/ShrimpBaseline";
import {
  AnimalType,
  StageType,
  TransportMode,
} from "../../../../../../graphql/types";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";

interface ShrimpInputSupplyComponentProperties {
  farms: ShrimpFarms[];
  farmId: string;
  farmName: string;
  stageIndex: number;
  itemIndex: number;
  productionProcessName: string;
  formType: FormType;
  // eslint-disable-next-line react/require-default-props
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/ban-types
  isSupplyEdit: boolean;
  // eslint-disable-next-line react/require-default-props
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
  stageType: StageType;
  baseline: ShrimpBaseline;
}

const ShrimpInputSupplyComponent: FC<ShrimpInputSupplyComponentProperties> = ({
  farms,
  farmId,
  farmName,
  stageIndex = 0,
  itemIndex = 0,
  productionProcessName,
  formType,
  isSupplyEdit,
  animalType,
  stageType,
  baseline,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.internalSources.${itemIndex}`;
  const intl = useIntl();
  const inputData =
    baseline.stages[stageIndex].stageData.input.internalSources[itemIndex];

  let stageTypeCode = "";
  switch (stageType) {
    case StageType.Nursery:
      stageTypeCode = "POST_LARVAE";
      break;
    case StageType.Growing:
    default:
      stageTypeCode = "JUVENILES";
      break;
  }

  const getAnimalSystemTypeLabel = (at: string) => {
    switch (at) {
      case "LITOPENAEUS_VANNAMEI":
        return "Litopenaeus Vannamei";
      case "PENAEUS_MONODON":
        return "Penaeus Monodon";
      default:
        return "";
    }
  };

  const farmOptions = () => {
    // Filter out the Farm that are not relevant
    const resultFarms = farms
      .filter((farm) => farm.farmId === inputData.comingFromFarm)
      .map((farm) => ({ text: farm.farmName, value: farm.farmId }));
    // Add farm for currently opened stages
    if (!resultFarms || resultFarms.length === 0)
      resultFarms.push({ text: farmName, value: farmId });
    // Because a farm can have multiple processes and baselines, its entry can be duplicated
    return sortedUniqBy(resultFarms, "value");
  };

  const stageOptions = () => {
    const currentlySelectedFarm: string = inputData.comingFromFarm as string;
    const currentlySelectedStage: string = inputData.comingFromStage as string;
    const currentBaselineValues = baseline.stages.map((stage: ShrimpStage) => ({
      stageId: stage.id,
      stageName: stage.name,
      farmId,
      productionProcessName,
    }));
    return [...farms, ...currentBaselineValues]
      .filter((farm) => farm.stageId === currentlySelectedStage)
      .filter((farm) => farm.farmId === currentlySelectedFarm)
      .map((farm) => ({
        text: `${farm.productionProcessName} (${getAnimalSystemTypeLabel(
          animalType
        )}) ${farm.stageName}`,
        value: farm.stageId,
      }));
  };

  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : "kg";

  const selectedTransportMode = useWatch({
    name: `${fieldItemPrefix}.transportMode`,
  });
  const [distanceUnit, setDistanceUnit] = useState(
    userUOM.unitTransportDistanceTerrestrial
  );
  useEffect(() => {
    switch (selectedTransportMode) {
      case TransportMode.InlandShip:
        setDistanceUnit(userUOM.unitTransportDistanceInlandWaterways);
        break;
      case TransportMode.SeaShip:
        setDistanceUnit(userUOM.unitTransportDistanceSea);
        break;
      case TransportMode.Train:
      case TransportMode.Truck:
        setDistanceUnit(userUOM.unitTransportDistanceTerrestrial);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransportMode]);

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={inputData.id}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.originBaselineId`}
        value={fc.getValues(`${fieldItemPrefix}.originBaselineId`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={fc.getValues(`${fieldItemPrefix}.stageType`)}
      />
      <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
        <div
          style={{ marginBottom: "var(--dsm-spacing-px-4)", marginTop: "10px" }}
        >
          <ReactHookDsmSelect
            style={{ width: "100%" }}
            name={`${fieldItemPrefix}.comingFromFarm`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_FARM",
            })}`}
            disabled
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_FARM.PLACEHOLDER",
            })}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.COMING_FROM_FARM.TOOLTIP`,
            })}
            options={farmOptions()}
            defaultValue={inputData.comingFromFarm as string}
          />
        </div>
        <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
          <ReactHookDsmSelect
            style={{ width: "100%" }}
            name={`${fieldItemPrefix}.comingFromStage`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_STAGE",
            })}`}
            disabled
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.COMING_FROM_STAGE.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.COMING_FROM_STAGE.PLACEHOLDER",
            })}
            options={stageOptions()}
            defaultValue={inputData.comingFromStage as string}
          />
        </div>
        <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
          <ReactHookDsmSelect
            style={{ width: "100%" }}
            name={`${fieldItemPrefix}.outputName`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.OUTPUT_NAME",
            })}`}
            disabled
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.OUTPUT_NAME.PLACEHOLDER",
            })}
            options={[
              {
                text: inputData.outputName as string,
                value: inputData.outputName as string,
              },
            ]}
            defaultValue={inputData.outputName as string}
          />
        </div>
        {!isSupplyEdit && !fc.getValues(`${fieldItemPrefix}.totalWeight`) ? (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.originalTotalWeight`}
            label={`${intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT`,
            })}`}
            disabled
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT.TOOLTIP`,
            })}
            adornment={barnOutputMassUnit}
            defaultValue={inputData.totalWeight}
          />
        ) : (
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.totalWeight`}
            label={`${intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT`,
            })}`}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT.TOOLTIP`,
            })}
            metricUnit={intl.formatMessage(
              { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
              { unit: barnOutputMassUnit }
            )}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={inputData.totalWeight}
            disabled={formType === FormType.View || !isSupplyEdit}
            minAllowedValue={0}
            displayFormattedUnit={false}
            originalMetricUnit={barnOutputMassUnit}
            displayTypeSelect={isSupplyEdit}
          />
        )}
        {!isSupplyEdit &&
        !fc.getValues(`${fieldItemPrefix}.distanceTraveled`) ? (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.originalDistanceTraveled`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.DISTANCE_TRAVELED",
            })}`}
            disabled
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.SUPPLY.DISTANCE_TRAVELED.TOOLTIP`,
            })}
            adornment={distanceUnit}
            defaultValue={inputData.distanceTraveled}
          />
        ) : (
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.distanceTraveled`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.DISTANCE_TRAVELED",
            })}`}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.SUPPLY.DISTANCE_TRAVELED.TOOLTIP`,
            })}
            metricUnit={distanceUnit}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={inputData.distanceTraveled}
            disabled={formType === FormType.View || !isSupplyEdit}
            minAllowedValue={0}
            displayTypeSelect={isSupplyEdit}
          />
        )}

        {!isSupplyEdit &&
        (!fc.getValues(`${fieldItemPrefix}.transportMode`) ||
          fc.getValues(`${fieldItemPrefix}.transportMode`) ===
            inputData.transportMode) ? (
          <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
            <ReactHookDsmSelect
              style={{ width: "100%" }}
              name={`${fieldItemPrefix}.originalTransportMode`}
              label={`${intl.formatMessage({
                id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE",
              })}`}
              disabled
              tooltip={intl.formatMessage({
                id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TRANSPORT_MODE.TOOLTIP`,
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE.PLACEHOLDER",
              })}
              options={Object.keys(TransportMode).map((key) => ({
                value: TransportMode[key as keyof typeof TransportMode],
                text: TransportMode[key as keyof typeof TransportMode]
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
              }))}
              defaultValue={inputData.transportMode as string}
            />
          </div>
        ) : (
          <RowSelectWithMetricsV2
            name={`${fieldItemPrefix}.transportMode`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE",
            })}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TRANSPORT_MODE.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE.PLACEHOLDER",
            })}
            disabled={formType === FormType.View || !isSupplyEdit}
            options={Object.keys(TransportMode).map((key) => ({
              value: TransportMode[key as keyof typeof TransportMode],
              text: TransportMode[key as keyof typeof TransportMode]
                .replace(/_/g, " ")
                .toLowerCase()
                .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            }))}
            baseline={inputData.transportMode}
            needMarginBottom={false}
          />
        )}
      </DsmGrid>
    </>
  );
};

export default ShrimpInputSupplyComponent;
