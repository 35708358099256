import { FC, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";

import { BaselineDialogProps, FormType, ShrimpFarms } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";

import { cloneDeep } from "lodash";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";

import { AnimalType } from "../../../../../../graphql/types";
import { ShrimpBaseline } from "../../../../models/Baseline/ShrimpBaseline";
import ShrimpOutputForm from "./ShrimpOutputForm";
import { Button, Typography } from "@material-ui/core";
import {
  ShrimpIntervention,
  ShrimpOutputs,
  ShrimpStageData,
} from "../../../../models/Intervention/ShrimpIntervention";
import SGrowingOutputForm from "../../Baseline/Shrimp/SGrowingOutputForm";
import { v4 as uuidv4 } from "uuid";
import React from "react";

interface ShrimpOutputDialogProps extends BaselineDialogProps {
  farms: ShrimpFarms[];
  formType: FormType;
  stageIndex: number;
  formVisible: boolean;
  handleCancel: (param?: string | null) => void;
  baseline: ShrimpBaseline;
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

const ShrimpOutputDialog: FC<ShrimpOutputDialogProps> = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
  animalType,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const formTitle = intl.formatMessage({
    id: "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE",
  });

  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fieldItemPrefixAdditions = `stages.${stageIndex}.stageData.output.outputAdditions`;

  const currResetValue = useRef<ShrimpOutputs>();
  const fc = useFormContext<ShrimpIntervention>();

  const currentStageData = baseline.stages[stageIndex].stageData;

  const [isOutputEdit, setisOutputEdit] = useState<boolean>(false);

  const { control } = useFormContext<ShrimpBaseline>();

  const {
    fields: outputs,
    append: addOutput,
    remove: removeOutput,
  } = useFieldArray({
    name: `${fieldItemPrefixAdditions}`,
    control,
    keyName: "keyId",
  });

  const addOutputForm = () => {
    addOutput({
      id: uuidv4(),
      name: "",
      price: "",
      totalWeight: "",
      soldExternally: "",
      proteinContent: "",
    });
  };

  const removeOutputForm = (index: number) => {
    removeOutput(index);
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as ShrimpIntervention;

      const stageData =
        (resetObject.stages?.[stageIndex]
          ?.stageData as unknown as ShrimpStageData) || {};

      if (resetObject?.stages?.[stageIndex])
        resetObject.stages[stageIndex].stageData = stageData;

      if (stageData?.output) {
        stageData.output = currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  const cancelOutputEdit = () => {
    (baseline.stages[stageIndex].stageData.output?.outputs || [])
      ?.filter((item) => !!item.name)
      ?.forEach((_, index) => {
        fc.setValue(`${fieldItemPrefix}.outputs.${index}.price`, "");
        fc.setValue(`${fieldItemPrefix}.outputs.${index}.totalWeight`, "");
        fc.setValue(`${fieldItemPrefix}.outputs.${index}.proteinContent`, "");
      });
    for (let index = outputs.length - 1; index >= 0; index--) {
      removeOutput(index);
    }
    setisOutputEdit(false);
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant="interventionWide"
      datasetType="intervention"
    >
      <DsmGrid
        className={classes.dsmGridOneColumnWithBorder}
        style={{ padding: "var(--dsm-spacing-px-4)" }}
      >
        {currentStageData.output.outputs &&
          currentStageData.output.outputs.map((item, index) => (
            <>
              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <p>{currentStageData.output.outputs[index].name}</p>
              </Typography>

              <ShrimpOutputForm
                key={item.id}
                stageIndex={stageIndex}
                itemIndex={index}
                formType={formType}
                removeHandler={removeOutputForm}
                baseline={baseline}
                editEnabled={isOutputEdit}
              />

              {index === currentStageData.output.outputs.length ? (
                <></>
              ) : (
                <br />
              )}
            </>
          ))}

        {outputs.length > 0 ? (
          outputs &&
          outputs.map((item, index) => (
            <>
              <SGrowingOutputForm
                key={item.keyId}
                stageIndex={stageIndex}
                itemIndex={index}
                formType={isOutputEdit ? FormType.Add : FormType.View}
                isIntervention
                removeHandler={removeOutputForm}
                animalType={animalType}
              />
            </>
          ))
        ) : (
          <></>
        )}

        <div
          id="button-div"
          style={{
            color: "var(--dsm-color-neutral-darker",
            left: "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            href=""
            style={{
              textDecoration: "underline",
              color: "#0070BA",
              display: !isOutputEdit && formType !== "view" ? "flex" : "none",
              placeSelf: "start",
              textTransform: "none",
            }}
            onClick={() => setisOutputEdit(true)}
            disabled={formType === "view" || isOutputEdit === true}
          >
            <DsmIcon
              slot="before"
              name="editor/pencil-01"
              style={{ paddingRight: "5px", height: "24px", width: "24px" }}
            />
            {intl.formatMessage({ id: "GENERAL.EDIT_INPUT" })}
          </Button>

          {isOutputEdit === true && (
            <>
              <Button
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#0070BA",
                  placeSelf: "start",
                  textTransform: "none",
                }}
                onClick={addOutputForm}
                disabled={formType === "view"}
              >
                <DsmIcon
                  slot="before"
                  name="general/plus-circle"
                  style={{ paddingRight: "5px", height: "24px", width: "24px" }}
                />
                {intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.SHRIMP.ADD_OUTPUT",
                })}
              </Button>

              <Button
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#FF0000",
                  placeSelf: "start",
                  textTransform: "none",
                }}
                onClick={() => cancelOutputEdit()}
                disabled={formType === "view"}
              >
                <DsmIcon
                  slot="before"
                  name="general/minus-circle"
                  style={{ paddingRight: "5px", height: "24px", width: "24px" }}
                />
                {intl.formatMessage({ id: "GENERAL.CANCEL" })}
              </Button>
            </>
          )}
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={() => {
          fc.trigger(fieldItemPrefix);
          handleCancel();
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default ShrimpOutputDialog;
