/* eslint-disable no-param-reassign */
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
// import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { addDateProperties } from '../../../../sustell_15/utils/datetime-utils';
import { emissionFieldsMapInV2 } from './emissionFields';
import { getImpactData } from '../../../../sustell_15/components/helpers/ImpactNumbers';
import { floatValueInUserUnits } from '../../Baseline/mapper/inCommonMapperSustell';
import { interventionFloatValueInUserUnits } from './inCommonMapperSustell';
import { StageType } from '../../../../../graphql/types';
import mapProcessingInStage from './inProcessingMapperSustell';

export const mapResourceUse = (resourceUseData, formDataResourceObj) => {
  const formData = formDataResourceObj;
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const electricity = resourceUseData?.electricityUse;
  if (electricity || electricity === 0) {
    formData.electricityUse = interventionFloatValueInUserUnits(
      electricity.value,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse,
      electricity.change_type,
      4
    );
    formData.electricityUse_changeMetric =
      electricity.change_type || 'relative';
  }

  const gas = resourceUseData?.gasUse;
  if (gas || gas === 0) {
    formData.gasUse = interventionFloatValueInUserUnits(
      gas.value,
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse,
      gas.change_type,
      4
    );
    formData.gasUse_changeMetric = gas.change_type || 'relative';
  }

  const water = resourceUseData?.waterUse;
  if (water || water === 0) {
    formData.waterUse = interventionFloatValueInUserUnits(
      water.value,
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse,
      water.change_type,
      3
    );
    formData.waterUse_changeMetric = water.change_type || 'relative';
  }

  const electricityPhotovoltaicUse =
    resourceUseData?.electricityPhotovoltaicUse;
  if (electricityPhotovoltaicUse || electricityPhotovoltaicUse === 0) {
    formData.electricityPhotovoltaicUse = interventionFloatValueInUserUnits(
      electricityPhotovoltaicUse.value,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse,
      electricityPhotovoltaicUse.change_type,
      4
    );
    formData.electricityPhotovoltaicUse_changeMetric =
      electricityPhotovoltaicUse.change_type || 'relative';
  }

  const electricityWindUse = resourceUseData?.electricityWindUse;
  if (electricityWindUse || electricityWindUse === 0) {
    formData.electricityWindUse = interventionFloatValueInUserUnits(
      electricityWindUse.value,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse,
      electricityWindUse.change_type,
      4
    );
    formData.electricityWindUse_changeMetric =
      electricityWindUse.change_type || 'relative';
  }

  const diesel = resourceUseData?.dieselUse;
  if (diesel || diesel === 0) {
    formData.dieselUse = interventionFloatValueInUserUnits(
      diesel.value,
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse,
      diesel.change_type,
      4
    );
    formData.dieselUse_changeMetric = diesel.change_type || 'relative';
  }

  const gasoline = resourceUseData?.gasolineUse;
  if (gasoline || gasoline === 0) {
    formData.gasolineUse = interventionFloatValueInUserUnits(
      gasoline.value,
      userUOM.unitGasolineUse,
      defaultMetric.unitGasolineUse,
      gasoline.change_type,
      3
    );
    formData.gasolineUse_changeMetric = gasoline.change_type || 'relative';
  }

  const heavyFuelOil = resourceUseData?.heavyFuelOilUse;
  if (heavyFuelOil || heavyFuelOil === 0) {
    formData.heavyFuelOilUse = interventionFloatValueInUserUnits(
      heavyFuelOil.value,
      userUOM.unitHeavyFuelOilUse,
      defaultMetric.unitHeavyFuelOilUse,
      heavyFuelOil.change_type,
      3
    );
    formData.heavyFuelOilUse_changeMetric = heavyFuelOil.change_type || 'relative';
  }

  const propaneOrLpg = resourceUseData?.propaneOrLpgUse;
  if (propaneOrLpg || propaneOrLpg === 0) {
    formData.propaneOrLpgUse = interventionFloatValueInUserUnits(
      propaneOrLpg.value,
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse,
      propaneOrLpg.change_type,
      3
    );
    formData.propaneOrLpgUse_changeMetric =
      propaneOrLpg.change_type || 'relative';
  }

  const coal = resourceUseData?.coalUse;
  if (coal || coal === 0) {
    formData.coalUse = interventionFloatValueInUserUnits(
      coal.value,
      userUOM.unitHeatUse,
      defaultMetric.unitHeatUse,
      coal.change_type,
      3
    );
    formData.coalUse_changeMetric = coal.change_type || 'relative';
  }
  return formData;
};

export const createCoreForm = (inData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const tempValue = floatValueInUserUnits(
    inData.averageAnnualTemperature,
    userUOM.unitAverageAnnualTemperature,
    defaultMetric.unitAverageAnnualTemperature,
    1
  );

  const result = {
    id: inData.id,
    farmId: inData.farmId,
    productionProcessId: inData.productionProcessId,
    baseline: inData.baseline,
    info: {
      name: inData.name,
      oldName: inData.oldName || inData.name,
      description: inData.description,
      interventionCategory: inData.category || '',
    },
    resourceUse: {},
    materialUse: {},
    beddingUse: {},
    stages: [],
    copyFrom: 'New',
    updatedAt: inData.updatedAt,
    lastUpdatedBy: inData.lastUpdatedBy,
    // used for Footprint box
    impactSummary: {},
  };

  if (tempValue !== undefined) result.info.avgAnualTemperature = tempValue;
  if (inData.age !== undefined) result.age = inData.age;

  // add date fileds
  addDateProperties(result, 'updatedAt');

  return result;
};

export const mapCompoundFeedForOneStage = (feedingProgramData, formDataFeedObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  // check feeds ration
  if (
    feedingProgramData?.compoundFeedUses &&
    Array.isArray(feedingProgramData.compoundFeedUses)
  ) {
    const compoundFeeds = [];
    feedingProgramData.compoundFeedUses.forEach((oneFeedData) => {
      const feedChangeMetric = oneFeedData.quantity?.change_type || 'relative';
      let value = oneFeedData.quantity?.value;
      // eslint-disable-next-line no-restricted-globals
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      const oneCompoundFeed = {
        feedType: oneFeedData.compoundFeedId,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      compoundFeeds.push(oneCompoundFeed);
    });
    formDataFeedObj.compoundFeeds = compoundFeeds;
  }

  // check feeds additional compound feed
  if (
    feedingProgramData?.compoundFeedUsesAdditions &&
    Array.isArray(feedingProgramData.compoundFeedUsesAdditions)
  ) {
    const compoundFeedsAdditions = [];
    feedingProgramData.compoundFeedUsesAdditions.forEach((addnlFeedData) => {
      let value = addnlFeedData.quantity;
      // eslint-disable-next-line no-restricted-globals
      if (value && !isNaN(value))
        value = floatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          precision
        );
      const oneCompoundFeed = {
        feedType: addnlFeedData.compoundFeedId,
        kgPerAnimal: value,
      };
      compoundFeedsAdditions.push(oneCompoundFeed);
    });
    formDataFeedObj.compoundFeedsAdditions = compoundFeedsAdditions;
  }
};

export const mapSingleIngridientsForOneStage = (
  feedingProgramData,
  formDataFeedObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  if (
    feedingProgramData?.singleIngredientUses &&
    Array.isArray(feedingProgramData.singleIngredientUses)
  ) {
    const singleIngredients = [];
    feedingProgramData.singleIngredientUses.forEach((oneFeedData) => {
      const feedChangeMetric = oneFeedData.quantity?.change_type || 'relative';
      let value = oneFeedData.quantity?.value;
      // eslint-disable-next-line no-restricted-globals
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      const oneSingleFeed = {
        id: oneFeedData.ingredient.id,
        feedType: oneFeedData.ingredient.name,
        origin: oneFeedData.ingredient.origin,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      singleIngredients.push(oneSingleFeed);
    });
    formDataFeedObj.singleIngredients = singleIngredients;
  }

  if (
    feedingProgramData?.singleIngredientUsesAdditions &&
    Array.isArray(feedingProgramData.singleIngredientUsesAdditions)
  ) {
    const singleIngredientsAdditions = [];
    feedingProgramData.singleIngredientUsesAdditions.forEach(
      (addnlFeedData) => {
        let value = addnlFeedData.quantity;
        // eslint-disable-next-line no-restricted-globals
        if (value && !isNaN(value))
          value = floatValueInUserUnits(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            precision
          );
        const oneSingleFeed = {
          id: addnlFeedData.ingredient.id,
          feedType: addnlFeedData.ingredient.name,
          origin: addnlFeedData.ingredient.origin,
          kgPerAnimal: value,
        };
        singleIngredientsAdditions.push(oneSingleFeed);
      }
    );
    formDataFeedObj.singleIngredientsAdditions = singleIngredientsAdditions;
  }
};

export const mapFeedingProgramForOneStage = (
  stageFeedData,
  formDataFeedObj
) => {
  mapCompoundFeedForOneStage(stageFeedData, formDataFeedObj);
  mapSingleIngridientsForOneStage(stageFeedData, formDataFeedObj);

  return formDataFeedObj;
};

export const mapEmissionsForOneStage = (inputEmissions, formDataObj) => {
  // check if emission data exists for this barn
  const formData = formDataObj;
  if (inputEmissions && Array.isArray(inputEmissions)) {
    // first try with the same index - should be at same position
    inputEmissions.forEach((emission) => {
      // Restore the original intervention that was made on emission field only, for the sake of correct form input
      const fieldName = emissionFieldsMapInV2.get(emission.emissionType);
      const changeMetric = emission.change?.change_type;
      formData[fieldName] = emission.change?.value;
      // this adapts all previously saved intervention, that uses 'percentage' as change type to display correctly the value as set
      formData[`${fieldName}_changeMetric`] =
        !changeMetric || changeMetric === 'percentage' ? 'set' : changeMetric;
    });
  }
};

const mapStageData = (interventionStages, formData) => {
  if (interventionStages && Array.isArray(interventionStages)) {
    interventionStages.forEach((oneStageIntervention) => {
      const stageData = oneStageIntervention.stageData || {};
      // let stageData;
      // if (typeof oneStageIntervention.stageData === 'string')
      //   stageData = JSON.parse(oneStageIntervention.stageData) || {};
      // else stageData = oneStageIntervention.stageData || {};

      if (oneStageIntervention.type === StageType.Processing) {
        const oneStageFormData = {
          id: oneStageIntervention?.id,
          type: 'PROCESSING',
          facilityId: oneStageIntervention?.facilityId,
          name: oneStageIntervention?.name,
          stageData: {
            processingInput: {},
            processingOutput: {},
            processingPackaging: {}
          },
        };
        // eslint-disable-next-line no-param-reassign
        oneStageIntervention.stageData = stageData;
        oneStageFormData.stageData = mapProcessingInStage(oneStageIntervention.stageData, oneStageFormData.stageData);
        formData.stages.push(oneStageFormData);
      } else {
        const oneStageFormData = {
          id: oneStageIntervention?.id,
          name: oneStageIntervention?.name,
          stageData: {
            input: {
              // startDate: oneStageIntervention.startDate || '',
              // endDate: oneStageIntervention.endDate || '',
            },
            feed: {
              compoundFeeds: [],
              compoundFeedsAdditions: [],
              singleIngredients: [],
              singleIngredientsAdditions: [],
            },
            emissions: {},
            housing: {},
            output: {},
          },
        };

        // mapBeddingForOneStage(stageData?.feedingProgram, oneStageFormData.stageData.manure);
        mapFeedingProgramForOneStage(
          stageData?.feedingProgram,
          oneStageFormData.stageData.feed
        );
        mapEmissionsForOneStage(
          stageData?.emissionMitigations,
          oneStageFormData.stageData.emissions
        );

        formData.stages.push(oneStageFormData);
      }
    });
  }
  return formData;
};

export const mapCommonInterventionDataIn = (inData) => {
  const formData = createCoreForm(inData);
  mapStageData(inData.stages, formData);
  getImpactData(inData, formData);
  return formData;
};
