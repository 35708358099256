import { capitalize } from "lodash";
import { CompoundFeedDatabase, Maybe } from "../../../graphql/types";
import lookupValues from "../helpers/lookupValues";
import { CFDatabaseFoundation } from "../models/CompoundFeed";
import { IntlShape } from "react-intl";

export const getUIDatabaseFoundation = (db?: Maybe<CompoundFeedDatabase>) => {
  if (db === CompoundFeedDatabase.Gfli) return CFDatabaseFoundation.GFLI;
  if (db === CompoundFeedDatabase.Afp_6_3) return CFDatabaseFoundation.AFP_6_3;
  // default
  return CFDatabaseFoundation.AFP_5;
};

export const getDatabase = (db?: Maybe<CFDatabaseFoundation>) => {
  if (db === CFDatabaseFoundation.GFLI) return CompoundFeedDatabase.Gfli;
  if (db === CFDatabaseFoundation.AFP_6_3) return CompoundFeedDatabase.Afp_6_3;
  // default
  return CompoundFeedDatabase.Afp_5;
};

export const getDefaultIngredientsAddonsNames = (): string[] => {
  const names: string[] = [];
  lookupValues.defaultIngredientsAddons.forEach((item) => {
    names.push(...item.ingredients.map((ingredeint) => ingredeint.name));
  });

  return names;
};

export const formatAnimalSystem = (
  intl: IntlShape,
  animalSystems?: Maybe<string>[],
  databaseFoundation?: Maybe<CompoundFeedDatabase>
) => {
  const formatedSystems: string[] = [];

  animalSystems?.forEach((animalSystem) => {
    if (animalSystem) {
      if (animalSystem.toLowerCase().includes("all animal")) {
        formatedSystems.push(
          intl.formatMessage({
            id: "COMPOUNDFEEDS.ALL_TYPES",
          })
        );
      } else {
        const formattedString = animalSystem
          .replace("_", " ")
          .replace("V2", "")
          .toLowerCase()
          .replace(" system", "")
          .replace("layer", "laying hen")
          .replace("fattening", "");

        const nameComplement =
          databaseFoundation === CompoundFeedDatabase.Gfli ||
          databaseFoundation === CompoundFeedDatabase.Afp_6_3
            ? " 2.0"
            : " 1.0";
        if (formattedString.includes("1.0")) {
          formatedSystems.push(capitalize(formattedString));
        } else {
          formatedSystems.push(capitalize(formattedString + nameComplement));
        }
      }
    }
  });

  return formatedSystems;
};
