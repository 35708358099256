import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DsmIcon, DsmButton, DsmGrid } from "@dsm-dcs/design-system-react";
import { FormType, ListEntry, MMSSystemsBlockProps } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { getManureFormForSingleEntry } from "../../../../helpers/manureManagementSystemsLookups";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";

import { ManureManagementSystem } from "../../../../models/Baseline/PigBaseline";
import {
  ManureForm,
  ManureManagementSystemType,
} from "../../../../../../graphql/generated/blonk/pigs";
import { Maybe } from "../../../../../../graphql/types";

interface MMSBlockComponentProps extends MMSSystemsBlockProps {
  manureSystem: ManureManagementSystem;
}
const MMSBlockComponent: FC<MMSBlockComponentProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType = FormType.View,
  manureManagementSystems,
  filteredMMSList,
  removeHandler,
  mmsChangeHandler,
  manureSystem,
}) => {
  const intl = useIntl();

  const classes = processAndStageStylesV2() as any;

  const fieldItemPrefix = `stages.${stageIndex}.stageData.housing.manureSystems.${itemIndex}`;
  const [matchingStorages, setMatchingStorages] = useState<ListEntry[]>([]);

  const formContext = useFormContext();
  const { getValues, setValue } = useFormContext();

  const filterMatchingStorage = (value: string) => {
    const selBaseName = value;
    if (selBaseName) {
      const newMatchingStorages =
        manureManagementSystems?.filter(
          (item) =>
            item.value.startsWith(selBaseName) && item.value.endsWith("MONTH")
        ) || [];
      newMatchingStorages.forEach((item) => {
        const currItem = item;
        const firstDigit = item.text.search(/\d/);
        // check if there is over / under in the name
        let substringIndex = firstDigit;
        const overIndex = item.text.lastIndexOf(" under");
        const underIndex = item.text.lastIndexOf(" over");
        if (overIndex > -1) substringIndex = overIndex;
        if (underIndex > -1) substringIndex = underIndex;
        currItem.text = item.text
          .substring(substringIndex)
          .replace("month", " month");
      });
      setMatchingStorages(newMatchingStorages);
      if (newMatchingStorages.length === 0) {
        setValue(`${fieldItemPrefix}.mmsHoldingDuration`, null);
      }
    }
  };

  const revalidateMMS = () => {
    formContext
      .trigger(`stages.${stageIndex}.stageData.housing.manureSystems`)
      .then(() => {})
      .catch(() => {});
  };

  // initial set local manure form

  // if not set, setup inputs values
  // for some reason, on first open of dialog formContex.getValues will not return values
  // that are set using value property of that component
  useEffect(() => {
    const mmsForm = getValues(fieldItemPrefix) as ManureManagementSystem;
    if (mmsForm?.id === undefined && manureSystem.id)
      setValue(`${fieldItemPrefix}.id`, manureSystem.id);

    if (mmsForm?.mmsType === undefined && manureSystem.mmsType) {
      setValue(`${fieldItemPrefix}.mmsType`, manureSystem.mmsType);
      // mms type set, localManureForm must be set beased on this type
      const localManureForm =
        manureSystem.localManureForm ||
        getManureFormForSingleEntry(
          manureSystem.mmsType as unknown as ManureManagementSystemType
        );
      setValue(`${fieldItemPrefix}.localManureForm`, localManureForm);
      filterMatchingStorage(manureSystem.mmsType);
    }

    if (mmsForm?.mmsHoldingDuration === undefined)
      setValue(
        `${fieldItemPrefix}.mmsHoldingDuration`,
        manureSystem.mmsHoldingDuration
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manureSystem]);

  const showShare = (): boolean => {
    const currentLocalManureForm = formContext.getValues(
      `${fieldItemPrefix}.localManureForm`
    ) as ManureForm;

    const manureSystems = formContext.getValues(
      `stages.${stageIndex}.stageData.housing.manureSystems`
    ) as ManureManagementSystem[];

    // Share box is visible if there are >=2 MMS of same kind; solid or liquide
    if (currentLocalManureForm) {
      const count =
        manureSystems?.filter(
          (item) => item.localManureForm === currentLocalManureForm
        )?.length || 0;

      // if intervention, check additions as well
      let interventionCount = 0;
      const manureSystemsAdditions = getValues(
        `stages.${stageIndex}.stageData.housing.manureSystemsAdditions`
      ) as ManureManagementSystem[];
      interventionCount =
        manureSystemsAdditions?.filter(
          (item) => item.localManureForm === currentLocalManureForm
        )?.length || 0;

      return count + interventionCount > 1;
    }

    return false;
  };

  // if addition mms is changed, check should share be visible and if not and set share
  useEffect(() => {
    if (!showShare()) {
      // share input is hidden
      // if baseline is 100, reset share
      if (manureSystem?.share === 100) {
        setValue(`${fieldItemPrefix}.share`, "");
        setValue(`${fieldItemPrefix}.share_changeMetric`, "relative");
      }
    }
  });

  const onMMSChange = (e: any) => {
    const eventTarget = e.target;
    filterMatchingStorage(eventTarget.value as string);
    if (mmsChangeHandler) mmsChangeHandler();
    const localManureForm = getManureFormForSingleEntry(eventTarget.value);
    setValue(`${fieldItemPrefix}.localManureForm`, localManureForm);

    // if value same as the baseline's, set holdingDuration from the baseline
    if (eventTarget.value === manureSystem?.mmsType)
      setValue(
        `${fieldItemPrefix}.mmsHoldingDuration`,
        manureSystem?.mmsHoldingDuration
      );
    // if not, reset value
    else setValue(`${fieldItemPrefix}.mmsHoldingDuration`, "");

    // if not showing share, reset value
    if (!showShare()) {
      // share input is hidden
      // if baseline is 100, reset share
      if (manureSystem?.share === 100) {
        setValue(`${fieldItemPrefix}.share`, "");
        setValue(`${fieldItemPrefix}.share_changeMetric`, "relative");
      }
      // case when baseline is not 100 and share is not visible
      // this will cause error in validation without possibility to change share
      // set intervention to 100
      else {
        setValue(`${fieldItemPrefix}.share`, "100");
        setValue(`${fieldItemPrefix}.share_changeMetric`, "set");
      }
    }

    revalidateMMS();
  };

  const onMMSChange2 = (e: any) => {
    const eventTarget = e.target;
    if (mmsChangeHandler) mmsChangeHandler();
    setValue(
      `${fieldItemPrefix}.localManureForm`,
      getManureFormForSingleEntry(eventTarget.value)
    );
    revalidateMMS();
  };

  const currentMMSCount = ((
    getValues(`stages.${stageIndex}.stageData.housing.manureSystems`) || []
  )?.length || 0) as number;

  const className =
    currentMMSCount > 1 ? classes.additionalEntriesBlockHolder : "";

  useEffect(() => {
    filterMatchingStorage(formContext.getValues(`${fieldItemPrefix}.mmsType`));
  }, []);

  // get the human redable label for duration, for baseline value
  const getDurationText = (
    baselineMmsType: string,
    baselineMmsHoldingDuration: Maybe<ManureManagementSystemType> | undefined
  ): string => {
    let retVal: string | undefined | null =
      baselineMmsHoldingDuration as string;
    if (baselineMmsType && baselineMmsHoldingDuration) {
      const newMatchingStorages =
        manureManagementSystems?.filter(
          (item) =>
            item.value.startsWith(baselineMmsType) &&
            item.value.endsWith("MONTH")
        ) || [];
      newMatchingStorages.forEach((item) => {
        const currItem = item;
        const firstDigit = item.text.search(/\d/);
        // check if there is over / under in the name
        let substringIndex = firstDigit;
        const overIndex = item.text.lastIndexOf(" under");
        const underIndex = item.text.lastIndexOf(" over");
        if (overIndex > -1) substringIndex = overIndex;
        if (underIndex > -1) substringIndex = underIndex;
        currItem.text = item.text
          .substring(substringIndex)
          .replace("month", " month");
      });
      retVal =
        newMatchingStorages?.find(
          (item) => item.value === baselineMmsHoldingDuration
        )?.text || baselineMmsHoldingDuration;
    }

    return retVal;
  };

  return (
    <div className={className}>
      {removeHandler && currentMMSCount > 1 && (
        <DsmButton
          variant="text"
          style={{ position: "relative", width: "100%" }}
          onClick={() => removeHandler(itemIndex)}
        >
          <DsmIcon
            name="general/x-close"
            style={{
              position: "absolute",
              color: "var(--dsm-color-neutral-darker",
              right: "0",
            }}
          />
        </DsmButton>
      )}
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.localManureForm`}
        defaultValue={
          formContext.getValues(`${fieldItemPrefix}.localManureForm`) ||
          manureSystem?.localManureForm
        }
      />

      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={manureSystem?.id}
      />
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <input
          type="hidden"
          value={manureSystem?.mmsHoldingDuration || manureSystem?.mmsType}
          ref={formContext.register()}
          name={`${fieldItemPrefix}.mmsType_old`}
        />
        <RowSelectWithMetricsV2
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.MANURE.MMS",
          })}
          name={`${fieldItemPrefix}.mmsType`}
          disabled={formType === FormType.View}
          options={filteredMMSList}
          baseline={manureSystem?.mmsType}
          changeHandler={onMMSChange}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.mmsType`) ||
            manureSystem?.mmsType
          }
        />

        {matchingStorages && matchingStorages.length > 0 && (
          <RowSelectWithMetricsV2
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.HOUSING.HOLDING_PERIOD",
            })}
            name={`${fieldItemPrefix}.mmsHoldingDuration`}
            disabled={formType === FormType.View}
            options={matchingStorages}
            // the baseline mms type can be different than selected one, thats why we need to lookup duration name with this function
            baseline={getDurationText(
              manureSystem?.mmsType,
              manureSystem?.mmsHoldingDuration
            )}
            changeHandler={onMMSChange2}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.mmsHoldingDuration`) ||
              manureSystem?.mmsHoldingDuration
            }
          />
        )}
      </DsmGrid>
      {showShare() && (
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.share`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.HOUSING.MMS.SHARE",
          })}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.HOUSING.MMS.SHARE",
          })}
          metricUnit={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.HOUSING.MMS.SHARE.UNIT",
          })}
          baseline={manureSystem ? manureSystem.share : null}
          disabled={formType === FormType.View}
          isManureSystem={true}
        />
      )}
    </div>
  );
};

export default MMSBlockComponent;
