import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { FormType } from "../../common";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { defaultUnitsV2, unitLong } from "../../../../utils/unit-utils";
import {
  AllBaselinesV2,
  BaselinePropertyValue,
  ResourceUseV2,
} from "../../../../models/Baseline";
import { AnimalType, ResourceUseType } from "../../../../../../graphql/types";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { isMarineFish, isShrimp } from "../../../../helpers/animals";

interface InterventionResourcesProps {
  formType: FormType;
  baseline: AllBaselinesV2 | undefined;
  animalType: AnimalType;
  // eslint-disable-next-line react/require-default-props
  inputType: string;
  // eslint-disable-next-line react/require-default-props
  isInternventionDialog?: boolean;
  stageDataResourceUse?: ResourceUseV2;
  enableFieldArrays?: (value: string) => void;
}

const InterventionResourcesWithoutArrays: FC<InterventionResourcesProps> = ({
  formType = FormType.View,
  animalType,
  baseline,
  inputType,
  isInternventionDialog = false,
  stageDataResourceUse,
  enableFieldArrays,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, trigger } = useFormContext();
  const fieldPrefix = inputType;
  const baselineResourceUse =
    inputType === "resourceUse" ? baseline?.resourceUse : stageDataResourceUse;

  const getBaselineItemValue = (
    type: string,
    isEnergyType = false
  ): BaselinePropertyValue | undefined => {
    if (isEnergyType) {
      return baselineResourceUse?.energyTypes.find((item) => item.type === type)
        ?.value;
    }
    return baselineResourceUse?.selfGeneratedRenewables.find(
      (item) => item.type === type
    )?.value;
  };

  const isShrimpOrMarineFish = () => {
    return isShrimp(animalType) || isMarineFish(animalType);
  };

  return (
    <>
      <DsmGrid
        className={classes.dsmGridOneColumnIntervention}
        style={{
          marginTop: `var(--dsm-spacing-px-6)`,
          width: isInternventionDialog ? "100%" : "50%",
        }}
      >
        <RowControlledTextFieldWithMetrics
          name={`${fieldPrefix}.electricityUse`}
          label={intl.formatMessage({
            id: "BASELINE.FORM.RESOURCES.ELECTRICITY",
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: isShrimpOrMarineFish()
              ? "BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP"
              : "BASELINE.FORM.RESOURCES.ELECTRICITY.TOOLTIP",
          })}
          metricUnit={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            userUOM?.unitElectricityUse
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                unitLong(userUOM.unitElectricityUse as string)
              : defaultUnitsV2.unitElectricityUse
          }
          baseline={baselineResourceUse?.electricityUse}
        />

        {getBaselineItemValue("electricityPhotovoltaicUse") && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.electricityPhotovoltaicUse`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE",
            })}  ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${
                ResourceUseType.ElectricityPhotovoltaic as string
              }`,
            })}`}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE.TOOLTIP",
            })}
            metricUnit={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitElectricityUse
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  unitLong(userUOM.unitElectricityUse as string)
                : defaultUnitsV2.unitElectricityUse
            }
            baseline={getBaselineItemValue("electricityPhotovoltaicUse")}
          />
        )}

        {getBaselineItemValue("electricityWindUse") && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.electricityWindUse`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE",
            })}  ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${
                ResourceUseType.ElectricityWind as string
              }`,
            })}`}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE.TOOLTIP",
            })}
            metricUnit={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitElectricityUse
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  unitLong(userUOM.unitElectricityUse as string)
                : defaultUnitsV2.unitElectricityUse
            }
            baseline={getBaselineItemValue("electricityWindUse")}
          />
        )}
      </DsmGrid>
      <DsmButton
        variant="text"
        onClick={() => {
          if (enableFieldArrays) {
            enableFieldArrays("selfGeneratedRenewables");
          }
        }}
        style={{ marginTop: "8px" }}
        disabled={formType === FormType.View}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({
          id: "BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_BUTTON",
        })}
      </DsmButton>

      <DsmGrid
        className={classes.dsmGridOneColumnIntervention}
        style={{
          marginTop: `var(--dsm-spacing-px-6)`,
          width: isInternventionDialog ? "100%" : "50%",
        }}
      >
        {!isMarineFish(animalType) && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.gasUse`}
            label={intl.formatMessage({ id: "BASELINE.FORM.RESOURCES.GAS" })}
            tooltip={intl.formatMessage({
              id: isShrimp(animalType) ? 
              "BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP" : 
              "BASELINE.FORM.RESOURCES.GAS.TOOLTIP",
            })}
            metricUnit={
              userUOM?.unitNaturalGasUse
                ? unitLong(userUOM.unitNaturalGasUse)
                : defaultUnitsV2.unitNaturalGasUse
            }
            disabled={formType === FormType.View}
            baseline={baselineResourceUse?.gasUse}
          />
        )}

        {(isMarineFish(animalType) ||
          getBaselineItemValue("dieselUse", true)) && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.dieselUse`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE",
            })}  ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${ResourceUseType.Diesel}`,
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.GAS.TOOLTIP",
            })}
            metricUnit={
              userUOM?.unitDieselUse
                ? unitLong(userUOM.unitDieselUse)
                : defaultUnitsV2.unitDieselUse
            }
            disabled={formType === FormType.View}
            baseline={
              isMarineFish(animalType)
                ? baselineResourceUse?.dieselUse
                : getBaselineItemValue("dieselUse", true)
            }
          />
        )}

        {getBaselineItemValue("gasolineUse", true) && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.gasolineUse`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE",
            })} ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${ResourceUseType.Gasoline}`,
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.GAS.TOOLTIP",
            })}
            metricUnit={
              userUOM?.unitGasolineUse
                ? unitLong(userUOM.unitGasolineUse)
                : defaultUnitsV2.unitGasolineUse
            }
            disabled={formType === FormType.View}
            baseline={getBaselineItemValue("gasolineUse", true)}
          />
        )}
        {getBaselineItemValue("heavyFuelOilUse", true) && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.heavyFuelOilUse`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE",
            })} ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${ResourceUseType.HeavyFuelOil}`,
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.GAS.TOOLTIP",
            })}
            metricUnit={
              userUOM?.unitHeavyFuelOilUse
                ? unitLong(userUOM.unitHeavyFuelOilUse)
                : defaultUnitsV2.unitHeavyFuelOilUse
            }
            disabled={formType === FormType.View}
            baseline={getBaselineItemValue("heavyFuelOilUse", true)}
          />
        )}
        {getBaselineItemValue("propaneOrLpgUse", true) && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.propaneOrLpgUse`}
            label={`
            ${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE",
            })} ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${ResourceUseType.PropaneOrLpg}`,
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.GAS.TOOLTIP",
            })}
            metricUnit={
              userUOM?.unitNaturalGasUse
                ? unitLong(userUOM.unitNaturalGasUse)
                : "MJ"
            }
            disabled={formType === FormType.View}
            baseline={getBaselineItemValue("propaneOrLpgUse", true)}
          />
        )}

        {getBaselineItemValue("coalUse", true) && (
          <RowControlledTextFieldWithMetrics
            name={`${fieldPrefix}.coalUse`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE",
            })}  ${intl.formatMessage({
              id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${ResourceUseType.Coal}`,
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.RESOURCES.GAS.TOOLTIP",
            })}
            metricUnit={
              userUOM?.unitHeatUse
                ? unitLong(userUOM.unitHeatUse)
                : defaultUnitsV2.unitHeatUse
            }
            disabled={formType === FormType.View}
            baseline={getBaselineItemValue("coalUse", true)}
          />
        )}
      </DsmGrid>
      <DsmButton
        variant="text"
        onClick={() => {
          if (enableFieldArrays) {
            enableFieldArrays("energyTypes");
          }
        }}
        style={{ marginTop: "8px" }}
        disabled={formType === FormType.View}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({
          id: "BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY.TYPE_BUTTON",
        })}
      </DsmButton>
      <DsmGrid
        className={classes.dsmGridOneColumnIntervention}
        style={{
          marginTop: `var(--dsm-spacing-px-6)`,
          width: isInternventionDialog ? "100%" : "50%",
        }}
      >
        <RowControlledTextFieldWithMetrics
          name={`${fieldPrefix}.waterUse`}
          label={intl.formatMessage({
            id: isShrimp(animalType) ? 
            "BASELINE.FORM.RESOURCES.FRESHWATER" : 
            "BASELINE.FORM.RESOURCES.WATER",
          })}
          tooltip={intl.formatMessage({
            id: isShrimpOrMarineFish() ? 
            "BASELINE.FORM.RESOURCES.WATER.SHRIMP.TOOLTIP" : 
            "BASELINE.FORM.RESOURCES.WATER.TOOLTIP",
          })}
          metricUnit={
            userUOM?.unitWaterUse
              ? unitLong(userUOM.unitWaterUse)
              : defaultUnitsV2.unitWaterUse
          }
          disabled={formType === FormType.View}
          baseline={baselineResourceUse?.waterUse}
        />
      </DsmGrid>
    </>
  );
};
export default InterventionResourcesWithoutArrays;
