/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { capitalize, sortedUniqBy } from 'lodash';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
  DatabaseFoundation,
  InputAnimalOrigin,
} from '../../../../../../graphql/generated/blonk/pigs';
import { Maybe, StageType } from '../../../../../../graphql/types';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../helpers/helperTypes';
import { FormType, ListEntry, PoultryFarms } from '../../common';
import {
  InternalSource,
  PoultryBaseline,
  PoultryStage,
} from '../../../../models/Baseline/PoultryBaseline';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  defaultUnitsV2,
  explicitConvertValue,
  unitLong,
} from '../../../../utils/unit-utils';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';

interface PoultryInputPartInternalSourceProps {
  farms: PoultryFarms[];
  farmId: string;
  farmName: string;
  allowedStagesForFarm: string[];
  productionProcessName: string;
  stageIndex: number;
  // eslint-disable-next-line react/require-default-props
  itemIndex?: number;
  propName: string;
  formType: FormType;
  stageType: StageType;
  intlPrefix: string;
  // eslint-disable-next-line react/require-default-props
  removeHandler?: Maybe<(index: number) => void>;
  sourceSelected: boolean;
  // eslint-disable-next-line react/require-default-props
  triggerSelectionError?: boolean;
  animalType: string;
  // eslint-disable-next-line react/require-default-props
  smallUnits?: boolean;
}

const PoultryInputPartInternalSource: FC<
  PoultryInputPartInternalSourceProps
> = ({
  farms,
  farmId,
  farmName,
  allowedStagesForFarm,
  productionProcessName,
  stageIndex = 0,
  itemIndex,
  propName = 'internalSources',
  stageType,
  intlPrefix,
  formType,
  removeHandler,
  sourceSelected,
  triggerSelectionError = false,
  animalType,
  smallUnits = false,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<PoultryBaseline>();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const barnSmallQuantityUnits = unitLong(
    userUOM.unitBarnSmallQuantityUnits as string
  );
  const distanceUnit = unitLong(
    userUOM.unitTransportDistanceTerrestrial as string
  );
  const calculateInitialDatabaseValue = () => {
    let initValue: DatabaseFoundation = fc.getValues(`info.databaseFoundation`);
    if (!initValue || stageType === StageType.Hatching)
      initValue = userProfile.getUserDatabasePrefs()
        .databaseType as DatabaseFoundation;
    return initValue;
  };
  const fieldItemPrefix =
    itemIndex || itemIndex === 0
      ? `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`
      : `stages.${stageIndex}.stageData.input.${propName}`;
  let selectedFarmId = '';
  useWatch({ name: `${fieldItemPrefix}` });

  const getAnimalSystemTypeLabel = (at: string) => {
    switch (at) {
      case 'LAYING_HENS_V2':
        return 'Laying Hens';
      case 'BROILER_V2':
        return 'Broiler';
      case 'TURKEY':
        return 'Turkey';
      default:
        return '';
    }
  };

  const invalidateStageSelection = (e: ReactChangedType) => {
    fc.setValue(`${fieldItemPrefix}.farmName`, e.target.name);
    const newSelection: string = e.target.value as string;
    if (newSelection !== selectedFarmId) {
      fc.setValue(`${fieldItemPrefix}.originStageId`, '');
      fc.setValue(`${fieldItemPrefix}.averageWeight`, '');
      selectedFarmId = newSelection;
    }
  };

  const addCurrentFarm = (resultFarms: ListEntry[]) => {
    const currentStage: PoultryStage = fc.getValues(`stages.${stageIndex}`);
    const stages = fc
      .getValues('stages')
      .filter((stage) => allowedStagesForFarm.includes(stage.type))
      .filter((stage) => currentStage?.id !== stage.id);
    // If there are more then one stage on current opened baseline only then add the current farm and db
    if (stages?.length > 0) resultFarms.push({ text: farmName, value: farmId });
  };

  const farmOptions = () => {
    // Filter out the Farm that are not relevant
    const resultFarms = farms
      .filter(
        (farm) =>
          farm.databaseFoundation === calculateInitialDatabaseValue() ||
          !farm.databaseFoundation
      )
      .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
      .filter((farm) => !farm.benchmark)
      .map((farm) => ({
        text: `${farm.farmName}`,
        value: farm.farmId,
      }));
    // Add farm for currently opened stages
    addCurrentFarm(resultFarms);
    // Because a farm can have multiple processes and baselines, its entry can be duplicated
    return sortedUniqBy(resultFarms, 'value');
  };

  const generateSelectedIds = (): string[] => {
    const sources: InternalSource[] = fc.getValues(
      `stages.${stageIndex}.stageData.input.${propName}`
    );
    return sources?.map(
      (internalSources: InternalSource) => internalSources.originStageId
    ) as string[];
  };

  const stageOptions = () => {
    const currentlySelectedFarm: string =
      fc.getValues(`${fieldItemPrefix}.farmId`) || farmId;
    const currentlySelectedStage: string = fc.getValues(
      `${fieldItemPrefix}.originStageId`
    );
    const selectedStageIds =
      stageType === StageType.Growing ? generateSelectedIds() : [];
    const currentStage: PoultryStage = fc.getValues(`stages.${stageIndex}`);
    const currentBaselineValues = fc.getValues('stages').map((stage) => ({
      databaseFoundation: calculateInitialDatabaseValue(),
      stageId: stage.id,
      stageType: stage.type,
      stageName: stage.name,
      farmId,
      productionProcessName,
    }));
    return [...farms, ...currentBaselineValues]
      .filter(
        (farm) =>
          farm.databaseFoundation === calculateInitialDatabaseValue() ||
          !farm.databaseFoundation
      )
      .filter(
        (farm) =>
          !selectedStageIds.includes(farm.stageId) ||
          currentlySelectedStage === farm.stageId
      )
      .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
      .filter((farm) => farm.farmId === currentlySelectedFarm)
      .filter((farm) => farm.stageId !== currentStage?.id)
      .map((farm) => ({
        text: `${farm.stageName} (${capitalize(farm.stageType)}) ${getAnimalSystemTypeLabel(
          animalType
        )} `,
        value: farm.stageId,
      }));
  };

  const stageSelection = async (e: ReactChangedType) => {
    const selectedStageId: string = e.target.value as string;
    const farm: PoultryFarms | undefined = farms.find(
      (f: PoultryFarms) => f.stageId === selectedStageId
    );
    if (farm) {
      // Stage selected from other farm
      fc.setValue(`${fieldItemPrefix}.farmName`, farm.farmName);
      fc.setValue(`${fieldItemPrefix}.stageName`, farm.stageName);
      fc.setValue(`${fieldItemPrefix}.stageType`, farm.stageType);
      fc.setValue(`${fieldItemPrefix}.baselineRef`, farm.reference);
      if (
        stageType === StageType.Growing &&
        farm.stageType === StageType.Hatching
      ) {
        const convertVal = explicitConvertValue(
          Number(farm.averageWeight),
          defaultUnitsV2.unitBarnSmallQuantityUnits,
          userUOM.unitBarnOutputMass
        );
        fc.setValue(
          `${fieldItemPrefix}.averageWeight`,
          convertVal ? parseFloat(String(convertVal)).toFixed(4) : ''
        );
      } else {
        fc.setValue(
          `${fieldItemPrefix}.averageWeight`,
          Number(farm.averageWeight).toFixed(4)
        );
      }
    } else {
      // Stage selected from this farm
      const currentStage: PoultryStage = fc.getValues(`stages.${stageIndex}`);
      const foundStage = fc
        .getValues('stages')
        .filter((stage) => allowedStagesForFarm.includes(stage.type))
        .filter((stage) => currentStage?.id !== stage.id)
        .find((stage) => selectedStageId === stage.id);
      if (foundStage) {
        fc.setValue(`${fieldItemPrefix}.farmName`, farmName);
        fc.setValue(`${fieldItemPrefix}.stageName`, foundStage.name);
        fc.setValue(`${fieldItemPrefix}.stageType`, foundStage.type);
        fc.setValue(`${fieldItemPrefix}.baselineRef`, '');
        if (
          stageType === StageType.Growing &&
          foundStage.type === StageType.Hatching
        ) {
          fc.setValue(
            `${fieldItemPrefix}.averageWeight`,
            explicitConvertValue(
              Number(foundStage?.stageData?.output?.averageWeight),
              defaultUnitsV2.unitBarnSmallQuantityUnits,
              userUOM.unitBarnOutputMass
            )
          );
        } else {
          fc.setValue(
            `${fieldItemPrefix}.averageWeight`,
            foundStage?.stageData?.output?.averageWeight
          );
        }
      }
    }
    await fc.trigger(`${fieldItemPrefix}.originStageId`);
  };

  const defaultFarm = (): string => {
    const options = farmOptions();
    const farm: string = fc.getValues(`${fieldItemPrefix}.farmId`) || farmId;
    const farmExists = options?.filter(
      (option: { value: string }) => option.value === farm
    )?.length;
    return farmExists ? farm : '';
  };

  const defaultStage = (): string => {
    const options = stageOptions();
    const stageId: string = fc.getValues(`${fieldItemPrefix}.originStageId`);
    const stageExists = options?.filter(
      (option) => option.value === stageId
    )?.length;
    return stageExists ? stageId : '';
  };

  const getAverageWeightValue = () => {
    const averageWeight = fc.getValues(`${fieldItemPrefix}.averageWeight`);
    const value = parseFloat(averageWeight as string).toFixed(4);
    if (averageWeight) return value;
    return;
  };

  const setRightUnit = () =>  {
    if (smallUnits) {
    return barnSmallQuantityUnits === 'g'
      ? 'g' + intl.formatMessage({ id: "EGG_WEIGHT_UNIT" })
      : barnSmallQuantityUnits;
    }
    
    return barnOutputMassUnit === 'kg' ? 'kg/animal' : barnOutputMassUnit;
  }

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={fc.getValues(`${fieldItemPrefix}.id`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={fc.getValues(`${fieldItemPrefix}.stageType`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.farmName`}
        value={fc.getValues(`${fieldItemPrefix}.farmName`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageName`}
        value={fc.getValues(`${fieldItemPrefix}.stageName`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.baselineRef`}
        value={fc.getValues(`${fieldItemPrefix}.baselineRef`)}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View &&
          removeHandler &&
          itemIndex !== undefined &&
          itemIndex !== null &&
          itemIndex !== 0 && (
            <DsmButton
              variant="text"
              style={{ position: 'relative', width: '100%' }}
              onClick={() => removeHandler(itemIndex)}
              disabled={!sourceSelected}
            >
              <DsmIcon
                name="general/x-close"
                style={{
                  position: 'absolute',
                  color: 'var(--dsm-color-neutral-darker',
                  right: '0',
                }}
              />
            </DsmButton>
          )}
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.farmId`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.INPUT.FARM',
            })}
            disabled={formType === FormType.View || !sourceSelected}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.INPUT.FARM.ADORNMENT',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.INPUT.FARM.TOOLTIP',
            })}
            options={farmOptions()}
            changeHandler={invalidateStageSelection}
            required
            defaultValue={defaultFarm()}
          />
        </div>
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.originStageId`}
            label={intl.formatMessage({
              id: `SUSTELL.STAGE.POULTRY.INPUT.STAGE`,
            })}
            disabled={formType === FormType.View || !sourceSelected}
            adornment={intl.formatMessage({
              id: `SUSTELL.STAGE.POULTRY.INPUT.STAGE.ADORNMENT`,
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.INPUT.STAGE.TOOLTIP',
            })}
            options={stageOptions()}
            changeHandler={async (e: ReactChangedType) => {
              await stageSelection(e);
              if (triggerSelectionError)
                await fc.trigger(
                  `stages.${stageIndex}.stageData.input.selection`
                );
            }}
            required
            defaultValue={defaultStage()}
          />
        </div>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberAnimals`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE`,
          })}
          adornment={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE.ADORNMENT`,
          })}
          disabled={formType === FormType.View || !sourceSelected}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE.TOOLTIP`,
          })}
          required
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.numberAnimals`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT`,
          })}
          adornment={setRightUnit()}
          disabled={
            formType === FormType.View ||
            !sourceSelected ||
            !fc.getValues(`${fieldItemPrefix}.originStageId`)
          }
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT.TOOLTIP`,
          })}
          required
          type="number"
          defaultValue={getAverageWeightValue()}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.transportDistance`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.DISTANCE',
          })}
          disabled={formType === FormType.View || !sourceSelected}
          adornment={distanceUnit}
          type="number"
          tooltip={intl.formatMessage(
            {
              id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.DISTANCE.TOOLTIP`,
            },
            { stageType: stageType.toLocaleLowerCase() }
          )}
          defaultValue={fc.getValues(`${fieldItemPrefix}.transportDistance`)}
        />
      </DsmGrid>
    </>
  );
};

export default PoultryInputPartInternalSource;
