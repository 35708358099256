/* eslint-disable react/require-default-props */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

import { BaselineFeedWithOrigin } from '../../../sustell_15/models/Baseline';
import { FormType, ListEntry } from '../../../sustell_15/components/FarmFlow/common';
import { PigIntervention } from '../../../sustell_15/models/Intervention';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import { unitLong } from '../../../sustell_15/utils/unit-utils';
import SustellInterventionSingleIngredient from './SustellInterventionSingleIngredient';

interface InterventionSingleIngredientFeedProps {
  formType?: FormType;
  singleIngredient: BaselineFeedWithOrigin;
  ingredientIndex: number;
  fieldItemPrefix: string;
  availableOrigins?: ListEntry[];
  displayTypeSelect?: boolean;
}

const SustellInterventionSingleIngredientFeedComponent = ({
  formType = FormType.Add,
  singleIngredient,
  ingredientIndex,
  fieldItemPrefix,
  availableOrigins,
  displayTypeSelect = true,
}: InterventionSingleIngredientFeedProps) => {
  const intl = useIntl();
  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass as string)
    : ('kg' as string);

  const fc = useFormContext<PigIntervention>();

  return (
    <React.Fragment key={`InterventionSingleIngredient_${singleIngredient.id}`}>
      <input
        type="hidden"
        value={singleIngredient.id}
        ref={fc.register()}
        name={`${fieldItemPrefix}.singleIngredients[${ingredientIndex}].id`}
      />
      <input
        type="hidden"
        value={singleIngredient.feedType}
        ref={fc.register()}
        name={`${fieldItemPrefix}.singleIngredients[${ingredientIndex}].feedType`}
      />
      <SustellInterventionSingleIngredient
        fieldItemPrefix={`${fieldItemPrefix}.singleIngredients[${ingredientIndex}]`}
        tooltip={intl.formatMessage({
          id: 'INTERVENTION.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP',
        })}
        metricUnit={intl.formatMessage(
          { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
          { unit: barnOutputMassUnit }
        )}
        baseline={singleIngredient}
        disabled={formType === 'view'}
        minAllowedValue={0}
        availableOrigins={availableOrigins}
        shortLabel
        displayFormattedUnit={false}
        originalMetricUnit={barnOutputMassUnit}
        displayTypeSelect={displayTypeSelect}
      />
    </React.Fragment>
  );
};

export default SustellInterventionSingleIngredientFeedComponent;
