/* eslint-disable no-param-reassign */
import { PrimaryPackagingType, SecondaryPackagingType, StageType, TertiaryPackagingType } from '../../../../../graphql/types';
import { FacilityOutputs, ProcessingInput, ProcessingStage, Packaging, ProcessingPackaging, ProcessingOutput, TransportTypeDistance, FacilityOutputNames, ProcessingPackagingItem } from '../../../../sustell_15/models/Facility/Processing';
import { PackagingDBModel, ProcessingInputDBModel, ProcessingOutputDBModel, ProcessingPackagingDBModel, ProcessingPackagingItemDBModel, ProcessingStageDBModel, ProcessingStageDataDBModel, TransportDBModel } from '../../../../sustell_15/models/TempBackendModels/TempProcessingDB';
import { formatInMassInput, formatInTransportInput } from './inMapperHelpers';
import {
  OUTPUT_PACKAGING_PROPS,
} from './outProcessingMapperSustell';


const mapTransportType = (
  incomingData?: Array<TransportDBModel>
):Array<TransportTypeDistance>  => {
  if (!incomingData || !incomingData.length) return [];

  return incomingData
    .map((item) => ({
      transportMode: item.transportMode,
      distance: formatInTransportInput(item.distance, item.transportMode),
    }));
};

const mapProcessingInput = (incomingData: ProcessingInputDBModel): ProcessingInput => {
  if (!incomingData) return {} as ProcessingInput

  const mappedData: ProcessingInput = {
    originStageId: incomingData?.originStageId,
    animalType: incomingData?.animalType,
    shrinkingRate: incomingData?.shrinkingRate,
    quantity: incomingData?.quantity,
    stageType: incomingData?.originStageType,
    name: String(incomingData?.name),
    averageWeight: formatInMassInput(incomingData?.averageWeight),
    transport: mapTransportType(incomingData?.transport)
  }

  return mappedData
};

const mapProcessingOutput = (incomingData: ProcessingOutputDBModel): ProcessingOutput => {
  if (!incomingData) return {} as ProcessingOutput

  const mappedData: ProcessingOutput = OUTPUT_PACKAGING_PROPS.reduce((acc: ProcessingOutput, packagingProp: FacilityOutputNames) => {
    const packagingItem = incomingData[packagingProp];

    if (!packagingItem) return acc;

    acc[packagingProp] = {
      price: packagingItem.price ? Number(packagingItem.price): "",
      weight: packagingItem.weight ? formatInMassInput(packagingItem.weight): "",
      calculate: !!incomingData?.[packagingProp]?.calculate
    };

    return acc
  }, {})

  return mappedData
};

const isPackagingEmpty = (packaging: PackagingDBModel): boolean => {
  if (!packaging) return true;
  if ((packaging?.secondary && packaging.secondary.length > 0) 
    || (packaging?.primary && packaging?.primary.length > 0)
    || (packaging?.tertiary && packaging?.tertiary.length > 0)) {
      return false;
    }

  return true;
}

const mapPackagingItem = (
  incomingData: Array<ProcessingPackagingItemDBModel<unknown>>
): Array<ProcessingPackagingItem<unknown>> | null => {
  if (!incomingData || !incomingData.length) return null;

  const mappedItem = incomingData
    .filter((packagingItem) => packagingItem.type && packagingItem.amount)
    .map((packagingItem) => ({
      type: packagingItem.type,
      amount: formatInMassInput(packagingItem.amount),
    }));

  return mappedItem;
};

const mapPackagingSet = (incomingData: PackagingDBModel): Packaging => {
  if (!incomingData) return {} as Packaging

  let mappedData: Packaging = {};

  if (incomingData?.primary && incomingData?.primary?.length) {
    mappedData = {
      ...mappedData,
      primary: mapPackagingItem(incomingData.primary) as Array<
      ProcessingPackagingItem<PrimaryPackagingType>
      >,
    };
  }

  if (incomingData?.secondary && incomingData?.secondary?.length) {
    const secondaryPackaging = mapPackagingItem(incomingData.secondary)
    mappedData = {
      ...mappedData,
      secondary: secondaryPackaging?.length ? secondaryPackaging[0] as 
      ProcessingPackagingItem<SecondaryPackagingType> : null
    };
  }

  if (incomingData?.tertiary && incomingData?.tertiary?.length) {
    mappedData = {
      ...mappedData,
      tertiary: mapPackagingItem(incomingData.tertiary) as Array<
      ProcessingPackagingItem<TertiaryPackagingType>
      >,
    };
  }

  return mappedData
};

const mapProcessingPackaging = (incomingData: ProcessingPackagingDBModel): ProcessingPackaging => {
  if (!incomingData) return {} as ProcessingPackaging

  const mappedData: ProcessingPackaging = OUTPUT_PACKAGING_PROPS.reduce((acc: ProcessingPackaging, packagingProp: FacilityOutputNames) => {
    const packagingItem = incomingData?.[packagingProp];
    if (!packagingItem || isPackagingEmpty(packagingItem)) return acc;

    acc[packagingProp] = mapPackagingSet(packagingItem);
    
    // if not primary category, add it to the additonal ones
    if (packagingProp !== FacilityOutputNames.FRESH_MEAT_AND_EDIBLE_OFFAL) {
      acc.additionalCategories?.push({ outputType: FacilityOutputs[packagingProp] })
    }

    return acc;
  }, { additionalCategories: [] })

  return mappedData
};

export const mapInProcessingStage = (incomingData: ProcessingStageDBModel): ProcessingStage => {
  if (!incomingData) return {} as ProcessingStage;

  try {
    const stageData = typeof incomingData.stageData === 'string'
    ? (JSON.parse(String(incomingData.stageData)) as ProcessingStageDataDBModel)
    : incomingData.stageData;
  
    // attributes productionSystem and facilityType aren't used in either in or out mappers.
    const mappedStage: Omit<ProcessingStage, 'productionSystem' | 'facilityType'> = {    
      facilityId: incomingData.facilityId,
      type: incomingData?.type || StageType.Processing,
      farmId: incomingData?.farmId || '',
      name: incomingData?.name || '',
      id: incomingData?.id,
      stageData: {
        processingInput: mapProcessingInput(stageData.processingInput),
        processingOutput: mapProcessingOutput(stageData.processingOutput),
        processingPackaging: mapProcessingPackaging(stageData.processingPackaging)
  
      }
    }
  
    return mappedStage as ProcessingStage;
  } catch(err) {
    console.error('Error on mapInProcessingStage: ', err)
  }

  return {} as ProcessingStage;
};

export default mapInProcessingStage;
