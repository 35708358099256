/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmFieldset, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2OutputDairyCow,
} from '../../../../models/Baseline/DairyV2Baseline';
import { Tooltip } from '@material-ui/core';
import { defaultValues } from '../../baselineDefaultValues';

const dairyOutputStageDefaultValues =
  defaultValues.DAIRY_V2.stages[0].stageData.output;

const DairyV2OutputDairyCowDialog: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<DairyV2OutputDairyCow>();
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const formContext = useFormContext<DairyV2Baseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  
  const numberOfMortalitiesWatch = useWatch({
    name: `${fieldItemPrefix}.numberOfMortalitiesCows`,
  });
 
  const getNumberOfMortalitiesValue = (): number => 
    formContext.getValues(`${fieldItemPrefix}.numberOfMortalitiesCows`) 
    ? Number(formContext.getValues(`${fieldItemPrefix}.numberOfMortalitiesCows`)) 
    : 0;
 
  useEffect(() => {
    if (getNumberOfMortalitiesValue() === 0) {
      formContext.setValue(`${fieldItemPrefix}.averageWeightAtMortalityCows`, 0);
    }
    formContext.trigger(`${fieldItemPrefix}.averageWeightAtMortalityCows`);
  }, [numberOfMortalitiesWatch]);

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyCowStageData = resetObject.stages[itemIndex]?.stageData as DairyV2DairyCowStageData
      if (dairyCowStageData.output) {
        dairyCowStageData.output = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    handleCancel('reset');
  };

  const resetDefault = () => {
    formContext.setValue(
      `${fieldItemPrefix}.averageMilkFat`,
      dairyOutputStageDefaultValues.averageMilkFat
    );
    formContext.clearErrors(`${fieldItemPrefix}.averageMilkFat`);
    // reset averageWeightAtBirth
    formContext.setValue(
      `${fieldItemPrefix}.averageWeightAtBirth`,
      dairyOutputStageDefaultValues.averageWeightAtBirth
    );
    formContext.clearErrors(`${fieldItemPrefix}.averageWeightAtBirth`);
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant="wide"
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <span className={classes.headingTitleVariant}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.MILK',
              })}
            </span>
          </div>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.yearlyMilkProduction`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.yearlyMilkProduction`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageMilkFat`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT.PLACEHOLDER',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT.TOOLTIP',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageMilkFat`
              )}
              required
            />
          </DsmFieldset>
        </div>
        <div>
          <div style={{ height: '117px' }}/>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageMilkProtein`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN.PLACEHOLDER',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN.TOOLTIP',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageMilkProtein`
              )}
              required
            />
          </DsmFieldset>
        </div>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <span className={classes.headingTitleVariant}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COWS',
              })}
            </span>
          </div>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.cowsToAnotherStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.cowsToAnotherStage`
              )}
              changeHandler={() => {
                if (formContext.getValues(`${fieldItemPrefix}.cowsSold`)) {
                  formContext.trigger(`${fieldItemPrefix}.cowsSold`);
                }
              }}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.cowsSold`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.cowsSold`
              )}
              changeHandler={() => {
                if (formContext.getValues(`${fieldItemPrefix}.cowsToAnotherStage`)) {
                  formContext.trigger(`${fieldItemPrefix}.cowsToAnotherStage`);
                }
              }}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.numberOfMortalitiesCows`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.NUMBER_OF_MORTALITIES_COWS',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.NUMBER_OF_MORTALITIES_COWS.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.numberOfMortalitiesCows`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightAtMortalityCows`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View ||
                getNumberOfMortalitiesValue() === 0}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightAtMortalityCows`
              )}
              required
            />
          </DsmFieldset>
        </div>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <span className={classes.headingTitleVariant}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.CALVES',
              })}
            </span>
          </div>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.weanedCalvesToAnotherStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.WEANED_CALVES_TO_ANOTHER_STAGE',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.WEANED_CALVES_TO_ANOTHER_STAGE.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.WEANED_CALVES_TO_ANOTHER_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.weanedCalvesToAnotherStage`
              )}
              changeHandler={() => {
                if (formContext.getValues(`${fieldItemPrefix}.weanedCalvesSold`)) {
                  formContext.trigger(`${fieldItemPrefix}.weanedCalvesSold`);
                }
              }}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.weanedCalvesSold`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.WEANED_CALVES_SOLD',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.WEANED_CALVES_SOLD.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.weanedCalvesSold`
              )}
              changeHandler={() => {
                if (formContext.getValues(`${fieldItemPrefix}.weanedCalvesToAnotherStage`)) {
                  formContext.trigger(`${fieldItemPrefix}.weanedCalvesToAnotherStage`);
                }
              }}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightAtBirth`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_BIRTH',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_BIRTH.TOOLTIP'
              })}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightAtBirth`
              )}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_BIRTH.ADORNMENT',
                },
                {
                  unit: userUOM?.unitBarnOutputMass
                    ? unitLong(userUOM.unitBarnOutputMass)
                    : 'kg',
                }
              )}
              required
            />
          </DsmFieldset>
        </div>
        <div className={classes.resetButtonContainer}>
          <DsmButton
            variant="text"
            style={{ float: 'left' }}
            onClick={() => resetDefault()}
            disabled={formType === FormType.View}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.RESET_DEFAULT',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(85%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(fieldItemPrefix);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default DairyV2OutputDairyCowDialog;
