import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Maybe } from "graphql/jsutils/Maybe";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { capitalize, sortedUniqBy } from "lodash";
import { useIntl } from "../../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../../_metronic/layout";
import {
  AnimalType,
  DatabaseFoundation,
  InputAnimalOrigin,
} from "../../../../../../../graphql/generated/blonk/pigs";
import { StageType } from "../../../../../../../graphql/types";
import ReactHookDsmInput from "../../../../../../modules/Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../../../../../modules/Helpers/ReactHookDsmSelect2";
import {
  CSSClassesList,
  ReactChangedType,
} from "../../../../../helpers/helperTypes";
import { InternalSource } from "../../../../../models/Baseline/PigBaseline";
import { enumToOptionsArrayWithTranslatedStrings } from "../../../../../utils/obj-utils";
import { FormType, ListEntry, PigFarms, PigStage } from "../../../common";
import { UserProfilePrefs } from "../../../../../../modules/Helpers/UserProfilePrefs";

interface InternalInputProperties {
  farms: PigFarms[];
  farmId: string;
  farmName: string;
  allowedStagesForFarm: string[];
  productionProcessName: string;
  stageIndex: number;
  itemIndex: number;
  stages: Array<ListEntry>;
  stageOriginAnimalType?: AnimalType | string | undefined;
  breedingStagesList: Array<string>;
  formType: FormType;
  stageType: StageType;
  isIntervention?: boolean;
  distanceUnit: string;
  removeHandler?: Maybe<Function>;
  baselineStageOriginAnimalType?: Maybe<AnimalType> | string | undefined;
  barnOutputMassUnit: string;
  // eslint-disable-next-line react/require-default-props
  triggerSelectionError?: boolean;
}

const InternalSourcesInput: FC<InternalInputProperties> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  allowedStagesForFarm,
  stageIndex = 0,
  itemIndex = 0,
  stageType,
  stageOriginAnimalType = AnimalType.Piglet,
  formType,
  isIntervention = false,
  triggerSelectionError = false,
  distanceUnit,
  removeHandler,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();
  const userProfile = UserProfilePrefs.getInstance();

  const propName = isIntervention
    ? "internalSourcesAdditions"
    : "internalSources";
  const fieldItemPrefix =
    itemIndex || itemIndex === 0
      ? `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`
      : `stages.${stageIndex}.stageData.input.${propName}`;
  let selectedFarmId = "";
  const intl = useIntl();
  const newAnimalTooltip =
    stageType === StageType.Breeding
      ? "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.NEW_ANIMALS.TOOLTIP"
      : "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.NEW_ANIMALS.TOOLTIP";
  const fromStageTooltip =
    stageType === StageType.Breeding
      ? "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.FROM_STAGE.TOOLTIP"
      : "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.FROM_STAGE.TOOLTIP";
  const distanceTooltip =
    stageType === StageType.Breeding
      ? "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.TRANSPORT_DISTANCE.TOOLTIP"
      : "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.TRANSPORT_DISTANCE.TOOLTIP";

  const inputStageId = useWatch({
    name: `${fieldItemPrefix}.originStageId`,
    defaultValue: formContext.getValues(`${fieldItemPrefix}.originStageId`) as
      | string
      | undefined,
  }) as string;

  const updateStageOriginAnimalType = (e: ReactChangedType) => {
    // console.log(e);
    const newOriginAnimalType = e?.target?.value as string | undefined;
    formContext.setValue(
      `stages.${stageIndex}.stageData.input.originAnimalType`,
      newOriginAnimalType
    );
  };

  const calculateInitialDatabaseValue = () => {
    let initValue: DatabaseFoundation = formContext.getValues(
      `info.databaseFoundation`
    );
    if (!initValue)
      initValue = userProfile.getUserDatabasePrefs()
        .databaseType as DatabaseFoundation;
    return initValue;
  };

  const addCurrentFarm = (resultFarms: ListEntry[]) => {
    const currentStage: PigStage = formContext.getValues(
      `stages.${stageIndex}`
    );
    const stages = formContext
      .getValues("stages")
      .filter((stage: { type: string }) =>
        allowedStagesForFarm.includes(stage.type)
      )
      .filter((stage: { id: string }) => currentStage?.id !== stage.id);
    // If there are more then one stage on current opened baseline only then add the current farm and db
    if (stages?.length > 0) {
      const isExists = resultFarms.find(rFarm => rFarm.value === farmId);
      if (!isExists) resultFarms.push({ text: farmName, value: farmId })
    };
  };

  const farmOptions = () => {
    // Filter out the Farm that are not relevant
    const resultFarms = farms
      .filter(
        (farm) =>
          farm.databaseFoundation === calculateInitialDatabaseValue() ||
          !farm.databaseFoundation
      )
      .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
      .filter((farm) => !farm.benchmark)
      .map((farm) => ({
        text: `${farm.farmName}`,
        value: farm.farmId,
      }));
    // Add farm for currently opened stages
    addCurrentFarm(resultFarms);
    // Because a farm can have multiple processes and baselines, its entry can be duplicated
    return sortedUniqBy(resultFarms, "value");
  };

  const defaultFarm = (): string => {
    const options = farmOptions();
    const farm: string =
      formContext.getValues(`${fieldItemPrefix}.farmId`) || farmId;
    const farmExists = options?.filter(
      (option: { value: string }) => option.value === farm
    )?.length;
    return farmExists ? farm : "";
  };

  const invalidateStageSelection = (e: ReactChangedType) => {
    formContext.setValue(`${fieldItemPrefix}.farmName`, e.target.name);
    const newSelection: string = e.target.value as string;
    if (newSelection !== selectedFarmId) {
      formContext.setValue(`${fieldItemPrefix}.originStageId`, "");
      selectedFarmId = newSelection;
    }
  };

  const generateSelectedIds = (): string[] => {
    const sources: InternalSource[] = formContext.getValues(
      `stages.${stageIndex}.stageData.input.${propName}`
    );
    return sources?.map(
      (internalSources: InternalSource) => internalSources.originStageId
    ) as string[];
  };

  const stageOptions = () => {
    const currentlySelectedFarm: string =
      formContext.getValues(`${fieldItemPrefix}.farmId`) || farmId;
    const currentlySelectedStage: string = formContext.getValues(
      `${fieldItemPrefix}.originStageId`
    );
    const selectedStageIds = generateSelectedIds();
    const currentStage: PigStage = formContext.getValues(
      `stages.${stageIndex}`
    );
    const currentBaselineValues = formContext
      .getValues("stages")
      .map((stage: { id: string; type: StageType; name: string }) => ({
        databaseFoundation: calculateInitialDatabaseValue(),
        stageId: stage.id,
        stageType: stage.type,
        stageName: stage.name,
        farmId,
        productionProcessName,
      }));
    return [...farms, ...currentBaselineValues]
      .filter(
        (farm) =>
          farm && (farm.databaseFoundation === calculateInitialDatabaseValue() ||
          !farm.databaseFoundation)
      )
      .filter(
        (farm) =>
          !selectedStageIds.includes(farm.stageId) ||
          currentlySelectedStage === farm.stageId
      )
      .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
      .filter((farm) => farm.farmId === currentlySelectedFarm)
      .filter((farm) => farm.stageId !== currentStage?.id)
      .map((farm) => ({
        text: `${farm.stageName} (${capitalize(farm.stageType)})`,
        value: farm.stageId,
        stageType: farm.stageType,
      }));
  };

  const breadingStages = stageOptions().filter(
    (stage) => stage.stageType === StageType.Breeding
  );
  const isInputStageBreeding = breadingStages
    .map((stage) => stage.value)
    .includes(inputStageId);
  if (isInputStageBreeding)
    formContext.setValue(
      `${fieldItemPrefix}.originAnimalType`,
      stageOriginAnimalType
    );

  const stageSelection = async (e: ReactChangedType) => {
   
    const selectedStageId: string = e.target.value as string;
    const farm: PigFarms | undefined = farms.find(
      (f: PigFarms) => f.stageId === selectedStageId
    );
    if (farm) {
      // Stage selected from other farm
      formContext.setValue(`${fieldItemPrefix}.farmName`, farm.farmName);
      formContext.setValue(`${fieldItemPrefix}.stageName`, farm.stageName);
      formContext.setValue(`${fieldItemPrefix}.stageType`, farm.stageType);
      formContext.setValue(`${fieldItemPrefix}.baselineRef`, farm.reference);
      formContext.setValue(`${fieldItemPrefix}.averageAgePiglets`, farm.averageAgePiglets);
      formContext.setValue(`${fieldItemPrefix}.averageWeightPiglets`, farm.averageWeightPiglets);
      formContext.setValue(`${fieldItemPrefix}.averageWeightPigs`, farm.averageWeightPigs);
      formContext.setValue(`${fieldItemPrefix}.averageAgePigs`, farm.averageAgePigs);
    } else {
      // Stage selected from this farm
      const currentStage: PigStage = formContext.getValues(
        `stages.${stageIndex}`
      );
     
      const foundStage = formContext
        .getValues("stages")
        .filter((stage: { type: StageType }) =>
          allowedStagesForFarm.includes(stage.type)
        )
        .filter((stage: { id: string }) => currentStage?.id !== stage.id)
        .find((stage: { id: string }) => selectedStageId === stage.id);
      if (foundStage) {
        formContext.setValue(`${fieldItemPrefix}.farmName`, farmName);
        formContext.setValue(`${fieldItemPrefix}.stageName`, foundStage.name);
        formContext.setValue(`${fieldItemPrefix}.stageType`, foundStage.type);
        formContext.setValue(`${fieldItemPrefix}.baselineRef`, "");
        formContext.setValue(`${fieldItemPrefix}.averageAgePiglets`, foundStage.stageData?.output?.averageAgePiglets);
        formContext.setValue(`${fieldItemPrefix}.averageWeightPiglets`, foundStage.stageData?.output?.averageWeightPiglets);
        formContext.setValue(`${fieldItemPrefix}.averageWeightPigs`, foundStage.stageData?.output?.averageWeightPigs);
        formContext.setValue(`${fieldItemPrefix}.averageAgePigs`, "");
      }
    }
    await formContext.trigger(`${fieldItemPrefix}.originStageId`);
  };

  const defaultStage = (): string => {
    const options = stageOptions();
    const stageId: string = formContext.getValues(
      `${fieldItemPrefix}.originStageId`
    );
    const stageExists = options?.filter(
      (option) => option.value === stageId
    )?.length;
    return stageExists ? stageId : "";
  };

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={formContext.getValues(`${fieldItemPrefix}.stageType`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.farmName`}
        value={formContext.getValues(`${fieldItemPrefix}.farmName`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageName`}
        value={formContext.getValues(`${fieldItemPrefix}.stageName`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.baselineRef`}
        value={formContext.getValues(`${fieldItemPrefix}.baselineRef`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageAgePiglets`}
        value={formContext.getValues(`${fieldItemPrefix}.averageAgePiglets`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageWeightPiglets`}
        value={formContext.getValues(`${fieldItemPrefix}.averageWeightPiglets`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageWeightPigs`}
        value={formContext.getValues(`${fieldItemPrefix}.averageWeightPigs`)}
      />
       <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.averageAgePigs`}
        value={formContext.getValues(`${fieldItemPrefix}.averageAgePigs`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.hasBreedingInput`}
        value={isInputStageBreeding ? "true" : "false"}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && (
          <DsmButton
            variant="text"
            style={{ position: "relative", width: "100%" }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: "absolute",
                color: "var(--dsm-color-neutral-darker",
                right: "0",
              }}
            />
          </DsmButton>
        )}
        <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
          <ReactHookDsmSelect
            style={{ width: "100%" }}
            name={`${fieldItemPrefix}.farmId`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.POULTRY.INPUT.FARM",
            })}
            disabled={formType === FormType.View}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.POULTRY.INPUT.FARM.ADORNMENT",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.POULTRY.INPUT.FARM.TOOLTIP",
            })}
            options={farmOptions()}
            changeHandler={invalidateStageSelection}
            required
            defaultValue={defaultFarm()}
          />
        </div>
        <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
          <ReactHookDsmSelect
            style={{ width: "100%" }}
            name={`${fieldItemPrefix}.originStageId`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE",
            })}
            disabled={formType === FormType.View}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER",
            })}
            tooltip={intl.formatMessage({ id: fromStageTooltip })}
            options={stageOptions()}
            changeHandler={async (e: ReactChangedType) => {
              await stageSelection(e);
              if (triggerSelectionError)
                await formContext.trigger(
                  `stages.${stageIndex}.stageData.input.selection`
                );
            }}
            required
            defaultValue={defaultStage()}
          />
        </div>
        {isInputStageBreeding && (
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.originAnimalType`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE",
            })}
            disabled={true}
            adornment={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.PLACEHOLDER",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.TOOLTIP",
            })}
            options={enumToOptionsArrayWithTranslatedStrings(
              AnimalType,
              intl,
              "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE"
            )}
            changeHandler={updateStageOriginAnimalType}
            required
            defaultValue={stageOriginAnimalType}
          />
        )}
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberPigs`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NEW_ANIMALS",
          })}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER",
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({ id: newAnimalTooltip })}
          required
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.numberPigs`) as
              | string
              | number
          }
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTransport`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.TRANSPORT_DISTANCE",
          })}
          disabled={formType === FormType.View}
          adornment={distanceUnit}
          tooltip={intl.formatMessage({ id: distanceTooltip })}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.distanceTransport`) as
              | string
              | number
          }
          type="number"
        />
      </DsmGrid>
    </>
  );
};

export default InternalSourcesInput;
