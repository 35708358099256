import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { cloneDeep } from 'lodash';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { PigInterventionFormDialogProps } from './InterventionTypes';
import { FormType, ListEntry } from '../../common';
import {
  PigIntervention,
  CommonInterventionFeed,
  AllInterventions,
} from '../../../../models/Intervention';
import {
  DSM_DISABLED_LABEL,
  processAndStageStylesV2,
} from '../../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import {
  CompoundFeedDatabase,
  MasterDataOption,
  Maybe,
} from '../../../../../../graphql/types';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds_v2';
import SustellSingleIngredientFeed from '../../../../../modules/Farms/Baseline/SustellSingleIngredientFeeds_v2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import { PigStageData } from '../../../../models/Baseline/PigBaseline';
import SustellInterventionSingleIngredientFeedComponent from '../../../../../modules/Farms/Intervention/SustellInterventionSingleIngredientFeedComponent';

interface CompoundFeed {
  display_name: string;
  value: string;
  databaseFoundation: CompoundFeedDatabase;
}

interface FeedsInterventionsFormDialogV2rops
  extends PigInterventionFormDialogProps {
  // eslint-disable-next-line react/require-default-props
  intervention?: PigIntervention | null;
  // eslint-disable-next-line react/require-default-props
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  singleIngredients?: any;
  origins?: ListEntry[];
}

const FeedsInterventionsFormDialogV2 = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
  compoundFeeds,
  singleIngredients,
  intervention = null,
  origins,
}: FeedsInterventionsFormDialogV2rops) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;
  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass as string)
    : ('kg' as string);

  const currResetValue = useRef<CommonInterventionFeed>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;
  const fc = useFormContext<PigIntervention>();

  const {
    fields: feedsAdditions,
    append: appendFeedsAdditions,
    remove: removeFeedsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.compoundFeedsAdditions`, // TODO: check field names
    control: fc.control,
    keyName: 'keyId',
  });

  const {
    fields: ingredientsAdditions,
    append: appendIngredientsAdditions,
    remove: removeIngredientsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.singleIngredientsAdditions`, // TODO: check field names
    control: fc.control,
    keyName: 'keyId',
  });

  const getBaselineFeedLabel = (id: string) => {
    const feedDisplayName = compoundFeeds?.find(
      (item) => item.value === id
    )?.display_name;
    if (!feedDisplayName) {
      console.log('not found CF for id: ', id);
    }
    return feedDisplayName || 'unknown';
  };

  const databaseFoundation =
    baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli;
  const feedBaseline = (
    baseline?.stages?.[stageIndex]?.stageData as PigStageData
  )?.feed;

  const getCompoundFeedsList = (databaseOption: CompoundFeedDatabase) => {
    const feeds = (compoundFeeds as unknown as CompoundFeed[]) || [];
    return feeds.filter(
      (feed: CompoundFeed) => feed.databaseFoundation === databaseOption
    );
  };

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as AllInterventions;
      const stageData = resetObject.stages?.[stageIndex]?.stageData;
      if (stageData?.feed) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        stageData.feed = { ...currResetValue.current };
        fc.reset(resetObject as PigIntervention, {
          errors: true,
        });
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  useEffect(() => {
    if (formVisible) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = { totalFeedIntake: 0 };
      } else {
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const labelDisabled =
    formType === FormType.View ? { color: DSM_DISABLED_LABEL as string } : {};

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="ultrawide"
      iconCode="shapes/cube-02"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridTwoColumn2To3}>
        <DsmFieldset
          style={{ width: '58%', marginTop: `var(--dsm-spacing-px-4)` }}
        >
          <ReactHookDsmSelect
            name="databaseFoundation"
            defaultValue={databaseFoundation}
            label={`${intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION',
            })}`}
            disabled
            options={getDatabaseOptions() as []}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP_POULTRY_DAIRY_BEEF_SWINE',
            })}
          />
        </DsmFieldset>
      </DsmGrid>
      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        className={`${classes.dsmGridTwoColumn2To3} ${classes.topVerticalSpacer}`}
      >
        <DsmGrid
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          className={classes.dsmGridOneColumnIntervention}
        >
          <h6 style={labelDisabled}>
            {intl.formatMessage({ id: 'COMPOUNDFEEDS.CARD.TITLE' })}
          </h6>
          {(feedBaseline?.compoundFeeds || [])
            ?.filter((item) => !!item.feedType)
            ?.map((feedItem, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment
                key={`InterventionCompoundFeed_${feedItem.feedType}_${i}`}
              >
                <input
                  type="hidden"
                  value={feedItem.feedType}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].feedType`}
                />
                <RowControlledTextFieldWithMetrics
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
                  label={getBaselineFeedLabel(feedItem.feedType)}
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
                  })}
                  metricUnit={intl.formatMessage(
                    { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                    { unit: barnOutputMassUnit }
                  )}
                  // precision={precision}
                  baseline={feedItem.kgPerAnimal}
                  disabled={formType === 'view'}
                  minAllowedValue={0}
                  displayFormattedUnit={false}
                  originalMetricUnit={barnOutputMassUnit}
                />
              </React.Fragment>
            ))}

          <SustellRationFeeds
            key="compoundFeedsAdditions"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED',
            })}
            availableFeedItems={getCompoundFeedsList(databaseFoundation)}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="compoundFeedsAdditions"
            // updateTotalFeedIntake={updateTotalFeedIntake}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
            })}
            formState={formType}
            compoundFeed
            feedData={feedsAdditions}
            setFeedData={(newItem: any) => {
              console.log('setFeedData', newItem);
            }}
            fieldsAppenRemoveObject={{
              fields: feedsAdditions,
              append: appendFeedsAdditions,
              remove: removeFeedsAdditions,
            }}
          />
        </DsmGrid>
        <DsmGrid
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          className={classes.dsmGridOneColumnIntervention}
        >
          <h6 style={labelDisabled}>
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.SINGLE_INGREDIENT_TITLE',
            })}
          </h6>
          {(feedBaseline?.singleIngredients || [])
            ?.filter((item) => !!item.feedType && !!item.origin)
            ?.map((singleIngredientItem, i) => (
              <SustellInterventionSingleIngredientFeedComponent
                formType={formType}
                singleIngredient={singleIngredientItem}
                ingredientIndex={i}
                fieldItemPrefix={fieldItemPrefix}
                availableOrigins={origins}
              />
            ))}

          <SustellSingleIngredientFeed
            key="singleIngredients"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.SINGLE_INGREDIENT',
            })}
            barnItemIndex={stageIndex}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            availableFeedItems={singleIngredients}
            availableOrigins={origins}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="singleIngredientsAdditions"
            tooltip={
              <div key="feeds-form-dialog-singleIngredients">
                {intl.formatMessage(
                  {
                    id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP',
                  },
                  { br: <br /> }
                )}
              </div>
            }
            formState={formType}
            feedData={ingredientsAdditions}
            fieldsAppenRemoveObject={{
              fields: ingredientsAdditions,
              append: appendIngredientsAdditions,
              remove: removeIngredientsAdditions,
            }}
          />
        </DsmGrid>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default FeedsInterventionsFormDialogV2;
