import { FC } from "react";
import { DsmProgressSteps } from "@dsm-dcs/design-system-react";
import { useHistory } from "react-router";
import { DsmProgressStepsCustomEvent } from "@dsm-dcs/design-system";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";

interface SustellInterventionStepsProps {
  active:string,
  completed?: Array<string>,
  onStepChange?: (e:any)=>void
  customerID?: string,
  farmID?:string | undefined,
  processID?: string | undefined,
  datasetID?:string | undefined
};


export const SustellInterventionSteps: FC<SustellInterventionStepsProps> = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const { 
    active, 
    completed, 
    onStepChange, 
    customerID,
    farmID,
    processID,
    datasetID
  } = props;
  const stepString = intl.formatMessage({ id: 'SUSTELL.STEP' });

  const steps = [
      { 
        id:"1",
        title:`${stepString} 1`,
        completed:completed?.some( item => item === "1") || false,
        description: intl.formatMessage({ id: "SUSTELL.INTERVENTION.PROFILE_STEP"})
      },
      { 
        id:"2",
        completed:completed?.some( item => item === "2") || false,
        title:`${stepString} 2`,
        description: intl.formatMessage({ id: "SUSTELL.INTERVENTION.STAGE_STEP"})
      }
    ];


  // replace any with DsmCustom
  const callStepChange = (e:DsmProgressStepsCustomEvent<string>)=>{
    // for first three steps it's fixed as they do a move to separate pages
    // console.log("called step change", e.detail)
    if(e.detail){
      const targetId = e.detail;
      if(targetId === '1' && active!=='1' && customerID && farmID){
        history.push(`/customers/${customerID}/farm/${farmID}`);
        return;
      }
      if(targetId === '2' && active!=='2' &&customerID && farmID){
        history.push(`/customers/${customerID}/farm/${farmID}/pp`);
        return;
      }

    }
    if(onStepChange)
        onStepChange(e.detail);
  }
  
  return (
    <DsmProgressSteps 
      steps={steps} 
      active-step={active} 
      style={{ marginTop: 'var(--dsm-spacing-px-16)'}}
      onDsmStepChange={ callStepChange }
    />
  );
};

export default SustellInterventionSteps;