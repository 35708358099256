import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { ShrimpIntervention } from "../../../../models/Intervention/ShrimpIntervention";
import { ShrimpGrowingStageData } from "../../../../models/Baseline/ShrimpBaseline";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import { StageType } from "../../../../../../graphql/types";

interface ShrimpInputStockComponentProps {
  formType: FormType;
  fieldItemPrefix: string;
  currentStageData: ShrimpGrowingStageData;
  stageType: StageType;
}

const ShrimpInputStockComponent: FC<ShrimpInputStockComponentProps> = ({
  formType = FormType.Add,
  fieldItemPrefix,
  currentStageData,
  stageType,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : "kg";

  const fc = useFormContext<ShrimpIntervention>();

  const [isStockEdit, setIsStockEdit] = useState<boolean>(false);

  const cancelStockEdit = () => {
    fc.setValue(`${fieldItemPrefix}.totalWeightAtStartDate`, "");
    fc.setValue(`${fieldItemPrefix}.totalWeightAtEndDate`, "");
    fc.setValue(`${fieldItemPrefix}.priceAtStartDate`, "");
    fc.setValue(`${fieldItemPrefix}.priceAtEndDate`, "");
    setIsStockEdit(false);
  };

  const getStageTypeCode = () => {
    switch (stageType) {
      case StageType.Hatching:
        return "BROODSTOCK";
      case StageType.Nursery:
        return "POST_LARVAE";
      case StageType.Growing:
      default:
        return "JUVENILES";
    }
  };

  const stageTypeCode = getStageTypeCode();

  return (
    <DsmGrid
      className={
        isStockEdit
          ? classes.dsmGridOneShorterColumnInterventionWithBorder
          : classes.dsmGridTwoColumnWithBorder
      }
    >
      {!isStockEdit &&
      !fc.getValues(`${fieldItemPrefix}.totalWeightAtStartDate`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalTotalWeightAtStartDate`}
          label={`${intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_START`,
          })}`}
          type="number"
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_START.TOOLTIP`,
          })}
          disabled
          adornment={barnOutputMassUnit}
          defaultValue={currentStageData.input.totalWeightAtStartDate}
          style={{ marginTop: "10px" }}
        />
      ) : (
        <div style={{ marginTop: "10px" }}>
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.totalWeightAtStartDate`}
            label={`${intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_START`,
            })}`}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_START.TOOLTIP`,
            })}
            metricUnit={intl.formatMessage(
              { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
              { unit: barnOutputMassUnit }
            )}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={currentStageData.input.totalWeightAtStartDate}
            disabled={formType === "view" || !isStockEdit}
            minAllowedValue={0}
            displayFormattedUnit={false}
            originalMetricUnit={barnOutputMassUnit}
            displayTypeSelect={isStockEdit}
          />
        </div>
      )}
      {!isStockEdit &&
      !fc.getValues(`${fieldItemPrefix}.totalWeightAtEndDate`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalTotalWeightAtEndDate`}
          label={`${intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_END`,
          })}`}
          type="number"
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_END.TOOLTIP`,
          })}
          disabled
          adornment={barnOutputMassUnit}
          defaultValue={currentStageData.input.totalWeightAtEndDate}
          style={{ marginTop: "10px" }}
        />
      ) : (
        <div style={{ marginTop: !isStockEdit ? "10px" : "0px" }}>
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.totalWeightAtEndDate`}
            label={`${intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_END`,
            })}`}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT_END.TOOLTIP`,
            })}
            metricUnit={intl.formatMessage(
              { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
              { unit: barnOutputMassUnit }
            )}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={currentStageData.input.totalWeightAtEndDate}
            disabled={formType === "view" || !isStockEdit}
            minAllowedValue={0}
            displayFormattedUnit={false}
            originalMetricUnit={barnOutputMassUnit}
            displayTypeSelect={isStockEdit}
          />
        </div>
      )}
      {!isStockEdit && !fc.getValues(`${fieldItemPrefix}.priceAtStartDate`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalPriceAtStartDate`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_START",
          })}`}
          type="number"
          disabled
          adornment={intl.formatMessage(
            {
              id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_START.PLACEHOLDER",
            },
            {
              unit: barnOutputMassUnit,
            }
          )}
          defaultValue={currentStageData.input.priceAtStartDate}
        />
      ) : (
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.priceAtStartDate`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_START",
          })}`}
          metricUnit={intl.formatMessage(
            {
              id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_START.PLACEHOLDER",
            },
            {
              unit: barnOutputMassUnit,
            }
          )}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          baseline={currentStageData.input.priceAtStartDate}
          disabled={formType === "view" || !isStockEdit}
          minAllowedValue={0}
          displayTypeSelect={isStockEdit}
        />
      )}
      {!isStockEdit && !fc.getValues(`${fieldItemPrefix}.priceAtEndDate`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalPriceAtEndDate`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_END",
          })}`}
          type="number"
          disabled
          adornment={intl.formatMessage(
            {
              id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_END.PLACEHOLDER",
            },
            {
              unit: barnOutputMassUnit,
            }
          )}
          defaultValue={currentStageData.input.priceAtEndDate}
        />
      ) : (
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.priceAtEndDate`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_END",
          })}`}
          metricUnit={intl.formatMessage(
            {
              id: "BASELINE.FORM.JUVENILES.SHRIMP.PRICE_END.PLACEHOLDER",
            },
            {
              unit: barnOutputMassUnit,
            }
          )}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          baseline={currentStageData.input.priceAtEndDate}
          disabled={formType === "view" || !isStockEdit}
          minAllowedValue={0}
          displayTypeSelect={isStockEdit}
        />
      )}
      {!isStockEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsStockEdit(true)}
          disabled={formType === "view"}
          style={{ width: "120px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <DsmButton
          variant="text"
          destructive
          onClick={() => cancelStockEdit()}
          disabled={formType === "view"}
          style={{ width: "160px" }}
        >
          <DsmIcon slot="before" name="general/minus-circle" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.CANCEL",
            })}
          </u>
        </DsmButton>
      )}
    </DsmGrid>
  );
};

export default ShrimpInputStockComponent;
