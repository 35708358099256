import React, { useContext, useRef, useState } from 'react';
import Select from 'react-select';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Menu,
  Typography,
} from '@material-ui/core';
import {
  Close,
  ExitToAppSharp,
  ExpandMore,
  FilterList,
  SwapHoriz,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { footprintDetailsStyles } from '../../../../_metronic/layout';
import toAbsoluteUrl from '../../helpers/assetsHelpers';
import { translateKeyMapping } from '../../utils/string-utils';
import {
  FootprintFilterType,
  FootprintModel,
} from '../../models/Footprint';
import FootprintTableIcon, {
  FootprintIconSpecificStylesMap,
} from './common/FootprintIcon';
import FootprintCategoryTable from './FootprintCategoryTable';
import { exportToExcel } from './FootprintExcelExport';
import useFootprintCompare, { DatasetOption } from './useFootprintCompare';
import useFootprintFilters from './useFootprintFilters';
import { FootprintCategoryName } from '../../models/Footprint/FootprintTypes';
import { FootPrintType } from '../../../../graphql/types';
import { AnalysisGroupsFarm, AnalysisGroupsFeed } from '../../models/Dashboard/DashboardTypes';
import { AnimalTypeContext } from '../../pages/FootPrintPage';
import { getAnimalAnalysisGroup } from '../helpers/AnimalSpecificLabels';
import Can from '../../../modules/Auth/Can';

interface FootprintDetailsProps {
  footprint: FootprintModel;
  customerID: string;
  baselineFarmID: string;
  // eslint-disable-next-line react/require-default-props
  comparisonReference?: string;
  type: string;
  setCategoryFilters: (categories: FootprintCategoryName[]) => void;
  setAnalysisGroupFilters: (analysisGroups: string[]) => void;
  // eslint-disable-next-line react/require-default-props
  stageName?: string | undefined;
}

const FootprintDetails = (props: FootprintDetailsProps) => {
  const {
    footprint,
    setCategoryFilters,
    setAnalysisGroupFilters,
    customerID,
    type,
    baselineFarmID,
    comparisonReference,
    stageName,
  } = props;
  // This useFootprintCompare will return all datasets for customer
  const {
    getDatasets,
    getDefaultValue,
    baseline,
    isCompare,
    datasets,
    comparison,
    compareButtonClick,
    closeCompareView,
    swapButtonClick,
    handleDatasetBChange,
    handleDatasetAChange,
  } = useFootprintCompare({
    customerID,
    footprint,
    type,
    comparisonReference,
  });

  const {
    footprintFiltersMasterData,
    clearAllFilters,
    updateFilters,
    saveFooprintFilterSettings,
  } = useFootprintFilters({
    footprint,
    setCategoryFilters,
    setAnalysisGroupFilters,
  });

  const filtersMasterData = footprintFiltersMasterData();

  const menuAnchorEl = useRef<HTMLButtonElement | null>(null);

  const [filterType, setFilterType] = useState<FootprintFilterType | null>(
    null
  );

  const classes = footprintDetailsStyles();
  const intl = useIntl();

  const getDatasetOptions = () => {
    if (!footprint || !datasets || datasets.length === 0) {
      return {
        baseline: undefined,
        comparison: undefined
      };
    }
    const options = datasets.flatMap(d => d.options);

    const baselineOption = options.find(o => o.value === footprint.reference);
    let comparisonOption;
    if (footprint?.comparison && footprint.comparison?.reference) {
      comparisonOption = options.find(o => o.value === footprint.comparison?.reference);
      if (comparisonOption && stageName) comparisonOption.stageName = stageName;
    }
    if (baselineOption && stageName) {
      baselineOption.stageName = stageName;
    }
    return {
      baseline: baselineOption,
      comparison: comparisonOption
    }
  }

  const handleSaveClick = (saveFilterTypeSettings: FootprintFilterType) => {
    // then/catch needed to avoid eslint errors
    // if necessary confirmation dialog can be added
    saveFooprintFilterSettings(saveFilterTypeSettings)
      .then(() => {})
      .catch(() => {});
  };

  const handleAnalysisGroupClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    menuAnchorEl.current = event.currentTarget as HTMLButtonElement;
    setFilterType(FootprintFilterType.ANALYSIS_GROUP);
  };

  const handleCustomizedViewClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    menuAnchorEl.current = event.currentTarget as HTMLButtonElement;
    setFilterType(FootprintFilterType.CATEGORY);
  };

  const handleClose = () => {
    menuAnchorEl.current = null;
    setFilterType(null);
  };

  const handleChangeCategory = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = evt.target;
    const categoryValue = value.split('###')[1];
    const categorySection = value.split('###')[0];
    updateFilters(
      checked,
      categoryValue,
      FootprintFilterType.CATEGORY,
      categorySection
    );
  };

  const handleChangeAnalysisGroup = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = evt.target;
    updateFilters(checked, value, FootprintFilterType.ANALYSIS_GROUP, FootprintFilterType.ANALYSIS_GROUP);
  };

  const isCategoryChecked = (key: FootprintCategoryName) => {
    const { categoryFilters } = footprint;
    return categoryFilters.includes(key);
  };

  const isAnalysisGroupChecked = (analysis: string) => {
    const { analysisGroupFilters } = footprint;
    return analysisGroupFilters.includes(analysis);
  };

  const animalType: string | null = useContext(AnimalTypeContext);

  const getFilterMenuOptions = () => {
    if (filterType === FootprintFilterType.CATEGORY)
      return (
        filtersMasterData &&
        filtersMasterData.impactCategories &&
        filtersMasterData.impactCategories.map((dataRow) => (
          <ExpansionPanel key={dataRow.compartment}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              className={classes.accordionSummaryStyles}
              style={{
                color:
                  FootprintIconSpecificStylesMap[
                    dataRow.compartment.toUpperCase() as keyof typeof FootprintIconSpecificStylesMap
                  ].textColor,
              }}
            >
              <FootprintTableIcon
                image={toAbsoluteUrl(
                  `/media/sustell_15/icons/production_process_icons/${dataRow.compartment}_icon.svg`
                )}
                compartment={dataRow.compartment}
                isIconBackGround={false}
              />
              {intl.formatMessage({
                id: `SUSTELL.FOOTPRINT.BUTTONS.CUSTOMIZEVIEW.ACCORDION.TITLE.${dataRow.compartment.toUpperCase()}`,
              })}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.accordionDetailsStyles}>
              <FormGroup>
                {dataRow.categories.map((row) => (
                  <FormControlLabel
                    key={row.key}
                    className={classes.accordionFormLableStyles}
                    control={
                      <Checkbox
                        checked={isCategoryChecked(row.key)}
                        value={`${dataRow.compartment}###${row.key}`}
                        onChange={handleChangeCategory}
                      />
                    }
                    label={intl.formatMessage({
                      id: `SUSTELL.FOOTPRINT.${translateKeyMapping(row.key)}`,
                      defaultMessage: row.key,
                    })}
                  />
                ))}
              </FormGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      );
    if (filterType === FootprintFilterType.ANALYSIS_GROUP)
      return (
        <Box style={{ marginLeft: '16px', marginRight: '16px' }}>
          <FormGroup>
            {filtersMasterData &&
              filtersMasterData.analysisGroups &&
              filtersMasterData.analysisGroups
                // Allow only filtering by analysis groups that are can be found in type (farm or feed)
                .filter(
                  groupItem => groupItem === 'Unknown'
                  || (footprint.type === FootPrintType.CompoundFeed && Object.values<string>(AnalysisGroupsFeed).includes(groupItem))
                  || (footprint.type === FootPrintType.FarmBaseline && Object.values<string>(AnalysisGroupsFarm).includes(groupItem))
                )
                .map((analisysGroup) => (
                <FormControlLabel
                  key={analisysGroup}
                  className={classes.accordionFormLableStyles}
                  control={
                    <Checkbox
                      checked={isAnalysisGroupChecked(analisysGroup)}
                      value={analisysGroup}
                      onChange={handleChangeAnalysisGroup}
                    />
                  }
                  label={intl.formatMessage({
                    id: `SUSTELL.FOOTPRINT.${translateKeyMapping(
                      analisysGroup
                    )}`,
                    defaultMessage: getAnimalAnalysisGroup(animalType, analisysGroup, intl),
                  })}
                />
              ))}
          </FormGroup>
        </Box>
      );

    return null;
  };

  return (
    <Box>
      <Typography className={classes.footprintDetailsTitle}>
        {intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.DETAILS.TITLE',
        })}
      </Typography>
      <Typography className={classes.footprintDetailsDescription}>
        {intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.DETAILS.DESCRIPTION',
        })}
      </Typography>
      <br />
      <Grid container direction="row">
        <Grid item xs={2}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.buttonStyles}
            onClick={compareButtonClick}
          >
            {intl.formatMessage({ id: 'SUSTELL.FOOTPRINT.BUTTONS.COMPARE' })}
          </Button>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              aria-expanded={
                filterType === FootprintFilterType.ANALYSIS_GROUP
                  ? 'true'
                  : undefined
              }
              onClick={handleAnalysisGroupClick}
              color="secondary"
              variant="outlined"
              startIcon={<FilterList />}
              className={classes.buttonStyles}
              style={{ marginRight: '15px' }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.BUTTONS.SHOWIMPACTFROM',
              })}
            </Button>
            <Button
              aria-expanded={
                filterType === FootprintFilterType.CATEGORY ? 'true' : undefined
              }
              onClick={handleCustomizedViewClick}
              color="secondary"
              variant="outlined"
              className={classes.buttonStyles}
              style={{ marginRight: '15px' }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.BUTTONS.CUSTOMIZEVIEW',
              })}
            </Button>
            <Menu
              id="fade-menu"
              anchorEl={menuAnchorEl.current}
              keepMounted
              open={filterType !== null}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              className={classes.menuPaperStyles}
            >
              <Box>
                <Box style={{ paddingLeft: 16 }}>
                  <Typography
                    className={classes.customizeViewFilterMenuTitleStyles}
                    style={{ fontWeight: '500' }}
                  >
                    {filterType === FootprintFilterType.CATEGORY &&
                      intl.formatMessage({
                        id: 'SUSTELL.FOOTPRINT.BUTTONS.CUSTOMIZEVIEW.MENU.TITLE',
                      })}

                    {filterType === FootprintFilterType.ANALYSIS_GROUP &&
                      intl.formatMessage({
                        id: 'SUSTELL.FOOTPRINT.BUTTONS.IMPACTFROM.MENU.TITLE',
                      })}
                  </Typography>
                  <Button
                    // filterType cannot be null when this function is triggered
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => handleSaveClick(filterType!)}
                    className={classes.linkButtonStyles}
                  >
                    {intl.formatMessage({
                      id: 'SUSTELL.FOOTPRINT.BUTTONS.MENU.LINK.SAVE_VIEW',
                    })}
                  </Button>
                  <Button
                    // filterType cannot be null when this function is triggered
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => clearAllFilters(filterType!)}
                    className={classes.linkButtonStyles}
                  >
                    {intl.formatMessage({
                      id: 'SUSTELL.FOOTPRINT.BUTTONS.MENU.LINK.CLEAR_ALL',
                    })}
                  </Button>
                </Box>
                <Divider className={classes.dividerStyles} />
                <Box className={classes.accordionStyles}>
                  {getFilterMenuOptions()}
                </Box>
              </Box>
            </Menu>


            {(Can('export', 'Dashboard') && (
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ExitToAppSharp />}
              className={classes.buttonStyles}
              onClick={() => {
                  const options = getDatasetOptions();
                  // eslint-disable-next-line no-void
                  void exportToExcel(intl, footprint, options?.baseline, options?.comparison)
                }
              }
            >
              {intl.formatMessage({ id: 'SUSTELL.FOOTPRINT.BUTTONS.EXPORT' })}
            </Button>))}



          </Box>
        </Grid>
      </Grid>
      {isCompare && getDatasets().length > 0 && (
        <Grid container spacing={1} style={{ marginTop: 15 }}>
          <Grid item xs={5}>
            <InputLabel
              htmlFor="age-customized-select"
              style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.DATASETA',
              })}
            </InputLabel>
            <Select
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.SUGGESTIONS',
              })}
              isSearchable
              isClearable
              defaultValue={getDefaultValue as unknown as DatasetOption}
              value={baseline}
              onChange={handleDatasetAChange}
              options={getDatasets()}
            />
          </Grid>
          <Grid item xs={1} className={classes.compareButtonsStyles}>
            <Button
              variant="outlined"
              className={classes.swapButtonStyle}
              onClick={swapButtonClick}
            >
              <SwapHoriz />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <InputLabel
              htmlFor="age-customized-select"
              style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.DATASETB',
              })}
            </InputLabel>
            <Select
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.SUGGESTIONS',
              })}
              isSearchable
              isClearable
              value={comparison}
              onChange={handleDatasetBChange}
              options={getDatasets(true)}
            />
          </Grid>
          <Grid item xs={1} className={classes.compareButtonsStyles}>
            <Button
              variant="outlined"
              className={classes.closeButtonStyle}
              onClick={closeCompareView}
            >
              <Close />
            </Button>
          </Grid>
        </Grid>
      )}
      <br />
      {footprint.categories.length === 0 && (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{ height: '300px' }}
        >
          <Grid item>
            <Alert severity="warning">
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.WARNING_NOT_FOUND_OR_EMPTY',
              })}
            </Alert>
          </Grid>
        </Grid>
      )}
      {footprint.categories.length > 0 && (
        <FootprintCategoryTable
          footprint={footprint}
          type={type}
          baselineFarmID={baselineFarmID}
          customerID={customerID}
        />
      )}
    </Box>
  );
};

export default FootprintDetails;
