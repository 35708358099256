import { CSSProperties, FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DSM_DARK_GREY, DSM_MAIN_SELECTED_COLOR, processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { TargetAnimal } from '../../../../models/Baseline/CattleBaseline';
import { resolvePath } from '../../../../../modules/Helpers/resolvePathFn';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { CattleHousingAndManure } from '../../../../models/Intervention/CattleIntervention';
import { FormType } from '../../common';

type DairyV2HousingComponentProps = {
    stageIndex: number;
    target?: TargetAnimal;
    isViewMode: boolean;
    isDairyCowStage: boolean;
    baseline?: CattleHousingAndManure;
    formType: FormType;
    fieldItemPrefix: string;
};

const DairyV2HousingComponent: FC<DairyV2HousingComponentProps> = ({
    formType = FormType.Add,
    stageIndex,
    target,
    isViewMode,
    isDairyCowStage,
    baseline,
    fieldItemPrefix,
}) => {
    const fc = useFormContext();
    const intl = useIntl();
    const classes = processAndStageStylesV2() as CSSClassesList;

    const boldTextStyle = {
        fontWeight: "bold",
        fontSize: "18px",
        color: "black",
        margin: "0 5px 0 0",
        padding: 0,
    };

    const timeInHousingWatch = useWatch({
        name: `${fieldItemPrefix}.timeInHousing`,
    });
    const timeInFeedlotWatch = useWatch({
        name: `${fieldItemPrefix}.timeInFeedlot`,
    });
    const timeGrazingLargeAreasWatch = useWatch({
        name: `${fieldItemPrefix}.timeGrazingLargeAreas`,
    });
    const timeGrazingPasturesWatch = useWatch({
        name: `${fieldItemPrefix}.timeGrazingPastures`,
    });

    const getTimeInHousingValue = (): number =>
        timeInHousingWatch ? Number(timeInHousingWatch) : 0;

    const getTimeInFeedlotValue = (): number =>
        timeInFeedlotWatch ? Number(timeInFeedlotWatch) : 0;

    const getTimeGrazingLargeAreasValue = (): number =>
        timeGrazingLargeAreasWatch ? Number(timeGrazingLargeAreasWatch) : 0;

    const getTimeGrazingPasturesValue = (): number =>
        timeGrazingPasturesWatch ? Number(timeGrazingPasturesWatch) : 0;

    const handleHousingTime = async () => {
        if (
            !timeInHousingWatch &&
            timeInFeedlotWatch &&
            timeGrazingLargeAreasWatch &&
            timeGrazingPasturesWatch
        ) {
            let value =
                getTimeInFeedlotValue() +
                getTimeGrazingLargeAreasValue() +
                getTimeGrazingPasturesValue();

            value = 100 - value;

            fc.setValue(
                `${fieldItemPrefix}.timeInHousing`,
                value > 0 ? value : 0
            );
        }

        if (
            timeInHousingWatch &&
            !timeInFeedlotWatch &&
            timeGrazingLargeAreasWatch &&
            timeGrazingPasturesWatch
        ) {
            let value =
                getTimeInHousingValue() +
                getTimeGrazingLargeAreasValue() +
                getTimeGrazingPasturesValue();

            value = 100 - value;

            fc.setValue(
                `${fieldItemPrefix}.timeInFeedlot`,
                value > 0 ? value : 0
            );
        }

        if (
            timeInHousingWatch &&
            timeInFeedlotWatch &&
            !timeGrazingLargeAreasWatch &&
            timeGrazingPasturesWatch
        ) {
            let value =
                getTimeInHousingValue() +
                getTimeInFeedlotValue() +
                getTimeGrazingPasturesValue();

            value = 100 - value;

            fc.setValue(
                `${fieldItemPrefix}.timeGrazingLargeAreas`,
                value > 0 ? value : 0
            );
        }

        if (
            timeInHousingWatch &&
            timeInFeedlotWatch &&
            timeGrazingLargeAreasWatch &&
            !timeGrazingPasturesWatch
        ) {
            let value =
                getTimeInHousingValue() +
                getTimeInFeedlotValue() +
                getTimeGrazingLargeAreasValue();

            value = 100 - value;

            fc.setValue(
                `${fieldItemPrefix}.timeGrazingPastures`,
                value > 0 ? value : 0
            );
        }

        const total =
            getTimeInHousingValue() +
            getTimeInFeedlotValue() +
            getTimeGrazingLargeAreasValue() +
            getTimeGrazingPasturesValue();

        if (total === 100) {
            if (!timeInHousingWatch) {
                fc.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
            }
            if (!timeInFeedlotWatch) {
                fc.setValue(`${fieldItemPrefix}.timeInFeedlot`, 0);
            }
            if (!timeGrazingLargeAreasWatch) {
                fc.setValue(`${fieldItemPrefix}.timeGrazingLargeAreas`, 0);
            }
            if (!timeGrazingPasturesWatch) {
                fc.setValue(`${fieldItemPrefix}.timeGrazingPastures`, 0);
            }
        }

        await fc.trigger(`${fieldItemPrefix}.timeInHousing`);
        await fc.trigger(`${fieldItemPrefix}.timeInFeedlot`);
        await fc.trigger(`${fieldItemPrefix}.timeGrazingLargeAreas`);
        await fc.trigger(`${fieldItemPrefix}.timeGrazingPastures`);
    };

    const renderAdditionText = () => <span style={boldTextStyle}>+</span>;

    const isTimeSpentError = () =>
        Boolean(
            resolvePath(fc.errors, `${fieldItemPrefix}.timeInHousing`, null)
        );

    const getTotalTimeSpentText = () => {
        const totalTimeSpentTextStyle = isTimeSpentError()
            ? { color: "var(--dsm-color-error)", fontWeight: "500" }
            : ({ color: "var(--dsm-color-basic-black)" } as CSSProperties);
        const totalTimeSpentText = intl.formatMessage({
            id: "SUSTELL.STAGE.DAIRY.MMS.TOTAL_TIME_SPENT",
        });
        const errorHelperText = intl.formatMessage({
            id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SUM_MUST_BE_100",
        });
        const totalPercentageText = "= 100%";

        const mountedTextElement = (
            <div style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px"
            }}>
                <span
                    style={{
                        fontWeight: "bold",
                        color: "var(--dsm-color-basic-black)",
                        fontSize: "18px",
                    }}
                >
                    {totalPercentageText}
                </span>
                <span
                    style={{
                        ...totalTimeSpentTextStyle,
                        marginLeft: "var(--dsm-spacing-px-1)",
                    }}
                >
                    ({totalTimeSpentText})
                </span>
            </div>
        );

        if (isTimeSpentError()) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "15px",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        {mountedTextElement}
                        <ErrorOutlineIcon
                            fontSize="small"
                            color="error"
                            style={{
                                marginLeft: "var(--dsm-spacing-px-2)",
                                alignSelf: "center",
                            }}
                        />
                    </div>
                    <span style={{ color: "var(--dsm-color-error)", fontSize: "10px" }}>
                        {errorHelperText}
                    </span>
                </div>
            );
        }

        return mountedTextElement;
    };

    return (
        <div className={classes.dsmGridOneColumn}>
            <DsmGrid
                className={classes.additionalEntriesMMSBlockHolder}
                style={{
                    backgroundColor: DSM_MAIN_SELECTED_COLOR,
                    padding: 'var(--dsm-spacing-px-4)',
                    paddingBottom: isViewMode ? 0 : 'var(--dsm-spacing-px-4)'
                }}
            >
                <div className={classes.dsmGridOneColumn}>
                    {isViewMode && !(fc.getValues(`${fieldItemPrefix}.timeInHousing`)) ? (
                        <ReactHookDsmInput
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING",
                            })}
                            name={`${fieldItemPrefix}.originalTimeInHousing`}
                            adornment="%"
                            type="number"
                            disabled
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_IN_HOUSING.TOOLTIP",
                            })}
                            defaultValue={baseline?.timeInHousing as string}
                            showHelperText={false}
                            focusLostHandler={handleHousingTime}
                        />
                    ) : (
                        <RowControlledTextFieldWithMetrics
                            name={`${fieldItemPrefix}.timeInHousing`}
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING",
                            })}
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_IN_HOUSING.TOOLTIP",
                            })}
                            metricUnit={' '}
                            baseline={baseline?.timeInHousing}
                            disabled={formType === "view" || isViewMode}
                            minAllowedValue={0}
                            displayTypeSelect={!isViewMode}
                        />
                    )}
                </div>
            </DsmGrid>

            <DsmGrid
                className={classes.dsmGridOneColumnWithBorder}
                style={{
                    padding: 'var(--dsm-spacing-px-4)',
                    paddingBottom: 0,
                    borderBottom: '0',
                }}
            >
                <div
                    className={classes.dsmGridTwoColumn}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        gap: 0
                    }}
                >
                    {renderAdditionText()}

                    {isViewMode && !(fc.getValues(`${fieldItemPrefix}.timeInFeedlot`)) ? (
                        <ReactHookDsmInput
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_FEEDLOT",
                            })}
                            name={`${fieldItemPrefix}.originalTimeInFeedlot`}
                            adornment="%"
                            type="number"
                            disabled
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_FEEDLOT.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_IN_FEEDLOT.TOOLTIP",
                            })}
                            defaultValue={baseline?.timeInFeedlot as string}
                            showHelperText={false}
                            focusLostHandler={handleHousingTime}
                        />
                    ) : (
                        <RowControlledTextFieldWithMetrics
                            name={`${fieldItemPrefix}.timeInFeedlot`}
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_FEEDLOT",
                            })}
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_FEEDLOT.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_IN_FEEDLOT.TOOLTIP",
                            })}
                            metricUnit={' '}
                            baseline={baseline?.timeInFeedlot}
                            disabled={formType === "view" || isViewMode}
                            minAllowedValue={0}
                            displayTypeSelect={!isViewMode}
                        />
                    )}
                </div>

                <div
                    className={classes.dsmGridTwoColumn}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        marginTop: !isViewMode ? '15px' : '',
                        marginBottom: !isViewMode ? '15px' : '',
                        gap: 0
                    }}
                >
                    {renderAdditionText()}
                    {isViewMode && !(fc.getValues(`${fieldItemPrefix}.timeGrazingLargeAreas`)) ? (
                        <ReactHookDsmInput
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS",
                            })}
                            name={`${fieldItemPrefix}.originalTimeGrazingLargeAreas`}
                            adornment="%"
                            type="number"
                            disabled
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_GRAZING_LARGE_AREAS.TOOLTIP",
                            })}
                            defaultValue={baseline?.timeGrazingLargeAreas as string}
                            showHelperText={false}
                            focusLostHandler={handleHousingTime}
                        />
                    ) : (
                        <RowControlledTextFieldWithMetrics
                            name={`${fieldItemPrefix}.timeGrazingLargeAreas`}
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS",
                            })}
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_GRAZING_LARGE_AREAS.TOOLTIP",
                            })}
                            metricUnit={' '}
                            baseline={baseline?.timeGrazingLargeAreas}
                            disabled={formType === "view" || isViewMode}
                            minAllowedValue={0}
                            displayTypeSelect={!isViewMode}
                        />
                    )}
                </div>

                <div
                    className={classes.dsmGridTwoColumn}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        marginBottom: !isViewMode ? '15px' : '',
                        gap: 0
                    }}
                >
                    {renderAdditionText()}
                    {isViewMode && !(fc.getValues(`${fieldItemPrefix}.timeGrazingPastures`)) ? (
                        <ReactHookDsmInput
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_PASTURES",
                            })}
                            name={`${fieldItemPrefix}.originalTimeGrazingPastures`}
                            adornment="%"
                            type="number"
                            disabled={isViewMode}
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_PASTURES.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_GRAZING_PASTURES.TOOLTIP",
                            })}
                            defaultValue={baseline?.timeGrazingPastures as string}
                            showHelperText={false}
                            focusLostHandler={handleHousingTime}
                        />
                    ) : (
                        <RowControlledTextFieldWithMetrics
                            name={`${fieldItemPrefix}.timeGrazingPastures`}
                            label={intl.formatMessage({
                                id: "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_PASTURES",
                            })}
                            tooltip={intl.formatMessage({
                                id: isDairyCowStage
                                    ? "SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_GRAZING_PASTURES.TOOLTIP"
                                    : "SUSTELL.STAGE.DAIRY.HOUSING.CONTINUOUS_GROWING.TIME_SPENT_GRAZING_PASTURES.TOOLTIP",
                            })}
                            metricUnit={' '}
                            baseline={baseline?.timeGrazingPastures}
                            disabled={formType === "view" || isViewMode}
                            minAllowedValue={0}
                            displayTypeSelect={!isViewMode}
                        />
                    )}

                </div>
                <hr
                    style={{
                        borderWidth: "1px",
                        borderColor: DSM_DARK_GREY,
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }}
                />
                {getTotalTimeSpentText()}
            </DsmGrid>
        </div>
    );
};

DairyV2HousingComponent.defaultProps = {
    target: undefined,
};

export default DairyV2HousingComponent;
