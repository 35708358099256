// This is disabled to avoid used of defaultProps property and to use classic destructing with default values for props
/* eslint-disable react/require-default-props */
import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  MutableRefObject,
  DetailedHTMLProps,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import ReactHookDsmSelect from "../../Helpers/ReactHookDsmSelect2";
import lookupValues from "../../../sustell_15/helpers/lookupValues";
import { resolvePath } from "../../Helpers/resolvePathFn";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import {
  DSM_DISABLED_LABEL,
  processAndStageStylesV2,
} from "../../../../_metronic/layout";

const inlineTooltipClass = {
  color: "var(--dsm-color-neutral-darker)",
  position: "absolute",
  height: "16px",
  width: "16px",
  top: "29px",
  right: "7px",
};

const useStyles = makeStyles({
  root: {
    // minHeight: '74px',
    "& input": {
      marginTop: "-4px",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "24px",
      fontSize: "13px",
      lineHeight: "28px",
    },
    "& label": {
      position: "relative",
      width: "125% !important",
      paddingLeft: "0px",
      marginBottom: "9px",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& legend": {
      display: "none",
    },
    "& label.Mui-focused": {
      color: "var(--dsm-color-primary)",
    },
    "& label.Mui-error": {
      color: "var(--dsm-color-error)",
    },
    "& .MuiOutlinedInput-root": {
      "& div.MuiInputAdornment-positionEnd": {
        "& p": {
          fontSize: "13px",
          marginBottom: "3px",
        },
      },
      "& fieldset": {
        border: "1px var(--dsm-color-neutral-darker) solid",
        borderRadius: "2px",
      },
      "&.Mui-focused fieldset": {
        border: "1px var(--dsm-color-primary) solid",
      },
      "&.Mui-error fieldset": {
        border: "1px var(--dsm-color-error) solid",
      },
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px !important",
    },
  },
});

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: any;
  children: any;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  condition ? wrapper(children) : children;

interface RowControlledTextFieldWithMetricsProps {
  name: string;
  label: string;
  tooltip?:
    | string
    | DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  updateTotalMethane?: () => void;
  metricUnit: string;
  baseline: number | string | null | undefined;
  updateTotalFeedIntake?: (name: string, value: number | null) => void;
  disabled: boolean;
  readonly?: boolean;
  minAllowedValue?: number;
  maxAllowedValue?: number;
  precision?: number;
  onlySetMetricIfNoBaseline?: boolean | null;
  inputRef?: MutableRefObject<number | string | null>;
  updateRef?: (
    name: string,
    inputRef: MutableRefObject<number | string | null>,
    newValue: number | string
  ) => void;
  clearDefaultStyle?: boolean;
  type?: React.InputHTMLAttributes<unknown>["type"];
  displayFormattedUnit?: boolean;
  originalMetricUnit?: string;
  displayTypeSelect?: boolean;
  isManureSystem?: boolean;
}

const RowControlledTextFieldWithMetrics = ({
  name,
  label,
  tooltip,
  updateTotalMethane,
  metricUnit,
  baseline,
  updateTotalFeedIntake,
  disabled,
  readonly,
  minAllowedValue = 0,
  maxAllowedValue,
  precision = 2,
  onlySetMetricIfNoBaseline = false,
  inputRef = undefined,
  updateRef = undefined,
  clearDefaultStyle = false,
  type = "text",
  displayFormattedUnit = true,
  originalMetricUnit = "",
  displayTypeSelect = true,
  isManureSystem = false,
}: RowControlledTextFieldWithMetricsProps) => {
  const intl = useIntl();
  const fc = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;

  const classesLocal = useStyles();
  let numOldValue: number | null = null;
  if (typeof baseline === "number") numOldValue = baseline;
  else if (typeof baseline === "string") numOldValue = parseFloat(baseline);

  if (Number.isNaN(numOldValue)) numOldValue = null;

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const localError = resolvePath(fc.errors, name, null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currResetValue = resolvePath(
    fc.control.defaultValuesRef.current,
    name,
    null
  );

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isTouched = resolvePath(fc.formState.dirtyFields, name, false);

  const [metricValue, setMetricValue] = useState<string | null>();
  const initialRender = useRef(true);

  const getCorrectDisplayText = (
    formattedUnit: string,
    originalUnit: string
  ) => {
    return displayFormattedUnit ? formattedUnit : originalUnit;
  };

  let addornmentTxt =
    metricValue === "relative" || metricValue === undefined
      ? "%"
      : getCorrectDisplayText(metricUnit, originalMetricUnit);

  const calculateNewValues = (newValueFromForm: string): number | "" => {
    const metric = fc.getValues(`${name}_changeMetric`) as string;
    // addornmentTxt must also be updated here if values are set after loading data
    addornmentTxt =
      metric === "relative" || metric === undefined
        ? "%"
        : getCorrectDisplayText(metricUnit, originalMetricUnit);

    const newValue = parseFloat(newValueFromForm);

    if (Number.isNaN(newValue)) {
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, numOldValue);
      fc.setValue(`${name}_newValue`, "");
      if (inputRef && updateRef && inputRef.current !== "") {
        updateRef(name, inputRef, "");
      }
      if (updateTotalMethane) updateTotalMethane();
      return "";
    }
    if (metric === "set") {
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, newValue);
      fc.setValue(`${name}_newValue`, newValue);
      if (inputRef && updateRef && inputRef.current !== newValue) {
        updateRef(name, inputRef, newValue);
      }
      if (updateTotalMethane) updateTotalMethane();
      return newValue;
    }
    if ((numOldValue || numOldValue === 0) && metric !== "") {
      let result: string | number = "";
      if (metric === "absolute") {
        result = numOldValue + newValue;
        if (updateTotalFeedIntake) updateTotalFeedIntake(name, result);
        fc.setValue(`${name}_newValue`, result);
        if (inputRef && updateRef && inputRef.current !== result) {
          updateRef(name, inputRef, result);
        }
        if (updateTotalMethane) updateTotalMethane();
        return result;
      }
      // relative
      result = numOldValue * (1 + newValue / 100);
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, result);
      fc.setValue(`${name}_newValue`, result);
      if (inputRef && updateRef && inputRef.current !== result) {
        updateRef(name, inputRef, result);
      }
      if (updateTotalMethane) updateTotalMethane();
      return result;
    }

    return "";
  };

  const checkMetricUnit = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value && !Number.isNaN(e.target.value) && numOldValue === null)
      fc.setValue(`${name}_changeMetric`, "set");
  };

  const getMetrics = () => {
    if (isManureSystem) {
      return { set: lookupValues.interventionValueMetrics.set };
    }
    return lookupValues.interventionValueMetrics;
  };

  const getMetricOptions = () => {
    // if baseline not defined, only set option is possible
    if (onlySetMetricIfNoBaseline)
      return baseline || baseline === 0
        ? getMetrics()
        : { set: lookupValues.interventionValueMetrics.set };

    return getMetrics();
  };

  const getDefaultValue = () => {
    if (isManureSystem) return "set";
    if (onlySetMetricIfNoBaseline)
      return baseline || baseline === 0 ? "relative" : "set";

    return "relative";
  };

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else {
      // eslint-disable-next-line no-void
      void fc.trigger(name);
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [metricValue, fc, fc.trigger, name]);

  const labelDisabled = disabled
    ? { color: DSM_DISABLED_LABEL as string, width: "max-content" }
    : { width: "max-content" };

  return (
    // <DsmGrid className={ classes.dsmGridTwoColumnsInterventionComponent}>
    <ConditionalWrapper
      condition={!clearDefaultStyle}
      wrapper={(children: any) => (
        <DsmGrid
          className={
            displayTypeSelect
              ? classes.dsmGridTwoColumnsInterventionComponent
              : classes.dsmGridTwoColumnsHiddenInterventionComponent
          }
        >
          {children}
        </DsmGrid>
      )}
    >
      <div style={{ position: "relative", display: "inline-block" }}>
        <Controller
          control={fc.control}
          name={name}
          defaultValue=""
          render={({ onChange, onBlur, value, ref }) => {
            // allows zeroes to be visible
            let localVal: string =
              typeof value === "string" ? value : String(value);

            if (localVal === "" && currResetValue === 0 && !isTouched)
              localVal = "0";

            const localNew = calculateNewValues(localVal);
            const style =
              !Number.isNaN(localNew) &&
              (Number(localNew) < minAllowedValue ||
                (Number(localNew) > 100 &&
                  (metricUnit === "percentage" || metricUnit === "%")) ||
                (maxAllowedValue && Number(localNew) > maxAllowedValue))
                ? { color: "red" }
                : {};
            const metricUnitShort =
              metricUnit === "number"
                ? ""
                : metricUnit.replace(
                    /p[o,a,e]r animal/,
                    getCorrectDisplayText("p.a.", "")
                  ); // will work for english, spanish, portuguese and french
            const minValue = metricValue === "relative" ? -100 : 0;

            return (
              <>
                <TextField
                  className={classesLocal.root}
                  label={label}
                  variant="outlined"
                  value={localVal || localVal === "0" ? localVal : ""}
                  fullWidth
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                    checkMetricUnit(e);
                  }}
                  onBlur={onBlur}
                  type={type}
                  error={!!localError}
                  // TODO Fix when resolvePath is noverted to TS
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  helperText={localError ? localError.message : ""}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                      height: "28px",
                      lineHeight: "28px",
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: "12px" }}
                      >
                        {addornmentTxt}
                      </InputAdornment>
                    ),
                    readOnly: readonly,
                    inputProps: {
                      min: minValue,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    variant: "standard",
                    disableAnimation: true,
                    style: {
                      width: "100%",
                    },
                  }}
                  disabled={disabled}
                />
                <div style={labelDisabled}>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE",
                  })}{" "}
                  <strong>
                    {typeof numOldValue === "number"
                      ? `${numOldValue.toFixed(precision)} ${metricUnitShort}`
                      : " - "}
                  </strong>
                  /{" "}
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.CHANGE.NEW_VALUE",
                  })}{" "}
                  <strong style={style}>
                    {" "}
                    {typeof localNew === "number" && !Number.isNaN(localNew)
                      ? `${localNew.toFixed(precision)} ${metricUnitShort}`
                      : " - "}{" "}
                  </strong>
                </div>
              </>
            );
          }}
        />
        <Controller
          control={fc.control}
          name={`${name}_newValue`}
          defaultValue=""
          // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unsafe-assignment
          render={({ value, name }) => (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <input type="hidden" name={name} value={value} />
          )}
        />
        {tooltip && (
          <Tooltip title={tooltip} placement="left">
            <DsmIcon
              name="general/help-circle"
              style={inlineTooltipClass}
              title=""
            />
          </Tooltip>
        )}
      </div>
      {displayTypeSelect && (
        <ReactHookDsmSelect
          name={`${name}_changeMetric`}
          label={intl.formatMessage({
            id: "INTERVENTION.FORM.CHANGE.CHANGE_TYPE",
          })}
          defaultValue={getDefaultValue()}
          changeHandler={(e: ChangeEvent<HTMLSelectElement>) =>
            setMetricValue(e.target.value)
          }
          disabled={!!disabled}
          options={Object.entries(getMetricOptions()).map(([i]) => ({
            value: i,
            text: intl.formatMessage({
              id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`,
            }),
          }))}
        >
          {/* besiede i was label */}
          {Object.entries(getMetricOptions()).map(([i]) => (
            <MenuItem key={i} value={i}>
              {intl.formatMessage({
                id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`,
              })}
            </MenuItem>
          ))}
        </ReactHookDsmSelect>
      )}
      {/* </DsmGrid> */}
    </ConditionalWrapper>
  );
};

// RowControlledTextFieldWithMetrics.defaultProps = defaultProps;

export default RowControlledTextFieldWithMetrics;
