import { FC, SetStateAction, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { BaselineStageProps } from '../../common';
import StageButtonBox from '../../StageButtonBox_v2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import SlaughterhouseAnimalsFormDialog from './SlaughterhouseAnimalsFormDialog';
import SlaughterhousePackagingFormDialog from './SlaughterhousePackagingFormDialog';
import SlaughterhouseOutputFormDialog from './SlaughterhouseOutputFormDialog';
import { PigBaseline } from '../../../../models/Baseline/PigBaseline';
import { ProcessingStageData } from '../../../../models/Facility/Processing';
import { FacilityTableItem } from '../../../../models/Facility/FacilityTypes';
import { stageDataPartPig } from '../../../../../modules/Farms/Baseline/validationSchema/pigV2BaselineValidation';
import { MarineFishProcessBaseline } from '../../../../models/Baseline/MarineFishBaseline';
import { StageButtonBoxProps } from '../../StageButtonBox';
import {
  isCuttingFacility,
  isMarineFishFacility,
} from '../../../../helpers/facilities';

interface InitialCalcuationData {
  prefix: 'processingInput' | 'processingOutput' | 'processingPackaging';
}

interface CloseDialogData {
  type: string;
  setFunc: React.Dispatch<React.SetStateAction<number>>;
  prefix: 'processingInput' | 'processingOutput' | 'processingPackaging';
}

type SlaughterhouseDialogType =
  | 'processingInput'
  | 'processingOutput'
  | 'processingPackaging'
  | null;
interface SlaughterhouseStagesProps
  extends Omit<
    BaselineStageProps,
    | 'compoundFeeds'
    | 'manureManagementSystems'
    | 'singleIngredients'
    | 'baseline'
  > {
  baseline: PigBaseline | MarineFishProcessBaseline;
  // eslint-disable-next-line react/require-default-props
  facilities?: FacilityTableItem[] | null;
  calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

const SlaughterhouseStages: FC<SlaughterhouseStagesProps> = ({
  stageIndex,
  baseline,
  item,
  formType,
  facilities = null,
  calculateCompleteness,
}) => {
  const intl = useIntl();
  const [activeDialog, setActiveDialog] =
    useState<SlaughterhouseDialogType>(null);
  const showDialog = (dialog: SetStateAction<SlaughterhouseDialogType>) => {
    setActiveDialog(dialog);
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const facility = facilities?.find((fac) => fac.id === item?.facilityId);

  const [animalsCompleteness, setAnimalsCompleteness] = useState(0);
  const [outputCompleteness, setOutputCompleteness] = useState(0);
  const [packagingCompletness, setPackagingCompletness] = useState(0);

  const formContext = useFormContext<PigBaseline>();
  const stagePrefix = `stages.${stageIndex}`;

  const initialCalculation = (initialCalcuationData: InitialCalcuationData) => {
    return calculateCompleteness(
      stageDataPartPig,
      `stages[${stageIndex}].stageData.${initialCalcuationData.prefix}`
    );
  };

  const getAnimalsDialogCodes = (): Pick<
    StageButtonBoxProps,
    'iconCode' | 'titleCode'
  > => {
    const isMarineFish = isMarineFishFacility(facility?.targetSpecies.at(0));
    const isCutting = isCuttingFacility(facility?.type);
    const carcassesTitleCode = 'SUSTELL.PROCESS.DIALOG.STAGE.CARCASSES.TITLE';
    const marineFishTileCode = 'SUSTELL.PROCESS.DIALOG.STAGE.MARINE_FISH.TITLE';
    const marineFishIconCode = 'spiecies/aquaculture';
    const defaultAnimalsTitleCode =
      'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE';
    const defaultAnimalsIconCode = 'general/check-heart';

    const iconCode = isMarineFish ? marineFishIconCode : defaultAnimalsIconCode;
    const titleCode = isMarineFish
      ? marineFishTileCode
      : defaultAnimalsTitleCode;

    if (!isCutting) return { titleCode, iconCode };
    return { titleCode: carcassesTitleCode, iconCode };
  };

  const closeDialog = (closeDialogData: CloseDialogData) => {
    const completeness = calculateCompleteness(
      stageDataPartPig,
      `stages[${stageIndex}].stageData.${closeDialogData.prefix}`
    );
    if (formType !== 'view' && closeDialogData.type === 'confirm') {
      closeDialogData.setFunc(completeness);
    } else if (closeDialogData.type === 'reset') {
      if (!completeness)
        formContext.trigger(`${stagePrefix}.stageData.${activeDialog}`);
      else formContext.clearErrors(`${stagePrefix}.stageData.${activeDialog}`);
    }
    setActiveDialog(null);
  };

  useEffect(() => {
    if (formType === 'edit' && !item.newStage && item?.facilityId) {
      setAnimalsCompleteness(initialCalculation({ prefix: 'processingInput' }));
      setOutputCompleteness(initialCalculation({ prefix: 'processingOutput' }));
      setPackagingCompletness(
        initialCalculation({ prefix: 'processingPackaging' })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        defaultValue={item.animalType}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.facilityId`}
        defaultValue={item.facilityId}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.facilityType`}
        defaultValue={
          facility?.type || formContext.getValues(`${stagePrefix}.facilityType`)
        }
      />
      {activeDialog === 'processingInput' && (
        <SlaughterhouseAnimalsFormDialog
          formVisible={activeDialog === 'processingInput'}
          formTitle={intl.formatMessage({
            id: getAnimalsDialogCodes().titleCode,
          })}
          iconCode={getAnimalsDialogCodes().iconCode}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setAnimalsCompleteness,
              prefix: 'processingInput',
            })
          }
          facility={facility}
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {activeDialog === 'processingOutput' && (
        <SlaughterhouseOutputFormDialog
          formVisible={activeDialog === 'processingOutput'}
          formTitle={intl.formatMessage({
            id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
          })}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputCompleteness,
              prefix: 'processingOutput',
            })
          }
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
          facility={facility}
        />
      )}
      {activeDialog === 'processingPackaging' && (
        <SlaughterhousePackagingFormDialog
          formVisible={activeDialog === 'processingPackaging'}
          formTitle={intl.formatMessage({
            id: 'SUSTELL.PROCESS.DIALOG.STAGE.PACKAGING.TITLE',
          })}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setPackagingCompletness,
              prefix: 'processingPackaging',
            })
          }
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
          facility={facility}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <StageButtonBox
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={animalsCompleteness}
          error={
            formContext.errors?.stages &&
            (
              formContext.errors?.stages[stageIndex]
                ?.stageData as ProcessingStageData
            )?.processingInput
          }
          handleOpen={() => showDialog('processingInput')}
          iconCode={getAnimalsDialogCodes().iconCode}
          titleCode={getAnimalsDialogCodes().titleCode}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={outputCompleteness}
          error={
            formContext.errors?.stages &&
            (
              formContext.errors?.stages[stageIndex]
                ?.stageData as ProcessingStageData
            )?.processingOutput
          }
          handleOpen={() => showDialog('processingOutput')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.PACKAGING.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.PROCESS.DIALOG.STAGE.PACKAGING.DESCRIPTION"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={packagingCompletness}
          error={
            formContext.errors?.stages &&
            (
              formContext.errors?.stages[stageIndex]
                ?.stageData as ProcessingStageData
            )?.processingPackaging
          }
          handleOpen={() => showDialog('processingPackaging')}
        />
        <Box style={{ width: '218px' }} />
        <Box style={{ width: '218px' }} />
      </Grid>
    </>
  );
};

export default SlaughterhouseStages;
