import { Dispatch, FC, SetStateAction } from "react";
import { FormType } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { DairyIntervention } from "../../../../models/Intervention";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CattleFeed } from "../../../../models/Intervention/CattleIntervention";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import SustellRationFeeds from "../../../../../modules/Farms/Baseline/SustellRationFeeds_v2";
import { CompoundFeedDatabase, MasterDataOption, Maybe } from "../../../../../../graphql/types";

interface CompoundFeed {
  display_name: string;
  value: string;
  databaseFoundation: CompoundFeedDatabase;
}

interface DairyV2FeedCompoundFeedComponentProp {
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  databaseFoundation: CompoundFeedDatabase;
  formType: FormType,
  fieldItemPrefix: string,
  currentStageData: CattleFeed,
  isCompFeedEdit: boolean,
  setIsCompFeedEdit: Dispatch<SetStateAction<boolean>>
}

const DairyV2FeedCompoundFeedComponent: FC<DairyV2FeedCompoundFeedComponentProp> = ({
  compoundFeeds,
  databaseFoundation,
  formType = FormType.Add,
  fieldItemPrefix,
  currentStageData,
  isCompFeedEdit,
  setIsCompFeedEdit
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<DairyIntervention>();

  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass as string) : ("kg" as string);

  const {
    fields: feedsAdditions,
    append: appendFeedsAdditions,
    remove: removeFeedsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.compoundFeedsAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const resetEdit = () => {
    (currentStageData?.compoundFeeds || [])
      ?.filter((item) => !!item.feedType)
      ?.forEach((_, index) => {
        fc.setValue(`${fieldItemPrefix}.compoundFeeds[${index}].kgPerAnimal`, "");
      });
    if (currentStageData.compoundFeeds?.length != undefined) {
      for (let i = currentStageData.compoundFeeds?.length; i >= 0; i--) {
        removeFeedsAdditions(i);
      };
    }
    setIsCompFeedEdit(false);
  };

  const getBaselineCompoundFeedLabel = (id: string) => {
    const feedDisplayName = compoundFeeds?.find(
      (item) => item.value === id
    )?.display_name;
    return feedDisplayName || "unknown";
  };

  const getCompoundFeedsList = (databaseOption: CompoundFeedDatabase) => {
    const feeds = (compoundFeeds as unknown as CompoundFeed[]) || [];
    return feeds.filter(
      (feed: CompoundFeed) => feed.databaseFoundation === databaseOption
    );
  };

  return (
    <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
      {(currentStageData?.compoundFeeds || [])
        ?.filter((item) => !!item.feedType)
        ?.map((feedItem, i: number) => (
          !isCompFeedEdit &&
          !fc.getValues(`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`)
        ) ? (
          <DsmGrid className={classes.dsmGridTwoColumnsInterventionComponent}>
            <ReactHookDsmInput
              key={`${fieldItemPrefix}.compoundFeeds[${i}].originalKgPerAnimal`}
              defaultValue={feedItem.kgPerAnimal ?? ''}
              name={`${fieldItemPrefix}.compoundFeeds[${i}].originalKgPerAnimal`}
              label={getBaselineCompoundFeedLabel(feedItem.feedType)}
              adornment={barnOutputMassUnit}
              disabled={true}
              type="number"
            />
          </DsmGrid>
        ) : (
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
            label={getBaselineCompoundFeedLabel(feedItem.feedType)}
            tooltip={intl.formatMessage({
              id: "INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP",
            })}
            metricUnit={intl.formatMessage(
              { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
              { unit: barnOutputMassUnit }
            )}
            baseline={feedItem.kgPerAnimal}
            disabled={formType === "view" || !isCompFeedEdit}
            minAllowedValue={0}
            displayFormattedUnit={false}
            originalMetricUnit={barnOutputMassUnit}
            displayTypeSelect={isCompFeedEdit}
          />
        )
        )}
      {(isCompFeedEdit ||
        (!isCompFeedEdit && feedsAdditions?.length !== 0)) && (
          <SustellRationFeeds
            key="compoundFeedsAdditions"
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.FEED.COMPOUND_FEED",
            })}
            availableFeedItems={getCompoundFeedsList(databaseFoundation)}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="compoundFeedsAdditions"
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP",
            })}
            formState={isCompFeedEdit ? formType : FormType.View}
            compoundFeed
            feedData={feedsAdditions}
            fieldsAppenRemoveObject={{
              fields: feedsAdditions,
              append: appendFeedsAdditions,
              remove: removeFeedsAdditions,
            }}
            allowNewFeeds={isCompFeedEdit}
          />
        )}
      {!isCompFeedEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsCompFeedEdit(true)}
          disabled={formType === FormType.View}
          style={{ width: "120px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <DsmButton
          variant="text"
          destructive
          onClick={() => resetEdit()}
          disabled={formType === FormType.View}
          style={{ width: "160px" }}
        >
          <DsmIcon slot="before" name="general/minus-circle" />
          <u>
            {intl.formatMessage({
              id: "GENERAL.REMOVE_CHANGE_TYPE",
            })}
          </u>
        </DsmButton>
      )}
    </DsmGrid>
  )
}

export default DairyV2FeedCompoundFeedComponent;
