import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { unitLong } from '../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../../sustell_15/components/CompoundFeedWizard/types';
import ReactHookDsmInput from '../../Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../Helpers/ReactHookDsmSelect2';
import lookupValues from '../../../sustell_15/helpers/lookupValues';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';

const NaturalFeedInput = (props) => {
  const {
    fieldItemPrefix,
    subFieldName,
    label,
    index,
    formState,
    availableFeedItems,
    availableOrigins,
    removeHandler,
  } = props;

  const classes = processAndStageStylesV2();
  const { register, control, errors, getValues, setValue } = useFormContext();
  const intl = useIntl();
  const resolvePath = (object, path, defaultValue) =>
    path
      .split(/[.[\]'"]/)
      .filter((p) => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  const idName = `${fieldItemPrefix}.${subFieldName}[${index}].id`;
  const feedTypeFieldName = `${fieldItemPrefix}.${subFieldName}[${index}].type`;
  const originFieldName = `${fieldItemPrefix}.${subFieldName}[${index}].origin`;
  const feedTypeWatch = useWatch({ control, name: feedTypeFieldName });
  const amountFieldName = `${fieldItemPrefix}.${subFieldName}[${index}].quantity`;
  const proteinName = `${fieldItemPrefix}.${subFieldName}[${index}].crudeProteinContent`;

  const localError = resolvePath(errors, amountFieldName, null);
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  let selectedItemOrigin = getValues(originFieldName);

  selectedItemOrigin =
    selectedItemOrigin !== undefined ? selectedItemOrigin : '';

  let amountValue = getValues(amountFieldName);
  amountValue = amountValue !== undefined ? amountValue : '';

  const defaultProtein = getValues(proteinName);

  const onFeedTypeChange = (event) => {
    setValue(
      `${fieldItemPrefix}.${subFieldName}[${index}].crudeProteinContent`,
      lookupValues.naturalFeedProteinContentDefault[event.target.value]
    );
  }

  const labelKey = 'SUSTELL.AMOUNT';
  const proteinLabelKey = 'SUSTELL.STAGE.FEED.CRUDE_PROTEIN_CONTENT';
  return (
    <DsmGrid
      className={classes.naturalFeedBlock}
    >
      <input
        ref={register()}
        type="hidden"
        name={idName}
        defaultValue={getValues(idName) || ''}
      />
      <ReactHookDsmSelect
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPart1`}
        label={label}
        name={feedTypeFieldName}
        defaultValue={getValues(feedTypeFieldName)}
        disabled={formState === FormType.View}
        options={availableFeedItems}
        changeHandler={onFeedTypeChange}
      />
      <ReactHookDsmSelect
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPartOrigin`}
        label={intl.formatMessage({
          id: 'SUSTELL.STAGE.FEED.SINGLE_INGREDIENT.ORIGIN',
        })}
        name={originFieldName}
        defaultValue={selectedItemOrigin}
        disabled={formState === FormType.View}
        options={availableOrigins}
      />
      <ReactHookDsmInput
        style={{ marginTop: '3px' }}
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPart2`}
        defaultValue={amountValue}
        name={amountFieldName}
        label={intl.formatMessage({ id: labelKey })}
        adornment={
          userUOM?.unitBarnOutputMass
            ? unitLong(userUOM.unitBarnOutputMass)
            : 'kg'
        }
        helperText={localError && localError.message ? localError.message : ''}
        error={Boolean(localError)}
        disabled={formState === 'view' || !feedTypeWatch}
        tooltip={intl.formatMessage({
          id: 'NATURAL_FEED_AMOUNT.TOOLTIP'
        })}
      />
      <ReactHookDsmInput
        style={{ marginTop: '3px' }}
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPartProtein`}
        defaultValue={defaultProtein}
        name={proteinName}
        label={intl.formatMessage({ id: proteinLabelKey })}
        adornment="%"
        disabled={formState === 'view' || !feedTypeWatch}
        tooltip={intl.formatMessage({
          id: 'NATURAL_FEED_PROTEIN.TOOLTIP'
        })}
      />
      {formState !== 'view' && (
        <DsmButton
          variant="text"
          iconOnly
          destructive
          onClick={removeHandler}
          style={{ padding: '0px', marginTop: '24px' }}
        >
          <DsmIcon slot="before" name="general/trash-01" />
        </DsmButton>
      )}
    </DsmGrid>
  );
};
export default NaturalFeedInput;
