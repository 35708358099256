import { FC, useEffect, useRef, useState } from "react";
import { FormType } from "../../common";
import { DairyV2Baseline } from "../../../../models/Baseline/DairyV2Baseline";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { useFormContext } from "react-hook-form";
import { DairyIntervention } from "../../../../models/Intervention";
import { CompoundFeedDatabase, MasterDataOption, Maybe, StageType } from "../../../../../../graphql/types";
import { DairyV2ContinuousGrowingStageData, DairyV2DairyCowStageData } from "../../../../models/Intervention/DairyV2Intervention";
import { CattleFeed } from "../../../../models/Intervention/CattleIntervention";
import { cloneDeep } from "lodash";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import DairyV2FeedCompoundFeedComponent from "./DairyV2FeedCompoundFeedComponent";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { getDatabaseOptions } from "../../../CompoundFeedWizard/utils";
import DairyV2FeedSingleIngredientComponent from "./DairyV2FeedSingleIngredientComponent";
import { Origin } from "../types";
import DairyV2FeedDietCharacterisationDialog from "./DairyV2FeedDietCharacterisationDialog";
import DairyV2FeedFreshGrassComponent from "./DairyV2FeedFreshGrassComponent";
import DairyV2FeedMothersMilkComponent from "./DairyV2FeedMothersMilkComponent";
import { Spacer } from "../../../helpers/Spacer";

interface DairyV2FeedDialogProps {
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  singleIngredients: Maybe<Array<MasterDataOption>>;
  origins: Maybe<Array<Origin>>;
  formType: FormType;
  stageIndex: number;
  stageType: string;
  baseline: DairyV2Baseline;
  handleCancel: (param?: string | null) => void;
}

const DairyV2FeedDialog: FC<DairyV2FeedDialogProps> = ({
  compoundFeeds,
  singleIngredients,
  origins,
  formType = FormType.Add,
  stageIndex = 0,
  stageType,
  baseline,
  handleCancel,
}) => {
  const classes: CSSClassesList = processAndStageStylesV2();
  const intl = useIntl();
  const currResetValue = useRef<CattleFeed>();
  const fc = useFormContext<DairyIntervention>();

  const [isDietEdit, setIsDietEdit] = useState(false);
  const [isCompFeedEdit, setIsCompFeedEdit] = useState(false);
  const [isSingleIngEdit, setIsSingleIngEdit] = useState(false);
  const [isMotherMilkEdit, setIsMotherMilkEdit] = useState(false);
  const [isFreshGrassEdit, setIsFreshGrassEdit] = useState(false);
  const isContinuousGrowth = stageType === StageType.ContinuousGrowing;

  const formTitle = intl.formatMessage({ id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE', });
  const formIntroText = intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.FEED.CONTINUOUS_GROWING.DESCRIPTION', })
  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  const currentStageData = baseline.stages[stageIndex].stageData;

  let dairyV2FeedData;
  switch (stageType) {
    case StageType.ContinuousGrowing:
      dairyV2FeedData = (currentStageData as DairyV2ContinuousGrowingStageData).feed;
      break;

    case StageType.DairyCow:
      dairyV2FeedData = (currentStageData as DairyV2DairyCowStageData).feed;
      break;

    default:
      dairyV2FeedData = {} as CattleFeed;
      break;
  }

  useEffect(() => {
    currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
  });

  const handleDialogCancelClick = () => {
    if (currResetValue.current) {
      for (let [propertyName, key] of Object.entries(currResetValue.current)) {
        fc.setValue(`${fieldItemPrefix}.${propertyName}`, key);
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <DialogContainer
      formVisible={true}
      handleClose={handleDialogCancelClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      introText={formIntroText}
      datasetType="intervention"
      width="1272px"
      dialogContentStyles={
        {
          paddingBottom: '1.25rem',
          overflowY: 'unset',
        }
      }
    >
      <DsmGrid className={classes.dsmGridThreeColumnSecondBigOthersSmall}>
        <ReactHookDsmSelect
          name="databaseFoundation"
          defaultValue={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
          label={`${intl.formatMessage({ id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION' })}`}
          disabled
          options={getDatabaseOptions() as []}
        />
        {isContinuousGrowth &&
          <DairyV2FeedDietCharacterisationDialog
            formType={formType}
            fieldItemPrefix={fieldItemPrefix}
            currentStageData={dairyV2FeedData}
            isDietEdit={isDietEdit}
            setIsDietEdit={setIsDietEdit}
          />}
      </DsmGrid>
      <Spacer />
      <DsmGrid className={isCompFeedEdit || isSingleIngEdit ?
        classes.dsmGridTwoColumn2To3 : classes.dsmGridThreeColumnSecondBigOthersSmall}>
        <div>
          <p className={classes.headingTitleVariant}>
            {intl.formatMessage({ id: "SUSTELL.STAGE.FEED.COMPOUND_FEED" })}
          </p>
          <DairyV2FeedCompoundFeedComponent
            compoundFeeds={compoundFeeds}
            databaseFoundation={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
            formType={formType}
            fieldItemPrefix={fieldItemPrefix}
            currentStageData={dairyV2FeedData}
            isCompFeedEdit={isCompFeedEdit}
            setIsCompFeedEdit={setIsCompFeedEdit}
          />
        </div>
        <div>
          <p className={classes.headingTitleVariant}>
            {intl.formatMessage({ id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT" })}
          </p>
          <DairyV2FeedSingleIngredientComponent
            singleIngredients={singleIngredients}
            origins={origins}
            formType={formType}
            stageIndex={stageIndex}
            fieldItemPrefix={fieldItemPrefix}
            currentStageData={dairyV2FeedData}
            isSingleIngEdit={isSingleIngEdit}
            setIsSingleIngEdit={setIsSingleIngEdit}
          />
        </div>
      </DsmGrid>
      <Spacer height="23.5px" />
      {isContinuousGrowth ? (
        <>
          <DairyV2FeedMothersMilkComponent
            formType={formType}
            fieldItemPrefix={fieldItemPrefix}
            currentStageData={dairyV2FeedData}
            isMotherMilkEdit={isMotherMilkEdit}
            setIsMotherMilkEdit={setIsMotherMilkEdit}
          />
          <Spacer />
        </>
      ) : (
        <></>
      )}
      <p className={classes.headingTitleVariant}>
        {intl.formatMessage({ id: "SUSTELL.STAGE.FEED.FRESH_GRASS" })}
      </p>
      <DairyV2FeedFreshGrassComponent
        formType={formType}
        fieldItemPrefix={fieldItemPrefix}
        currentStageData={dairyV2FeedData}
        isContinuousGrowth={isContinuousGrowth}
        isFreshGrassEdit={isFreshGrassEdit}
        setIsFreshGrassEdit={setIsFreshGrassEdit}
      />
      <DsmButtonControlGroup
        cancelHandler={handleDialogCancelClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  )
}

export default DairyV2FeedDialog;
