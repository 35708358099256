import { MenuItem } from "@material-ui/core";
// import { useIntl } from 'react-intl';
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import {
  DSM_DISABLED_LABEL,
  processAndStageStylesV2,
} from "../../../../_metronic/layout";
import ReactHookDsmSelect from "../../Helpers/ReactHookDsmSelect2";

const RowSelectWithMetricsV2 = (props) => {
  let {
    name,
    label,
    control,
    options,
    tooltip,
    variant,
    baseline,
    disabled,
    originalChanged = undefined,
    changeHandler = undefined,
    adornment = undefined,
    originalLabel = true,
  } = props;
  const classes = processAndStageStylesV2();
  const intl = useIntl();
  let originalDisplayName = baseline;
  if (baseline && options?.findIndex((e) => e.value === baseline) > -1) {
    originalDisplayName = options.filter((e) => e.value === baseline)[0]?.text;
  }

  const labelDisabled = disabled
    ? { color: DSM_DISABLED_LABEL, width: "max-content" }
    : { width: "max-content" };

  return (
    <DsmGrid
      className={classes.dsmGridOneColumnNoRowgap}
      style={{ marginBottom: "var(--dsm-spacing-px-4)" }}
    >
      {/* <input hidden name={name} /> */}
      <ReactHookDsmSelect
        name={`${name}`}
        defaultValue={baseline}
        options={options}
        label={label}
        control={control}
        variant={variant}
        fullWidth
        style={{ marginBottom: 0 }}
        tooltip={tooltip || ""}
        adornment={adornment || ""}
        disabled={disabled}
        changeHandler={(e) => {
          // console.log(e.target.value, baseline)

          // if onChange event provided, trigger it
          if (changeHandler) {
            changeHandler(e);
            return;
          }

          if (e.target.value !== baseline && originalChanged) {
            originalChanged(true);
          } else {
            originalChanged(false);
          }
        }}
      >
        {(options || []).map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </ReactHookDsmSelect>
      {originalLabel && (
        <div style={labelDisabled}>
          {intl.formatMessage({
            id: "INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE",
          })}
          <strong>{" " + originalDisplayName}</strong>
        </div>
      )}
    </DsmGrid>
  );
};

export default RowSelectWithMetricsV2;
