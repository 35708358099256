import { Dispatch, FC, SetStateAction } from "react";
import { CattleFeed } from "../../../../models/Intervention/CattleIntervention";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { useFormContext } from "react-hook-form";
import { DairyIntervention } from "../../../../models/Intervention";
import { enumToOptionsArrayWithTranslatedStrings } from "../../../../utils/obj-utils";
import { FeedStatus } from "../../../../../../graphql/generated/blonk/cattle";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { FormType } from "../../common";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";

interface DairyV2FeedDietCharacterisationDialogProp {
  formType: FormType,
  fieldItemPrefix: string;
  currentStageData: CattleFeed;
  isDietEdit: boolean;
  setIsDietEdit: Dispatch<SetStateAction<boolean>>;
}

const DairyV2FeedDietCharacterisationDialog: FC<DairyV2FeedDietCharacterisationDialogProp> = ({
  formType,
  fieldItemPrefix,
  currentStageData,
  isDietEdit,
  setIsDietEdit
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<DairyIntervention>();

  const dietCharacterisationOptions = () => enumToOptionsArrayWithTranslatedStrings(
    FeedStatus, intl, 'SUSTELL.STAGE.DAIRY.FEED');

  const resetEdit = () => {
    fc.setValue(`${fieldItemPrefix}.dietCharacterisation`, currentStageData.dietCharacterisation)
    setIsDietEdit(false);
  };

  return (
    <DsmGrid className={classes.dsmGridOneColumnNoGap}>
      <RowSelectWithMetricsV2
        name={`${fieldItemPrefix}.dietCharacterisation`}
        label={`${intl.formatMessage({
          id: 'SUSTELL.STAGE.DAIRY.FEED.DIET_CHARACTERISATION',
        })}`}
        disabled={!isDietEdit}
        baseline={currentStageData.dietCharacterisation}
        options={dietCharacterisationOptions()}
        tooltip={intl.formatMessage({
          id: 'SUSTELL.STAGE.DAIRY.FEED.CONTINUOUS_GROWING.DIET_CHARACTERISATION.TOOLTIP'
        })}
        needMarginBottom={false}
        originalLabel={
          fc.getValues(`${fieldItemPrefix}.dietCharacterisation`) !== currentStageData.dietCharacterisation
        }
      />
      {!isDietEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsDietEdit(true)}
          disabled={formType === FormType.View}
          style={{ width: "120px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <DsmButton
          variant="text"
          destructive
          onClick={() => resetEdit()}
          disabled={formType === FormType.View}
          style={{ width: "160px" }}
        >
          <DsmIcon slot="before" name="general/minus-circle" />
          <u>
            {intl.formatMessage({
              id: "GENERAL.REMOVE_CHANGE_TYPE",
            })}
          </u>
        </DsmButton>
      )}
    </DsmGrid>
  )
}

export default DairyV2FeedDietCharacterisationDialog;
