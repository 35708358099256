/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { FormType } from "../../common";
import { ShrimpBaseline } from "../../../../models/Baseline/ShrimpBaseline";
import { StageType, TransportMode } from "../../../../../../graphql/types";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";
import { InputAnimalOrigin } from "../../../../../../graphql/generated/blonk/shrimp";

interface ShrimpInputExternalComponentProperties {
  stageIndex: number;
  itemIndex: number;
  formType: FormType;
  // eslint-disable-next-line react/require-default-props
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/ban-types
  isExternalEdit: boolean;
  // eslint-disable-next-line react/require-default-props
  stageType: StageType;
  baseline: ShrimpBaseline;
}

const ShrimpInputExternalComponent: FC<
  ShrimpInputExternalComponentProperties
> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  isExternalEdit,
  stageType,
  baseline,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.externalSources.${itemIndex}`;
  const intl = useIntl();
  const inputData =
    baseline.stages[stageIndex].stageData.input.externalSources[itemIndex];

  let stageTypeCode = "";
  switch (stageType) {
    case StageType.Nursery:
      stageTypeCode = "POST_LARVAE";
      break;
    case StageType.Growing:
    default:
      stageTypeCode = "JUVENILES";
      break;
  }

  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : "kg";

  const selectedTransportMode = useWatch({
    name: `${fieldItemPrefix}.transportMode`,
  });
  const [distanceUnit, setDistanceUnit] = useState(
    userUOM.unitTransportDistanceTerrestrial
  );
  useEffect(() => {
    switch (selectedTransportMode) {
      case TransportMode.InlandShip:
        setDistanceUnit(userUOM.unitTransportDistanceInlandWaterways);
        break;
      case TransportMode.SeaShip:
        setDistanceUnit(userUOM.unitTransportDistanceSea);
        break;
      case TransportMode.Train:
      case TransportMode.Truck:
        setDistanceUnit(userUOM.unitTransportDistanceTerrestrial);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransportMode]);

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={inputData.id}
      />
      <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
        <div style={{ marginTop: "10px" }}>
          {!isExternalEdit &&
          !fc.getValues(`${fieldItemPrefix}.totalWeight`) ? (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalTotalWeight`}
              label={`${intl.formatMessage({
                id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT`,
              })}`}
              disabled
              tooltip={intl.formatMessage({
                id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT.TOOLTIP`,
              })}
              adornment={barnOutputMassUnit}
              defaultValue={inputData.totalWeight}
            />
          ) : (
            <RowControlledTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalWeight`}
              label={`${intl.formatMessage({
                id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT`,
              })}`}
              tooltip={intl.formatMessage({
                id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT.TOOLTIP`,
              })}
              metricUnit={intl.formatMessage(
                { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
                { unit: barnOutputMassUnit }
              )}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              baseline={inputData.totalWeight}
              disabled={formType === FormType.View || !isExternalEdit}
              minAllowedValue={0}
              displayFormattedUnit={false}
              originalMetricUnit={barnOutputMassUnit}
              displayTypeSelect={isExternalEdit}
            />
          )}
        </div>
        {!isExternalEdit &&
        !fc.getValues(`${fieldItemPrefix}.distanceTraveled`) ? (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.originalDistanceTraveled`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.DISTANCE_TRAVELED",
            })}`}
            disabled
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.SUPPLY.DISTANCE_TRAVELED.TOOLTIP`,
            })}
            adornment={distanceUnit}
            defaultValue={inputData.distanceTraveled}
          />
        ) : (
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.distanceTraveled`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.DISTANCE_TRAVELED",
            })}`}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.SUPPLY.DISTANCE_TRAVELED.TOOLTIP`,
            })}
            metricUnit={distanceUnit}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={inputData.distanceTraveled}
            disabled={formType === FormType.View || !isExternalEdit}
            minAllowedValue={0}
            displayTypeSelect={isExternalEdit}
          />
        )}

        {!isExternalEdit &&
        (!fc.getValues(`${fieldItemPrefix}.transportMode`) ||
          fc.getValues(`${fieldItemPrefix}.transportMode`) ===
            inputData.transportMode) ? (
          <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
            <ReactHookDsmSelect
              style={{ width: "100%" }}
              name={`${fieldItemPrefix}.originalTransportMode`}
              label={`${intl.formatMessage({
                id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE",
              })}`}
              disabled
              tooltip={intl.formatMessage({
                id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TRANSPORT_MODE.TOOLTIP`,
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE.PLACEHOLDER",
              })}
              options={Object.keys(TransportMode).map((key) => ({
                value: TransportMode[key as keyof typeof TransportMode],
                text: TransportMode[key as keyof typeof TransportMode]
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
              }))}
              defaultValue={inputData.transportMode as string}
            />
          </div>
        ) : (
          <RowSelectWithMetricsV2
            name={`${fieldItemPrefix}.transportMode`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE",
            })}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TRANSPORT_MODE.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE.PLACEHOLDER",
            })}
            disabled={formType === FormType.View || !isExternalEdit}
            options={Object.keys(TransportMode).map((key) => ({
              value: TransportMode[key as keyof typeof TransportMode],
              text: TransportMode[key as keyof typeof TransportMode]
                .replace(/_/g, " ")
                .toLowerCase()
                .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            }))}
            baseline={inputData.transportMode}
            needMarginBottom={false}
          />
        )}
      </DsmGrid>
    </>
  );
};

export default ShrimpInputExternalComponent;
