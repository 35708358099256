import { FC, Fragment, useState } from "react";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { FormType, ListEntry } from "../../common";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { BaselineFeedWithOrigin } from "../../../../models/Baseline";
import {
  CompoundFeedDatabase,
  MasterDataOption,
  Maybe,
  StageType,
} from "../../../../../../graphql/types";
import { unitLong } from "../../../../utils/unit-utils";
import SustellRationFeeds from "../../../../../modules/Farms/Baseline/SustellRationFeeds_v2";
import SustellSingleIngredientFeed from "../../../../../modules/Farms/Baseline/SustellSingleIngredientFeeds_v2";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import SustellInterventionSingleIngredientFeedComponent from "../../../../../modules/Farms/Intervention/SustellInterventionSingleIngredientFeedComponent";
import {
  NaturalFeed,
  ShrimpFeed,
} from "../../../../models/Baseline/ShrimpBaseline";
import { ShrimpIntervention } from "../../../../models/Intervention/ShrimpIntervention";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { MasterDataEnumType } from "../CommonDataParts/InterventionTypes";
import SustellNaturalFeeds from "../../../../../modules/Farms/Baseline/SustellNaturalFeeds";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";

interface CompoundFeed {
  display_name: string;
  value: string;
  databaseFoundation: CompoundFeedDatabase;
}

interface ShrimpFeedFormProps {
  formType: FormType;
  itemIndex: number;
  // eslint-disable-next-line react/require-default-props
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  singleIngredients?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  origins?: ListEntry[];
  databaseFoundation: CompoundFeedDatabase;
  feedBaseline: ShrimpFeed;
  fieldItemPrefix: string;
  masterDataEnums: MasterDataEnumType | null;
  stageType: StageType;
}

const ShrimpFeedForm: FC<ShrimpFeedFormProps> = ({
  formType,
  itemIndex,
  compoundFeeds,
  singleIngredients,
  origins,
  databaseFoundation,
  feedBaseline,
  fieldItemPrefix,
  masterDataEnums,
  stageType,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const fc = useFormContext<ShrimpIntervention>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass as string)
    : ("kg" as string);

  const [isCompoundFeedEdit, setIsCompoundFeedEdit] = useState<boolean>(false);
  const [isSingleIngredientEdit, setIsSingleIngredientEdit] =
    useState<boolean>(false);
  const [isNaturalFeedEdit, setIsNaturalFeedEdit] = useState<boolean>(false);

  const getCompoundFeedsList = (databaseOption: CompoundFeedDatabase) => {
    const feeds = (compoundFeeds as unknown as CompoundFeed[]) || [];
    return feeds.filter(
      (feed: CompoundFeed) => feed.databaseFoundation === databaseOption
    );
  };

  const {
    fields: feedsAdditions,
    append: appendFeedsAdditions,
    remove: removeFeedsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.compoundFeedsAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const {
    fields: ingredientsAdditions,
    append: appendIngredientsAdditions,
    remove: removeIngredientsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.singleIngredientsAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const {
    fields: naturalFeedAdditions,
    append: appendNaturalFeedAdditions,
    remove: removeNaturalFeedAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.naturalFeedAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const getBaselineCompoundFeedLabel = (id: string) => {
    const feedDisplayName = compoundFeeds?.find(
      (item) => item.value === id
    )?.display_name;
    return feedDisplayName || "unknown";
  };

  const cancelCompoundFeedEdit = () => {
    (feedBaseline?.compoundFeeds || [])
      ?.filter((item) => !!item.feedType)
      ?.forEach((_, index) => {
        fc.setValue(
          `${fieldItemPrefix}.compoundFeeds[${index}].kgPerAnimal`,
          ""
        );
      });
    for (let index = feedsAdditions.length - 1; index >= 0; index--) {
      removeFeedsAdditions(index);
    }
    setIsCompoundFeedEdit(false);
  };

  const cancelSingleIngredientsEdit = () => {
    (feedBaseline?.singleIngredients || [])
      ?.filter((item) => !!item.feedType)
      ?.forEach((item, index) => {
        fc.setValue(
          `${fieldItemPrefix}.singleIngredients[${index}].kgPerAnimal`,
          ""
        );
        fc.setValue(
          `${fieldItemPrefix}.singleIngredients[${index}].origin`,
          item.origin
        );
      });
    for (let index = ingredientsAdditions.length - 1; index >= 0; index--) {
      removeIngredientsAdditions(index);
    }
    setIsSingleIngredientEdit(false);
  };

  const cancelNaturalFeedEdit = () => {
    (feedBaseline?.naturalFeed || [])
      ?.filter((item) => !!item.type)
      ?.forEach((item, index) => {
        fc.setValue(`${fieldItemPrefix}.naturalFeed[${index}].quantity`, "");
        fc.setValue(
          `${fieldItemPrefix}.naturalFeed[${index}].origin`,
          item.origin
        );
        fc.setValue(
          `${fieldItemPrefix}.naturalFeed[${index}].crudeProteinContent`,
          ""
        );
      });
    for (let index = naturalFeedAdditions.length - 1; index >= 0; index--) {
      removeNaturalFeedAdditions(index);
    }
    setIsNaturalFeedEdit(false);
  };

  return (
    <div>
      <DsmGrid
        className={`${classes.dsmGridTwoColumn2To3} ${classes.topVerticalSpacer}`}
      >
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <h6>{intl.formatMessage({ id: "COMPOUNDFEEDS.CARD.TITLE" })}</h6>
          <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
            {(feedBaseline?.compoundFeeds || [])
              ?.filter((item) => !!item.feedType)
              ?.map((feedItem, i: number) =>
                // eslint-disable-next-line react/no-array-index-key
                !isCompoundFeedEdit &&
                !fc.getValues(
                  `${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`
                ) ? (
                  <DsmGrid
                    className={classes.dsmGridTwoColumnsInterventionComponent}
                  >
                    <ReactHookDsmInput
                      key={`${fieldItemPrefix}.compoundFeeds[${i}].originalKgPerAnimal`}
                      defaultValue={feedItem.kgPerAnimal}
                      name={`${fieldItemPrefix}.compoundFeeds[${i}].originalKgPerAnimal`}
                      label={getBaselineCompoundFeedLabel(feedItem.feedType)}
                      adornment={barnOutputMassUnit}
                      disabled={true}
                      type="number"
                    />
                  </DsmGrid>
                ) : (
                  <Fragment
                    key={`InterventionCompoundFeed_${feedItem.feedType}_${i}`}
                  >
                    <input
                      type="hidden"
                      value={feedItem.feedType}
                      ref={fc.register()}
                      name={`${fieldItemPrefix}.compoundFeeds[${i}].feedType`}
                    />
                    <RowControlledTextFieldWithMetrics
                      name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
                      label={getBaselineCompoundFeedLabel(feedItem.feedType)}
                      tooltip={intl.formatMessage({
                        id: "INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP",
                      })}
                      metricUnit={intl.formatMessage(
                        { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
                        { unit: barnOutputMassUnit }
                      )}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      baseline={feedItem.kgPerAnimal}
                      disabled={formType === "view" || !isCompoundFeedEdit}
                      minAllowedValue={0}
                      displayFormattedUnit={false}
                      originalMetricUnit={barnOutputMassUnit}
                      displayTypeSelect={isCompoundFeedEdit}
                    />
                  </Fragment>
                )
              )}
            {(isCompoundFeedEdit ||
              (!isCompoundFeedEdit && feedsAdditions?.length !== 0)) && (
              <SustellRationFeeds
                key="compoundFeedsAdditions"
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.FEED.COMPOUND_FEED",
                })}
                availableFeedItems={getCompoundFeedsList(databaseFoundation)}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName="compoundFeedsAdditions"
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP",
                })}
                formState={isCompoundFeedEdit ? formType : FormType.View}
                compoundFeed
                feedData={feedsAdditions}
                fieldsAppenRemoveObject={{
                  fields: feedsAdditions,
                  append: appendFeedsAdditions,
                  remove: removeFeedsAdditions,
                }}
                allowNewFeeds={isCompoundFeedEdit}
              />
            )}
            {!isCompoundFeedEdit ? (
              <DsmButton
                variant="text"
                onClick={() => setIsCompoundFeedEdit(true)}
                disabled={formType === "view"}
                style={{ width: "120px" }}
              >
                <DsmIcon slot="before" name="general/edit-02" />
                <u>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.EDIT_INPUT",
                  })}
                </u>
              </DsmButton>
            ) : (
              <DsmButton
                variant="text"
                destructive
                onClick={() => cancelCompoundFeedEdit()}
                disabled={formType === "view"}
                style={{ width: "160px" }}
              >
                <DsmIcon slot="before" name="general/minus-circle" />
                <u>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.CANCEL",
                  })}
                </u>
              </DsmButton>
            )}
          </DsmGrid>
        </DsmGrid>
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <h6>
            {intl.formatMessage({
              id: "PRODUCTION.DATA.BARN.SINGLE_INGREDIENTS",
            })}
          </h6>
          <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
            {(feedBaseline?.singleIngredients || [])
              ?.filter((item) => !!item.feedType && !!item.origin)
              ?.map((singleIngredientItem: BaselineFeedWithOrigin, i: number) =>
                !isSingleIngredientEdit &&
                !fc.getValues(
                  `${fieldItemPrefix}.singleIngredients[${i}].kgPerAnimal`
                ) &&
                !fc.getValues(
                  `${fieldItemPrefix}.singleIngredients[${i}].origin`
                ) ? (
                  <DsmGrid
                    className={
                      classes.dsmGridTwoColumnsIngredientInterventionComponent
                    }
                  >
                    <ReactHookDsmInput
                      key={`${fieldItemPrefix}.singleIngredients[${i}].originalKgPerAnimal`}
                      defaultValue={singleIngredientItem.kgPerAnimal}
                      name={`${fieldItemPrefix}.singleIngredients[${i}].originalKgPerAnimal`}
                      label={singleIngredientItem.feedType}
                      adornment={barnOutputMassUnit}
                      disabled={true}
                      type="number"
                    />
                    <ReactHookDsmSelect
                      key={`${fieldItemPrefix}.singleIngredients[${i}].originalOrigin`}
                      label={intl.formatMessage({
                        id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT.ORIGIN",
                      })}
                      name={`${fieldItemPrefix}.singleIngredients[${i}].originalOrigin`}
                      defaultValue={singleIngredientItem.origin}
                      disabled={true}
                      options={origins}
                    />
                  </DsmGrid>
                ) : (
                  <SustellInterventionSingleIngredientFeedComponent
                    formType={
                      !isSingleIngredientEdit ? FormType.View : formType
                    }
                    singleIngredient={singleIngredientItem}
                    ingredientIndex={i}
                    fieldItemPrefix={fieldItemPrefix}
                    availableOrigins={origins}
                    displayTypeSelect={isSingleIngredientEdit}
                  />
                )
              )}
            {(isSingleIngredientEdit ||
              (!isSingleIngredientEdit &&
                ingredientsAdditions?.length !== 0)) && (
              <SustellSingleIngredientFeed
                key="singleIngredients"
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT",
                })}
                barnItemIndex={itemIndex}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                availableFeedItems={singleIngredients}
                availableOrigins={origins}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName="singleIngredientsAdditions"
                tooltip={
                  <div key="feeds-form-dialog-singleIngredients">
                    {intl.formatMessage(
                      {
                        id: "BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP",
                      },
                      { br: <br /> }
                    )}
                  </div>
                }
                formState={isSingleIngredientEdit ? formType : FormType.View}
                feedData={ingredientsAdditions}
                fieldsAppenRemoveObject={{
                  fields: ingredientsAdditions,
                  append: appendIngredientsAdditions,
                  remove: removeIngredientsAdditions,
                }}
                allowNewIngredients={isSingleIngredientEdit}
              />
            )}
            {!isSingleIngredientEdit ? (
              <DsmButton
                variant="text"
                onClick={() => setIsSingleIngredientEdit(true)}
                disabled={formType === "view"}
                style={{ width: "120px" }}
              >
                <DsmIcon slot="before" name="general/edit-02" />
                <u>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.EDIT_INPUT",
                  })}
                </u>
              </DsmButton>
            ) : (
              <DsmButton
                variant="text"
                destructive
                onClick={() => cancelSingleIngredientsEdit()}
                disabled={formType === "view"}
                style={{ width: "160px" }}
              >
                <DsmIcon slot="before" name="general/minus-circle" />
                <u>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.CANCEL",
                  })}
                </u>
              </DsmButton>
            )}
          </DsmGrid>
        </DsmGrid>
      </DsmGrid>
      {stageType === StageType.Hatching && (
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <h6>
            {intl.formatMessage({
              id: "SUSTELL.STAGE.FEED.NATURAL_FEED",
            })}
          </h6>
          <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
            {(feedBaseline?.naturalFeed || [])
              ?.filter((item) => !!item.type && !!item.origin)
              ?.map((naturalFeedItem: NaturalFeed, i: number) =>
                !isNaturalFeedEdit &&
                !fc.getValues(
                  `${fieldItemPrefix}.naturalFeed[${i}].quantity`
                ) &&
                !fc.getValues(`${fieldItemPrefix}.naturalFeed[${i}].origin`) &&
                !fc.getValues(
                  `${fieldItemPrefix}.naturalFeed[${i}].crudeProteinContent`
                ) ? (
                  <DsmGrid className={classes.naturalFeedBlock}>
                    <ReactHookDsmInput
                      key={`${fieldItemPrefix}.naturalFeed[${i}].originalQuantity`}
                      defaultValue={naturalFeedItem.quantity}
                      name={`${fieldItemPrefix}.naturalFeed[${i}].originalQuantity`}
                      label={naturalFeedItem.type}
                      adornment={barnOutputMassUnit}
                      disabled={true}
                      type="number"
                    />
                    <ReactHookDsmSelect
                      key={`${fieldItemPrefix}.naturalFeed[${i}].originalOrigin`}
                      label={intl.formatMessage({
                        id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT.ORIGIN",
                      })}
                      name={`${fieldItemPrefix}.naturalFeed[${i}].originalOrigin`}
                      defaultValue={naturalFeedItem.origin}
                      disabled={true}
                      options={origins}
                    />
                    <ReactHookDsmInput
                      key={`${fieldItemPrefix}.naturalFeed[${i}].originalCrudeProteinContent`}
                      defaultValue={
                        naturalFeedItem.crudeProteinContent ?? undefined
                      }
                      name={`${fieldItemPrefix}.naturalFeed[${i}].originalCrudeProteinContent`}
                      label={intl.formatMessage({
                        id: "SUSTELL.STAGE.FEED.CRUDE_PROTEIN_CONTENT",
                      })}
                      adornment="%"
                      disabled={true}
                      type="number"
                    />
                  </DsmGrid>
                ) : (
                  <DsmGrid
                    className={classes.dsmGridThreeColumnsInterventionComponent}
                  >
                    <input
                      type="hidden"
                      value={naturalFeedItem.id}
                      ref={fc.register()}
                      name={`${fieldItemPrefix}.naturalFeed[${i}].id`}
                    />
                    <input
                      type="hidden"
                      value={naturalFeedItem.type}
                      ref={fc.register()}
                      name={`${fieldItemPrefix}.naturalFeed[${i}].type`}
                    />
                    <RowControlledTextFieldWithMetrics
                      name={`${fieldItemPrefix}.naturalFeed[${i}].quantity`}
                      label={naturalFeedItem.type}
                      tooltip={intl.formatMessage({
                        id: `NATURAL_FEED_AMOUNT.TOOLTIP`,
                      })}
                      metricUnit={intl.formatMessage(
                        { id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX" },
                        { unit: barnOutputMassUnit }
                      )}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      baseline={naturalFeedItem.quantity}
                      disabled={formType === "view" || !isNaturalFeedEdit}
                      minAllowedValue={0}
                      displayFormattedUnit={false}
                      originalMetricUnit={barnOutputMassUnit}
                      displayTypeSelect={isNaturalFeedEdit}
                    />
                    <RowSelectWithMetricsV2
                      name={`${fieldItemPrefix}.naturalFeed[${i}].origin`}
                      label={intl.formatMessage({
                        id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT.ORIGIN",
                      })}
                      disabled={
                        formType === FormType.View || !isNaturalFeedEdit
                      }
                      options={origins}
                      baseline={naturalFeedItem.origin}
                      needMarginBottom={false}
                    />
                    <RowControlledTextFieldWithMetrics
                      name={`${fieldItemPrefix}.naturalFeed[${i}].crudeProteinContent`}
                      label={`${intl.formatMessage({
                        id: "SUSTELL.STAGE.FEED.CRUDE_PROTEIN_CONTENT",
                      })}`}
                      tooltip={intl.formatMessage({
                        id: "NATURAL_FEED_PROTEIN.TOOLTIP",
                      })}
                      metricUnit="%"
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      baseline={naturalFeedItem.crudeProteinContent}
                      disabled={formType === "view" || !isNaturalFeedEdit}
                      minAllowedValue={0}
                      displayTypeSelect={isNaturalFeedEdit}
                    />
                  </DsmGrid>
                )
              )}
            {(isNaturalFeedEdit ||
              (!isNaturalFeedEdit && naturalFeedAdditions?.length !== 0)) && (
              <SustellNaturalFeeds
                key="naturalFeed"
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.FEED.NATURAL_FEED",
                })}
                barnItemIndex={itemIndex}
                availableFeedItems={masterDataEnums?.NaturalFeedType || []}
                availableOrigins={origins}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName="naturalFeedAdditions"
                tooltip={
                  <div key={"feeds-form-dialog-naturalFeed"}>
                    {intl.formatMessage(
                      {
                        id: "BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP",
                      },
                      { br: <br /> }
                    )}
                  </div>
                }
                formState={isNaturalFeedEdit ? formType : FormType.View}
                feedData={naturalFeedAdditions}
                fieldsAppenRemoveObject={{
                  fields: naturalFeedAdditions,
                  append: appendNaturalFeedAdditions,
                  remove: removeNaturalFeedAdditions,
                }}
                allowNewIngredients={isNaturalFeedEdit}
              />
            )}
            {!isNaturalFeedEdit ? (
              <DsmButton
                variant="text"
                onClick={() => setIsNaturalFeedEdit(true)}
                disabled={formType === "view"}
                style={{ width: "120px" }}
              >
                <DsmIcon slot="before" name="general/edit-02" />
                <u>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.EDIT_INPUT",
                  })}
                </u>
              </DsmButton>
            ) : (
              <DsmButton
                variant="text"
                destructive
                onClick={() => cancelNaturalFeedEdit()}
                disabled={formType === "view"}
                style={{ width: "160px" }}
              >
                <DsmIcon slot="before" name="general/minus-circle" />
                <u>
                  {intl.formatMessage({
                    id: "INTERVENTION.FORM.CANCEL",
                  })}
                </u>
              </DsmButton>
            )}
          </DsmGrid>
        </DsmGrid>
      )}
    </div>
  );
};

export default ShrimpFeedForm;
