/* eslint-disable import/prefer-default-export */
import { ADDON_INGREDIENT_SEPARATOR } from "../../components/CompoundFeedWizard/utils";
import lookupValues from "../../helpers/lookupValues";

type Ingredient = {
  name: string;
  addonType: string;
  origins: string[];
  value: string;
  text: string;
};

type CompoundFeedV2CustomerAddonsType = {
  addonType: string;
  ingredients: {
    name: string;
    origin: string;
  }[];
}[];

export const addMappedIngredientAddons = (
  compoundFeedV2CustomerAddons: CompoundFeedV2CustomerAddonsType,
  ingredients: Ingredient[]
) => {
  const updatedIngredients = [...ingredients];

  if (compoundFeedV2CustomerAddons?.length) {
    compoundFeedV2CustomerAddons.forEach((customerAddon) => {
      const mappedBaselineAddon =
        lookupValues.mappedBaselineIngredientsAddons.find(
          (addon) => addon.addonType === customerAddon.addonType
        );
      if (mappedBaselineAddon) {
        customerAddon.ingredients.forEach((ingredient) => {
          const isMappedBaselineIngredient =
            mappedBaselineAddon.ingredients.find(
              (item) => item.name === ingredient.name
            );
          if (isMappedBaselineIngredient) {
            const existingIngredient = updatedIngredients.findIndex(
              (item) =>
                item.name === ingredient.name &&
                item.addonType === customerAddon.addonType
            );
            if (existingIngredient > -1) {
              updatedIngredients[existingIngredient].origins = [
                ...updatedIngredients[existingIngredient].origins,
                ingredient.origin,
              ];
            } else {
              updatedIngredients.push({
                name: ingredient.name,
                value: `${ingredient.name}${ADDON_INGREDIENT_SEPARATOR}${customerAddon.addonType}`,
                text: `${ingredient.name} - Addon (${customerAddon.addonType})`,
                addonType: customerAddon.addonType,
                origins: [ingredient.origin],
              });
            }
          }
        });
      }
    });
  }

  return updatedIngredients;
};
