import { FC } from "react";
import { FormType } from "../../common";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { ResourceUseType } from "../../../../../../graphql/types";
import { defaultUnitsV2, unitLong } from "../../../../utils/unit-utils";
import { useFormContext } from "react-hook-form";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";

interface ShrimpResourceAdditionalItemProps {
  labelSelect: string;
  labelInput: string;
  stylesInput?: any;
  placeholderSelect: string;
  typeFieldName?: string;
  amountFieldName?: string;
  defaultValueType: string;
  defaultValueAmount: string | number | undefined;
  index: number;
  formType: FormType;
  fieldItemPrefix: string;
  placeholderUnit?: string;
  tooltipType?: string;
  tooltipUse?: string;
  isRenewables?: boolean;
  unavailableOptions?: string[];
  onChange?: () => void;
  required?: boolean;
  editEnabled?: boolean;
}

const ShrimpResourceAdditionalItem: FC<ShrimpResourceAdditionalItemProps> = ({
  typeFieldName = 'type',
  amountFieldName = 'value',
  index,
  formType,
  defaultValueType,
  defaultValueAmount,
  fieldItemPrefix,
  editEnabled = false,
}) => {
  const intl = useIntl();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const fc = useFormContext();

  const fieldTypeOriginalValue = `${fieldItemPrefix}[${index}].originalValue`;
  const fieldTypeName = `${fieldItemPrefix}[${index}].${typeFieldName}`;
  const fieldAmountName = `${fieldItemPrefix}[${index}].${amountFieldName}`;

  let resourceType;
  let metricUnit;

  switch (defaultValueType) {
    case "electricityPhotovoltaicUse":
      resourceType = ResourceUseType.ElectricityPhotovoltaic;
      metricUnit = userUOM?.unitElectricityUse ? unitLong(userUOM.unitElectricityUse as string)
        : defaultUnitsV2.unitElectricityUse;
      break;
    case "electricityWindUse":
      resourceType = ResourceUseType.ElectricityWind;
      metricUnit = userUOM?.unitElectricityUse ? unitLong(userUOM.unitElectricityUse as string)
        : defaultUnitsV2.unitElectricityUse;
      break;
    case "coalUse":
      resourceType = ResourceUseType.Coal;
      metricUnit = userUOM?.unitHeatUse ?
        unitLong(userUOM.unitHeatUse)
        : defaultUnitsV2.unitHeatUse;
      break;
    case "dieselUse":
      resourceType = ResourceUseType.Diesel;
      metricUnit = userUOM?.unitDieselUse ? unitLong(userUOM.unitDieselUse)
        : defaultUnitsV2.unitDieselUse;
      break;
    case "gasolineUse":
      resourceType = ResourceUseType.Gasoline;
      metricUnit = userUOM?.unitGasolineUse ? unitLong(userUOM.unitGasolineUse)
        : defaultUnitsV2.unitGasolineUse;
      break;
    case "heavyFuelOilUse":
      resourceType = ResourceUseType.HeavyFuelOil;
      metricUnit = userUOM?.unitHeavyFuelOilUse ? unitLong(userUOM.unitHeavyFuelOilUse)
        : defaultUnitsV2.unitHeavyFuelOilUse;
      break;
    case "propaneOrLpgUse":
      resourceType = ResourceUseType.PropaneOrLpg;
      metricUnit = userUOM?.unitNaturalGasUse ? unitLong(userUOM.unitNaturalGasUse) : 'MJ';
      break;
    default:
      break;
  }

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={fieldTypeName}
        value={defaultValueType}
      />
      {!editEnabled && !fc.getValues(fieldAmountName) ? (
        <ReactHookDsmInput
          name={fieldTypeOriginalValue}
          label={`${intl.formatMessage({
            id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${resourceType}`
          })} ` + `${intl.formatMessage({ id: 'GENERAL.USE' }).toLowerCase()}`}
          tooltip=' '
          adornment={metricUnit}
          type="number"
          disabled
          defaultValue={defaultValueAmount ?? ''}
        />
      ) : (
        <RowTextFieldWithMetrics
          name={fieldAmountName}
          label={`${intl.formatMessage({
            id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${resourceType}`
          })} ` + `${intl.formatMessage({ id: 'GENERAL.USE' }).toLowerCase()}`}
          metricUnit={metricUnit as string}
          baseline={defaultValueAmount}
          tooltip={`${intl.formatMessage({ id: 'GENERAL.USE' })}`}
          disabled={formType === FormType.View || !editEnabled}
          displayTypeSelect={editEnabled}
        />
      )}
    </>
  );
};

export default ShrimpResourceAdditionalItem;