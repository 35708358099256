/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuxiliaryFacilityInputs, ChemicalInput, CreateFacilityInput, RefrigerantInput, ResourceUse, UpdateFacilityInput } from "../../../../../graphql/types";
import { floatValueInDefaultUnit } from "../../../../modules/Farms/Baseline/mapper/floatHelperFunctions";
import { UserProfilePrefs } from "../../../../modules/Helpers/UserProfilePrefs";
import { EnergyCarrierType, RenewableType, SlaughterhouseFacilityForm } from "../../../models/Facility/FacilityTypes";
import { defaultUnitsV2 as defaultMetric } from "../../../utils/unit-utils";


export const getUnitForEnergyType = (type: EnergyCarrierType, userUOM: any) => {
  switch (type) {
    case EnergyCarrierType.Coal: return {userUnit: userUOM.unitHeatUse , defaultUnit: defaultMetric.unitHeatUse };
    case EnergyCarrierType.Diesel: return {userUnit: userUOM.unitDieselUse , defaultUnit: defaultMetric.unitDieselUse };
    case EnergyCarrierType.Electricity: return {userUnit: userUOM.unitElectricityUse , defaultUnit: defaultMetric.unitElectricityUse };
    case EnergyCarrierType.Gasoline: return {userUnit: userUOM.unitGasolineUse , defaultUnit: defaultMetric.unitGasolineUse };
    case EnergyCarrierType.NaturalGas: return {userUnit: userUOM.unitNaturalGasUse , defaultUnit: defaultMetric.unitNaturalGasUse };
    case EnergyCarrierType.PropaneOrLpg: return {userUnit: userUOM.unitNaturalGasUse , defaultUnit: defaultMetric.unitNaturalGasUse };
    default: return undefined;
  }
}

const mapResourceUseOut = 
  (formData: SlaughterhouseFacilityForm, userUOM: any) : ResourceUse => {
    const retVal: ResourceUse = {};

    const waterUse = floatValueInDefaultUnit(
      formData.waterUse,
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse
    ) as number;
    
    retVal.waterUse = waterUse;

    if (formData.electricity && !Number.isNaN(formData.electricity)) {
      const amount = floatValueInDefaultUnit(
        formData.electricity,
        userUOM.unitElectricityUse,
        defaultMetric.unitElectricityUse
      ) as number;

      retVal.electricityUse = amount;
    }

    if (formData.gasUse && !Number.isNaN(formData.gasUse)) {
      const amount = floatValueInDefaultUnit(
        formData.gasUse,
        userUOM.unitNaturalGasUse,
        defaultMetric.unitNaturalGasUse
      ) as number;
      
      retVal.gasUse = amount;
    }

    (formData?.additionalEnergyTypes || []).forEach(additionalItem => {
      const units = getUnitForEnergyType(additionalItem.type, userUOM);
      if (additionalItem.amount && units && !Number.isNaN(additionalItem.amount)) {
        const amount = floatValueInDefaultUnit(
          additionalItem.amount,
          units.userUnit,
          units.defaultUnit
        ) as number;

        switch (additionalItem.type) {
          case EnergyCarrierType.Coal: retVal.coalUse = amount; break;
          case EnergyCarrierType.Diesel: retVal.dieselUse = amount; break;
          case EnergyCarrierType.Electricity: retVal.electricityUse = amount; break;
          case EnergyCarrierType.Gasoline: retVal.gasolineUse = amount; break;
          case EnergyCarrierType.NaturalGas:retVal.gasUse = amount; break;
          case EnergyCarrierType.PropaneOrLpg: retVal.propaneOrLpgUse = amount; break;
          default: break;
        }
      }
    });

    (formData?.selfGeneratedRenewables || []).forEach(item => {
      if (item.amount) {
        const amount = floatValueInDefaultUnit(
          item.amount,
          userUOM.unitElectricityUse,
          defaultMetric.unitElectricityUse
        ) as number;

        if (item.type === RenewableType.ELECTRICITY_PHOTOVOLTAIC) {
          retVal.electricityPhotovoltaicUse = amount;
        } else if (item.type === RenewableType.ELECTRICITY_WIND) {
          retVal.electricityWindUse = amount
       }
      }
    })

    return retVal;
}

const mapAuxiliaryInputsOut = (formData: SlaughterhouseFacilityForm, userUOM: any): AuxiliaryFacilityInputs => {
  const wastewaterAmount = floatValueInDefaultUnit(
    formData.wasteWaterAmount,
    userUOM.unitWaterUse,
    defaultMetric.unitWaterUse
  ) as number;
  
  const chemicals: ChemicalInput[] = [];
  const refrigerants: RefrigerantInput[] = [];

  (formData?.auxiliaryInputs?.chemicals || []).forEach(item => {
    if (item.amount && !Number.isNaN(item.amount)) {
      const amount = floatValueInDefaultUnit(
        item.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      ) as number;

      chemicals.push({
        type: item.type,
        amount
      })
    }
  });

  (formData?.auxiliaryInputs?.refrigerants || []).forEach(item => {
    if (item.amount && !Number.isNaN(item.amount)) {
      const amount = floatValueInDefaultUnit(
        item.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      ) as number;

      refrigerants.push({
        type: item.type,
        amount
      })
    }
  });

  const retVal: AuxiliaryFacilityInputs = {
    wastewaterAmount
  }

  if (chemicals) {
    retVal.chemicals = chemicals;
  }

  if (refrigerants) {
    retVal.refrigerants = refrigerants;
  }

  return retVal;
}

const mapFacilityDataOut = (formData: SlaughterhouseFacilityForm): CreateFacilityInput | UpdateFacilityInput => {
    const userPrefs = UserProfilePrefs.getInstance();
    const userUOM = userPrefs.getUserUnitPrefs();

    const totalLiveweight = floatValueInDefaultUnit(
      formData.totalLiveweight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    ) as number;

    const retVal: CreateFacilityInput = {
      auxiliaryInputs: mapAuxiliaryInputsOut(formData, userUOM),
      resourceUse: mapResourceUseOut(formData, userUOM),
      location: formData.location,
      name: formData.name,
      type: formData.type,
      targetSpecies: [formData.targetSpecies],
      currency: formData.currency,
      totalLiveweight
    };

    return retVal;
  }

  export default mapFacilityDataOut;
  