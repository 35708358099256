import mapLayingHensInterventionDataOutSustell from './outLayingHensMappeSustell';
import mapLayingHensProductionDataInSustell from './inLayingHensMapperSustell';
import mapBroilerInterventionDataOutSustell from './outBroilerMapperSustell';
import mapBroilerInterventionDataInSustell from './inBroilerMapperSustell';
import mapSalmonInterventionDataOutSustell from './outSalmonMapperSustell';
import mapSalmonInterventionDataInSustell from './inSalmonMapperSustell';
import mapPigFatteningInterventionDataOutSustell from './outPigFatteningMapperSustell';
import mapPigFatteningInterventionDataInSustell from './inPigFatteningMapperSustell';
import mapDairyInterventionDataOutSustell from './outDairyMapperSustell';
import mapDairyInterventionDataInSustell from './inDairyMapperSustell';
import mapPigInterventionDataOutSustell from './outPigMapperSustellV2';
import mapPigInterventionDataInSustell from './inPigMapperSustellV2';
import mapMarineFishInterventionDataInSustell from './inMarineFishMapperSustell';
import mapMarineFishInterventionDataOutSustell from './outMarineFishMapperSustell';
import mapPoultryInterventionDataInSustell from './inPoultryMapperSustell';
import mapPoultryInterventionDataOutSustell from './outPoultryMapperSustell';
import mapShrimpInterventionDataInSustell from './inShrimpMapperSustell';
import mapShrimpInterventionDataOutSustell from './outShrimpMapperSustell';

export const mapInterventionDataOutSustell = (
  formData,
  animalType,
  isDraft = false
) => {
  switch (animalType) {
    case 'LAYING_HENS':
      return mapLayingHensInterventionDataOutSustell(formData);
    case 'BROILER':
      return mapBroilerInterventionDataOutSustell(formData);
    case 'DAIRY':
      return mapDairyInterventionDataOutSustell(formData, isDraft);
    case 'SALMON':
      return mapSalmonInterventionDataOutSustell(formData);
    case 'SALMON_V2':
    case 'SEA_BASS':
    case 'SEA_BREAM':
      return mapMarineFishInterventionDataOutSustell(formData);
    case 'PIG_FATTENING':
      return mapPigFatteningInterventionDataOutSustell(formData);
    case 'PIG':
      return mapPigInterventionDataOutSustell(formData);
    case 'LAYING_HENS_V2':
    case 'BROILER_V2':
    case 'TURKEY':
      return mapPoultryInterventionDataOutSustell(formData, animalType);
    case 'LITOPENAEUS_VANNAMEI':
    case 'PENAEUS_MONODON':
      return mapShrimpInterventionDataOutSustell(formData);
    default:
      console.error(`Unsupported animal type for out mapping ${animalType}`);
  }
};

export const mapInterventionDataInSustell = (
  receivedData,
  animalType,
  isDraft = false
) => {
  const inData = { ...receivedData };
  const intervention =
    typeof inData.interventionData === 'string'
      ? JSON.parse(inData.interventionData)
      : inData.interventionData;
  inData.interventionData = intervention;

  switch (animalType) {
    case 'LAYING_HENS':
      return mapLayingHensProductionDataInSustell(inData);
    case 'BROILER':
      return mapBroilerInterventionDataInSustell(inData);
    case 'DAIRY':
      return mapDairyInterventionDataInSustell(inData, isDraft);
    case 'SALMON':
      return mapSalmonInterventionDataInSustell(inData);
    case 'SALMON_V2':
    case 'SEA_BASS':
    case 'SEA_BREAM':
      return mapMarineFishInterventionDataInSustell(inData);
    case 'PIG_FATTENING':
      return mapPigFatteningInterventionDataInSustell(inData);
    case 'PIG':
      return mapPigInterventionDataInSustell(inData);
    case 'LAYING_HENS_V2':
    case 'BROILER_V2':
    case 'TURKEY':
      return mapPoultryInterventionDataInSustell(inData);
    case 'LITOPENAEUS_VANNAMEI':
    case 'PENAEUS_MONODON':
      return mapShrimpInterventionDataInSustell(inData);
    default:
      console.error(`Unsupported animal type for out mapping ${animalType}`);
  }
};
