import {
  AnimalType,
  ManureForm,
  SurfaceType,
} from "../../../../../graphql/generated/blonk/pigs";
import { StageType } from "../../../../../graphql/types";
import { defaultValues } from "../../../../sustell_15/components/FarmFlow/baselineDefaultValues";
import { getBaseTypeForMonthEndingMMS } from "../../../../sustell_15/utils/string-utils";
import { defaultUnitsV2 as defaultMetric } from "../../../../sustell_15/utils/unit-utils";
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import {
  floatValueInUserUnits,
  mapCommonProductionDataIn,
  mapResourceUse,
} from "./inCommonMapperSustellV2";
import { BREEDING_PIGLET_AVG_AGE_END_DEFAULT } from "./outPigMapperSustellV2"

const ANIMAL_TYPE = "PIG";

const mapOutputForOneStage = (stageOutputDataObj, outputFormDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputFormData = outputFormDataObj;
  if (stageOutputDataObj) {
    // weight of pigs - conversion to default units
    if (stageOutputDataObj.averageWeightPigs)
      outputFormData.averageWeightPigs = floatValueInUserUnits(
        stageOutputDataObj.averageWeightPigs,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
    // average Age Piglets
    if (stageOutputDataObj.averageAgePiglets) {
      outputFormData.averageAgePiglets = stageOutputDataObj.averageAgePiglets;
    } else {
      outputFormData.averageAgePiglets = BREEDING_PIGLET_AVG_AGE_END_DEFAULT;
    }

    // weight of piglets - conversion to default units
    if (stageOutputDataObj.averageWeightPiglets)
      outputFormData.averageWeightPiglets = floatValueInUserUnits(
        stageOutputDataObj.averageWeightPiglets,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );

    // number of pigs
    if (
      stageOutputDataObj.mortalityPigs ||
      stageOutputDataObj.mortalityPigs === 0
    )
      outputFormData.mortalityPigs = stageOutputDataObj.mortalityPigs;

    // number of piglets
    if (
      stageOutputDataObj.mortalityPiglets ||
      stageOutputDataObj.mortalityPiglets === 0
    )
      outputFormData.mortalityPiglets = stageOutputDataObj.mortalityPiglets;

    // number of pigs
    if (stageOutputDataObj.pigsToStage)
      outputFormData.pigsToStage = stageOutputDataObj.pigsToStage;

    // number of piglets
    if (stageOutputDataObj.pigletsToStage)
      outputFormData.pigletsToStage = stageOutputDataObj.pigletsToStage;

    // number of pigs
    if (stageOutputDataObj.soldPigs)
      outputFormData.soldPigs = stageOutputDataObj.soldPigs;

    // number of piglets
    if (stageOutputDataObj.soldPiglets)
      outputFormData.soldPiglets = stageOutputDataObj.soldPiglets;

    // number of days
    if (stageOutputDataObj.averageAgePigs)
      outputFormData.averageAgePigs = stageOutputDataObj.averageAgePigs;

    // number of days
    if (stageOutputDataObj.ageAtMortalityPigs)
      outputFormData.ageAtMortalityPigs = stageOutputDataObj.ageAtMortalityPigs;

    // price in EUR - possible future conversion needed
    if (stageOutputDataObj.priceSoldPigs)
      outputFormData.priceSoldPigs = stageOutputDataObj.priceSoldPigs;

    // price in EUR - possible future conversion needed
    if (stageOutputDataObj.priceSoldPiglets)
      outputFormData.priceSoldPiglets = stageOutputDataObj.priceSoldPiglets;

    // weight of pigs - conversion to default units
    if (stageOutputDataObj.weightAtMortalityPigs)
      outputFormData.weightAtMortalityPigs = floatValueInUserUnits(
        stageOutputDataObj.weightAtMortalityPigs,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );

    // weight of piglets - conversion to default units
    if (stageOutputDataObj.weightAtMortalityPiglets)
      outputFormData.weightAtMortalityPiglets = floatValueInUserUnits(
        stageOutputDataObj.weightAtMortalityPiglets,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
  }
  if (Object.keys(outputFormData).length === 0) {
    // provide default values if for any reason data is missing in draft
    const stageOutputDefaults =
      defaultValues[ANIMAL_TYPE]?.stages[0]?.stageData?.output;
    if (stageOutputDefaults) Object.assign(outputFormData, stageOutputDefaults);
  }
};

const mapInputForOneStage = (
  stageDataObj,
  formStageDataInputPart,
  stageType,
  formData
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formInputData = formStageDataInputPart;
  const stageData = stageDataObj;

  if (stageType === "FATTENING") {
    if (stageData.stockPresent) {
      // field is only present if stockPresent was checked
      formInputData.stockPresent = true;
      formInputData.optionalInput = {};
      formInputData.optionalInput.pigsPresentAtStart =
        stageData.pigsPresentAtStart;
    }
    if (formInputData.stockPresent) {
      formInputData.optionalInput.pigsPresentAtEnd = stageData.pigsPresentAtEnd;
      if (stageData.averageWeightPigsStart)
        formInputData.optionalInput.averageWeightPigsStart =
          floatValueInUserUnits(
            stageData.averageWeightPigsStart,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          );
      if (stageData.averageWeightPigsEnd)
        formInputData.optionalInput.averageWeightPigsEnd =
          floatValueInUserUnits(
            stageData.averageWeightPigsEnd,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          );

      formInputData.optionalInput.averageAgePigsStart =
        stageData.averageAgePigsStart || "";
      formInputData.optionalInput.averageAgePigsEnd =
        stageData.averageAgePigsEnd || "";
      formInputData.optionalInput.pigsPriceAtStart =
        stageData.pricePerWeightPigsStart || "";
      formInputData.optionalInput.pigsPriceAtStart =
        stageData.pricePerWeightPigsEnd || "";
    }
  }
  if (stageType === "BREEDING") {
    formInputData.pigsPresentAtStart = stageData.pigsPresentAtStart || "";
    formInputData.pigsPresentAtEnd = stageData.pigsPresentAtEnd || "";
  }
  const { originAnimalType } = formInputData;
  formInputData.internalSources = [];
  formInputData.externalSources = [];
  if (
    stageData.animalsInput?.incomings &&
    stageData.animalsInput.incomings.length > 0
  ) {
    stageData.animalsInput?.incomings?.forEach((item) => {
      if (item.origin === "PRODUCTION_SYSTEM") {
        const internalSource = {
          id: item.id,
          farmId: item.originFarmId || formData.farmId,
          farmName: item.originFarmName,
          numberPigs: item.numberPigs,
          originStageId: item.originStageId,
          stageName: item.originStageName,
          stageType: item.stageType,
          baselineRef: item.baselineRef,
          averageWeightPiglets: item.averageWeightPiglets,
          averageWeightPigs: item.averageWeightPigs,
          averageAgePigs: item.averageAgePigs,
          averageAgePiglets: item.averageAgePiglets,
          originAnimalType,
          distanceTransport: floatValueInUserUnits(
            item.distanceTransport,
            userUOM.unitTransportDistanceTerrestrial,
            defaultMetric.unitTransportDistanceTerrestrial
          ),
        };
        formInputData.internalSources.push(internalSource);
      }
      if (item.origin === "BACKGROUND_DATA") {
        formInputData.externalSources.push({
          id: item.id,
          numberPigs: item.numberPigs,
          averageWeightOfPigs: floatValueInUserUnits(
            item.averageWeightPigs,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          ),
          averageAgeOfPigs: item.averageAgePigs,
          distanceTransport: floatValueInUserUnits(
            item.distanceTransport,
            userUOM.unitTransportDistanceTerrestrial,
            defaultMetric.unitTransportDistanceTerrestrial
          ),
        });
      }
    });
  }
};

const mapHousingForOneStage = (stageHousingData, formHousingDataObj) => {
  const housingFormData = formHousingDataObj;

  housingFormData.surfaceType =
    stageHousingData?.surfaceType || SurfaceType.SlattedFloor; // set default
  housingFormData.manureForm = stageHousingData?.manureForm || "";
  housingFormData.Nreplacement = stageHousingData?.Nreplacement || "";
  housingFormData.Preplacement = stageHousingData?.Preplacement || "";

  housingFormData.manureSystems = [];
  const totalNumOfSolids =
    stageHousingData?.solidManureManagementSystem?.length || 0;

  stageHousingData?.solidManureManagementSystem?.forEach((item) => {
    if (item.mmsType) {
      const baseName = getBaseTypeForMonthEndingMMS(item.mmsType);
      const newItem = {
        id: item.id,
        localManureForm: ManureForm.Solid,
        mmsType: baseName,
        mmsHoldingDuration: baseName === item.mmsType ? "" : item.mmsType,
        share: totalNumOfSolids === 1 ? 100 : item.share,
      };
      housingFormData.manureSystems.push(newItem);
    }
  });
  const totalNumOfLiquidSlurry =
    stageHousingData?.liquidSlurryManureManagementSystem?.length || 0;

  stageHousingData?.liquidSlurryManureManagementSystem?.forEach((item) => {
    if (item.mmsType) {
      const baseName = getBaseTypeForMonthEndingMMS(item.mmsType);
      const newItem = {
        id: item.id,
        localManureForm: ManureForm.LiquidSlurry,
        mmsType: baseName,
        mmsHoldingDuration: baseName === item.mmsType ? "" : item.mmsType,
        share: totalNumOfLiquidSlurry === 1 ? 100 : item.share,
      };
      housingFormData.manureSystems.push(newItem);
    }
  });

  if (totalNumOfSolids > 0 && totalNumOfLiquidSlurry > 0)
    housingFormData.manureForm = ManureForm.SolidAndLiquidSlurry;
  else if (totalNumOfSolids > 0) housingFormData.manureForm = ManureForm.Solid;
  else if (totalNumOfLiquidSlurry > 0)
    housingFormData.manureForm = ManureForm.LiquidSlurry;

  if (housingFormData.manureSystems.length === 0) {
    // add default empty item to manure
    housingFormData.manureSystems.push({
      localManureForm: "",
      mmsType: "",
      mmsHoldingDuration: "",
      share: "",
    });
  }
};

const mapBeddingForOneStage = (stageBeddingDataObj, formHousingDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formHousingDataObj;

  formHousingData.beddingSystems = [];
  if (stageBeddingDataObj) {
    Object.keys(stageBeddingDataObj).forEach((key) => {
      let value = "";
      if (stageBeddingDataObj[key])
        value = floatValueInUserUnits(
          Number(stageBeddingDataObj[key]),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf("Use");
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);
      // console.log("key",key, trimmedKey);
      const beddingType = trimmedKey
        .replace(/[A-Z]/g, (m) => "_" + m.toLowerCase())
        .toUpperCase();

      const item = {
        beddingType,
        beddingAmount: value,
      };
      formHousingData.beddingSystems.push(item);
    });
  }
};

const mapMaterialsForOneStage = (stageMaterialsDataObj, formHousingDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formHousingDataObj;

  formHousingData.materials = [];
  if (stageMaterialsDataObj) {
    Object.keys(stageMaterialsDataObj).forEach((key) => {
      let value = "";
      if (stageMaterialsDataObj[key])
        value = floatValueInUserUnits(
          Number(stageMaterialsDataObj[key]),
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf("Use");
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);

      const materialType = trimmedKey
        .replace(/[A-Z]/g, (m) => "_" + m.toLowerCase())
        .toUpperCase();

      const item = {
        materialType,
        materialAmount: value,
      };
      formHousingData.materials.push(item);
    });
  }
};

const mapPigProductionDataInSustell = (inData, isDraft = false) => {
  let formData;
  try {
    formData = mapCommonProductionDataIn(inData, isDraft);
    mapResourceUse(inData.resourceUse, formData.resourceUse, [
      "electricityUse",
      "gasUse",
      "waterUse",
    ]);

    if (inData.stages && Array.isArray(inData.stages)) {
      const breedingStages = [];
      inData.stages.forEach((stage, index) => {
        if (stage.type !== StageType.Processing) {
          const stageFormData = formData.stages[index]?.stageData;
          if (stageFormData) {
            const { stageData: stageDataJSON } = stage;
            const stageData = JSON.parse(stageDataJSON);
            stageFormData.input.originAnimalType =
              stageData.animalsInput?.originAnimalType;
            if (stage.type === StageType.Breeding)
              breedingStages.push(stage.id);
            // map output
            mapOutputForOneStage(stageData.animalsOutput, stageFormData.output);
            mapInputForOneStage(
              stageData,
              stageFormData.input,
              stage.type,
              formData
            );
            mapHousingForOneStage(stageData.housing, stageFormData.housing);
            mapBeddingForOneStage(stageData.beddingUse, stageFormData.housing);
            mapMaterialsForOneStage(
              stageData.materialUse,
              stageFormData.housing
            );
          }
        }
      });
      if (breedingStages.length > 0) {
        // now when all stages are added - check if some breeding stage is used elsewhere as internal input
        // and if so set originAnimalType if not already set

        formData.stages?.forEach((formStage) => {
          const { input } = formStage.stageData;
          const internalSources = input?.internalSources || [];
          internalSources?.forEach((item) => {
            const thisInternalSource = item;
            if (
              breedingStages.includes(thisInternalSource.originStageId) &&
              !thisInternalSource.originAnimalType
            ) {
              // if data is missing on stage input level
              if (!input.originAnimalType)
                input.originAnimalType = AnimalType.Piglet;
              thisInternalSource.originAnimalType = input.originAnimalType;
            }
          });
        });
      }
    }
  } catch (err) {
    console.error("Error on inPigMapperSustellV2: ", err);
  }

  return formData;
};

export default mapPigProductionDataInSustell;
