import { FC, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { cloneDeep } from "lodash";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import {
  ShrimpInput,
  ShrimpIntervention,
  ShrimpStageData,
} from "../../../../models/Intervention/ShrimpIntervention";
import {
  ShrimpBaseline,
  ShrimpGrowingStageData,
} from "../../../../models/Baseline/ShrimpBaseline";
import ShrimpInputDurationComponent from "./ShrimpInputDurationComponent";
import ShrimpInputPondComponent from "./ShrimpInputPondComponent";
import ShrimpInputStockComponent from "./ShrimpInputStockComponent";
import { StageType } from "../../../../../../graphql/types";

interface ShrimpInputHatcheryDialogProps {
  formType: FormType;
  stageIndex: number;
  formVisible: boolean;
  handleCancel: (param?: string | null) => void;
  baseline: ShrimpBaseline;
}

const ShrimpInputHatcheryDialog: FC<ShrimpInputHatcheryDialogProps> = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;
  const currResetValue = useRef<ShrimpInput>();
  const fc = useFormContext<ShrimpIntervention>();
  const currentStageData = baseline.stages[stageIndex]
    .stageData as ShrimpGrowingStageData;

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as ShrimpIntervention;
      const stageData =
        (resetObject.stages?.[stageIndex]
          ?.stageData as unknown as ShrimpStageData) || {};
      if (resetObject?.stages?.[stageIndex])
        resetObject.stages[stageIndex].stageData = stageData;
      if (stageData?.input) {
        stageData.input = currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <div>
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleResetClick}
        iconCode="general/check-heart"
        variant="wide"
        formTitle={intl.formatMessage({
          id: "BASELINE.FORM.BROODSTOCK.SHRIMP.BROODSTOCK",
        })}
      >
        <ShrimpInputDurationComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          currentStageData={currentStageData}
        />
        <ShrimpInputPondComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          currentStageData={currentStageData}
          stageType={StageType.Hatching}
        />
        <p className={classes.headingTitleVariant}>
          {intl.formatMessage({ id: "BASELINE.FORM.INPUT.SHRIMP.STOCK" })}
        </p>
        <ShrimpInputStockComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          currentStageData={currentStageData}
          stageType={StageType.Hatching}
        />
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={() => {
            fc.trigger(fieldItemPrefix);
            handleCancel();
          }}
          saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
        />
      </DialogContainer>
    </div>
  );
};

export default ShrimpInputHatcheryDialog;
