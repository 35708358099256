import { FC } from "react";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { useFormContext } from "react-hook-form";
import { InputAnimalOrigin } from "../../../../../../graphql/generated/blonk/cattle";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { DairyV2Baseline, DairyV2ContinuousGrowingStageData } from "../../../../models/Baseline/DairyV2Baseline";
import { Spacer } from "../../../helpers/Spacer";

interface DairyV2OutputContinuousGrowingFormProps {
  editEnabled?: boolean;
  stageIndex: number;
  baseline: DairyV2Baseline;
}

const DairyV2OutputContinuousGrowingForm: FC<DairyV2OutputContinuousGrowingFormProps> = ({
  editEnabled = false,
  stageIndex = 0,
  baseline,
}) => {
  const intl = useIntl();

  const fc = useFormContext();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;

  const currentStageData = baseline.stages[stageIndex].stageData;
  const outputData = (currentStageData as DairyV2ContinuousGrowingStageData).output;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={fc.getValues(`${fieldItemPrefix}.id`) as string}
      />
      {!editEnabled ? (
        <>
          {!!fc.getValues(`${fieldItemPrefix}.cowsToAnotherStage`) ?
            <>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.cowsToAnotherStage`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_TO_ANOTHER_STAGE"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.BEEF.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={outputData?.cowsToAnotherStage}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalCowsToAnotherStage`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_TO_ANOTHER_STAGE"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP"
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_TO_ANOTHER_STAGE.PLACEHOLDER"
              })}
              type="number"
              defaultValue={outputData.cowsToAnotherStage}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`) ?
            <>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_OF_COWS_LEAVING_THE_STAGE"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={outputData?.averageWeightOfCowsLeavingTheStage}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageWeightLeaving`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_OF_COWS_LEAVING_THE_STAGE"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP"
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_OF_COWS_LEAVING_THE_STAGE.PLACEHOLDER"
              })}
              type="number"
              defaultValue={outputData.averageWeightOfCowsLeavingTheStage}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.averageAge`) ?
            <>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageAge`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_AGE_OF_COWS_LEAVING_THE_STAGE"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_AGE.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={outputData?.averageAge}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageAge`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_AGE_OF_COWS_LEAVING_THE_STAGE"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_AGE.TOOLTIP"
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_AGE_OF_COWS_LEAVING_THE_STAGE.PLACEHOLDER"
              })}
              type="number"
              defaultValue={outputData.averageAge}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.cowsSold`) ?
            <>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.cowsSold`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_SOLD"
                })}
                type="number"
                metricUnit=""
                baseline={outputData?.cowsSold}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalCowsSold`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_SOLD"
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_SOLD.PLACEHOLDER"
              })}
              type="number"
              defaultValue={outputData.cowsSold}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.numberOfMortalitiesCows`) ?
            <>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.numberOfMortalitiesCows`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.DAIRY_V2.NUMBER_OF_MORTALITIES"
                })}
                type="number"
                metricUnit=""
                baseline={outputData?.numberOfMortalitiesCows}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNumberOfMortalities`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.NUMBER_OF_MORTALITIES"
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.NUMBER_OF_MORTALITIES.PLACEHOLDER"
              })}
              type="number"
              defaultValue={outputData.numberOfMortalitiesCows}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.averageWeightAtMortalityCows`) ?
            <>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageWeightAtMortalityCows`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_AT_MORTALITY"
                })}
                type="number"
                metricUnit=""
                baseline={outputData?.averageWeightAtMortalityCows}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageWeightMortality`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_AT_MORTALITY"
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_AT_MORTALITY.PLACEHOLDER"
              })}
              type="number"
              defaultValue={outputData.averageWeightAtMortalityCows}
              disabled
            />
          }
        </>
      ) : (
        <>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.cowsToAnotherStage`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_TO_ANOTHER_STAGE"
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP"
            })}
            type="number"
            metricUnit=""
            baseline={outputData?.cowsToAnotherStage}
            disabled={!editEnabled}
            displayTypeSelect={editEnabled}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_OF_COWS_LEAVING_THE_STAGE"
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP"
            })}
            type="number"
            metricUnit=""
            baseline={outputData?.averageWeightOfCowsLeavingTheStage}
            disabled={!editEnabled}
            displayTypeSelect={editEnabled}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageAge`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_AGE_OF_COWS_LEAVING_THE_STAGE"
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_AGE.TOOLTIP"
            })}
            type="number"
            metricUnit=""
            baseline={outputData?.averageAge}
            disabled={!editEnabled}
            displayTypeSelect={editEnabled}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.cowsSold`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.DAIRY_V2.COWS_SOLD"
            })}
            type="number"
            metricUnit=""
            baseline={outputData?.cowsSold}
            disabled={!editEnabled}
            displayTypeSelect={editEnabled}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.numberOfMortalitiesCows`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.DAIRY_V2.NUMBER_OF_MORTALITIES"
            })}
            type="number"
            metricUnit=""
            baseline={outputData?.numberOfMortalitiesCows}
            disabled={!editEnabled}
            displayTypeSelect={editEnabled}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageWeightAtMortalityCows`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OUTPUT.DAIRY_V2.AVERAGE_WEIGHT_AT_MORTALITY"
            })}
            type="number"
            metricUnit=""
            baseline={outputData?.averageWeightAtMortalityCows}
            disabled={!editEnabled}
            displayTypeSelect={editEnabled}
          />
        </>
      )}
    </>
  )
}

export default DairyV2OutputContinuousGrowingForm;
