import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import {
  ShrimpBaseline,
  ShrimpOutputs,
} from '../../../../models/Baseline/ShrimpBaseline';
import SGrowingOutputForm from './SGrowingOutputForm';
import { AnimalType } from '../../../../../../graphql/types';
import lookupValues from '../../../../helpers/lookupValues';
import { Tooltip } from '@material-ui/core';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';

interface SOutputGrowingDialogProps extends BaselineDialogProps {
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
}

const SOutputGrowingDialog: FC<SOutputGrowingDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  animalType,
}) => {
  const intl = useIntl();
  const currResetValue = useRef<ShrimpOutputs>();
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const classes: CSSClassesList = processAndStageStylesV2();

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, reset, trigger, getValues, setValue, clearErrors } =
    useFormContext<ShrimpBaseline>();

  const {
    fields: outputs,
    append: addOutput,
    remove: removeOutput,
  } = useFieldArray({
    name: `${fieldItemPrefix}.outputs`,
    control,
    keyName: 'keyId',
  });

  const addOutputForm = () => {
    addOutput({
      type: '',
      amount: '',
    });
  };

  const removeOutputForm = (index: number) => {
    removeOutput(index);
  };

  useEffect(() => {
    if (outputs.length === 0) {
      addOutputForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as ShrimpBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {
          ...currResetValue.current,
        };
        reset(resetObject, { errors: true });
      }
    }
    handleCancel('reset');
  };

  const resetToDefaultValues = () => {
    const proteinContentDefault = lookupValues.shrimpProteinContentDefault[animalType].value;

    outputs?.forEach((item, index) => {
      setValue(
        `${fieldItemPrefix}.outputs.${index}.proteinContent`,
        proteinContentDefault
      );
      clearErrors(`${fieldItemPrefix}.outputs.${index}.proteinContent`);
    });
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
    >
      <div>
        {outputs &&
          outputs?.map((item, index) => (
            <SGrowingOutputForm
              key={item.keyId}
              stageIndex={itemIndex}
              itemIndex={index}
              formType={formType}
              removeHandler={removeOutputForm}
              animalType={animalType}
            />
          ))}
        <DsmButton
          variant="text"
          onClick={addOutputForm}
          disabled={formType === FormType.View}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          <u>
            {intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.SHRIMP.ADD_OUTPUT',
            })}
          </u>
        </DsmButton>
        <div className={classes.resetButtonContainer}>
          <DsmButton
            className="mt-3"
            widthFull
            variant="text"
            onClick={() => resetToDefaultValues()}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SHRIMP.RESET_DEFAULT_VALUES',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(85%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
      </div>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await trigger(`stages.${itemIndex}.stageData.output`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SOutputGrowingDialog;
