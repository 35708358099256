import { FC, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import DairyV2HousingAndManureForm from './DairyV2HousingAndManureForm';
import {
  DairyV2Intervention,
  DairyV2DairyCowStageData,
  DairyV2ContinuousGrowingStageData,
  DairyV2Stage,
} from '../../../../models/Intervention/DairyV2Intervention';
import { CattleHousingAndManure } from '../../../../models/Intervention/CattleIntervention';
import { DairyV2Baseline } from '../../../../models/Baseline/DairyV2Baseline';

export interface DairyV2HousingAndManureDialogProps {
  formType: FormType;
  stageIndex: number;
  formVisible: boolean;
  handleCancel: (param?: string | null) => void;
  baseline: DairyV2Baseline;
  stageType: string;
}

const DairyV2HousingAndManureDialog: FC<DairyV2HousingAndManureDialogProps> = ({
  formType = FormType.Add,
  baseline,
  stageIndex = 0,
  formVisible,
  stageType,
  handleCancel,
}) => {
  const intl = useIntl();

  const formContext = useFormContext<DairyV2Intervention>(); // create dairyV2 interventions
  const fieldItemPrefix = `stages.${stageIndex}.stageData.housing`;

  const formTitle = intl.formatMessage(
    { id: "SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE" },
    { br: " " }
  );

  const currResetValue = useRef<CattleHousingAndManure>();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep<CattleHousingAndManure>(
        formContext.getValues(fieldItemPrefix)
      );
    }
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Intervention;

      const housingReset = resetObject.stages[stageIndex]?.stageData as
        | DairyV2ContinuousGrowingStageData
        | DairyV2DairyCowStageData;

      if (housingReset?.housing) {
        housingReset.housing = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel("reset");
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DairyV2HousingAndManureForm
        formType={formType}
        stageType={stageType}
        stageIndex={stageIndex}
        baseline={baseline.stages[stageIndex] as DairyV2Stage}
        fieldItemPrefix={fieldItemPrefix}
      />

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          //await formContext.trigger(`stages.${stageIndex}.stageData.housing`);
          handleCancel("confirm");
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default DairyV2HousingAndManureDialog;
