import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { ShrimpIntervention } from "../../../../models/Intervention/ShrimpIntervention";
import { ShrimpGrowingStageData } from "../../../../models/Baseline/ShrimpBaseline";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";

interface ShrimpInputDurationComponentProps {
  formType: FormType;
  fieldItemPrefix: string;
  currentStageData: ShrimpGrowingStageData;
}

const ShrimpInputDurationComponent: FC<ShrimpInputDurationComponentProps> = ({
  formType = FormType.Add,
  fieldItemPrefix,
  currentStageData,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();

  const fc = useFormContext<ShrimpIntervention>();

  const [isDurationEdit, setIsDurationEdit] = useState<boolean>(false);

  const cancelDurationEdit = () => {
    fc.setValue(`${fieldItemPrefix}.stageDuration`, "");
    setIsDurationEdit(false);
  };

  return (
    <DsmGrid
      className={
        isDurationEdit
          ? classes.dsmGridOneShorterColumnInterventionWithBorder
          : classes.dsmGridTwoColumnWithBorder
      }
      style={{ border: "0px", rowGap: "var(--dsm-spacing-px-2)" }}
    >
      {!isDurationEdit && !fc.getValues(`${fieldItemPrefix}.stageDuration`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalStageDuration`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION",
          })}`}
          disabled
          type="number"
          adornment={intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION.ADORNMENT",
          })}
          defaultValue={currentStageData.input.stageDuration}
          tooltip={intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION.TOOLTIP",
          })}
        />
      ) : (
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.stageDuration`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION",
          })}`}
          tooltip={intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION.TOOLTIP",
          })}
          metricUnit={intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.STAGE_DURATION.ADORNMENT",
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          baseline={currentStageData.input.stageDuration}
          disabled={formType === "view" || !isDurationEdit}
          minAllowedValue={0}
          displayTypeSelect={isDurationEdit}
        />
      )}
      <div />
      {!isDurationEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsDurationEdit(true)}
          disabled={formType === "view"}
          style={{ width: "120px", marginBottom: "10px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <DsmButton
          variant="text"
          destructive
          onClick={() => cancelDurationEdit()}
          disabled={formType === "view"}
          style={{ width: "160px", marginBottom: "10px" }}
        >
          <DsmIcon slot="before" name="general/minus-circle" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.CANCEL",
            })}
          </u>
        </DsmButton>
      )}
    </DsmGrid>
  );
};

export default ShrimpInputDurationComponent;
