import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Maybe } from "graphql/jsutils/Maybe";

import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { FormType, ListEntry } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { TransportMode } from "../../../../../../graphql/types";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { ShrimpBaseline } from "../../../../models/Baseline/ShrimpBaseline";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";

interface ShrimpMaterialFormProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  removeHandler?: Maybe<Function>;
  required?: boolean;
  transportOptions: ListEntry[];
  editEnabled: boolean;
  baseline: ShrimpBaseline;
}

const ShrimpMaterialForm: FC<ShrimpMaterialFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  removeHandler,
  required = false,
  transportOptions,
  editEnabled = false,
  baseline,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const propName = "materialTypes";
  const fieldItemPrefix = `stages.${stageIndex}.stageData.operations.${propName}.${itemIndex}`;

  const selectedTransportMode = useWatch({
    name: `${fieldItemPrefix}.transportMode`,
  });
  const [distanceUnit, setDistanceUnit] = useState(
    userUOM.unitTransportDistanceTerrestrial
  );

  useEffect(() => {
    switch (selectedTransportMode) {
      case TransportMode.InlandShip:
        setDistanceUnit(userUOM.unitTransportDistanceInlandWaterways);
        break;
      case TransportMode.SeaShip:
        setDistanceUnit(userUOM.unitTransportDistanceSea);
        break;
      case TransportMode.Train:
      case TransportMode.Truck:
        setDistanceUnit(userUOM.unitTransportDistanceTerrestrial);
        break;
      default:
        break;
    }
  }, [selectedTransportMode]);

  const cubicMeterMaterials: string[] = ["WOOD"];

  const materialType =
    baseline?.stages[itemIndex]?.stageData?.operations?.materialTypes[
      itemIndex
    ];

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={materialType?.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.type`}
        value={materialType?.type}
      />
      {formType !== FormType.View && removeHandler && editEnabled && (
        <DsmButton
          variant="text"
          style={{ position: "relative", width: "100%" }}
          onClick={() => removeHandler(itemIndex)}
        >
          <DsmIcon
            name="general/x-close"
            style={{
              position: "absolute",
              color: "var(--dsm-color-neutral-darker",
              right: "0",
            }}
          />
        </DsmButton>
      )}

      <DsmGrid
        className={
          editEnabled
            ? classes.dsmGridTwoColumn
            : classes.dsmGridTwoColumnNoRowGap
        }
      >
        {!editEnabled && !formContext.getValues(`${fieldItemPrefix}.amount`) ? (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.originalAmount`}
            label={materialType?.type as unknown as string}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.TOOLTIP",
            })}
            adornment={intl.formatMessage({
              id:
                materialType &&
                cubicMeterMaterials.includes(
                  materialType.type as unknown as string
                )
                  ? "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.ADORNMENT_M3"
                  : "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.ADORNMENT_KG",
            })}
            type="number"
            disabled
            required={required}
            defaultValue={materialType?.amount as string}
          />
        ) : (
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.amount`}
            label={materialType?.type as unknown as string}
            type="number"
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.TOOLTIP",
            })}
            metricUnit=""
            baseline={materialType?.amount}
            disabled={formType === FormType.View || !editEnabled}
            displayTypeSelect={editEnabled}
          />
        )}

        {!editEnabled &&
        (!formContext.getValues(`${fieldItemPrefix}.transportMode`) ||
          formContext.getValues(`${fieldItemPrefix}.transportMode`) ===
            materialType?.transportMode) ? (
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TRANSPORT_MODE",
            })}
            placeholder={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TRANSPORT_MODE.PLACEHOLDER",
            })}
            name={`${fieldItemPrefix}.originalTransportMode`}
            options={transportOptions}
            disabled
            adornment=" "
            defaultValue={materialType?.transportMode as unknown as string}
          />
        ) : (
          <RowSelectWithMetricsV2
            name={`${fieldItemPrefix}.transportMode`}
            label={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TRANSPORT_MODE",
            })}
            adornment=" "
            disabled={formType === FormType.View || !editEnabled}
            options={transportOptions}
            baseline={materialType?.transportMode}
          />
        )}

        {!editEnabled &&
        !formContext.getValues(`${fieldItemPrefix}.distanceTraveled`) ? (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.originalDistanceTraveled`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_DISTANCE_TRAVELED",
            })}`}
            adornment={distanceUnit}
            type="number"
            disabled
            defaultValue={materialType?.distanceTraveled}
          />
        ) : (
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.distanceTraveled`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_DISTANCE_TRAVELED",
            })}`}
            type="number"
            tooltip=""
            metricUnit=""
            baseline={materialType?.distanceTraveled}
            disabled={formType === FormType.View || !editEnabled}
            displayTypeSelect={editEnabled}
          />
        )}

        {!editEnabled &&
        !formContext.getValues(`${fieldItemPrefix}.lifetimeOfMaterial`) ? (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.originalLifetimeOfMaterial`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME",
            })}`}
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME.TOOLTIP",
            })}
            adornment={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME.PLACEHOLDER",
            })}
            type="number"
            disabled
            required={required}
            defaultValue={materialType?.lifetimeOfMaterial}
          />
        ) : (
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.lifetimeOfMaterial`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME",
            })}`}
            type="number"
            tooltip={intl.formatMessage({
              id: "BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME.TOOLTIP",
            })}
            metricUnit=""
            baseline={materialType?.lifetimeOfMaterial}
            disabled={formType === FormType.View || !editEnabled}
            displayTypeSelect={editEnabled}
          />
        )}
      </DsmGrid>
    </>
  );
};

export default ShrimpMaterialForm;
