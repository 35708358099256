import { DsmCheckboxCustomEvent } from "@dsm-dcs/design-system";
import { cloneDeep } from "lodash";
import { DsmCheckbox, DsmGrid } from "@dsm-dcs/design-system-react";
import { useEffect, useRef } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import {
  DSM_DISABLED_LABEL,
  processAndStageStylesV2,
} from "../../../../../../_metronic/layout";
import { AnimalType } from "../../../../../../graphql/generated/blonk/pigs";
import { StageType } from "../../../../../../graphql/types";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import {
  CSSClassesList,
  UserUOMPreferences,
} from "../../../../helpers/helperTypes";
import {
  PigFatteningStageData,
  PigStage as PigBaselineStage,
} from "../../../../models/Baseline/PigBaseline";
import {
  PigInputFattening,
  PigInputFattening as PigInputFatteningIntervention,
  PigIntervention,
  PigStage,
} from "../../../../models/Intervention";
import { unitLong } from "../../../../utils/unit-utils";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import ExternalSourcesInput from "../../Baseline/PigFattening/v2.0/ExternalSourcesInput";
import InternalSourcesInput from "../../Baseline/PigFattening/v2.0/InternalSourcesInput";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { FormType, PigFarms } from "../../common";
import { PigInterventionFormDialogProps } from "../CommonDataParts/InterventionTypes";
import InterventionExternalSourcesInput from "./InterventionExternalSourcesInput";
import InterventionInternalSourcesInput from "./InterventionInternalSourcesInput";

export interface PFInterventionInputFormDialogProps
  extends PigInterventionFormDialogProps {
  // eslint-disable-next-line react/require-default-props
  intervention?: PigIntervention | null;
  farms: PigFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

const PFInterventionInputFormDialog = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
  intervention = null,
}: PFInterventionInputFormDialogProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs() as UserUOMPreferences;
  const barnOutputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitBarnOutputMassPrecision() as number;

  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : ("kg" as string);

  const currResetValue = useRef<PigInputFattening>();

  const formTitle = intl.formatMessage({
    id: "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE",
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;
  const fc = useFormContext<PigIntervention>();

  const {
    fields: internalSourcesAdditions,
    // append: appendInternalAdditions,
    remove: removeInternalAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSourcesAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const {
    fields: externalSourcesAdditions,
    // append: appendExternalAdditions,
    remove: removeExternalAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSourcesAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  // const addInternalSourceAdditions = () => {
  //   appendInternalAdditions({
  //     numberPigs: '',
  //     averageAgeOfPigs: '',
  //     originStageId: '',
  //     distanceTransport: '',
  //     farmId:'',
  //     averageWeightOfPigs: '',
  //     originAnimalType: ''
  //   });
  // };

  const removeInternalSourceAdditions = (index: number) => {
    removeInternalAdditions(index);
  };

  // const addExternalSourceAdditions = () => {
  //   appendExternalAdditions({
  //     numberPigs: '',
  //     averageAgeOfPigs: '',
  //     averageWeightOfPigs: '',
  //     distanceTransport: '',
  //   });
  // };

  const removeExternalSourceAdditions = (index: number) => {
    removeExternalAdditions(index);
  };

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PigIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData;
      if (stageData?.input) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        stageData.input = { ...currResetValue.current };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }

    handleCancel();
  };

  const toggleStock = (event: DsmCheckboxCustomEvent<any>) => {
    const newState = event.target.checked;
    fc.setValue(`${fieldItemPrefix}.stockPresent`, newState);
  };

  const stockPresent = useWatch({
    control: fc.control,
    name: `${fieldItemPrefix}.stockPresent`,
  }) as boolean;

  // if intervention cahnged, check if the stockPresent proeprty is set (true or false) at intervention level
  // if not, get this value from the baseline, and set it
  useEffect(() => {
    const interventionInput = intervention?.stages?.[stageIndex]?.stageData
      ?.input as PigInputFatteningIntervention;

    if (
      interventionInput?.stockPresent === null ||
      interventionInput?.stockPresent === undefined
    ) {
      const baselineInput = (
        baseline?.stages?.[stageIndex]?.stageData as PigFatteningStageData
      )?.input;
      fc.setValue(
        `${fieldItemPrefix}.stockPresent`,
        baselineInput?.stockPresent || false
      );
    }

    // no need to add other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervention]);

  // remove baseline used input stages and current stage from options
  const input = (
    baseline?.stages?.[stageIndex]?.stageData as PigFatteningStageData
  )?.input;

  const usedStages =
    input?.internalSources?.map((item) => item.originStageId) || [];

  const currentStage: PigStage | undefined =
    fc.getValues()?.stages?.[stageIndex];
  if (currentStage) usedStages.push(currentStage.id);

  // console.log("used stages => ", usedStages);
  // available stages as input soruce for intervention
  // are the ones not already used as input at the same stage in the baseline
  let stages =
    baseline?.stages
      .filter((item) => !usedStages.includes(item.id))
      .map((item) => ({ text: item.name, value: item.id })) || [];

  const baselineStageOriginAnimalType = (
    baseline?.stages[stageIndex]?.stageData as PigFatteningStageData
  )?.input?.originAnimalType;
  const initValueForAnimalType =
    baselineStageOriginAnimalType ||
    fc.getValues(`stages.${stageIndex}.stageData.input.originAnimalType`) ||
    "";

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const stageOriginAnimalType = useWatch({
    name: `stages.${stageIndex}.stageData.input.originAnimalType`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue: initValueForAnimalType,
  }) as AnimalType;

  const allStages = baseline?.stages || [];
  const breedingStagesList = allStages
    .filter((item) => item.type === StageType.Breeding)
    .map((item) => item.id);
  const fatteningStagesList = allStages
    .filter((item) => item.type === StageType.Fattening)
    .map((item) => item.id);
  if (stageOriginAnimalType === AnimalType.Piglet) {
    // no fattening stages should be allowed - so further filtering should be done
    stages = stages.filter((item) => !fatteningStagesList.includes(item.value));
  } else {
    // after change return to full list
    stages =
      baseline?.stages
        .filter((item) => !usedStages.includes(item.id))
        .map((item) => ({ text: item.name, value: item.id })) || [];
  }

  const labelDisabled =
    formType === FormType.View ? { color: DSM_DISABLED_LABEL as string } : {};

  useEffect(() => {
    if (formVisible && input) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {
          startDate: input.startDate as string,
          endDate: input.endDate as string,
          stockPresent: false,
        };
      } else {
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, input]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="wide"
      iconCode="general/check-heart"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        className={classes.dsmGridTwoColumnNoRowSpacing}
      >
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({ id: "BASELINE.FORM.FIELD.START_DATE" })}
          disabled
          required
          defaultValue={input.startDate as string}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({ id: "BASELINE.FORM.FIELD.END_DATE" })}
          disabled
          required
          defaultValue={input.endDate as string}
        />
      </DsmGrid>
      <DsmCheckbox
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        className={classes.fieldsVerticalSpacer}
        style={labelDisabled}
        label={intl.formatMessage({
          id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.STOCK_PRESENT",
        })}
        name={`${fieldItemPrefix}.stockPresent`}
        checked={stockPresent}
        onDsmChange={(e) => toggleStock(e)}
        disabled={formType === FormType.View}
        defaultValue={fc.getValues(`${fieldItemPrefix}.stockPresent`)}
      />
      {/* </DsmGrid> */}
      {stockPresent && (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        <DsmGrid className={classes.dsmGridTwoColumnIntervention}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.optionalInput.pigsPresentAtStart`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_START",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_START.INTERVENTION.TOOLTIP",
            })}
            type="number"
            metricUnit=""
            baseline={input?.optionalInput?.pigsPresentAtStart}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.optionalInput.pigsPresentAtEnd`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_END",
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_END.INTERVENTION.TOOLTIP",
            })}
            type="number"
            metricUnit=""
            baseline={input?.optionalInput?.pigsPresentAtEnd}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.optionalInput.averageWeightPigsStart`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.START",
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.START.INTERVENTION.TOOLTIP",
            })}
            metricUnit={barnOutputMassUnit}
            precision={barnOutputMassPrecision}
            baseline={input?.optionalInput?.averageWeightPigsStart}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.optionalInput.averageWeightPigsEnd`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.END",
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.END.INTERVENTION.TOOLTIP",
            })}
            metricUnit={barnOutputMassUnit}
            precision={barnOutputMassPrecision}
            baseline={input?.optionalInput?.averageWeightPigsEnd}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.optionalInput.averageAgePigsStart`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.START",
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.START.INTERVENTION.TOOLTIP",
            })}
            metricUnit={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.START.UNIT",
            })}
            baseline={input?.optionalInput?.averageAgePigsStart}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.optionalInput.averageAgePigsEnd`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.END",
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.END.INTERVENTION.TOOLTIP",
            })}
            metricUnit={intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.END.UNIT",
            })}
            baseline={input?.optionalInput?.averageAgePigsEnd}
            disabled={formType === FormType.View}
          />
        </DsmGrid>
      )}

      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        className={`${classes.dsmGridTwoColumnIntervention} ${classes.topVerticalSpacer}`}
      >
        <div>
          <h4 style={labelDisabled}>
            {intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERNAL_SOURCE",
            })}
          </h4>
          {input?.internalSources?.map((item, i) => (
            <InterventionInternalSourcesInput
              key={item.id}
              stageIndex={stageIndex}
              stages={
                baseline.stages.filter(
                  (stage) => stage.type !== StageType.Processing
                ) as PigBaselineStage[]
              }
              itemIndex={i}
              formType={formType}
              item={item}
              breedingStagesList={breedingStagesList}
              baselineStageOriginAnimalType={baselineStageOriginAnimalType}
            />
          ))}

          {internalSourcesAdditions &&
            internalSourcesAdditions?.map((item, index) => (
              <InternalSourcesInput
                farms={farms}
                farmId={farmId}
                farmName={farmName}
                allowedStagesForFarm={[StageType.Fattening, StageType.Breeding]}
                productionProcessName={productionProcessName}
                key={item.id as string}
                stageIndex={stageIndex}
                itemIndex={index}
                stages={stages}
                breedingStagesList={breedingStagesList}
                formType={formType}
                stageType={StageType.Breeding}
                stageOriginAnimalType={
                  stageOriginAnimalType || initValueForAnimalType
                }
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeInternalSourceAdditions}
                isIntervention
                baselineStageOriginAnimalType={baselineStageOriginAnimalType}
                barnOutputMassUnit={barnOutputMassUnit}
              />
            ))}

          {/* <DsmButton
            variant="text"
            onClick={addInternalSourceAdditions}
            disabled={formType === FormType.View || stages.length === internalSourcesAdditions.length}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_INTERNAL',
            })}
          </DsmButton> */}
        </div>
        <div>
          <h4 style={labelDisabled}>
            {intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.EXTERNAL_SOURCE",
            })}
          </h4>
          {input?.externalSources?.map((item, i) => (
            <InterventionExternalSourcesInput
              key={item.id}
              stageIndex={stageIndex}
              itemIndex={i}
              formType={formType}
              item={item}
            />
          ))}

          {externalSourcesAdditions &&
            externalSourcesAdditions?.map((item, index) => (
              <ExternalSourcesInput
                key={item.id as string}
                stageIndex={stageIndex}
                itemIndex={index}
                formType={formType}
                stageType={StageType.Breeding}
                barnOutputMassUnit={barnOutputMassUnit}
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeExternalSourceAdditions}
                isIntervention
              />
            ))}

          {/* <DsmButton 
            variant="text" 
            onClick={addExternalSourceAdditions}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_EXTERNAL',
            })}
          </DsmButton> */}
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default PFInterventionInputFormDialog;
