import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType, ListEntry } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { TransportMode } from '../../../../../../graphql/types';

interface SGrowingMaterialFormProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  isIntervention?: boolean;
  removeHandler?: Maybe<Function>;
  required?: boolean;
  materialOptions: ListEntry[];
  transportOptions: ListEntry[];
}

const SGrowingMaterialForm: FC<SGrowingMaterialFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  isIntervention,
  removeHandler,
  required = false,
  materialOptions,
  transportOptions
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const propName = isIntervention ? 'materialTypeAdditions' : 'materialTypes';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.operations.${propName}.${itemIndex}`;

  const selectedTransportMode = useWatch({name: `${fieldItemPrefix}.transportMode`});
  const [distanceUnit, setDistanceUnit] = useState(userUOM.unitTransportDistanceTerrestrial);
  useEffect(() => {
    switch (selectedTransportMode) {
      case TransportMode.InlandShip:
        setDistanceUnit(userUOM.unitTransportDistanceInlandWaterways)        
        break;
      case TransportMode.SeaShip:
        setDistanceUnit(userUOM.unitTransportDistanceSea)        
        break;
      case TransportMode.Train:
      case TransportMode.Truck:
        setDistanceUnit(userUOM.unitTransportDistanceTerrestrial)
        break;
      default:
        break
    }
  },[selectedTransportMode]);

  const cubicMeterMaterials: string[] = [
    'WOOD'
  ];

  const materialType: string | undefined = useWatch({name: `${fieldItemPrefix}.type`});

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid className={
            itemIndex !== 0
              ? classes.additionalEntriesBlockHolder
              : classes.additionalEntriesBlockHolderWithoutBorder
          }>
        {formType !== FormType.View && removeHandler && (itemIndex !== 0 || isIntervention) && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <DsmGrid className={classes.dsmGridFourColumn} style={{alignItems:'start'}}>
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE',
            })}
            placeholder={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE.PLACEHOLDER',
            })}
            name={`${fieldItemPrefix}.type`}
            options={materialOptions}
            required={required}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE.TOOLTIP',
            })}
            adornment=" "
            defaultValue={formContext.getValues(`${fieldItemPrefix}.type`)}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.amount`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT',
            })}`}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.TOOLTIP',
            })}
            adornment={intl.formatMessage({
              id: (materialType && cubicMeterMaterials.includes(materialType)) 
              ? 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.ADORNMENT_M3' 
              : 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TYPE_AMOUNT.ADORNMENT_KG',
            })}
            type="number"
            disabled={formType === FormType.View}
            required={required}
            defaultValue={formContext.getValues(`${fieldItemPrefix}.amount`)}
          />
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TRANSPORT_MODE',
            })}
            placeholder={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_TRANSPORT_MODE.PLACEHOLDER',
            })}
            name={`${fieldItemPrefix}.transportMode`}
            options={transportOptions}
            disabled={formType === FormType.View}
            adornment=" "
            defaultValue={formContext.getValues(`${fieldItemPrefix}.transportMode`)}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.distanceTraveled`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_DISTANCE_TRAVELED',
            })}`}
            adornment={distanceUnit}
            type="number"
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.distanceTraveled`
            )}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridFourColumn}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.lifetimeOfMaterial`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME',
            })}`}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME.TOOLTIP',
            })}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIAL_LIFETIME.PLACEHOLDER',
            })}
            type="number"
            disabled={formType === FormType.View}
            required={required}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.lifetimeOfMaterial`
            )}
          />
        </DsmGrid>
      </DsmGrid>
    </>
  );
};

export default SGrowingMaterialForm;
