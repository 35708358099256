import { cloneDeep } from 'lodash';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType, ListEntry } from '../../common';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { CompoundFeedDatabase, MasterDataOption, Maybe } from '../../../../../../graphql/types';
import { PoultryBaseline, PoultryBreedingStageData } from '../../../../models/Baseline/PoultryBaseline';
import { PoultryBreedingInterventionFeed, PoultryBreedingStageData as PoultryBreedingInterventionStageData, PoultryIntervention } from '../../../../models/Intervention/PoultryIntervention';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import FeedsFormCommonDataPart from '../CommonDataParts/FeedsFormCommonDataPart';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
  marginTop: '10px'
}

interface PoultryFeedBreedingDialogProps {
  formType: FormType;
  stageIndex: number;
  formVisible: boolean;
  handleCancel: (param?: string | null) => void;
  baseline: PoultryBaseline;
  compoundFeeds: Maybe<Array<MasterDataOption>>;
  singleIngredients: Maybe<Array<MasterDataOption>>;
  origins: ListEntry[];
}

const PoultryFeedBreedingDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
  compoundFeeds,
  singleIngredients,
  origins,
}: PoultryFeedBreedingDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<PoultryBreedingInterventionFeed>();
  const formTitle = intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE'});
  const fc = useFormContext<PoultryIntervention>();
  const getBaselineHenFeed = () => {
    const stageData = baseline.stages[stageIndex].stageData as unknown as PoultryBreedingStageData;
    return stageData.feed.henFeed;
  }
  const getBaselineRoosterFeed = () => {
    const stageData = baseline.stages[stageIndex].stageData as unknown as PoultryBreedingStageData;
    return stageData.feed.roosterFeed;
  }

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData as unknown as PoultryBreedingInterventionStageData;
      if (stageData?.feed) {
        stageData.feed = currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) { handleCancel() }
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(`stages.${stageIndex}.stageData.feed`));
      if (!currResetValue.current) {
        currResetValue.current = {
          henFeed : {totalFeedIntake: 0},
          roosterFeed: {totalFeedIntake: 0}
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="ultrawide"
      iconCode="shapes/cube-02"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridTwoColumn2To3}>
        <DsmFieldset
          style={{ width: '58%', marginTop: `var(--dsm-spacing-px-4)` }}
        >
          <ReactHookDsmSelect
            name="databaseFoundation"
            defaultValue={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
            label={`${intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION'})}`}
            disabled
            options={getDatabaseOptions() as []}
            tooltip={intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP_POULTRY_DAIRY_BEEF_SWINE'})}
          />
        </DsmFieldset>
      </DsmGrid>
      <p style={headingTitle}>
        {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.FEED.HEN_TITLE'})}
      </p>
      <FeedsFormCommonDataPart
        formType={formType}
        itemIndex={stageIndex}
        databaseFoundation={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
        feedBaseline={getBaselineHenFeed()}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        origins={origins}
        fieldItemPrefix={`stages.${stageIndex}.stageData.feed.henFeed`}
      />
      <p style={headingTitle}>
        {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.FEED.ROOSTER_TITLE'})}
      </p>
      <FeedsFormCommonDataPart
        formType={formType}
        itemIndex={stageIndex}
        databaseFoundation={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
        feedBaseline={getBaselineRoosterFeed()}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        origins={origins}
        fieldItemPrefix={`stages.${stageIndex}.stageData.feed.roosterFeed`}
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryFeedBreedingDialog;
