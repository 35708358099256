import { FC, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { footprintCategoryTableStyles } from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import {
  EmissionEntryModel,
  EmissionOptions,
  EmissionsStageModel,
} from '../../models/Footprint/internal';
import { ExactNumber } from '../helpers/ExactNumber';
import FootprintEmissionDialog from './FootprintEmissionDialog';
import ChipChangePercentage from '../helpers/ChipChange';
import { translateKeyMapping } from '../../utils/string-utils';
import { enumToOptionsArrayWithTranslatedStrings } from '../../utils/obj-utils';

interface FootprintEmissionTableProps {
  // eslint-disable-next-line react/require-default-props
  aggregatedEmissions?: EmissionsStageModel;
  options: EmissionOptions;
  baselineDatasetName: string;
  // eslint-disable-next-line react/require-default-props
  comparisonDatasetName?: string;
  hasComparison: boolean;
  title: string;
  subtitle?: string;
  mmsTypes?: string[];
  comparisonMMSTypes?: string[];
}

export const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: '12px !important',
    borderBottom: '1px solid #f0f0f0',
  },
  body: {
    border: 'none',
  },
  root: {
    '&:nth-of-type(1)': {
      Width: '298px',
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    height: '72px',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9FAFB',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

const FootprintEmissionTable: FC<FootprintEmissionTableProps> = ({
  title,
  subtitle,
  aggregatedEmissions = undefined,
  options,
  baselineDatasetName,
  comparisonDatasetName = 'Comparison dataset',
  hasComparison,
  mmsTypes,
  comparisonMMSTypes,
}) => {
  const intl = useIntl();
  const classes = footprintCategoryTableStyles();
  const [selectedEmission, setSelectedEmission] = useState<
    EmissionEntryModel | undefined
  >();
  const [detailsDialogVisible, setDetailsDialogVisible] =
    useState<boolean>(false);

  const hasBreakdownInfo = (emission: EmissionEntryModel) =>
    emission.usedData.length > 0 || emission.usedIntermediates.length > 0;

  const openEmissionDetails = (emission: EmissionEntryModel) => {
    if (hasBreakdownInfo(emission)) {
      setSelectedEmission(emission);
      setDetailsDialogVisible(true);
    }
  };

  return (
    <>
      {detailsDialogVisible && selectedEmission && (
        <FootprintEmissionDialog
          emissionEntry={selectedEmission}
          formVisible={detailsDialogVisible}
          handleClose={() => setDetailsDialogVisible(false)}
          hasComparison={hasComparison}
          baselineDatasetName={baselineDatasetName}
          comparisonDatasetName={comparisonDatasetName}
        />
      )}
      <TableContainer style={{ marginBottom: 15 }}>
        <Table aria-label="customized table" className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {title}
                </Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
                {mmsTypes && (
                  <Typography>
                    {intl.formatMessage({ id: 'MMS.EMISSIONS.TYPES' })}:{' '}
                    <b>
                      {enumToOptionsArrayWithTranslatedStrings(
                        mmsTypes.concat(
                          comparisonMMSTypes ? comparisonMMSTypes : []
                        ),
                        intl,
                        'SUSTELL.PIGS.ENUMS.MMS'
                      )
                        .map((a) => a.text)
                        .join(', ')}
                    </b>
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align={hasComparison ? 'left' : 'center'}>
                <Typography>
                  {hasComparison
                    ? baselineDatasetName
                    : intl.formatMessage({
                        id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.VALUE',
                      })}
                </Typography>
              </StyledTableCell>
              {hasComparison && (
                <StyledTableCell>{comparisonDatasetName}</StyledTableCell>
              )}
              {hasComparison && (
                <StyledTableCell align="center">
                  {intl.formatMessage({ id: 'REPORT.FOOTPRINTS.CHANGE' })}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedEmissions &&
              (aggregatedEmissions?.emissions || []).map((emissionEntry) => {
                const { value, intervenedValue, change } =
                  emissionEntry.displayValues(
                    options.periodType,
                    options.calcType
                  );
                return (
                  <StyledTableRow key={emissionEntry.emission}>
                    <StyledTableCell
                      onClick={() => openEmissionDetails(emissionEntry)}
                      style={
                        hasBreakdownInfo(emissionEntry)
                          ? { width: '50%', cursor: 'pointer' }
                          : {}
                      }
                    >
                      <Box style={{ display: 'flex' }}>
                        <Typography
                          className={classes.emissionsTableCellTitle}
                          style={{ paddingLeft: '10px', fontWeight: '500' }}
                        >
                          {intl.formatMessage({
                            id: `BASELINE.FORM.EMISSIONS.${translateKeyMapping(
                              emissionEntry.emission
                            )}`,
                          })}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      width="17%"
                      align={hasComparison ? 'left' : 'center'}
                    >
                      <Typography
                        className={classes.tableCellTitle}
                        style={{ fontWeight: '500' }}
                      >
                        <ExactNumber value={value} />
                      </Typography>
                      <Typography
                        className={classes.tableCellDescription}
                        style={{ fontWeight: '400' }}
                      >
                        {emissionEntry.unit === 'meter ** 2 * year' ? 'meter² per year' : emissionEntry.unit}
                      </Typography>
                    </StyledTableCell>
                    {hasComparison && (
                      <StyledTableCell width="17%">
                        <Typography
                          className={classes.tableCellTitle}
                          style={{ fontWeight: '500' }}
                        >
                          {intervenedValue && (
                            <ExactNumber value={intervenedValue} />
                          )}
                        </Typography>
                        <Typography
                          className={classes.tableCellDescription}
                          style={{ fontWeight: '400' }}
                        >
                          {emissionEntry.unit}
                        </Typography>
                      </StyledTableCell>
                    )}
                    {hasComparison && (
                      <StyledTableCell align="center">
                        {(change && Number.isFinite(change)) || change === 0 ? (
                          <ChipChangePercentage value={change} />
                        ) : (
                          <Typography
                            className={classes.tableCellTitle}
                            style={{ fontWeight: '400' }}
                          >
                            n/a
                          </Typography>
                        )}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FootprintEmissionTable;
