import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { ShrimpIntervention } from "../../../../models/Intervention/ShrimpIntervention";
import { ShrimpGrowingStageData } from "../../../../models/Baseline/ShrimpBaseline";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { StageType } from "../../../../../../graphql/types";

interface ShrimpInputPondComponentProps {
  formType: FormType;
  fieldItemPrefix: string;
  currentStageData: ShrimpGrowingStageData;
  stageType: StageType;
}

const ShrimpInputPondComponent: FC<ShrimpInputPondComponentProps> = ({
  formType = FormType.Add,
  fieldItemPrefix,
  currentStageData,
  stageType,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const fc = useFormContext<ShrimpIntervention>();

  const [isPondEdit, setIsPondEdit] = useState<boolean>(false);

  const cancelPondEdit = () => {
    fc.setValue(`${fieldItemPrefix}.totalPondArea`, "");
    fc.setValue(`${fieldItemPrefix}.emptyPeriod`, "");
    setIsPondEdit(false);
  };

  const getStageTypeCode = () => {
    switch (stageType) {
      case StageType.Hatching:
        return "BROODSTOCK";
      case StageType.Nursery:
        return "POST_LARVAE";
      case StageType.Growing:
      default:
        return "JUVENILES";
    }
  };

  const stageTypeCode = getStageTypeCode();

  return (
    <DsmGrid
      className={
        isPondEdit
          ? classes.dsmGridOneShorterColumnInterventionWithBorder
          : classes.dsmGridTwoColumnWithBorder
      }
    >
      {!isPondEdit && !fc.getValues(`${fieldItemPrefix}.totalPondArea`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalTotalPondArea`}
          label={intl.formatMessage({
            id:
              stageType === StageType.Hatching
                ? "BASELINE.FORM.BROODSTOCK.SHRIMP.TOTAL_TANK_AREA"
                : "BASELINE.FORM.JUVENILES.SHRIMP.TOTAL_POND_AREA",
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          adornment={userUOM.unitFarmSize}
          disabled
          type="number"
          tooltip={intl.formatMessage({
            id:
              stageType === StageType.Hatching
                ? "BASELINE.FORM.BROODSTOCK.SHRIMP.TOTAL_TANK_AREA.TOOLTIP"
                : "BASELINE.FORM.JUVENILES.SHRIMP.TOTAL_POND_AREA.TOOLTIP",
          })}
          defaultValue={currentStageData.input.totalPondArea}
          style={{ marginTop: "10px" }}
        />
      ) : (
        <div style={{ marginTop: "10px" }}>
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.totalPondArea`}
            label={intl.formatMessage({
              id:
                stageType === StageType.Hatching
                  ? "BASELINE.FORM.BROODSTOCK.SHRIMP.TOTAL_TANK_AREA"
                  : "BASELINE.FORM.JUVENILES.SHRIMP.TOTAL_POND_AREA",
            })}
            tooltip={intl.formatMessage({
              id:
                stageType === StageType.Hatching
                  ? "BASELINE.FORM.BROODSTOCK.SHRIMP.TOTAL_TANK_AREA.TOOLTIP"
                  : "BASELINE.FORM.JUVENILES.SHRIMP.TOTAL_POND_AREA.TOOLTIP",
            })}
            metricUnit={userUOM.unitFarmSize}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={currentStageData.input.totalPondArea}
            disabled={formType === "view" || !isPondEdit}
            minAllowedValue={0}
            displayTypeSelect={isPondEdit}
          />
        </div>
      )}
      {!isPondEdit && !fc.getValues(`${fieldItemPrefix}.emptyPeriod`) ? (
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.originalEmptyPeriod`}
          label={`${intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.EMPTY_PERIOD",
          })}`}
          disabled
          type="number"
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.EMPTY_PERIOD.TOOLTIP`,
          })}
          adornment={intl.formatMessage({
            id: "BASELINE.FORM.JUVENILES.SHRIMP.EMPTY_PERIOD.ADORNMENT",
          })}
          defaultValue={currentStageData.input.emptyPeriod}
          style={{ marginTop: "10px" }}
        />
      ) : (
        <div style={{ marginTop: !isPondEdit ? "10px" : "0px" }}>
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.emptyPeriod`}
            label={`${intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.EMPTY_PERIOD",
            })}`}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.EMPTY_PERIOD.TOOLTIP`,
            })}
            metricUnit={intl.formatMessage({
              id: "BASELINE.FORM.JUVENILES.SHRIMP.EMPTY_PERIOD.ADORNMENT",
            })}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            baseline={currentStageData.input.emptyPeriod}
            disabled={formType === "view" || !isPondEdit}
            minAllowedValue={0}
            displayTypeSelect={isPondEdit}
          />
        </div>
      )}
      {!isPondEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsPondEdit(true)}
          disabled={formType === "view"}
          style={{ width: "120px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <DsmButton
          variant="text"
          destructive
          onClick={() => cancelPondEdit()}
          disabled={formType === "view"}
          style={{ width: "160px" }}
        >
          <DsmIcon slot="before" name="general/minus-circle" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.CANCEL",
            })}
          </u>
        </DsmButton>
      )}
    </DsmGrid>
  );
};

export default ShrimpInputPondComponent;
