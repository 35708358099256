import { v4 as uuidv4 } from "uuid";
import { defaultUnitsV2 } from "../../../../sustell_15/utils/unit-utils";
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import {
  floatValueInUserUnits,
  getDefaultTransportUnit,
  getUserTransportUnit,
  setInterventionFloatValue,
} from "../../Baseline/mapper/inCommonMapperSustellV2";
import {
  ShrimpInput as ShrimpInterventionInput,
  GrowingInterventionSupply,
  GrowingInterventionExternalSource,
  ShrimpOperations as ShrimpInterventionOperations,
  FertilizerTypeInput,
  ShrimpMaterialTypeInput,
  ShrimpChemicalTypeInput as ShrimpInterventionChemicalTypeInput,
  ShrimpOutputs as ShrimpInterventionOutputs,
  ShrimpOutput as ShrimpInterventionOutput,
  ShrimpInterventionFeed,
  ShrimpStageData,
  ShrimpEnergyTypeInput as ShrimpInterventionEnergyTypeInput,
  NaturalFeedIntervention,
} from "../../../../sustell_15/models/Intervention/ShrimpIntervention";
import {
  ShrimpOutput as ShrimpBaselineOutput,
  FertilizerTypeInput as ShrimpBaselineFertilizerTypeInput,
  ShrimpMaterialTypeInput as ShrimpBaselineMaterialTypeInput,
  ShrimpChemicalTypeInput as ShrimpBaselineChemicalTypeInput,
  GrowingSupply as ShrimpBaselineInputGrowingSupply,
  GrowingExternalSource as ShrimpBaselineExternalSource,
  NaturalFeed,
} from "../../../../sustell_15/models/Baseline/ShrimpBaseline";
import {
  createCoreForm,
  mapCompoundFeedForOneStage,
  mapResourceUse,
  mapSingleIngridientsForOneStage,
} from "./inCommonMapperSustellV2";
import { Salinity } from "../../../../../graphql/generated/blonk/shrimp";
import {
  GrowingInterventionSupply as TempGrowingInterventionSupply,
  GrowingInterventionExternalSource as TempGrowingInterventionExternalSource,
  ShrimpGrowingStageMaterialTypeInput,
  ShrimpOutput,
  ShrimpChemicalTypeInput,
  ShrimpStageDBModel,
  FeedingProgramDBModel,
  ShrimpStageDataDBModel,
  ShrimpInterventionDBModel,
  FertilizerTypeInput as TempFertilizerTypeInput,
  ShrimpEnergyTypeInput as ShrimpEnergyTypeInputDB,
  NaturalFeedDB,
} from "../../../../sustell_15/models/TempBackendModels/TempInterventionModels/TempShrimpInterventionDB";
import { EnergyCarrierType } from "../../../../../graphql/generated/blonk/cattle";
import {
  AnimalsInputDBModel,
  StageMaterialDBModel,
} from "../../../../sustell_15/models/TempBackendModels/TempShrimpDB";

const initStageData = (): ShrimpStageData => {
  return {
    input: {
      totalPondArea: "",
      totalPondArea_newValue: "",
      totalPondArea_changeMetric: "",
      emptyPeriod: "",
      emptyPeriod_newValue: "",
      emptyPeriod_changeMetric: "",
      stageDuration: "",
      stageDuration_newValue: "",
      stageDuration_changeMetric: "",
      totalWeightAtStartDate: "",
      totalWeightAtStartDate_newValue: "",
      totalWeightAtStartDate_changeMetric: "",
      totalWeightAtEndDate: "",
      totalWeightAtEndDate_newValue: "",
      totalWeightAtEndDate_changeMetric: "",
      priceAtStartDate: "",
      priceAtStartDate_newValue: "",
      priceAtStartDate_changeMetric: "",
      priceAtEndDate: "",
      priceAtEndDate_newValue: "",
      priceAtEndDate_changeMetric: "",
      internalSources: [],
      externalSources: [],
      internalSourcesAdditions: [],
      externalSourcesAdditions: [],
    },
    feed: {
      compoundFeeds: [],
      singleIngredients: [],
      naturalFeed: [],
      naturalFeedAdditions: [],
    },
    operations: {
      resourceUse: {
        electricityUse: "",
        electricityUse_newValue: "",
        electricityUse_changeMetric: "",
        gasUse: "",
        gasUse_newValue: "",
        gasUse_changeMetric: "",
        waterUse: "",
        waterUse_newValue: "",
        waterUse_changeMetric: "",
        watersalinity: Salinity.BrackishOrFreshwater || Salinity.Saline,
        watersalinity_newValue: "",
        watersalinity_changeMetric: "",
        nitrogenConcentrationIn: "",
        nitrogenConcentrationIn_newValue: "",
        nitrogenConcentrationIn_changeMetric: "",
        nitrogenConcentrationOut: "",
        nitrogenConcentrationOut_newValue: "",
        nitrogenConcentrationOut_changeMetric: "",
        waterEnteringPondIn: "",
        waterEnteringPondIn_newValue: "",
        waterEnteringPondIn_changeMetric: "",
        waterEnteringPondOut: "",
        waterEnteringPondOut_newValue: "",
        waterEnteringPondOut_changeMetric: "",
        selfGeneratedRenewables: [],
        energyTypes: [],
        selfGeneratedRenewablesAdditions: [],
        energyTypesAdditions: [],
      },
      fertilizerTypes: [],
      materialTypes: [],
      chemicalTypes: [],
      iceAmount: "",
      iceAmount_newValue: "",
      iceAmount_changeMetric: "",
      fertilizerTypeAdditions: [],
      materialTypeAdditions: [],
      chemicalTypeAdditions: [],
    },
    output: {
      outputs: [],
      outputAdditions: [],
    },
  };
};

const mapNaturalFeedForOneStage = (
  feedBlockData: ShrimpInterventionFeed,
  feedingProgram: FeedingProgramDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (feedingProgram) {
    feedingProgram.naturalFeed?.forEach((item: NaturalFeedDB) => {
      const naturalFeedData = {
        id: item.id || uuidv4(),
        type: item.type,
        origin: item.origin?.value,
        origin_changeMetric: item.origin?.change_type,
        quantity: 0,
        crudeProteinContent: 0,
      };

      setInterventionFloatValue(
        item,
        "quantity",
        naturalFeedData,
        "quantity",
        userUOM.unitBarnOutputMass,
        defaultUnitsV2.unitBarnOutputMass
      );

      setInterventionFloatValue(
        item,
        "crudeProteinContent",
        naturalFeedData,
        "crudeProteinContent"
      );

      feedBlockData?.naturalFeed?.push(
        naturalFeedData as NaturalFeedIntervention
      );
    });

    if (
      feedingProgram.naturalFeedAdditions &&
      Array.isArray(feedingProgram.naturalFeedAdditions)
    ) {
      feedingProgram.naturalFeedAdditions?.forEach((item) => {
        const newFeed = {
          id: item.id || uuidv4(),
          type: item.type,
          quantity: floatValueInUserUnits(
            item.quantity,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          origin: item.origin,
          crudeProteinContent: Number(item.crudeProteinContent),
        };

        feedBlockData?.naturalFeedAdditions?.push(newFeed as NaturalFeed);
      });
    }
  }
};

export const mapFeedingProgramForOneStage = (
  formDataFeedObj: ShrimpInterventionFeed,
  stageFeedData: FeedingProgramDBModel
) => {
  mapCompoundFeedForOneStage(stageFeedData, formDataFeedObj);
  mapSingleIngridientsForOneStage(stageFeedData, formDataFeedObj);
  mapNaturalFeedForOneStage(formDataFeedObj, stageFeedData);

  return formDataFeedObj;
};

const mapInputForOneStage = (
  inputBlockData: ShrimpInterventionInput,
  stageGeneralData: ShrimpStageDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const { stageData } = stageGeneralData;
  const { input } = stageData;

  if (input) {
    setInterventionFloatValue(
      input,
      "totalPondArea",
      inputBlockData,
      "totalPondArea"
    );

    setInterventionFloatValue(
      input,
      "emptyPeriod",
      inputBlockData,
      "emptyPeriod"
    );

    // stock
    if (
      input.totalWeightAtStartDate ||
      Number(input.totalWeightAtStartDate) === 0
    )
      setInterventionFloatValue(
        input,
        "totalWeightAtStartDate",
        inputBlockData,
        "totalWeightAtStartDate",
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );

    if (input.totalWeightAtEndDate || Number(input.totalWeightAtEndDate) === 0)
      setInterventionFloatValue(
        input,
        "totalWeightAtEndDate",
        inputBlockData,
        "totalWeightAtEndDate",
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );

    if (input.priceAtStartDate || Number(input.priceAtStartDate) === 0)
      setInterventionFloatValue(
        input,
        "priceAtStartDate",
        inputBlockData,
        "priceAtStartDate"
      );

    if (input.priceAtEndDate || Number(input.priceAtEndDate) === 0)
      setInterventionFloatValue(
        input,
        "priceAtEndDate",
        inputBlockData,
        "priceAtEndDate"
      );

    // input - supply
    input.internalSources?.forEach((item: TempGrowingInterventionSupply) => {
      const inputData = {
        id: item.id || uuidv4(),
        comingFromFarm: item.comingFromFarm,
        comingFromStage: item.comingFromStage,
        outputName: item.outputName,
        totalWeight: 0,
        distanceTraveled: 0,
        transportMode: item.transportMode?.value,
        transportMode_changeMetric: item.transportMode?.change_type,
      };

      setInterventionFloatValue(
        item,
        "totalWeight",
        inputData,
        "totalWeight",
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );

      setInterventionFloatValue(
        item,
        "distanceTraveled",
        inputData,
        "distanceTraveled"
      );

      inputBlockData?.internalSources?.push(
        inputData as GrowingInterventionSupply
      );
    });

    // input - internal sources additions
    if (
      input?.internalSourcesAdditions &&
      Array.isArray(input.internalSourcesAdditions)
    ) {
      input.internalSourcesAdditions?.forEach((item: AnimalsInputDBModel) => {
        let distanceTraveledValue = 0;
        let transportModeValue = "";

        if (item.transport) {
          distanceTraveledValue = floatValueInUserUnits(
            item.transport[0]?.distance,
            getUserTransportUnit(item.transport[0]?.mode),
            getDefaultTransportUnit(item.transport[0]?.mode)
          );

          transportModeValue = item.transport[0].mode;
          distanceTraveledValue = item.transport[0].distance;
        }

        const newInputInternalSourceData = {
          id: item.id || uuidv4(),
          comingFromFarm: item.originFarmId,
          comingFromStage: item.originStageId,
          outputName: item.originOutputName,
          totalWeight: floatValueInUserUnits(
            item.weight,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          distanceTraveled: distanceTraveledValue,
          transportMode: transportModeValue,
        };

        inputBlockData?.internalSourcesAdditions?.push(
          newInputInternalSourceData as ShrimpBaselineInputGrowingSupply
        );
      });
    }

    // input - external sources
    input.externalSources?.forEach(
      (item: TempGrowingInterventionExternalSource) => {
        const externalSource = {
          id: item.id || uuidv4(),
          totalWeight: 0,
          distanceTraveled: 0,
          transportMode: item.transportMode?.value,
          transportMode_changeMetric: item.transportMode?.change_type,
        };

        setInterventionFloatValue(
          item,
          "totalWeight",
          externalSource,
          "totalWeight",
          userUOM.unitOutputMass,
          defaultUnitsV2.unitOutputMass,
          outputMassPrecision
        );

        setInterventionFloatValue(
          item,
          "distanceTraveled",
          externalSource,
          "distanceTraveled"
        );

        inputBlockData?.externalSources?.push(
          externalSource as GrowingInterventionExternalSource
        );
      }
    );

    // input - external sources additions
    if (
      input?.externalSourcesAdditions &&
      Array.isArray(input.externalSourcesAdditions)
    ) {
      input.externalSourcesAdditions?.forEach((item: AnimalsInputDBModel) => {
        let distanceTraveledValue = 0;
        let transportModeValue = "";

        if (item.transport) {
          distanceTraveledValue = floatValueInUserUnits(
            item.transport[0]?.distance,
            getUserTransportUnit(item.transport[0]?.mode),
            getDefaultTransportUnit(item.transport[0]?.mode)
          );

          distanceTraveledValue = item.transport[0].distance;
          transportModeValue = item.transport[0].mode;
        }

        const newInputExternalSourceData = {
          id: item.id || uuidv4(),
          totalWeight: floatValueInUserUnits(
            item.weight,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          distanceTraveled: distanceTraveledValue,
          transportMode: transportModeValue,
        };

        inputBlockData?.externalSourcesAdditions?.push(
          newInputExternalSourceData as ShrimpBaselineExternalSource
        );
      });
    }
  }
};

const mapOperationsForOneStage = (
  operationsBlockData: ShrimpInterventionOperations,
  stageGeneralData: ShrimpStageDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = stageGeneralData;
  const { operations } = stageData;

  if (operationsBlockData && operations) {
    const { resourceUse, fertilizerTypes, materialTypes, chemicalTypes } =
      operations;

    // resourceUse
    if (
      resourceUse?.electricityUse ||
      Number(resourceUse?.electricityUse) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "electricityUse",
        operationsBlockData.resourceUse,
        "electricityUse",
        userUOM.unitElectricityUse,
        defaultUnitsV2.unitElectricityUse
      );

    if (resourceUse?.gasUse || Number(resourceUse?.gasUse) === 0)
      setInterventionFloatValue(
        resourceUse,
        "gasUse",
        operationsBlockData.resourceUse,
        "gasUse",
        userUOM.unitNaturalGasUse,
        defaultUnitsV2.unitNaturalGasUse
      );

    if (resourceUse?.waterUse || Number(resourceUse?.waterUse) === 0)
      setInterventionFloatValue(
        resourceUse,
        "waterUse",
        operationsBlockData.resourceUse,
        "waterUse",
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    if (
      resourceUse?.selfGeneratedRenewables &&
      Array.isArray(resourceUse.selfGeneratedRenewables)
    ) {
      resourceUse.selfGeneratedRenewables.forEach(
        (item: ShrimpEnergyTypeInputDB) => {
          if (item && item.type) {
            const selfGeneratedRenewable = {
              id: uuidv4(),
              type: item.type,
              value: "",
            };

            setInterventionFloatValue(
              item,
              "value",
              selfGeneratedRenewable,
              "value",
              userUOM.unitElectricityUse,
              defaultUnitsV2.unitElectricityUse
            );

            operationsBlockData.resourceUse?.selfGeneratedRenewables?.push(
              selfGeneratedRenewable as ShrimpInterventionEnergyTypeInput
            );
          }
        }
      );
    }

    if (resourceUse?.energyTypes && Array.isArray(resourceUse?.energyTypes)) {
      resourceUse?.energyTypes.forEach((item: ShrimpEnergyTypeInputDB) => {
        if (item && item.type) {
          let userUnit = "";
          let defaultUnitV2 = "";

          switch (item.type) {
            case EnergyCarrierType.Coal:
              userUnit = userUOM.unitHeatUse;
              defaultUnitV2 = defaultUnitsV2.unitHeatUse;
              break;
            case EnergyCarrierType.Diesel:
              userUnit = userUOM.unitDieselUse;
              defaultUnitV2 = defaultUnitsV2.unitDieselUse;
              break;
            case EnergyCarrierType.Gasoline:
              userUnit = userUOM.unitGasolineUse;
              defaultUnitV2 = defaultUnitsV2.unitGasolineUse;
              break;
            case EnergyCarrierType.HeavyFuelOil:
              userUnit = userUOM.unitHeavyFuelOilUse;
              defaultUnitV2 = defaultUnitsV2.unitHeavyFuelOilUse;
              break;
            case EnergyCarrierType.PropaneOrLpg:
              userUnit = userUOM.unitNaturalGasUse;
              defaultUnitV2 = defaultUnitsV2.unitNaturalGasUse;
              break;
            default:
              break;
          }

          const energyType = {
            id: uuidv4(),
            type: item.type,
            value: "",
          };

          setInterventionFloatValue(
            item,
            "value",
            energyType,
            "value",
            userUnit,
            defaultUnitV2
          );

          operationsBlockData.resourceUse?.energyTypes?.push(
            energyType as ShrimpInterventionEnergyTypeInput
          );
        }
      });
    }

    if (
      resourceUse?.selfGeneratedRenewablesAdditions &&
      Array.isArray(resourceUse.selfGeneratedRenewablesAdditions)
    ) {
      resourceUse.selfGeneratedRenewablesAdditions.forEach((item) => {
        if (item && item.type) {
          const selfGeneratedRenewable = {
            id: uuidv4(),
            type: item.type,
            value: floatValueInUserUnits(
              item.value,
              userUOM.unitElectricityUse,
              defaultUnitsV2.unitElectricityUse
            ),
          };

          operationsBlockData.resourceUse?.selfGeneratedRenewablesAdditions?.push(
            selfGeneratedRenewable
          );
        }
      });
    }

    if (
      resourceUse?.energyTypesAdditions &&
      Array.isArray(resourceUse?.energyTypesAdditions)
    ) {
      resourceUse?.energyTypesAdditions.forEach((item) => {
        if (item && item.type) {
          let userUnit = "";
          let defaultUnitV2 = "";

          switch (item.type) {
            case EnergyCarrierType.Coal:
              userUnit = userUOM.unitHeatUse;
              defaultUnitV2 = defaultUnitsV2.unitHeatUse;
              break;
            case EnergyCarrierType.Diesel:
              userUnit = userUOM.unitDieselUse;
              defaultUnitV2 = defaultUnitsV2.unitDieselUse;
              break;
            case EnergyCarrierType.Gasoline:
              userUnit = userUOM.unitGasolineUse;
              defaultUnitV2 = defaultUnitsV2.unitGasolineUse;
              break;
            case EnergyCarrierType.HeavyFuelOil:
              userUnit = userUOM.unitHeavyFuelOilUse;
              defaultUnitV2 = defaultUnitsV2.unitHeavyFuelOilUse;
              break;
            case EnergyCarrierType.PropaneOrLpg:
              userUnit = userUOM.unitNaturalGasUse;
              defaultUnitV2 = defaultUnitsV2.unitNaturalGasUse;
              break;
            default:
              break;
          }

          const energyType = {
            id: uuidv4(),
            type: item.type,
            value: floatValueInUserUnits(item.value, userUnit, defaultUnitV2),
          };

          operationsBlockData.resourceUse?.energyTypesAdditions?.push(
            energyType
          );
        }
      });
    }

    // pondWaterProperties
    if (
      resourceUse?.waterEnteringPondIn ||
      Number(resourceUse?.waterEnteringPondIn) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "waterEnteringPondIn",
        operationsBlockData.resourceUse,
        "waterEnteringPondIn",
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    if (
      resourceUse?.nitrogenConcentrationIn ||
      Number(resourceUse?.nitrogenConcentrationIn) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "nitrogenConcentrationIn",
        operationsBlockData.resourceUse,
        "nitrogenConcentrationIn"
      );

    if (
      resourceUse?.waterEnteringPondOut ||
      Number(resourceUse?.waterEnteringPondOut) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "waterEnteringPondOut",
        operationsBlockData.resourceUse,
        "waterEnteringPondOut",
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    if (
      resourceUse?.nitrogenConcentrationOut ||
      Number(resourceUse?.nitrogenConcentrationOut) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "nitrogenConcentrationOut",
        operationsBlockData.resourceUse,
        "nitrogenConcentrationOut"
      );

    if (resourceUse?.watersalinity && operationsBlockData.resourceUse)
      operationsBlockData.resourceUse.watersalinity = resourceUse.watersalinity;

    // ice
    setInterventionFloatValue(
      operations,
      "iceAmount",
      operationsBlockData,
      "iceAmount",
      userUOM.unitBarnOutputMass,
      defaultUnitsV2.unitBarnOutputMass
    );

    // fertilizers
    fertilizerTypes?.forEach((item: TempFertilizerTypeInput) => {
      if (item.type) {
        const fertilizerType = {
          id: item.id || uuidv4(),
          type: item.type,
          amount: 0,
        };

        setInterventionFloatValue(
          item,
          "quantity",
          fertilizerType,
          "amount",
          userUOM.unitBarnOutputMass,
          defaultUnitsV2.unitBarnOutputMass
        );

        operationsBlockData.fertilizerTypes?.push(
          fertilizerType as FertilizerTypeInput
        );
      }
    });

    if (
      operations.fertilizerTypeAdditions &&
      Array.isArray(operations.fertilizerTypeAdditions)
    ) {
      operations.fertilizerTypeAdditions?.forEach((item) => {
        if (item.type) {
          const newFertilizer = {
            id: item.id || uuidv4(),
            amount: floatValueInUserUnits(
              item.quantity,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            type: item.type,
          };

          operationsBlockData?.fertilizerTypeAdditions?.push(
            newFertilizer as ShrimpBaselineFertilizerTypeInput
          );
        }
      });
    }

    // materialsUse
    materialTypes?.forEach((item: ShrimpGrowingStageMaterialTypeInput) => {
      if (item && item.type) {
        const materialType = {
          id: item.id || uuidv4(),
          type: item.type,
          amount: 0,
          lifetimeOfMaterial: 0,
          transportMode: item.transportMode?.value,
          transportMode_changeMetric: item.transportMode?.change_type,
          distanceTraveled: 0,
        };

        setInterventionFloatValue(
          item,
          "lifetimeOfMaterial",
          materialType,
          "lifetimeOfMaterial"
        );

        setInterventionFloatValue(
          item,
          "amount",
          materialType,
          "amount",
          userUOM.unitBarnOutputMass,
          defaultUnitsV2.unitBarnOutputMass
        );

        setInterventionFloatValue(
          item,
          "distanceTraveled",
          materialType,
          "distanceTraveled",
          getUserTransportUnit(item.transportMode),
          getDefaultTransportUnit(item.transportMode)
        );

        operationsBlockData.materialTypes?.push(
          materialType as ShrimpMaterialTypeInput
        );
      }
    });

    if (
      operations.materialTypeAdditions &&
      Array.isArray(operations.materialTypeAdditions)
    ) {
      operations.materialTypeAdditions?.forEach(
        (item: StageMaterialDBModel) => {
          if (item && item.type) {
            let transportModeValue;

            if (item.transport) {
              transportModeValue = item.transport[0].mode;
            }

            const newMaterial = {
              id: item.id || uuidv4(),
              type: item.type,
              amount: floatValueInUserUnits(
                item.amount,
                userUOM.unitBarnOutputMass,
                defaultUnitsV2.unitBarnOutputMass
              ),
              lifetimeOfMaterial: floatValueInUserUnits(item.lifeTime),
              transportMode: transportModeValue,
              distanceTraveled: item.transport
                ? floatValueInUserUnits(
                    item.transport[0]?.distance,
                    getUserTransportUnit(item.transport[0]?.mode),
                    getDefaultTransportUnit(item.transport[0]?.mode)
                  )
                : 0,
            };

            operationsBlockData.materialTypeAdditions?.push(
              newMaterial as ShrimpBaselineMaterialTypeInput
            );
          }
        }
      );
    }

    // chemicalsUse
    chemicalTypes?.forEach((item: ShrimpChemicalTypeInput) => {
      if (item && item.type) {
        const chemicalType = {
          id: item.id || uuidv4(),
          type: item.type,
          amount: 0,
        };

        setInterventionFloatValue(
          item,
          "amount",
          chemicalType,
          "amount",
          userUOM.unitBarnOutputMass,
          defaultUnitsV2.unitBarnOutputMass
        );

        operationsBlockData.chemicalTypes?.push(
          chemicalType as ShrimpInterventionChemicalTypeInput
        );
      }
    });

    if (
      operations.chemicalTypeAdditions &&
      Array.isArray(operations.chemicalTypeAdditions)
    ) {
      operations.chemicalTypeAdditions?.forEach((item) => {
        if (item && item.type) {
          const newChemical = {
            id: item.id || uuidv4(),
            amount: floatValueInUserUnits(
              item.amount,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            type: item.type,
          };

          operationsBlockData.chemicalTypeAdditions?.push(
            newChemical as ShrimpBaselineChemicalTypeInput
          );
        }
      });
    }
  }
};

const mapOutputForOneStage = (
  outputBlockData: ShrimpInterventionOutputs,
  stageGeneralData: ShrimpStageDataDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { output } = stageGeneralData;

  if (output) {
    output.outputs?.forEach((item: ShrimpOutput) => {
      const outputData = {
        id: item.id || uuidv4(),
        calculateLca: item.calculateLca,
        name: item.name,
        price: 0,
        soldExternally: item.soldExternally,
        totalWeight: 0,
        proteinContent: 0,
      };

      setInterventionFloatValue(item, "price", outputData, "price");

      setInterventionFloatValue(
        item,
        "totalWeight",
        outputData,
        "totalWeight",
        userUOM.unitBarnOutputMass,
        defaultUnitsV2.unitBarnOutputMass
      );

      setInterventionFloatValue(
        item,
        "proteinContent",
        outputData,
        "proteinContent"
      );

      outputBlockData?.outputs?.push(outputData as ShrimpInterventionOutput);
    });

    if (output.outputAdditions && Array.isArray(output.outputAdditions)) {
      output.outputAdditions?.forEach((item) => {
        const newOutput = {
          id: item.id || uuidv4(),
          calculateLca: item.calculateLca,
          name: item.name,
          price: Number(item.price),
          soldExternally: item.soldExternally ? "Yes" : "No",
          totalWeight: floatValueInUserUnits(
            item.weight,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          proteinContent: Number(item.proteinContent),
        };

        outputBlockData.outputAdditions?.push(
          newOutput as ShrimpBaselineOutput
        );
      });
    }
  }
};

const mapStageInterventionDataInSustell = (
  stageBlockData: ShrimpStageDBModel
) => {
  const { stageData } = stageBlockData;

  const oneStageData = {
    id: stageBlockData?.id || uuidv4(),
    name: stageBlockData?.name,
    type: stageBlockData?.type,
    stageData: { ...initStageData() },
  };

  if (stageData.input && oneStageData.stageData.input) {
    setInterventionFloatValue(
      stageData.input,
      "stageDuration",
      oneStageData.stageData.input,
      "stageDuration"
    );

    mapInputForOneStage(oneStageData.stageData.input, stageBlockData);
  }

  if (stageData.feedingProgram && oneStageData.stageData.feed)
    mapFeedingProgramForOneStage(
      oneStageData.stageData.feed,
      stageData.feedingProgram
    );

  if (stageData.operations && oneStageData.stageData.operations) {
    mapOperationsForOneStage(oneStageData.stageData.operations, stageBlockData);
  }

  if (stageData.output && oneStageData.stageData.output) {
    mapOutputForOneStage(oneStageData.stageData.output, stageData);
  }

  return oneStageData;
};

const mapShrimpInterventionDataInSustell = (
  inDataObj: ShrimpInterventionDBModel
) => {
  if (inDataObj) {
    let dataObj: ShrimpInterventionDBModel = { ...inDataObj, stages: [] };
    inDataObj.stages?.forEach((stage: ShrimpStageDBModel) => {
      let stageNew: ShrimpStageDBModel;
      if (stage.stageData && typeof stage.stageData === "string") {
        stageNew = { ...stage, stageData: JSON.parse(stage.stageData) || {} };
      } else {
        stageNew = { ...stage };
      }
      dataObj.stages?.push(stageNew);
    });

    const payloadObj = createCoreForm(dataObj);

    if (dataObj.resourceUse)
      mapResourceUse(dataObj.resourceUse, payloadObj.resourceUse);

    if (dataObj.stages && Array.isArray(dataObj.stages)) {
      return dataObj.stages.reduce(
        (payload, stage) => {
          const mappedStage = mapStageInterventionDataInSustell(stage);

          return {
            ...payload,
            stages: [...payload.stages, mappedStage],
          };
        },
        { ...payloadObj }
      );
    }

    return payloadObj;
  }

  return {};
};

export default mapShrimpInterventionDataInSustell;
