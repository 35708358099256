export default {
  dairyV2: process.env.REACT_APP_FEATURE_FLAG_DAIRY_V2 === 'true',
  poultryProcessingForInterventions:
    process.env.REACT_APP_FEATURE_FLAG_POULTY_PROCESSING_FOR_INTERVENTIONS ===
    'true',
  cloudWatchRUM: process.env.REACT_APP_FEATURE_FLAG_CLOUD_WATCH_RUM === 'true',
  feedV2Routing: process.env.REACT_APP_FEATURE_FLAG_FEED_V2_ROUTING === 'true',
  shrimpInterventions: process.env.REACT_APP_FEATURE_FLAG_SHRIMP_INTERVENTIONS === 'true',
  dairyInterventions: process.env.REACT_APP_FEATURE_FLAG_DAIRY_INTERVENTIONS === 'true',
};
