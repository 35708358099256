import { FC } from 'react';
import { DsmButton, DsmFieldset, DsmIcon } from '@dsm-dcs/design-system-react';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { FormType } from '../types';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import ReactHookDsmInput from '../../../../modules/Helpers/ReactHookDsmInput2';
import lookupValues from '../../../helpers/lookupValues';
import { floatValueInUserUnits } from '../../../../modules/Farms/Baseline/mapper/inCommonMapperSustellV2';
import { defaultUnitsV2 as defaultMetric } from '../../../utils/unit-utils';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import { CSSClassesList } from '../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';

interface PigNutritionProps {
  formType: FormType;
  targetSpecies: keyof typeof lookupValues.compoundFeedNutrientsDefault;
}

const PigNutrition: FC<PigNutritionProps> = ({ formType, targetSpecies }) => {
  
  const fc = useFormContext();
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const toCurrentEnergyUnits = (
    value?: number | string | null
  ): number | string | undefined => {
    if (value === null || value === undefined || value === '') return '';

    let numVal: number;
    if (typeof value === 'string') numVal = parseFloat(value);
    else numVal = value;

    // if ther is a change in units, do conversion
    if (
      defaultMetric.unitNutritionalGrossEnergy !==
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        userUOM.unitNutritionalGrossEnergy ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      defaultMetric.unitIngredientQuantityInputMass !==
        userUOM.unitIngredientQuantityInputMass
    )
      numVal = floatValueInUserUnits(
        numVal,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitIngredientQuantityInputMass}`,
        `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`
      ) as number;

    return numVal;
  };

  const energyUnit =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    userUOM?.unitIngredientQuantityInputMass &&
    userUOM?.unitNutritionalGrossEnergy
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `${userUOM?.unitNutritionalGrossEnergy as string}/${
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          userUOM?.unitIngredientQuantityInputMass as string
        }`
      : `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`;

  const resetDefault = () => {
    fc.setValue(
      'nutritional_analysis_data.dryMatterContent',
      lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]?.dryMatterContent
    );
    fc.setValue(
      'nutritional_analysis_data.grossEnergyContent',
      toCurrentEnergyUnits(
        lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
          ?.grossEnergyContent
      )
    );
    fc.setValue(
      'nutritional_analysis_data.ashContent',
      lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]?.ashContent
    );
    fc.setValue(
      'nutritional_analysis_data.digestibleEnergyContent',
      toCurrentEnergyUnits(
        lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
          ?.digestibleEnergyContent
      )
    );
    fc.setValue(
      'nutritional_analysis_data.phosphorusContent',
      lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]?.phosphorusContent
    );
  };

  return (
    <div>
      <Grid item xs={9}>
        <DsmFieldset style={{ width: '100%' }}>
          <ReactHookDsmInput
            name="nutritional_analysis_data.crudeProteinContent"
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.CRUDE_PROTEIN',
            })}
            type="number"
            disabled={formType === FormType.View}
            adornment="%"
            size="medium"
            required
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITION.CRUDE_PROTEIN_CONTENT.TOOLTIP',
            })}
          />
          <ReactHookDsmInput
            name="nutritional_analysis_data.dryMatterContent"
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.DRY_MATTER',
            })}
            type="number"
            disabled={formType === FormType.View}
            adornment="%"
            size="medium"
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITION.DRY_MATTER_CONTENT.TOOLTIP',
            })}
            defaultValue={
              lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
                ?.dryMatterContent
            }
          />
          <ReactHookDsmInput
            name="nutritional_analysis_data.grossEnergyContent"
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.GROSS_ENERGY',
            })}
            type="number"
            disabled={formType === FormType.View}
            adornment={energyUnit}
            size="medium"
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITION.GROSS_ENERGY_CONTENT.TOOLTIP',
            })}
            defaultValue={toCurrentEnergyUnits(
              lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
                ?.grossEnergyContent
            )}
          />
        </DsmFieldset>
      </Grid>
      <Grid item xs={9}>
        <DsmFieldset style={{ width: '100%' }}>
          <ReactHookDsmInput
            name="nutritional_analysis_data.digestibleEnergyContent"
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.DIGESTIBLE_ENERGY',
            })}
            type="number"
            disabled={formType === FormType.View}
            adornment={energyUnit}
            size="medium"
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITION.DIGESTIBLE_ENERGY_CONTENT.TOOLTIP',
            })}
            defaultValue={toCurrentEnergyUnits(
              lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
                ?.digestibleEnergyContent
            )}
          />

          <ReactHookDsmInput
            name="nutritional_analysis_data.phosphorusContent"
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.PHOSPHORUS',
            })}
            type="number"
            disabled={formType === FormType.View}
            adornment="%"
            size="medium"
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITION.PHOSPHORUS_CONTENT.TOOLTIP',
            })}
            defaultValue={
              lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
                ?.phosphorusContent
            }
          />

          <ReactHookDsmInput
            name="nutritional_analysis_data.ashContent"
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.ASH',
            })}
            type="number"
            disabled={formType === FormType.View}
            adornment="% of DM"
            size="medium"
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.NUTRITION.ASH_CONTENT.TOOLTIP',
            })}
            defaultValue={
              lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
                ?.ashContent
            }
          />
        </DsmFieldset>
      </Grid>
      <Grid item xs={9}>
        <DsmFieldset style={{ width: '100%' }}>
          <div className={classes.resetButtonContainer}>
            <DsmButton
              variant="text"
              style={{ marginTop: '23px', float: 'right' }}
              onClick={() => resetDefault()}
              className="mt-3"
              disabled={formType === FormType.View}
            >
              <DsmIcon slot="before" name="arrows/refresh-cw-01" />
              <u>
                {intl.formatMessage({
                  id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.RESET_DEFAULT',
                })}
              </u>
            </DsmButton>
            <Tooltip
              title={intl.formatMessage({
                id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
              })}
              placement="left"
              className='ml-3'
              style={{transform: 'translateY(85%)'}}
            >
              <DsmIcon
                name="general/help-circle"
                className={classes.inlineTooltipClass}
                title=""
              />
            </Tooltip>
          </div>
        </DsmFieldset>
      </Grid>
    </div>
  );
}

export default PigNutrition;
