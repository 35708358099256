import { get, cloneDeep } from 'lodash';
import { useState, useEffect, useRef, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid, DsmFieldset, DsmModal } from '@dsm-dcs/design-system-react';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { BaselineDialogProps, FormType } from '../../common';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import { DsmButtonV2, useButtonStyles } from '../../../helpers/DsmButton'
import { DatabaseFoundation } from '../../../../../../graphql/generated/blonk/pigs';
import { CompoundFeedDatabase, Maybe, StageType } from '../../../../../../graphql/types';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { PoultryBaseline, PoultryBreedingStageData, PoultryFeed, PoultryGrowingStageData, PoultryLayingStageData, PoultryStage } from '../../../../models/Baseline/PoultryBaseline';
import { CSSClassesList, ReactChangedType } from '../../../../helpers/helperTypes';
import { BaselineFeed } from '../../../../models/Baseline';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import FeedsFormCommonDataPart from '../../CommonDataParts/FeedsFormCommonPart';

interface SelectedDatabase {
  open: boolean;
  defaultValue: DatabaseFoundation;
  selectedValue: DatabaseFoundation | null;
}

interface PoultryFeedGrowingAndLayingDialogProps extends BaselineDialogProps {
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  origins: Maybe<Array<Origin>>;
}

const PoultryFeedGrowingAndLayingDialog: FC<PoultryFeedGrowingAndLayingDialogProps> = ({
  formVisible,
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  compoundFeeds,
  singleIngredients,
  origins
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const fc = useFormContext<PoultryBaseline>();
  const calculateInitialDatabaseValue = () => {
    let initValue: DatabaseFoundation = fc.getValues(`info.databaseFoundation`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (!initValue) initValue =  userProfile.getUserDatabasePrefs().databaseType;
    return initValue;
  }

  const [compoundFeedsData, setCompoundFeedData] = useState(compoundFeeds);
  const [singleIngredientsData, setSingleIngredientsData] = useState(singleIngredients);

  const intl = useIntl();
  const currResetValue = useRef<{feed: PoultryFeed, databaseFoundation: DatabaseFoundation}>();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const buttonClasses = useButtonStyles();
  const baselineDatabaseFoundation = calculateInitialDatabaseValue();
  const defalutDatabaseFoundation = baselineDatabaseFoundation;
  const initialDatabase = useRef<DatabaseFoundation>(calculateInitialDatabaseValue());
  const [openDatabaseSelectionWaringDialog, setOpenDatabaseSelectionWaringDialog] = useState<SelectedDatabase>({
    open: false,
    defaultValue: defalutDatabaseFoundation,
    selectedValue: null,
  });

  const getCompoundFeedsList = (databaseOption: DatabaseFoundation) => compoundFeeds?.filter((feed: CompoundFeedNameDatabase) => feed.databaseFoundation === databaseOption);

  const closeDialog = () => {
    setOpenDatabaseSelectionWaringDialog({open: false, selectedValue: null, defaultValue: defalutDatabaseFoundation});
  };

  const updateBreedingFeeds = (stage: PoultryStage, index: number) => {
    const henFeeds = (stage.stageData as PoultryBreedingStageData).feed.henFeed.compoundFeeds;
    const updatedHenFeeds: BaselineFeed[] | undefined =  henFeeds?.map(() => ({feedType: '', kgPerAnimal: 0}));
    fc.setValue(`stages.${index}.stageData.feed.henFeed.compoundFeeds`, updatedHenFeeds);
    const roosterFeeds = (stage.stageData as PoultryBreedingStageData).feed.roosterFeed.compoundFeeds;
    const updatedRoosterFeeds: BaselineFeed[] | undefined =  roosterFeeds?.map(() => ({feedType: '', kgPerAnimal: 0}));
    fc.setValue(`stages.${index}.stageData.feed.roosterFeed.compoundFeeds`, updatedRoosterFeeds);
  }

  const updateFeeds = (stage: PoultryStage, index: number) => {
    if (!('feed' in stage.stageData)) {
      return;
    }
    const feeds = (stage.stageData as PoultryGrowingStageData | PoultryLayingStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined =  feeds?.map(() => ({feedType: '', kgPerAnimal: 0}));
    fc.setValue(`stages.${index}.stageData.feed.compoundFeeds`, updatedFeeds);
  }

  const updateInputSources = (index: number) => {
    fc.setValue(`stages.${index}.stageData.input.internalSources`, []);
  }

  const handleDiscard = () => {
    getCompoundFeedsList(openDatabaseSelectionWaringDialog.defaultValue);
    fc.setValue(`info.databaseFoundation`, openDatabaseSelectionWaringDialog.defaultValue);
    closeDialog();
  };

  const handleConfirm = () => {
    closeDialog();
    getCompoundFeedsList(baselineDatabaseFoundation);
    const stages = fc.getValues('stages');
    stages.forEach((stage: PoultryStage, i: number) => {
      if (i === itemIndex) {
        if (stage.type === StageType.Breeding) updateBreedingFeeds(stage, i);
        else updateFeeds(stage, i);
        updateInputSources(i);
      }
    });
  };

  const handleChangeDatabaseFoundation = (event: ReactChangedType) => {
    if (event.stopPropagation) event.stopPropagation();
    const option = event.target.value as DatabaseFoundation | null;
    if (baselineDatabaseFoundation !== option) {
      setOpenDatabaseSelectionWaringDialog({open: true, selectedValue: option, defaultValue: defalutDatabaseFoundation});
    }
  };

  const handleSaveClick = async () => {
    const currFormValues = fc.getValues();
    fc.reset(currFormValues, { errors: true });
    const stages = currFormValues?.stages || [];
    stages.forEach((stage: PoultryStage, i: number) => {
      if (i !== itemIndex && initialDatabase.current !== baselineDatabaseFoundation) {
        if (stage.type === StageType.Breeding) updateBreedingFeeds(stage, i);
        else updateFeeds(stage, i);
        updateInputSources(i);
      }
    });
    if (handleSave) { 
      await fc.trigger(`stages.${itemIndex}.stageData.feed`); 
      handleSave('confirm');
    };
  };

  const handleResetClick = async () => {
    if (currResetValue.current) {
      const resetObject = {...fc.getValues()};
      const feedData = resetObject.stages[itemIndex]?.stageData as PoultryLayingStageData | PoultryGrowingStageData;
      if (feedData?.feed) {
        if (currResetValue.current?.feed) feedData.feed = currResetValue.current.feed;
        resetObject.info.databaseFoundation = currResetValue.current.databaseFoundation as unknown as CompoundFeedDatabase;
        fc.reset(resetObject, {errors: true});
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  useEffect(() => {
    if (formVisible) {
      const serializedData: PoultryFeed = fc.getValues(`stages.${itemIndex}.stageData.feed`);
      currResetValue.current = cloneDeep({feed: serializedData, databaseFoundation: baselineDatabaseFoundation});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  useEffect(() => {
    setCompoundFeedData(compoundFeeds);
    setSingleIngredientsData(singleIngredients);
  }, [compoundFeeds, singleIngredients]);
  
  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE'})}
      variant="ultrawide"
      introText={intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.FEED.DESCRIPTION'})}
    >
      {openDatabaseSelectionWaringDialog.open && (
        <DsmModal
          open={openDatabaseSelectionWaringDialog.open}
          icon="general/info-square"
          header={intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_HEADER'})}
          onDsmClosed={() => closeDialog()}
        >
          <p slot="content">
            {intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_BODY'})}
          </p>
          <>
            <DsmButtonV2
              slot="actions"
              onClick={handleDiscard}
              className={buttonClasses.secondaryButton}
            >
              {intl.formatMessage({id: 'GENERAL.CANCEL'})}
            </DsmButtonV2>
            <DsmButtonV2
              variant="secondary"
              slot="actions"
              onClick={handleConfirm}
            >
              {intl.formatMessage({id: 'GENERAL.CONFIRM'})}
            </DsmButtonV2>
          </>
        </DsmModal>
      )}
      <DsmGrid className={classes.dsmGridTwoColumn2To3}>
        <DsmFieldset style={{ width: '58%', marginTop: `var(--dsm-spacing-px-4)` }}>
          <ReactHookDsmSelect
            name="info.databaseFoundation"
            label={`${intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION'})}`}
            disabled={formType === FormType.View || formType === FormType.Edit}
            defaultValue={defalutDatabaseFoundation}
            options={getDatabaseOptions()}
            changeHandler={(e: ReactChangedType) => handleChangeDatabaseFoundation(e)}
            tooltip={intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP_POULTRY_DAIRY_BEEF_SWINE'})}
            required
          />
        </DsmFieldset>
      </DsmGrid>
      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
        <div style={{'color': '#E51F22', 'height': '14px', 'marginBottom': '10px', 'marginTop': '10px'}}>
        { get(fc.errors, `stages.${itemIndex}.stageData.feed.message`) &&  
          (<span>{ get(fc.errors, `stages.${itemIndex}.stageData.feed.message`) }</span>)
        }
        </div>
      </DsmGrid>
      <FeedsFormCommonDataPart
        formType={formType}
        formVisible={formVisible}
        itemIndex={itemIndex}
        fieldItemPrefix='feed'
        compoundFeeds={compoundFeedsData}
        singleIngredients={singleIngredientsData}
        origins={origins}
        keyCompoundFeeds='compoundFeeds'
        keySingleIngredients='singleIngredients'
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleSaveClick}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryFeedGrowingAndLayingDialog;
