import { FC, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';
import { CSSClassesList } from '../../../helpers/helperTypes';
import ReactHookDsmSelect from '../../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../../modules/Helpers/ReactHookDsmInput2';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { resolvePath } from '../../../../modules/Helpers/resolvePathFn';
import { CattleHousing, CattleHousingAndManure } from '../../../models/Baseline/CattleBaseline';

export type BeddingInputComponentProps = {
  beddingOptions: Array<{ text: string; value: string }>;
  isViewMode: boolean;
  beddingAmoutWeightUnit: string;
  stageIndex?: number;
  beddingAmountTooltipId?: string;
  beddingAmountLabelId?: string;
  beddingLabelId?: string;
  isIntervention?: boolean;
};

const BeddingInputComponent: FC<BeddingInputComponentProps> = ({
  stageIndex = 0,
  beddingOptions,
  isViewMode,
  beddingAmoutWeightUnit,
  beddingLabelId = 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING_TYPE',
  beddingAmountLabelId = 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT',
  beddingAmountTooltipId = 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT.TOOLTIP',
  isIntervention = false
}) => {
  type ReactChangedType = React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>;

  const intl = useIntl();
  const propName = isIntervention ? 'beddingSystemsAdditions' : 'beddingSystems';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.housing`;
  const formContext = useFormContext<CattleHousing | CattleHousingAndManure>();
  const { control } = formContext;
  const [,forceUpdate] = useState({})
  const {
    fields: beddingSystems,
    append: appendBedding,
    remove: removeBedding,
  } = useFieldArray({
    name: `${fieldItemPrefix}.${propName}`,
    control,
    keyName: 'keyId',
  });

  const addBedding = () => {
    appendBedding({
      beddingType: '',
      beddingAmount: '',
    });
  };

  const resetBeddingAmount = (index: number) => {
    formContext.setValue(
      `${fieldItemPrefix}.${propName}.${index}.beddingAmount`,
      '',
      { shouldValidate: true }
    );
  };

  const beddingsChanged = (e: ReactChangedType, index: number) => {
    if (!e.target.value) resetBeddingAmount(index);
    forceUpdate({})
  };

  const isEmptyBeddingSelected = (index: number) =>
    !formContext.getValues(
      `${fieldItemPrefix}.${propName}.${index}.beddingType`
    );

  const classes = processAndStageStylesV2() as CSSClassesList;

  const beddingSystemsError: FieldError | null = resolvePath(
    formContext.errors,
    `${fieldItemPrefix}.${propName}`,
    null
  ) as FieldError | null;

  const beefStyles = {
    container: { paddingTop: '0', width: '49%' },
    inputsContainer: { height: '60px' },
  };

  const componentStyle = beefStyles;

  return (
    <div
      style={{
        padding: '0px',
        ...componentStyle.container,
      }}
    >
      {!!beddingSystemsError && beddingSystemsError.type === 'min' && (
        <DsmGrid className={classes.dsmGridOneColumn}>
          <Alert severity="error">
            <AlertTitle>
              {intl.formatMessage({ id: 'GENERAL.ERROR' })}
            </AlertTitle>
            {beddingSystemsError.message}
          </Alert>
        </DsmGrid>
      )}

      {beddingSystems &&
        beddingSystems?.map((item, index) => (
          <DsmGrid
            key={item.keyId}
            className={classes.dsmGridThreeColumnTwoEqualLastSmall}
            style={{
              marginTop: index === 0 ? '0px' : '15px',
              marginBottom: '16px',  
              ...componentStyle.inputsContainer,
            }}
          >
            <ReactHookDsmSelect
              label={intl.formatMessage({
                id: beddingLabelId,
              })}
              name={`${fieldItemPrefix}.${propName}.${index}.beddingType`}
              options={beddingOptions}
              disabled={isViewMode}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.${propName}.${index}.beddingType`
              )}
              changeHandler={(e: ReactChangedType) => beddingsChanged(e, index)}
            />

            <ReactHookDsmInput
              label={intl.formatMessage({
                id: beddingAmountLabelId,
              })}
              name={`${fieldItemPrefix}.${propName}.${index}.beddingAmount`}
              adornment={beddingAmoutWeightUnit}
              type="number"
              disabled={isViewMode || isEmptyBeddingSelected(index)}
              required={!isEmptyBeddingSelected(index)}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.${propName}.${index}.beddingAmount`
              )}
              tooltip={
                beddingAmountTooltipId
                  ? intl.formatMessage({
                      id: beddingAmountTooltipId,
                    })
                  : ''
              }
            />

            {!isViewMode && (
              <DsmButton
                variant="text"
                iconOnly
                destructive
                onClick={() => removeBedding(index)}
                style={{
                  padding: '0px',
                  margin: '24px 0px 0px 0px',
                }}
              >
                <DsmIcon
                  slot="before"
                  name="general/trash-01"
                  style={{ margin: 0, padding: 0 }}
                />
              </DsmButton>
            )}
          </DsmGrid>
        ))}
      <DsmGrid className={classes.dsmGridOneColumn}>
        <DsmButton variant="text" onClick={addBedding} disabled={isViewMode}>
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_BEDDING',
          })}
        </DsmButton>
      </DsmGrid>
    </div>
  );
};

BeddingInputComponent.defaultProps = {
  beddingLabelId: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING_TYPE',
  beddingAmountLabelId: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT',
  beddingAmountTooltipId:
    'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT.TOOLTIP',
  stageIndex: 0,
};

export default BeddingInputComponent;
