import { useEffect, useRef, useState } from 'react';
import { DsmButton, DsmCheckbox, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Tooltip } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { SlaughterHouseInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { FacilityOutputs, ProcessingStageData as ProcessingStageDataBaseline } from '../../../../models/Facility/Processing';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { Currency } from '../../../../../../graphql/types';
import ProcessingControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/ProcessingControlledTextFieldWithMetrics';
import OutputDefinitionsDialog from '../../Baseline/Slaughterhouse_v2/OutputDefinitionsDialog';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { ProcessingOutput, ProcessingStageData } from '../../../../models/Intervention/Processing';
import { isPoultry } from '../../../../helpers/animals';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  height: '16px',
  width: '16px',
  display: 'inline-block',
};

interface SHInterventionOutputFormDialogProps extends SlaughterHouseInterventionFormDialogProps {
  currency: Currency | undefined;
}

const SHInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  currency,
  baseline,
}: SHInterventionOutputFormDialogProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<ProcessingOutput>();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.processingOutput`;
  const formContext = useFormContext();
  const isPoutryBaseline = isPoultry(baseline.animalType);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const [openPdfDialog, setOpenPdfDialog] = useState<boolean>(false);

  const processingOutputBaseline = (baseline?.stages?.at(stageIndex)?.stageData as ProcessingStageDataBaseline)?.processingOutput;
  const getBaselineValue = (type: string) => processingOutputBaseline[type.toString() as keyof typeof processingOutputBaseline];

  useEffect(() => {
    if (formVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currResetValue.current = { ...formContext.getValues(fieldItemPrefix) } as ProcessingOutput;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (resetObject.stages && (resetObject.stages.at(stageIndex)?.stageData as ProcessingStageData)?.processingOutput) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (resetObject.stages[stageIndex].stageData as ProcessingStageData).processingOutput = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel();
  };

  const handleOpenDefinitionsDialog = () => {
    setOpenPdfDialog(true)
  }

  const handleCloseDefinitionsDialog = () => {
    setOpenPdfDialog(false)
  }

  const getWeightAndPriceInputLabel = (val: FacilityOutputs) => {
    if (val === FacilityOutputs.cat3HidesAndSkins && isPoutryBaseline) {
      return intl.formatMessage({
        id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.CAT3_FEATHERS_AND_SKINS`,
      });
    }
    return intl.formatMessage({
      id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.${val}`,
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {openPdfDialog ?
        <OutputDefinitionsDialog
          open
          handleClose={handleCloseDefinitionsDialog}
          complement={{index: 6, column: 1, idComplement: 'POULTRY'}}
        />
        :
        <DialogContainer
          formVisible={formVisible}
          handleClose={handleResetClick}
          iconCode="arrows/arrow-circle-broken-right"
          variant="wide"
          formTitle={intl.formatMessage({
            id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
          })}
          datasetType="intervention"
          introText={intl.formatMessage({ id: "SUSTELL.STAGES.PROCESSING.OUTPUT.DESCRIPTION" })}
        >
          <DsmButton variant="text" onClick={handleOpenDefinitionsDialog} style={{ marginBottom: '16px' }}>
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({
              id: 'DATABASE_FOUNDATION_DEFINITIONS',
            })}
          </DsmButton>
          <DsmGrid className={classes.dsmGridThreeColumnLastSmall}>
            <h4>{intl.formatMessage({ id: 'GENERAL.WEIGHT' })}</h4>
            <h4>{intl.formatMessage({ id: 'GENERAL.PRICE' })}</h4>
            <div>
              <b>
                {intl.formatMessage(
                  { id: "SUSTELL.STAGES.PROCESSING.ANIMALS.SELECT_FOOTPRINT_TO_DISPLAY" }, {
                  tooltip:
                    <Tooltip title="Hint text">
                      <DsmIcon name='general/help-circle' style={inlineTooltipClass} />
                    </Tooltip>
                })}
              </b>
            </div>
            {Object.entries(FacilityOutputs).map(([key, val]) => (
              <>
                <ProcessingControlledTextFieldWithMetrics
                  name={`${fieldItemPrefix}.${key}.weight`}
                  label={getWeightAndPriceInputLabel(val)}
                  tooltip={getWeightAndPriceInputLabel(val)}
                  metricUnit={intl.formatMessage({
                    id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING.AMOUNT.UNIT',
                  })}
                  baseline={getBaselineValue(key)?.weight}
                  disabled={formType === FormType.View}
                  clearDefaultStyle
                />

                <ProcessingControlledTextFieldWithMetrics
                  name={`${fieldItemPrefix}.${key}.price`}
                  label={getWeightAndPriceInputLabel(val)}
                  tooltip={getWeightAndPriceInputLabel(val)}
                  metricUnit={`${currency || ''}/${barnOutputMassUnit}`}
                  baseline={getBaselineValue(key)?.price}
                  disabled={formType === FormType.View}
                  clearDefaultStyle
                />
                <DsmCheckbox
                  style={{ marginTop: '28px', marginLeft: 'auto', marginRight: 'auto' }}
                  name={`${fieldItemPrefix}.${key}.calculate`}
                  checked={getBaselineValue(key)?.calculate}
                  disabled
                />
              </>
            ))}
          </DsmGrid>

          <DsmButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </DialogContainer>
      }
    </>
  );
};

export default SHInterventionOutputFormDialog;
