import { IntlShape } from "react-intl";
import * as Yup from 'yup';
import { interventionValidationResourseUsagePartV2 } from './interventionValidationSchemaGeneralPart';
import { feedItemsTest, feedItemsTestWithOrigin, numericOptionalWithGreaterThanMin, numericRequiredWithGreaterThanMin, numericRequiredWithMin } from "../../Baseline/validationSchema/validationObjectBuilderFunctions";
import { interventionFieldWithMin } from "./interventionValidationObjectBuilderFunctions";

const stageDataPartShrimp = (intl: IntlShape) =>
  Yup.object({
    stages: Yup.array().of(
      Yup.object({
        stageData: Yup.object({
          input: Yup.object({
            stageDuration: interventionFieldWithMin(intl, 'stageDuration', 0),
            emptyPeriod: interventionFieldWithMin(intl, 'emptyPeriod', 0),
            totalPondArea: interventionFieldWithMin(intl, 'totalPondArea', 0),
            totalWeightAtStartDate: interventionFieldWithMin(intl, 'totalWeightAtStartDate', 0),
            totalWeightAtEndDate: interventionFieldWithMin(intl, 'totalWeightAtEndDate', 0),
            priceAtStartDate: interventionFieldWithMin(intl, 'priceAtStartDate', 0),
            priceAtEndDate: interventionFieldWithMin(intl, 'priceAtEndDate', 0),
            internalSourcesAdditions: Yup.array().of(
              Yup.object({
                comingFromFarm: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
                comingFromStage: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
                outputName: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
                totalWeight: numericRequiredWithGreaterThanMin(intl, 0).required(),
              })
            ),
            externalSourcesAdditions: Yup.array().of(
              Yup.object({
                totalWeight: numericRequiredWithGreaterThanMin(intl, 0).required(),
              })
            ),
          }).default({}),

          feed: Yup.object({
            compoundFeeds: Yup.array().of(
              Yup.object(
                {
                  kgPerAnimal: interventionFieldWithMin(intl, 'kgPerAnimal', 0),
                }
              )
            ),
            singleIngredients: Yup.array().of(
              Yup.object(
                {
                  kgPerAnimal: interventionFieldWithMin(intl, 'kgPerAnimal', 0),
                }
              )
            ),
            naturalFeed: Yup.array().of(
              Yup.object(
                {
                  quantity: interventionFieldWithMin(intl, 'quantity', 0),
                  crudeProteinContent: interventionFieldWithMin(intl, 'crudeProteinContent', 0),
                }
              )
            ),
            compoundFeedsAdditions: feedItemsTest(intl),
            singleIngredientsAdditions: feedItemsTestWithOrigin(intl, 0),
            naturalFeedAdditions: Yup.array().of(
              Yup.object({
                quantity: Yup.number()
                  .transform((changed, original) =>
                    original === "" ? undefined : changed
                  )
                  .when("type", {
                    is: (value: string) =>
                      value !== undefined && value !== "",
                    then: Yup.number()
                      .transform((changed, original) =>
                        original === "" ? undefined : changed
                      )
                      .typeError(
                        intl.formatMessage({ id: "VALIDATION.NUMERIC.INPUT" })
                      )
                      .min(
                        0,
                        intl.formatMessage(
                          { id: "VALIDATION.FIELD.MIN_VALUE" },
                          { value: 0 }
                        )
                      )
                      .required(
                        intl.formatMessage({ id: "VALIDATION.NUMERIC.INPUT" })
                      ),
                  }),
                crudeProteinContent: Yup.number()
                  .transform((changed, original) =>
                    original === "" ? undefined : changed
                  )
                  .typeError(
                    intl.formatMessage({ id: "VALIDATION.NUMERIC.INPUT" })
                  )
                  .max(
                    100,
                    intl.formatMessage(
                      { id: "VALIDATION.FIELD.MAX_VALUE" },
                      { value: 100 }
                    )
                  ),
              })
            ),
          }).default({}),

          operations: Yup.object({
            iceAmount: interventionFieldWithMin(intl, 'iceAmount', 0),
            fertilizerTypes: Yup.array().of(
              Yup.object({
                amount: interventionFieldWithMin(intl, 'amount', 0),
              }),
            ),
            materialTypes: Yup.array().of(
              Yup.object({
                amount: interventionFieldWithMin(intl, 'amount', 0),
                distanceTraveled: interventionFieldWithMin(intl, 'distanceTraveled', 0),
                lifetimeOfMaterial: interventionFieldWithMin(intl, 'lifetimeOfMaterial', 0),
              }),
            ),
            chemicalTypes: Yup.array().of(
              Yup.object({
                amount: interventionFieldWithMin(intl, 'amount', 0),
              }),
            ),
            fertilizerTypeAdditions: Yup.array().of(
              Yup.object({
                type: Yup.string(),
                amount: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
              })
            ),
            materialTypeAdditions: Yup.array().of(
              Yup.object({
                type: Yup.string(),
                amount: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
                lifetimeOfMaterial: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
              })
            ),
            chemicalTypeAdditions: Yup.array().of(
              Yup.object({
                type: Yup.string(),
                amount: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
              })
            ),
            resourceUse: Yup.object({
              electricityUse: interventionFieldWithMin(intl, 'electricityUse', 0),
              gasUse: interventionFieldWithMin(intl, 'gasUse', 0),
              nitrogenConcentrationIn: interventionFieldWithMin(intl, 'nitrogenConcentrationIn', 0),
              nitrogenConcentrationOut: interventionFieldWithMin(intl, 'nitrogenConcentrationOut', 0),
              waterEnteringPondIn: interventionFieldWithMin(intl, 'waterEnteringPondIn', 0),
              waterEnteringPondOut: interventionFieldWithMin(intl, 'waterEnteringPondOut', 0),
              waterUse: interventionFieldWithMin(intl, 'waterUse', 0),
              selfGeneratedRenewables: Yup.array().of(
                Yup.object({
                  value: interventionFieldWithMin(intl, 'value', 0),
                })
              ),
              energyTypes: Yup.array().of(
                Yup.object({
                  value: interventionFieldWithMin(intl, 'value', 0),
                })
              ),
              selfGeneratedRenewablesAdditions: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(),
                  value: Yup.number().when('type', {
                    is: (val: string) => val,
                    then: () => numericRequiredWithGreaterThanMin(intl, 0),
                    otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                  }),
                })
              ),
              energyTypesAdditions: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(),
                  value: Yup.number().when('type', {
                    is: (val: string) => val,
                    then: () => numericRequiredWithGreaterThanMin(intl, 0),
                    otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                  }),
                })
              )
            })
          }).default({}),

          output: Yup.object({
            outputs: Yup.array().of(
              Yup.object({
                price: interventionFieldWithMin(intl, 'price', 0),
                totalWeight: interventionFieldWithMin(intl, 'totalWeight', 0),
                proteinContent: interventionFieldWithMin(intl, 'proteinContent', 0),
              })
            ),
            outputAdditions: Yup.array().of(
              Yup.object({
                name: Yup.string()
                  .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
                  .min(
                    3,
                    intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
                  ),
                totalWeight: Yup.number().when('name', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
                soldExternally: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
              }).default({})
            )
          })
        }).default({})
      })
    ).required().min(1, 'At least one stage data is needed.'),
  });

const assembleValidationSchemaSustellShrimp = (
  baseSchema: any,
  confObj: { intl: IntlShape; userUOM?: any }
) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(
      Yup.object({
        resourceUse: interventionValidationResourseUsagePartV2(confObj),
      })
    )
    .concat(stageDataPartShrimp(confObj.intl));

  return combinedSchema;
};

export default assembleValidationSchemaSustellShrimp;
