import { useEffect } from 'react';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../../sustell_15/components/FarmFlow/common';
import NaturalFeedInput from './NaturalFeedInput';
import lookupValues from '../../../sustell_15/helpers/lookupValues';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';

const SustellNaturalFeeds = (props) => {
  const {
    label,
    availableFeedItems,
    availableOrigins,
    subFieldName,
    formState,
    updateTotalFeedIntake,
    animalType,
    fieldItemPrefix,
    fieldsAppenRemoveObject = undefined,
    allowNewIngredients = true,
  } = props;

  const { control, setValue, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `${fieldItemPrefix}.${subFieldName}`,
    control,
    keyName: 'keyId',
  });
  const classes = processAndStageStylesV2();

  const getFields = () => {
    if (!fieldsAppenRemoveObject?.fields) {
      return fields;
    }
    return fieldsAppenRemoveObject?.fields;
  };

  const getAppend = () => {
    if (!fieldsAppenRemoveObject?.append) {
      return append;
    }
    return fieldsAppenRemoveObject?.append;
  };

  const getRemove = () => {
    if (!fieldsAppenRemoveObject?.remove) {
      return remove;
    }
    return fieldsAppenRemoveObject?.remove;
  };
  const intl = useIntl();

  useEffect(() => {
    if (updateTotalFeedIntake) updateTotalFeedIntake();
  }, [updateTotalFeedIntake]);

  const addItem = () => {
    getAppend()({
      type: '',
      quantity: '',
      origin: '',
      crudeProteinContent: undefined,
    });
  };

  const removeItem = (selIndex) => {
    getRemove()(selIndex);
  };

  const availableFeedItemsFormated = () => {
    const formatedValues = [];
    availableFeedItems.forEach((availableFeedItem) => {
      const formatedText = availableFeedItem.value.charAt(0).toUpperCase() +
      availableFeedItem.value.slice(1).toLowerCase();

      formatedValues.push({
        value: availableFeedItem.value,
        text: formatedText.replace('_', ' ').replace('_', ' - '),
      });
    });

    formatedValues.sort((optionA, optionB) => 
      optionA.value.localeCompare(optionB.value)
    );

    return formatedValues;
  };

  const updatedFields = useWatch({ control, name: `${fieldItemPrefix}.${subFieldName}` });

  const resetToDefaultValues = () => {
    updatedFields?.forEach((item, index) => {
      const proteinContentDefault =
        lookupValues.naturalFeedProteinContentDefault[item.type];
      setValue(
        `${fieldItemPrefix}.${subFieldName}[${index}].crudeProteinContent`,
        proteinContentDefault
      );
      clearErrors(
        `${fieldItemPrefix}.${subFieldName}[${index}].crudeProteinContent`
      );
    });
  };

  return (
    <>
      <div>
        {getFields().map(
          (item, index) =>
            !(formState === 'view' && !item.type) && (
              <NaturalFeedInput
                key={item.keyId}
                label={label}
                availableFeedItems={availableFeedItemsFormated()}
                availableOrigins={availableOrigins}
                index={index}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName={subFieldName}
                feedTypeValue={item.feedType}
                updateTotalFeedIntake={updateTotalFeedIntake}
                formState={formState}
                animalType={animalType}
                removeHandler={() => removeItem(index)}
              />
            )
        )}
        {allowNewIngredients && 
          <DsmButton
            variant="text"
            onClick={addItem}
            disabled={formState === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            Add another {label.toLowerCase()}
          </DsmButton>
        }
      </div>
      <div className={classes.resetButtonContainer}>
        <DsmButton
          variant="text"
          onClick={() => resetToDefaultValues()}
          disabled={formState === FormType.View}
          className="mt-3"
        >
          <DsmIcon slot="before" name="arrows/refresh-cw-01" />
          <u>
            {intl.formatMessage({
              id: 'BASELINE.FORM.FEED.SHRIMP.RESET_DEFAULT_VALUES',
            })}
          </u>
        </DsmButton>
        <Tooltip
          title={intl.formatMessage({
            id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
          })}
          placement="left"
          className='ml-3'
          style={{transform: 'translateY(85%)'}}
        >
          <DsmIcon
            name="general/help-circle"
            className={classes.inlineTooltipClass}
            title=""
          />
        </Tooltip>
      </div>
    </>
  );
};
export default SustellNaturalFeeds;
