import { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';

import SustellNewRationInput from './NewRationInput_v2.2';
import { FormType } from '../../../sustell_15/components/FarmFlow/common';

const SustellRationFeeds = (props) => {
  const {
    label,
    availableFeedItems,
    subFieldName,
    formState,
    updateTotalFeedIntake,
    animalType,
    stageType,
    fieldItemPrefix,
    fieldsAppenRemoveObject = undefined,
    allowNewFeeds = true,
  } = props;

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `${fieldItemPrefix}.${subFieldName}`,
    control,
    keyName: 'keyId',
  });

  const getFields = () => {
    if (!fieldsAppenRemoveObject?.fields) {
      return fields;
    }
    return fieldsAppenRemoveObject?.fields;
  };

  const getAppend = () => {
    if (!fieldsAppenRemoveObject?.append) {
      return append;
    }
    return fieldsAppenRemoveObject?.append;
  };

  const getRemove = () => {
    if (!fieldsAppenRemoveObject?.remove) {
      return remove;
    }
    return fieldsAppenRemoveObject?.remove;
  };

  useEffect(() => {
    if (updateTotalFeedIntake) updateTotalFeedIntake();
  }, [updateTotalFeedIntake]);

  const addItem = () => {
    // console.log('APPEND')
    getAppend()({ feedType: '', kgPerAnimal: '' });
  };

  const removeItem = (selIndex) => {
    // console.log(`REMOVE ${fieldItemPrefix}.${subFieldName}[${selIndex}]`)
    getRemove()(selIndex);
  };

  return (
    <div>
      {getFields().map(
        (item, index) =>
          !(formState === 'view' && !item.feedType) && (
            <SustellNewRationInput
              key={item.keyId}
              label={label}
              availableFeedItems={availableFeedItems}
              index={index}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName={subFieldName}
              feedTypeValue={item.feedType}
              updateTotalFeedIntake={updateTotalFeedIntake}
              formState={formState}
              animalType={animalType}
              stageType={stageType}
              removeHandler={() => removeItem(index)}
            />
          )
      )}
      {allowNewFeeds && 
        <DsmButton
          variant="text"
          onClick={addItem}
          disabled={formState === FormType.View}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          Add another {label.toLowerCase()}
        </DsmButton>
      }
    </div>
  );
};
export default SustellRationFeeds;
