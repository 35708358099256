/* eslint-disable no-param-reassign */
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { FormatDateOptions, IntlShape } from 'react-intl';
import {
  defaultUnits,
  explicitConvertValue,
} from '../../utils/unit-utils';
import { UserProfilePrefs } from '../../../modules/Helpers/UserProfilePrefs';
import lookupValues from '../../helpers/lookupValues';
import { translateKeyMapping } from '../../utils/string-utils';
import { FootprintCategoryModel, FootprintModel } from '../../models/Footprint';
import { FootprintCategoryName, getCompartmentsForImpactCategory } from '../../models/Footprint/FootprintTypes';
import { DatasetOption } from './useFootprintCompare';
import { dateOnlyDisplayOptionsShort } from '../../utils/datetime-utils';
import { FootPrintType } from '../../../../graphql/types';

function addCategorySheet(intl: IntlShape, workbook: Excel.Workbook, category: FootprintCategoryName, data: ViewData, convRatio: number) {
  const translatedCategoryName = intl.formatMessage({
    id: `REPORT.FOOTPRINTS.${translateKeyMapping(category)}`,
    defaultMessage: category,
  });
  const sheet = workbook.addWorksheet(translatedCategoryName);

  sheet.getColumn(1).width = 40;
  sheet.getColumn(2).width = 15;
  sheet.getColumn(3).width = 15;
  sheet.getColumn(4).width = 15;
  sheet.getColumn(5).width = 15;

  // header
  sheet.getCell('A1').value = translatedCategoryName;
  sheet.getCell('A1').style = { font: { bold: true } };
  sheet.getCell('B1').value = data.footprint.name;
  sheet.mergeCells('B1:C1');
  sheet.getCell('B1').style = { font: { bold: true }, alignment: { horizontal: 'center' } };

  if (data.comparison) {
    sheet.getCell('D1').value = data.comparison?.name;
    sheet.mergeCells('D1:E1');
    sheet.getCell('D1').style = { font: { bold: true }, alignment: { horizontal: 'center' } };
    sheet.getCell('G1').value = intl.formatMessage({id: "REPORT.FOOTPRINTS.CHANGE_DIFFERENCE"});
    sheet.getColumn(7).width = 35;
    sheet.getCell('G1').style = { font: { bold: true }, alignment: { horizontal: 'center' } };
  }
  let totalBaseline = 0;
  let totalComparison = 0;

  // data rows
  data.impactComponents[category].forEach((impactData) => {
    const rowValues = []

    const impactAbsolute = convRatio !== 1 && impactData.impactAbsolute
    ? impactData.impactAbsolute * convRatio
    : impactData.impactAbsolute;

    const comparedImpactAbsolute = convRatio !== 1 && impactData.comparedImpactAbsolute
    ? impactData.comparedImpactAbsolute * convRatio
    : impactData.comparedImpactAbsolute;
    
    rowValues.push(`${impactData.label} (${impactData.unit})`)
    rowValues.push(impactAbsolute)
    rowValues.push(impactData.impact ? impactData.impact / 100 : 0)    
    totalBaseline += (impactAbsolute || 0);

    if (data.comparison) {
      rowValues.push(comparedImpactAbsolute)
      rowValues.push(impactData.comparedImpact ? impactData.comparedImpact / 100 : 0)
      totalComparison += (comparedImpactAbsolute || 0);
      if (comparedImpactAbsolute && impactAbsolute) {
        const diffPercentage = (comparedImpactAbsolute -impactAbsolute) / impactAbsolute;
        rowValues.push('');
        rowValues.push(diffPercentage);
      }
    }
    
    const row = sheet.addRow(rowValues);
    row.getCell(3).numFmt = '0.00%';
    row.getCell(5).numFmt = '0.00%';
    row.getCell(7).numFmt = '0.00%';
  });
  let totalDiff = 0;

  if (totalComparison) {
    totalDiff = (totalComparison - totalBaseline) / totalBaseline;
  }

  const rowValues = []
  rowValues.push(intl.formatMessage({id: "GENERAL.TOTAL"}));
  rowValues.push(totalBaseline);
  rowValues.push('');
  rowValues.push(totalComparison || '');
  rowValues.push('');
  rowValues.push('');
  rowValues.push(totalDiff || '');

  sheet.addRow([]);
  const row = sheet.addRow(rowValues);
  row.getCell(3).numFmt = '0.00%';
  row.getCell(5).numFmt = '0.00%';
  row.getCell(7).numFmt = '0.00%';

  return sheet;
}

const addDatasetSummary = (
  sheet: Excel.Worksheet,
  intl: IntlShape,
  data: ViewData,
  dataset: DatasetOption | null | undefined,
  firstColumn = 'A',
  secondColumn = 'B',
  isComparison = false,
) => {

  if (!isComparison) {
    sheet.getCell(`${firstColumn}1`).value = intl.formatMessage({
      id: "SUSTELL.DASHBOARD.EXPORT.DATE_OF",
    });
    sheet.getCell(`${secondColumn}1`).value =intl.formatDate (new Date(), dateOnlyDisplayOptionsShort as FormatDateOptions)
  }
  
  sheet.getCell(`${firstColumn}2`).value = intl.formatMessage({
    id: "GENERAL.NAME",
  });
  sheet.getCell(`${secondColumn}2`).value = dataset?.label;
  
  let type = intl.formatMessage({id: "SUSTELL.STAGE.FEED.COMPOUND_FEED"});
  if (data.footprint.type !== FootPrintType.CompoundFeed) {
    type = intl.formatMessage({id: "FARM"});
  }

  sheet.getCell(`${firstColumn}3`).value = intl.formatMessage({
    id: "GENERAL.TYPE",
  });
  sheet.getCell(`${secondColumn}3`).value = type;

  sheet.getCell(`${firstColumn}4`).value = intl.formatMessage({
    id: "GENERAL.YEAR",
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  sheet.getCell(`${secondColumn}4`).value = dataset?.year || new Date().getFullYear();

  if (data.footprint.type !== FootPrintType.CompoundFeed) {
    sheet.getCell(`${firstColumn}5`).value = intl.formatMessage({
      id: "BASELINE.FORM.FIELD.START_DATE",
    });
    sheet.getCell(`${secondColumn}5`).value = dataset?.startDate ? intl.formatDate (dataset.startDate, dateOnlyDisplayOptionsShort as FormatDateOptions) : '';

    sheet.getCell(`${firstColumn}6`).value = intl.formatMessage({
      id: "BASELINE.FORM.FIELD.END_DATE",
    });
    sheet.getCell(`${secondColumn}6`).value = dataset?.endDate ? intl.formatDate (dataset.endDate, dateOnlyDisplayOptionsShort as FormatDateOptions) : '';

    if (dataset?.stageName) {
      sheet.getCell(`${firstColumn}7`).value = intl.formatMessage({
        id: "REPORT.FOOTPRINTS.STAGE_SELECTION",
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      sheet.getCell(`${secondColumn}7`).value = dataset.stageName;
    }
  }
};

const addSummarySheet = (
  intl: IntlShape,
  workbook: Excel.Workbook,
  data: ViewData,
  convRatio: number,
  userUnitEnvImpactPer: string,
  baseline: DatasetOption | null | undefined,
  comparison: DatasetOption | null | undefined
) => {
  const sheet = workbook.addWorksheet(
    intl.formatMessage({ id: 'GENERAL.SUMMARY' })
  );

  // Add baseline summary
  addDatasetSummary(sheet, intl, data, baseline);

  // If exist also add for comparison
  if (comparison) {
    addDatasetSummary(sheet, intl, data, comparison, 'D', 'E', true);
  }

  const startRow = 9;

  sheet.getRow(startRow).font = { bold: true };
  sheet.getCell(`A${startRow}`).value = intl.formatMessage({
    id: 'REPORT.FOOTPRINTS.IMPACT_CATEGORY',
  });
  sheet.getCell(`B${startRow}`).value = intl.formatMessage({id: "REPORT.FOOTPRINTS.EXPORT_TITLE"});

  if (data?.comparison) {
    // sheet.getCell(`C${startRow}`).value = data.comparison.name;
    sheet.mergeCells(`B${startRow}:C${startRow}`);
    sheet.getCell(`B${startRow}`).style = { font: { bold: true }, alignment: { horizontal: 'center' } };
    sheet.getCell(`D${startRow}`).value = intl.formatMessage(
      { id: 'REPORT.FOOTPRINTS.UNITS' },
      { userUnits: userUnitEnvImpactPer }
    );
    sheet.getCell(`E${startRow}`).value = intl.formatMessage({
      id: 'REPORT.FOOTPRINTS.CHANGE',
    });
  } else {
    sheet.getCell(`C${startRow}`).value = intl.formatMessage(
      { id: 'REPORT.FOOTPRINTS.UNITS' },
      { userUnits: userUnitEnvImpactPer }
    );
  }

  data.impactCategories.forEach((category) => {
    const rowValues = []
    rowValues.push(intl.formatMessage({
      id: `REPORT.FOOTPRINTS.${translateKeyMapping(category.category_name)}`,
      defaultMessage: category.category_name,
    }))
    rowValues.push(convRatio !== 1 && category.value
      ? category.value * convRatio
      : category.value)
    
    if (data?.comparison) {
      rowValues.push(convRatio !== 1 && category.comparedValue
        ? category.comparedValue * convRatio
        : category.comparedValue)
      rowValues.push(category.unit)
      rowValues.push(category.diff)
    } else {
      rowValues.push(category.unit)
    }
    const row = sheet.addRow(rowValues);
    row.getCell(5).numFmt = '0.00';
  });

  sheet.getColumn(1).width = 40;
  sheet.getColumn(2).width = 15;
  sheet.getColumn(3).width = 15;
  if (data?.comparison) {
    sheet.getColumn(4).width = 15;
    sheet.getColumn(5).width = 15;
  }

  return sheet;
};

const sortOrder = (category: string) => {
  if (category === 'Air') return 1;
  if (category === 'Land') return 2;
  if (category === 'Human') return 3;
  if (category === 'Water') return 4;
  return 0;
};

const sortComparator = (row1: ImpactCategory, row2: ImpactCategory) => {
  const a = row1.compartments ? sortOrder(row1.compartments[0]) : 0;
  const b = row2.compartments ? sortOrder(row2.compartments[0]) : 0;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export default async function FootprintExcelExport(intl: IntlShape, data: ViewData, baseline: DatasetOption | null | undefined, comparison: DatasetOption | null | undefined): Promise<void> {
  // console.log("EXCEL DATA:",data);
  const workbook = new Excel.Workbook();
  const { convRatio, userUnitEnvImpactPer = 'kg' } = data.conversionSetup;

  // summary
  addSummarySheet(intl, workbook, data, convRatio, userUnitEnvImpactPer, baseline, comparison);

  // create category sheets as they are sorted
  data.impactCategories.forEach((category) =>
    addCategorySheet(intl, workbook, category.category_name, data, convRatio)
  );

  await workbook.xlsx.writeBuffer().then((resultData) => {
    const blob = new Blob([resultData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'footprint.xlsx');
  });
}

type ImpactCategory = {
  category_name: string,
  compartments: string[],
  value?: number,
  comparedValue: number | null,
  diff: number | null,
  unit: string | null
}

type ConversionSetup = {
  convRatio: number,
  defaultUnitEnvImpactPer: string,
  userUnitEnvImpactPer: string,
}

type ViewData = {
  footprint: FootprintModel,
  isComparison: boolean,
  impactCategories: ImpactCategory[],
  impactComponents: { [key in FootprintCategoryName] : ImpactComponentData[]},
  selectedFootprint?: FootprintModel | null,
  selectedComparison?: FootprintModel | null,
  comparison?: FootprintModel | null,
  conversionSetup: ConversionSetup
}

type ImpactComponentData = {
  label: string,
  impact?: number | null,
  impactAbsolute?: number | null,
  unit: string,
  comparedImpact?: number | null,
  comparedImpactAbsolute?: number | null,
}

const prepExportData = (footprint: FootprintModel): ViewData => {
  // TODO typescript depend on UserProfilePrefs.js
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const userUOM = userProfile.getUserUnitPrefs();
  const convRatio =
    !userUOM ||
    (userUOM && userUOM.unitEnvImpactPer === defaultUnits.unitEnvImpactPer)
      ? 1
      : explicitConvertValue(
          1,
          defaultUnits.unitEnvImpactPer,
          userUOM.unitEnvImpactPer,
        ) || 1;

  const viewData: ViewData = {
    footprint,
    isComparison: !!footprint.comparison,
    impactCategories: [],
    impactComponents: {} as { [key in FootprintCategoryName] : ImpactComponentData[]},
    selectedFootprint: null,
    selectedComparison: null,
    comparison: null,
    conversionSetup: {} as ConversionSetup
  };

  if (footprint == null) {
    return viewData;
  }
  
  const selectedFootprint = footprint;

  viewData.selectedFootprint = selectedFootprint;
  let selectedComparison = null;

  if (viewData.isComparison) {
    selectedComparison = footprint.comparison;

    viewData.selectedComparison = selectedComparison;
    viewData.comparison = footprint.comparison;
  }

  const impactCategoriesMapping = lookupValues.impactCategoriesMapping.all;
  const footprintCategories = selectedFootprint.unFilteredCategories; 

  footprintCategories.forEach((category) => {
    let comparedCategory: (FootprintCategoryModel | null | undefined) = null;
    if (viewData.isComparison) {
      comparedCategory = category.comparison;
    }

    if (category.key in impactCategoriesMapping) {
      let impactCategoryHasData = false;

      category.componentsUnfiltered.forEach((component) => {
        if (component.amount === 0.0) {
          return;
        }

        if (category.key in viewData.impactComponents === false)
          viewData.impactComponents[category.key] = [];

        let comparedImpactComponent = null;
        if (viewData.isComparison && comparedCategory) {
          comparedImpactComponent = component.comparison;
        }

        const impactComponentData: ImpactComponentData = {
          label: component.name,
          impact: component.percentage,
          impactAbsolute: component.amount,
          unit: category.unit,
          comparedImpact: null,
          comparedImpactAbsolute: null,
        };
        if (viewData.isComparison) {
          if (comparedImpactComponent) {
            impactComponentData.comparedImpact =
              comparedImpactComponent.percentage;
            impactComponentData.comparedImpactAbsolute =
              comparedImpactComponent.amount;
          } else {
            impactComponentData.comparedImpact = null;
          }
        }

        viewData.impactComponents[category.key].push(impactComponentData);

        // We found a component for this impact category
        impactCategoryHasData = true;
      });

      if (viewData.isComparison && comparedCategory) {
        const impactLabels = category.components.map((el) => el.label);
        const unmatchedComparedImpacts = comparedCategory.components.filter(
          (el) => !impactLabels.includes(el.label)
        );
        unmatchedComparedImpacts.forEach((impactComponent) => {
          if (impactComponent.percentage === 0.0) {
            return;
          }
          if (!(category.key in viewData.impactComponents))
            viewData.impactComponents[category.key] = [];

          const impactComponentData = {
            label: impactComponent.label,
            impact: null,
            comparedImpact: impactComponent.percentage,
            comparedImpactAbsolute: impactComponent.amount,
            unit: category.unit,
          };

          viewData.impactComponents[category.key].push(impactComponentData);
          impactCategoryHasData = true;
        });
      }

      if (impactCategoryHasData) {
        viewData.impactCategories.push({
          category_name: category.key,
          compartments: getCompartmentsForImpactCategory(category.key),
          value: category.value,
          comparedValue:
            viewData.isComparison && comparedCategory?.value
              ? comparedCategory?.value
              : null,
          diff:
            viewData.isComparison && comparedCategory?.value
              ? category.comparisonChangePercentage
              : null,
          unit: category.unit,
        });
      }
    }

    // Sort components on impact descending
    viewData.impactComponents[category.key].sort((a, b) => {
      if (a.impact && b.impact && a.impact > b.impact) {
        return -1;
      }
      if (b.impact && a.impact && b.impact > a.impact) {
        return 1;
      }
      return 0;
    });
  });

  viewData.impactCategories.sort(sortComparator);
  // this conversionSetup should later be added to return value from prepView when this function is removed
  viewData.conversionSetup = {
    convRatio,
    defaultUnitEnvImpactPer: defaultUnits.unitEnvImpactPer,
    userUnitEnvImpactPer: userUOM.unitEnvImpactPer,
  };
  return viewData;
};

export const exportToExcel = async (intl: IntlShape, footprint: FootprintModel, baseline: DatasetOption | null | undefined, comparison: DatasetOption | null | undefined) => {
  const exportData = prepExportData(footprint);
  // If export feed footprint with hidden components, only make Summary sheet
  await FootprintExcelExport(intl, exportData, baseline, comparison);
};
