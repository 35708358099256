import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import {
    availableMmsTypeForMonthlyStoragePeriods,
    availableMmsTypeForOverOrUnderStoragePeriod,
} from "../../../../models/Baseline/DairyV2Baseline";
import {
    CattleHousing,
    CattleManureManagementSystemType,
    ManurePhase,
    StoragePeriod,
} from "../../../../models/Baseline/CattleBaseline";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { FormType } from "../../common";
import { CattleHousingAndManure } from "../../../../models/Intervention/CattleIntervention";
import RowSelectWithMetricsV2 from "../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2";

type DairyV2InterventionMMSComponentProps = {
    stageIndex?: number;
    isViewMode?: boolean;
    mmsOptions: Array<{ text: string; value: string }>;
    storageOptions: Array<{ text: string; value: string }>;
    openDescriptionDialog: () => void;
    isDairyCowStage: boolean;
    fieldItemPrefix: string;
    baseline: CattleHousingAndManure;
    formType: FormType;
};

type MMSInput = {
    mmsType: string;
    storagePeriod: string;
    share: string;
    id: string;
};

const DairyV2InterventionMMSComponent: FC<DairyV2InterventionMMSComponentProps> = ({
    formType = FormType.Add,
    stageIndex = 0,
    isViewMode = false,
    mmsOptions,
    storageOptions,
    openDescriptionDialog,
    isDairyCowStage,
    fieldItemPrefix,
    baseline,
}) => {
    const [selectedMmsTypes, setSelectedMmsTypes] = useState<{
        index: number;
        mmsType: CattleManureManagementSystemType;
    }>();
    const [availableStorageOptions, setAvailableStorageOptions] = useState<{
        [key: string | number]: Array<{ text: string; value: string }>;
    }>({});
    const intl = useIntl();
    const classes = processAndStageStylesV2() as CSSClassesList;
    const baseItemPrefix = `${fieldItemPrefix}.manureSystems`;
    const mmsInputsStyle = { height: "60px" };
    const fc = useFormContext<CattleHousing>();

    const shouldDisplayShare = () => {
            const displayShare =
            baseline.manureSystems.length > 0 ||
            baseline.manureSystemsAdditions.length > 0;
            
            return displayShare;
    };

    const isMmsTypeAvailableForMonthlyRanges = (
        selectedMmsTypes: CattleManureManagementSystemType
    ) =>
        availableMmsTypeForMonthlyStoragePeriods.some(
            (mmsType) => mmsType === selectedMmsTypes
        );

    const isMmsTypeAvailableForOverOrUnder = (
        selectedMmsTypes: CattleManureManagementSystemType
    ) =>
        availableMmsTypeForOverOrUnderStoragePeriod.some(
            (mmsType) => mmsType === selectedMmsTypes
        );

    const isStorageFieldRequired = (
        seletedMmsType: CattleManureManagementSystemType
    ) =>
        isMmsTypeAvailableForMonthlyRanges(seletedMmsType) ||
        isMmsTypeAvailableForOverOrUnder(seletedMmsType);

    const filterAvailableMmsOptions = ({
        index,
        mmsType,
    }: {
        index: number;
        mmsType: CattleManureManagementSystemType;
    }) => {
        if (!isStorageFieldRequired(mmsType)) {
            fc.setValue(`${baseItemPrefix}.${index}.storagePeriod`, "");
            setAvailableStorageOptions((previousOptions) => ({
                ...previousOptions,
                [index]: [],
            }));
            return;
        }

        if (isMmsTypeAvailableForOverOrUnder(mmsType)) {
            setAvailableStorageOptions((previousOptions) => ({
                ...previousOptions,
                [index]: storageOptions.filter(
                    (option) =>
                        option.value.includes("over") || option.value.includes("under")
                ),
            }));
            return;
        }

        setAvailableStorageOptions((previousOptions) => ({
            ...previousOptions,
            [index]: storageOptions.filter(
                (option) =>
                    !option.value.includes("over") && !option.value.includes("under")
            ),
        }));
    };

    useEffect(() => {
        if (selectedMmsTypes && Object.keys(selectedMmsTypes).length) {
            filterAvailableMmsOptions(selectedMmsTypes);
        }
    }, [selectedMmsTypes]);

    const getMmsTypeDefaulttValue = (
        index: number
    ): CattleManureManagementSystemType =>
        fc.getValues(`${baseItemPrefix}.${index}.mmsType`);

    const updateManurePhase = (
        type: CattleManureManagementSystemType,
        index: number
    ) => {
        if (type === CattleManureManagementSystemType.DailySpread)
            fc.setValue(
                `${baseItemPrefix}.${index}.manurePhase`,
                ManurePhase.LiquidSlurry
            );
        else
            fc.setValue(
                `${baseItemPrefix}.${index}.manurePhase`,
                undefined
            );
    };

    const gridClassName = classes.dsmGridThreeColumnFirstBigOthersSmall;

    return (
        <div>
            {isDairyCowStage && (
                <DsmGrid
                    className={isViewMode ? classes.dsmGridTwoColumn : classes.dsmGridOneColumn}
                    style={{ paddingTop: 0 }}
                >
                    {isViewMode && !(fc.getValues(`${baseItemPrefix}.timeInHousingTied`)) ? (
                        <ReactHookDsmInput
                            type="number"
                            name={`${baseItemPrefix}.originalTimeInHousingTied`}
                            label={intl.formatMessage({
                                id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED',
                            })}
                            tooltip={intl.formatMessage({
                                id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED.TOOLTIP',
                            })}
                            adornment={intl.formatMessage({
                                id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED.ADORNMENT',
                            })}
                            disabled={isViewMode}
                            defaultValue={baseline?.timeInHousingTied as string}
                            required
                        />
                    ) : (
                        <RowControlledTextFieldWithMetrics
                            name={`${baseItemPrefix}.timeInHousingTied`}
                            label={intl.formatMessage({
                                id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED',
                            })}
                            tooltip={intl.formatMessage({
                                id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED.TOOLTIP',
                            })}
                            metricUnit={' '}
                            baseline={baseline?.timeInHousingTied}
                            disabled={formType === "view" || isViewMode}
                            minAllowedValue={0}
                            displayTypeSelect={!isViewMode}
                        />
                    )}
                </DsmGrid>
            )}
            <p className={classes.headingTitleVariant} style={{ paddingTop: 0 }}>
                {intl.formatMessage({
                    id: "SUSTELL.STAGE.DAIRY.MMS.TIME_SPENT_IN_HOUSING.DESCRIPTION",
                })}
            </p>

            {baseline?.manureSystems &&
                baseline?.manureSystems.map((item, index) => (
                    <div
                        key={item.id}
                    >
                        <div>
                            <input
                                ref={fc.register()}
                                type="hidden"
                                name={`${baseItemPrefix}.${index}.manurePhase`}
                                defaultValue={item.manurePhase}
                            />
                            <DsmGrid className={gridClassName}>
                                {isViewMode && !(fc.getValues(`${baseItemPrefix}.${index}.mmsType`)) ? (
                                    <ReactHookDsmSelect
                                        style={mmsInputsStyle}
                                        label={intl.formatMessage({
                                            id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE',
                                        })}
                                        name={`${baseItemPrefix}.${index}.originalMmsType`}
                                        options={mmsOptions}
                                        disabled
                                        defaultValue={item.mmsType as string}
                                        changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            setSelectedMmsTypes({
                                                index,
                                                mmsType: e.target
                                                    .value as CattleManureManagementSystemType,
                                            });
                                            updateManurePhase(
                                                e.target.value as CattleManureManagementSystemType,
                                                index
                                            );
                                        }}
                                        tooltip={intl.formatMessage({
                                            id: isDairyCowStage
                                                ? 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE.TOOLTIP'
                                                : 'SUSTELL.STAGE.DAIRY.MMS.CONTINUOUS_GROWING.MMS_TYPE.TOOLTIP',
                                        })}
                                        required
                                    />
                                ) : (
                                    <RowSelectWithMetricsV2
                                        style={mmsInputsStyle}
                                        name={`${baseItemPrefix}.${index}.mmsType`}
                                        label={intl.formatMessage({
                                            id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE',
                                        })}
                                        tooltip={intl.formatMessage({
                                            id: isDairyCowStage
                                                ? 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE.TOOLTIP'
                                                : 'SUSTELL.STAGE.DAIRY.MMS.CONTINUOUS_GROWING.MMS_TYPE.TOOLTIP',
                                        })}
                                        adornment={' '}
                                        disabled={formType === FormType.View || isViewMode}
                                        options={mmsOptions}
                                        baseline={item.mmsType as string}
                                        needMarginBottom={false}
                                        changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            setSelectedMmsTypes({
                                                index,
                                                mmsType: e.target
                                                    .value as CattleManureManagementSystemType,
                                            });
                                            updateManurePhase(
                                                e.target.value as CattleManureManagementSystemType,
                                                index
                                            );
                                        }}
                                    />
                                )}

                                {isStorageFieldRequired(getMmsTypeDefaulttValue(index)) ? (
                                    isViewMode && !(fc.getValues(`${baseItemPrefix}.${index}.storagePeriod`)) ? (
                                        <ReactHookDsmSelect
                                            style={mmsInputsStyle}
                                            label={intl.formatMessage({
                                                id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_STORAGE_PERIOD',
                                            })}
                                            name={`${baseItemPrefix}.${index}.originalStoragePeriod`}
                                            options={
                                                availableStorageOptions[index] ||
                                                filterAvailableMmsOptions({
                                                    index,
                                                    mmsType: getMmsTypeDefaulttValue(index),
                                                })
                                            }
                                            disabled
                                            defaultValue={item.storagePeriod as string}
                                            required={isStorageFieldRequired(getMmsTypeDefaulttValue(index))}
                                        />
                                    ) : (
                                        <RowSelectWithMetricsV2
                                            style={mmsInputsStyle}
                                            name={`${baseItemPrefix}.${index}.storagePeriod`}
                                            label={intl.formatMessage({
                                                id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_STORAGE_PERIOD',
                                            })}
                                            disabled={formType === FormType.View || isViewMode}
                                            options={
                                                availableStorageOptions[index] ||
                                                filterAvailableMmsOptions({
                                                    index,
                                                    mmsType: getMmsTypeDefaulttValue(index),
                                                })
                                            }
                                            baseline={item.storagePeriod as string}
                                            required={isStorageFieldRequired(getMmsTypeDefaulttValue(index))}
                                            needMarginBottom={false}
                                        />
                                    )
                                ) : (
                                    <div />
                                )}

                                {shouldDisplayShare() && (
                                        <ReactHookDsmInput
                                            style={mmsInputsStyle}
                                            label={intl.formatMessage({
                                                id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_SHARE',
                                            })}
                                            type="text"
                                            name={`${baseItemPrefix}.${index}.share`}
                                            disabled={formType === FormType.View || isViewMode}
                                            defaultValue={item.share as string}
                                            required
                                            tooltip={intl.formatMessage({
                                                id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_SHARE.TOOLTIP',
                                            })}
                                            adornment="%"
                                            changeHandler={() => fc.trigger(fieldItemPrefix)}
                                        />
                                )}
                            </DsmGrid>
                        </div>
                    </div>
                ))}
        </div>
    );
};

DairyV2InterventionMMSComponent.defaultProps = {
    stageIndex: 0,
    isViewMode: false,
};

export default DairyV2InterventionMMSComponent;
