/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { PoultryBaseline, PoultryOutputBreeding } from '../../../../models/Baseline/PoultryBaseline';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { StageType } from '../../../../../../graphql/types';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import lookupValues from '../../../../helpers/lookupValues';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
}

const PoultryOutputBreedingDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PoultryOutputBreeding>();
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const fc = useFormContext<PoultryBaseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnSmallQuantityUnits = unitLong(userUOM.unitBarnSmallQuantityUnits as string);

  useEffect(() => {
    if(formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = ()=>{
    if (currResetValue.current){
      const resetObject = {...fc.getValues()} as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel('reset');
  }

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant="wide"
    > 
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.HATCHING_TITLE'})}
            </span>
          </div>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.totalEggsToStage`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_TO_STAGE"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_TO_STAGE.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              focusLostHandler={() => {
                fc.trigger(`${fieldItemPrefix}.totalEggsToStage`); 
                fc.trigger(`${fieldItemPrefix}.totalEggsSold`);
              }}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_TO_STAGE.TOOLTIP"})}
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.totalEggsToStage`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.totalEggsSold`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_SOLD"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_SOLD.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              focusLostHandler={() => {
                fc.trigger(`${fieldItemPrefix}.totalEggsToStage`); 
                fc.trigger(`${fieldItemPrefix}.totalEggsSold`);
              }}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_SOLD.TOOLTIP"})}
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.totalEggsSold`)}
            />
          </DsmFieldset>
          <div>
            <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
              <span style={headingTitle}>
                {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.HENS_TITLE'})}
              </span>
            </div>
            <DsmGrid className={classes.additionalEntriesBlockHolder}>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.totalHensSold`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_HENS_SOLD"})}
                  adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_HENS_SOLD.PLACEHOLDER"})}
                  disabled={formType === FormType.View}
                  required
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.totalHensSold`)}
                />
              </DsmFieldset>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.averageHensWeight`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_HENS_WEIGHT"})}
                  adornment={barnOutputMassUnit === 'kg' ? 'kg/animal' : barnOutputMassUnit}
                  disabled={formType === FormType.View}
                  tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_HENS_WEIGHT.TOOLTIP"})}
                  required
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.averageHensWeight`)}
                />
              </DsmFieldset>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.mortalityHensAtPercent`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.MORTALITY_HENS_RATE"})}
                  adornment='%'
                  disabled={formType === FormType.View}
                  required
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.mortalityHensAtPercent`)}
                />
              </DsmFieldset>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.weightHensAtMortality`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.WEIGHT_HENS_AT_MORTALITY"})}
                  adornment={barnOutputMassUnit === 'kg' ? 'kg/animal' : barnOutputMassUnit}
                  disabled={formType === FormType.View}
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.weightHensAtMortality`)}
                />
              </DsmFieldset>
            </DsmGrid>
          </div>
        </div>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)", height: '26px'}} />
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeight`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_EGGS_WEIGHT"})}
              adornment={barnSmallQuantityUnits === 'g' ? ('g' + intl.formatMessage({ id: "EGG_WEIGHT_UNIT" })) 
                  : barnSmallQuantityUnits
                }
              disabled={formType === FormType.View}
              required
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_EGGS_WEIGHT.TOOLTIP"})}
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.averageWeight`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.priceEggs`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE_EGGS"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE_EGGS.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE_EGGS.TOOLTIP"})}
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.priceEggs`) || lookupValues.defaultPrice[StageType.Breeding].priceEggs}
            />
          </DsmFieldset>
          <div>
            <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
              <span style={headingTitle}>
                {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.ROOSTERS_TITLE'})}
              </span>
            </div>
            <DsmGrid className={classes.additionalEntriesBlockHolder}>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.totalRoostersSold`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_ROOSTERS_SOLD"})}
                  adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_ROOSTERS_SOLD.PLACEHOLDER"})}
                  disabled={formType === FormType.View}
                  required
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.totalRoostersSold`)}
                />
              </DsmFieldset>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.averageRoostersWeight`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_ROOSTERS_WEIGHT"})}
                  adornment={barnOutputMassUnit === 'kg' ? 'kg/animal' : barnOutputMassUnit}
                  disabled={formType === FormType.View}
                  tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_ROOSTERS_WEIGHT.TOOLTIP"})}
                  required
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.averageRoostersWeight`)}
                />
              </DsmFieldset>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.mortalityRoostersAtPercent`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.MORTALITY_ROOSTERS_RATE"})}
                  adornment='%'
                  disabled={formType === FormType.View}
                  required
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.mortalityRoostersAtPercent`)}
                />
              </DsmFieldset>
              <DsmFieldset>
                <ReactHookDsmInput
                  name={`${fieldItemPrefix}.weightRoostersAtMortality`}
                  label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.WEIGHT_ROOSTERS_AT_MORTALITY"})}
                  adornment={barnOutputMassUnit === 'kg' ? 'kg/animal' : barnOutputMassUnit}
                  disabled={formType === FormType.View}
                  type="number"
                  defaultValue={fc.getValues(`${fieldItemPrefix}.weightRoostersAtMortality`)}
                />
              </DsmFieldset>
            </DsmGrid>
          </div>
        </div>
      </DsmGrid>
      <div style={{marginTop: "var(--dsm-spacing-px-4)"}}>
        <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
          <span style={headingTitle}>
            {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.HENS_AND_ROOSTERS_TITLE'})}
          </span>
        </div>
        <div className={classes.additionalEntriesBlockHolder}>
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <DsmFieldset>
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.price`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE"})}
                adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE.PLACEHOLDER"})}
                disabled={formType === FormType.View}
                tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE.TOOLTIP"})}
                type="number"
                defaultValue={fc.getValues(`${fieldItemPrefix}.price`) || lookupValues.defaultPrice[StageType.Breeding].price}
              />
            </DsmFieldset>
          </DsmGrid>
        </div>
      </div>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await fc.trigger(`stages.${itemIndex}.stageData.output`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM" })}/>
    </DialogContainer>
  );
};

export default PoultryOutputBreedingDialog;