import * as Yup from 'yup';
import { defaultUnits } from '../../../../sustell_15/utils/unit-utils';
import { interventionValidationResourseUsagePartV2 } from './interventionValidationSchemaGeneralPart';
import {
  averageLiveWeightMortalitiesFn,
  calcInterventionValue,
  feedItemsInterventionTest,
  interventionFieldWithMin,
  interventionFieldWithMinMax,
  interventionFieldWithMoreThanMin
} from './interventionValidationObjectBuilderFunctions';
import { StageType } from '../../../../../graphql/types';
import {
  feedItemsTest,
  feedItemsTestWithOrigin,
  numericOptionalWithMin,
  numericRequiredWithGreaterThanMin,
  numericOptionalWithGreaterThanMin,
  numericRequiredWithMin,
  numericRequiredWithMinMax,
} from '../../Baseline/validationSchema/validationObjectBuilderFunctions';
import {
  ManureForm,
  SurfaceType,
} from '../../../../../graphql/generated/blonk/pigs';
import { processingStageData } from './processingInterventionValidation';

const stageInputBreeding = (intl, userUOM = defaultUnits) =>
  Yup.object({
    pigsPresentAtStart: interventionFieldWithMoreThanMin(intl, 'pigsPresentAtStart', 0),
    pigsPresentAtEnd: interventionFieldWithMoreThanMin(intl, 'pigsPresentAtEnd', 0),
    internalSources: Yup.array().of(
      Yup.object({
        numberPigs: interventionFieldWithMoreThanMin(intl, 'numberPigs', 0),
        distanceTransport: interventionFieldWithMin(
          intl,
          'distanceTransport',
          0
        ),
      })
    ),
    internalSourcesAdditions: Yup.array().of(
      Yup.object({
        farmId: Yup.string().required(
          intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
        ),
        originStageId: Yup.string().required(
          intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
        ),
        numberPigs: numericRequiredWithMin(intl, 0),
        distanceTransport: numericOptionalWithMin(intl, 0),
        originAnimalType: Yup.string().when("hasBreedingInput", {
          is: "true" || true,
          then: Yup.string().required(
            intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
          ),
          otherwise: Yup.string().notRequired().nullable(),
        }),
      })
    ),
    externalSources: Yup.array().of(
      Yup.object({
        numberPigs: interventionFieldWithMoreThanMin(intl, 'numberPigs', 0),
        averageAgeOfPigs: interventionFieldWithMinMax(
          intl,
          'averageAgeOfPigs',
          5,
          300,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        averageWeightOfPigs: interventionFieldWithMinMax(
          intl,
          'averageWeightOfPigs',
          25,
          350,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        distanceTransport: interventionFieldWithMin(
          intl,
          'distanceTransport',
          0
        ),
      })
    ),
    externalSourcesAdditions: Yup.array().of(
      Yup.object({
        numberPigs: numericRequiredWithMin(intl, 0),
        averageAgeOfPigs: numericRequiredWithMinMax(
          intl,
          5,
          300,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        averageWeightOfPigs: numericRequiredWithMinMax(
          intl,
          25,
          350,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        distanceTransport: numericOptionalWithMin(intl, 0),
      })
    ),
  }).default({});

const stageInputFattening = (intl, userUOM = defaultUnits) =>
  Yup.object({
    stockPresent: Yup.bool(),
    optionalInput: Yup.object()
      .when('stockPresent', {
        // is: (stockPresent) => stockPresent === true,
        is: true,
        then: Yup.object({
          pigsPresentAtStart: interventionFieldWithMoreThanMin(
            intl,
            'pigsPresentAtStart',
            0
          ),
          pigsPresentAtEnd: interventionFieldWithMoreThanMin(
            intl,
            'pigsPresentAtEnd',
            0
          ).test('animalNumberOK', '', function (value, testContext) {
            const { resolve, path, createError } = this;
            const pigsPresentAtStart =
              testContext.from[0]?.value?.pigsPresentAtStart;
            const animalsSold = testContext.from[2]?.value?.output?.soldPigs;
            const animalsToStage =
              testContext.from[2]?.value?.output?.pigsToStage;
            const animalsMortality =
              testContext.from[2]?.value?.output?.mortalityPigs;
            const internalIncomings =
              testContext.from[1]?.value.internalSources?.reduce(
                (sum, item) => {
                  if (item.numberPigs && Number(item.numberPigs))
                    return sum + Number(item.numberPigs);
                  return sum;
                },
                0
              ) || 0;
            const externalIncomings =
              testContext.from[1]?.value.externalSources?.reduce(
                (sum, item) => {
                  if (item.numberPigs && Number(item.numberPigs))
                    return sum + Number(item.numberPigs);
                  return sum;
                },
                0
              ) || 0;
            if (
              Number(pigsPresentAtStart) &&
              Number(animalsSold) &&
              Number(animalsToStage) + Number(animalsMortality)
            ) {
              const initialNumber =
                Number(pigsPresentAtStart) +
                internalIncomings +
                externalIncomings;
              const finalNumber =
                Number(value) +
                Number(animalsSold) +
                Number(animalsToStage) +
                Number(animalsMortality);
              if (initialNumber === finalNumber) return true;

              return createError({
                path,
                message: intl.formatMessage({
                  id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.ANIMAL_NUMBER_ERROR',
                }),
              });
            }
            return true;
          }),
          averageWeightPigsStart: interventionFieldWithMoreThanMin(
            intl,
            'averageWeightPigsStart',
            0
          ),
          averageWeightPigsEnd: interventionFieldWithMoreThanMin(
            intl,
            'averageWeightPigsEnd',
            0
          ),
          averageAgePigsStart: interventionFieldWithMinMax(
            intl,
            'averageAgePigsStart',
            5,
            300,
            'VALIDATION.NUMERIC.RANGE_ERROR'
          ),
          averageAgePigsEnd: interventionFieldWithMinMax(
            intl,
            'averageAgePigsEnd',
            5,
            300,
            'VALIDATION.NUMERIC.RANGE_ERROR'
          ),
        }).default({}),
      })
      .default({}),
    internalSources: Yup.array().of(
      Yup.object({
        numberPigs: interventionFieldWithMoreThanMin(intl, 'numberPigs', 0),
        distanceTransport: interventionFieldWithMin(
          intl,
          'distanceTransport',
          0
        ),
      })
    ),
    internalSourcesAdditions: Yup.array().of(
      Yup.object({
        farmId: Yup.string().required(
          intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
        ),
        originStageId: Yup.string().required(
          intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
        ),
        numberPigs: numericRequiredWithMin(intl, 0),
        distanceTransport: numericOptionalWithMin(intl, 0),
        originAnimalType: Yup.string().when("hasBreedingInput", {
          is: "true" || true,
          then: Yup.string().required(
            intl.formatMessage({ id: "VALIDATION.FIELD.INPUT_SELECT" })
          ),
          otherwise: Yup.string().notRequired().nullable(),
        }),
      })
    ),
    externalSources: Yup.array().of(
      Yup.object({
        numberPigs: interventionFieldWithMoreThanMin(intl, 'numberPigs', 0),
        averageAgeOfPigs: interventionFieldWithMinMax(
          intl,
          'averageAgeOfPigs',
          5,
          300,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        averageWeightOfPigs: interventionFieldWithMoreThanMin(
          intl,
          'averageWeightOfPigs',
          0
        ),
        distanceTransport: interventionFieldWithMin(
          intl,
          'distanceTransport',
          0
        ),
      })
    ),
    externalSourcesAdditions: Yup.array().of(
      Yup.object({
        numberPigs: numericRequiredWithMin(intl, 0),
        averageAgeOfPigs: numericRequiredWithMinMax(
          intl,
          5,
          300,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        averageWeightOfPigs: numericRequiredWithMinMax(
          intl,
          25,
          350,
          'VALIDATION.NUMERIC.RANGE_ERROR'
        ),
        distanceTransport: numericOptionalWithMin(intl, 0),
      })
    ),
  });

const stageFeed = (intl, userUOM = defaultUnits) =>
  Yup.object({
    compoundFeeds: feedItemsInterventionTest(intl, 0),
    compoundFeedsAdditions: feedItemsTest(intl),
    singleIngredients: feedItemsInterventionTest(intl, 0),
    singleIngredientsAdditions: feedItemsTestWithOrigin(intl, 0),
  }).default({});

const stageOutputBreeding = (intl, baseline, userUOM = defaultUnits) =>
  Yup.object({
    averageWeightPigs: averageLiveWeightMortalitiesFn(
      'Pig-Pigs',
      intl,
      userUOM,
      'averageWeightPigs'
    ),
    averageAgePiglets: interventionFieldWithMin(intl, 'averageAgePiglets', 0),
    averageWeightPiglets: averageLiveWeightMortalitiesFn(
      'Pig-Piglets',
      intl,
      userUOM,
      'averageWeightPiglets'
    ),
    mortalityPigs: interventionFieldWithMin(intl, 'mortalityPigs', 0),
    mortalityPiglets: interventionFieldWithMin(intl, 'mortalityPiglets', 0),
    pigsToStage: interventionFieldWithMin(intl, 'pigsToStage', 0),
    pigletsToStage: interventionFieldWithMin(intl, 'pigletsToStage', 0),
    soldPigs: interventionFieldWithMin(intl, 'soldPigs', 0),
    soldPiglets: interventionFieldWithMin(intl, 'soldPiglets', 0),
    priceSoldPigs: interventionFieldWithMin(intl, 'priceSoldPigs', 0),
    priceSoldPiglets: interventionFieldWithMin(intl, 'priceSoldPiglets', 0),
  }).default({});

const stageOutputFattening = (intl, baseline, userUOM = defaultUnits) =>
  Yup.object({
    averageAgePigs: interventionFieldWithMin(intl, 'averageWeightPigs', 0),
    averageWeightPigs: interventionFieldWithMin(
      intl,
      'averageWeightPiglets',
      0
    ),
    mortalityPigs: interventionFieldWithMin(intl, 'mortalityPigs', 0),
    pigsToStage: interventionFieldWithMin(intl, 'pigsToStage', 0),
    soldPigs: interventionFieldWithMin(intl, 'soldPigs', 0),
    priceSoldPigs: interventionFieldWithMin(intl, 'priceSoldPigs', 0),
  }).default({});

const stageEmissions = (intl, userUOM = defaultUnits) =>
  Yup.object({
    methaneEntericFermentation: interventionFieldWithMinMax(
      intl,
      'methaneEntericFermentation',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    methane: interventionFieldWithMinMax(
      intl,
      'methane',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nitrousOxideDirect: interventionFieldWithMinMax(
      intl,
      'nitrousOxideDirect',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nitrousOxideIndirect: interventionFieldWithMinMax(
      intl,
      'nitrousOxideIndirect',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    amonia: interventionFieldWithMinMax(
      intl,
      'amonia',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nitricOxide: interventionFieldWithMinMax(
      intl,
      'nitricOxide',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nonMethaneVolatile: interventionFieldWithMinMax(
      intl,
      'nonMethaneVolatile',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    PM10: interventionFieldWithMinMax(
      intl,
      'PM10',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    PM25: interventionFieldWithMinMax(
      intl,
      'PM25',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    totalSuspendedParticles: interventionFieldWithMinMax(
      intl,
      'totalSuspendedParticles',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
  }).default({});

const stageHousing = (intl, baseline, userUOM = defaultUnits) =>
  Yup.object({
    surfaceType: Yup.string()
      // when surface type for intervention is changed, all MMS are removed (baseline's and addditional)
      // check is tehr any mmm set
      .test(
        'anyMMSExists',
        '',
        function (interventionSurfaceType, testContext) {
          const { path, createError } = this;

          const baselineSurfaceType =
            testContext.from?.[0].value?.surfaceType_old;

          // if intervnetion surface type changed, chech for any MMS added as additional, as baseline's are removed
          if (
            baselineSurfaceType &&
            baselineSurfaceType !== interventionSurfaceType
          ) {
            const anyMMS =
              testContext.from?.[0].value?.manureSystemsAdditions?.some(
                (item) => item.mmsType
              ) || false;
            if (!anyMMS)
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'SUSTELL.STAGE.PIGS.HOUSING.SHARE.MMS',
                }),
              });
          }
          return true;
        }
      ),
    manureSystems: Yup.array()
      .of(
        Yup.object({
          localManureForm: Yup.string(),
          mmsType: Yup.string()
            // if new intervention is created nad manure dialog is not opened, then surface type will not been set
            // also non of data will be set, so no need to check manure type to be required
            .test(
              'RequiredIfSurfaceTypeSet',
              '',
              function (value, testContext) {
                const { path, createError } = this;
                const surfaceType = testContext?.from?.[1]?.value?.surfaceType;

                if (surfaceType && !value) {
                  return createError({
                    path,
                    message: intl.formatMessage({
                      id: 'VALIDATION.FIELD.INPUT_SELECT',
                    }),
                  });
                }

                return true;
              }
            ),
          share: Yup.number()
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .test('sumOK', '', function (value, testContext) {
              const { resolve, path, createError } = this;
              const [parent1, parent2] = testContext.from;
              const localManureForm = resolve(
                parent1?.value?.localManureForm || ''
              );

              if (localManureForm) {
                const sameTypeMMSAdditions = (
                  parent2?.value?.manureSystemsAdditions || []
                ).filter((item) => item.localManureForm === localManureForm);
                const mmsInterventionsList = (
                  parent2?.value?.manureSystems || []
                ).filter((item) => item.localManureForm === localManureForm);
                const index = Number(testContext?.from?.[3]?.value?.index);

                const sumAdditions = sameTypeMMSAdditions.reduce(
                  (acc, item) => {
                    if (item.share && !Number.isNaN(item.share))
                      return acc + Number(item.share);
                    return acc;
                  },
                  0
                );

                const sumInterventions = mmsInterventionsList.reduce(
                  (acc, item, mmsIndex) => {
                    const baselineVal =
                      baseline.stages?.[index]?.stageData?.housing
                        ?.manureSystems?.[mmsIndex].share;

                    return (
                      acc +
                      calcInterventionValue(
                        baselineVal,
                        item.share,
                        item.share_changeMetric
                      )
                    );
                  },
                  0
                );

                if (sumAdditions + sumInterventions !== 100) {
                  return createError({
                    path,
                    message: intl.formatMessage({
                      id: 'SUSTELL.STAGE.PIGS.HOUSING.MMS.PERCENT_SUM_ERROR',
                    }),
                  });
                }
                return true;
              }
              return true;
            }),
          mmsHoldingDuration: Yup.string().when('mmsType', {
            is: (val) => {
              return (
                val === 'LIQUID_COVER' ||
                val === 'LIQUID_CRUST' ||
                val === 'LIQUID_NO_CRUST' ||
                val === 'PIT' ||
                val === 'DEEP_BEDDING_ACTIVE_MIXING' ||
                val === 'DEEP_BEDDING_NO_MIXING'
              );
            },
            then: Yup.string()
              // transofrm to avoid error not null
              .transform((changed, original) =>
                original === null ? '' : changed
              )
              .required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
            otherwise: Yup.string().nullable(),
          }),
        })
      )
      .test('MMSTypesCorect', '', function (mmsValues, testContext) {
        const { path, parent, createError } = this;
        // get surface type, if not set, get it from baseline
        // it happens when new intervention is created, and dialog is not opened
        const surfaceType =
          parent?.surfaceType ||
          baseline?.stages?.[Number(testContext.from?.[2]?.value?.index)]
            ?.stageData?.housing?.surfaceType;

        // if surface type isn't deep bedding, then it must exist at leate one slurry MMS
        const mmsBaseline = mmsValues;
        const mmsAdditions =
          testContext?.from?.[0]?.value?.manureSystemsAdditions;
        if (surfaceType !== SurfaceType.DeepBedding) {
          const anyLiquid = mmsBaseline?.some(
            (mms) => mms.localManureForm === ManureForm.LiquidSlurry
          );

          const anyLiquidAdditions = mmsAdditions?.some(
            (mms) => mms.localManureForm === ManureForm.LiquidSlurry
          );
          if (
            (mmsBaseline?.length || mmsAdditions?.length) &&
            !anyLiquid &&
            !anyLiquidAdditions
          ) {
            // show error for at the first mms row
            let errorPath = '';
            // if there is a baseline MMS, show error on the first baseline MMS
            if (mmsBaseline?.length) errorPath = `${path || ''}.[0].mmsType`;
            // no baseline mms (housing type changed), show error on the first addition MMS
            else errorPath = `${path || ''}Additions.[0].mmsType`;
            return createError({
              path: errorPath,
              message: intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.MANURE.LIQUID_MMS_MISSING_ERROR',
              }),
            });
          }
        }
        return true;
      }),
    manureSystemsAdditions: Yup.array()
      .of(
        Yup.object({
          localManureForm: Yup.string(),
          mmsType: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          mmsHoldingDuration: Yup.string().when('mmsType', {
            is: (val) => {
              return (
                val === 'LIQUID_COVER' ||
                val === 'LIQUID_CRUST' ||
                val === 'LIQUID_NO_CRUST' ||
                val === 'PIT' ||
                val === 'DEEP_BEDDING_ACTIVE_MIXING' ||
                val === 'DEEP_BEDDING_NO_MIXING'
              );
            },
            then: Yup.string()
              // transofrm to avoid error not null
              .transform((changed, original) =>
                original === null ? '' : changed
              )
              .required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
            otherwise: Yup.string().nullable(),
          }),
          share: Yup.number()
            .transform((changed, original) => {
              return original === '' ? undefined : changed;
            })
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .test('sumOK', '', function (value, testContext) {
              const { resolve, path, createError } = this;
              const [parent1, parent2] = testContext.from;
              const localManureForm = resolve(
                parent1?.value?.localManureForm || ''
              );
              if (localManureForm) {
                const sameTypeMMSAdditions = (
                  parent2?.value?.manureSystemsAdditions || []
                ).filter((item) => item.localManureForm === localManureForm);
                const mmsInterventionsList = (
                  parent2?.value?.manureSystems || []
                ).filter((item) => item.localManureForm === localManureForm);
                const index = Number(testContext?.from?.[3]?.value?.index);

                const sumAdditions = sameTypeMMSAdditions.reduce(
                  (acc, item) => {
                    if (item.share && !Number.isNaN(item.share))
                      return acc + Number(item.share);

                    return acc;
                  },
                  0
                );
                const sumInterventions = mmsInterventionsList.reduce(
                  (acc, item, mmsIndex) => {
                    const baselineVal =
                      baseline.stages?.[index]?.stageData?.housing
                        ?.manureSystems?.[mmsIndex].share;

                    // must calculate share instead using share_newValue
                    // beacuse if dioalog is not oepend, then share_newValue is not set
                    return (
                      acc +
                      calcInterventionValue(
                        baselineVal,
                        item.share,
                        item.share_changeMetric
                      )
                    );
                  },
                  0
                );
                if (sumAdditions + sumInterventions !== 100) {
                  return createError({
                    path,
                    message: intl.formatMessage({
                      id: 'SUSTELL.STAGE.PIGS.HOUSING.MMS.PERCENT_SUM_ERROR',
                    }),
                  });
                }
                return true;
              }
              return true;
            }),
        })
      )
      .test('MMSTypesCorect', '', function (mmsValues, testContext) {
        const { path, parent, createError } = this;
        // get surface type, if not set, get it from baseline
        // it happens when new intervention is created, and dialog is not opened
        const surfaceType =
          parent?.surfaceType ||
          baseline?.stages?.[Number(testContext.from?.[2]?.value?.index)]
            ?.stageData?.housing?.surfaceType;
        // if surface type isn't deep bedding, then it must exist at leate one slurry MMS
        const mmsAdditions = mmsValues;
        const mmsBaseline = testContext?.from?.[0]?.value?.manureSystems;
        if (surfaceType !== SurfaceType.DeepBedding) {
          const anyLiquid = mmsBaseline?.some(
            (mms) => mms.localManureForm === ManureForm.LiquidSlurry
          );

          const anyLiquidAdditions = mmsAdditions?.some(
            (mms) => mms.localManureForm === ManureForm.LiquidSlurry
          );
          if (
            (mmsBaseline?.length || mmsAdditions?.length) &&
            !anyLiquid &&
            !anyLiquidAdditions
          ) {
            // show error for at the first mms row
            let errorPath = '';
            // if there is a baseline MMS, show error on the first baseline MMS
            if (mmsBaseline?.length) errorPath = `${path || ''}.[0].mmsType`;
            // no baseline mms (housing type changed), show error on the first addition MMS
            else errorPath = `${path || ''}Additions.[0].mmsType`;
            return createError({
              path: errorPath,
              message: intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.MANURE.LIQUID_MMS_MISSING_ERROR',
              }),
            });
          }
        }
        return true;
      }),
    Nreplacement: interventionFieldWithMinMax(intl, 'Nreplacement', 0, 100),
    Preplacement: interventionFieldWithMinMax(intl, 'Preplacement', 0, 100),
    beddingSystemsAdditions: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        beddingAmount: interventionFieldWithMin(intl, 'beddingAmount', 0),
      })
    ),
    materialsAdditions: Yup.array().of(
      Yup.object({
        materialType: Yup.string(),
        materialAmount: Yup.number().when('materialType', {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
    materials: Yup.array().of(
      Yup.object({
        materialType: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        materialAmount: interventionFieldWithMin(intl, 'materialAmount', 0),
      })
    ),
  }).default({});



const stageDataPartPigsV2 = ({
  intl,
  baseline,
  userUOM = defaultUnits,
  minValueOutput = 1,
}) =>
  Yup.object({
    stages: Yup.array().of(
      Yup.object({
        id: Yup.string(),
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
          .min(
            3,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_LENGTH' },
              { count: 3 }
            )
          ),
        type: Yup.string()
          .oneOf([StageType.Breeding, StageType.Fattening, StageType.Processing])
          .required(),

        stageData: Yup.object()
          .when('type', {
            is: StageType.Breeding,
            then: Yup.object({
              input: stageInputBreeding(intl, userUOM),
              housing: stageHousing(intl, baseline, userUOM),
              feed: stageFeed(intl, userUOM),
              output: stageOutputBreeding(intl, baseline, userUOM),
              emissions: stageEmissions(intl, userUOM),
            }).default({}),
          })
          .when('type', {
            is: StageType.Fattening,
            then: Yup.object({
              input: stageInputFattening(intl, userUOM),
              housing: stageHousing(intl, baseline, userUOM),
              feed: stageFeed(intl, userUOM),
              output: stageOutputFattening(intl, baseline, userUOM),
              emissions: stageEmissions(intl, userUOM),
            }),
          })
          .when('type',{
            is:StageType.Processing,
            then: processingStageData(intl, baseline)
          })
          .default({}),
      })
    ),
  });

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const combinedSchema = Yup.object({ info: baseSchema })
    .concat(
      Yup.object({
        resourceUse: interventionValidationResourseUsagePartV2(confObj),
      })
    )
    .concat(stageDataPartPigsV2(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
