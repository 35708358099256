import { FC } from "react";
import { CattleEmission } from "../../../../models/Intervention/CattleIntervention";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { useFormContext } from "react-hook-form";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { Spacer } from "../../../helpers/Spacer";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";

interface DairyV2EmissionMitigationFormProps {
  editEnabled: boolean,
  fieldItemPrefix: string,
  emissions: CattleEmission
}

const DairyV2EmissionMitigationForm: FC<DairyV2EmissionMitigationFormProps> = ({
  editEnabled,
  fieldItemPrefix,
  emissions,
}) => {
  const intl = useIntl();
  const fc = useFormContext();
  return (
    <>
      {!editEnabled ? (
        <>
          {!!fc.getValues(`${fieldItemPrefix}.methaneEntericFermentation`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.methaneEntericFermentation`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.methaneEntericFermentation}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalMethaneEntericFermentation`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.methaneEntericFermentation ?? ''}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.methaneMms`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.methaneMms`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.methaneMms}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalMethaneMms`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.methaneMms ?? ''}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.nmvoc`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.nmvoc`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.nmvoc}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNmvoc`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.nmvoc ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.nitrousOxideDirect`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.nitrousOxideDirect`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.nitrousOxideDirect}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNitrousOxideDirect`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.nitrousOxideDirect ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.nitrousOxideLeaching`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.nitrousOxideLeaching`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.nitrousOxideLeaching}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNitrousOxideLeaching`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.nitrousOxideLeaching ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.nitrogenOxidesStorage`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.nitrogenOxidesStorage`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.nitrogenOxidesStorage}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNitrogenOxidesStorage`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.nitrogenOxidesStorage ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.nitrousOxideVolatilization`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.nitrousOxideVolatilization`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.nitrousOxideVolatilization}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNitrousOxideVolatilization`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.nitrousOxideVolatilization ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.ammoniaHousing`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.ammoniaHousing`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.ammoniaHousing}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAmmoniaHousing`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.ammoniaHousing ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.ammoniaStorage`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.ammoniaStorage`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.ammoniaStorage}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAmmoniaStorage`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.ammoniaStorage ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.pm2_5`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.pm2_5`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.pm2_5}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalPm2_5`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.pm2_5 ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.ammoniaYard`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.ammoniaYard`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.ammoniaYard}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAmmoniaYard`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.ammoniaYard ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.tsp`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.tsp`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.tsp}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalTsp`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.tsp ?? ""}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.pm10`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.pm10`}
                label={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10",
                })}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10",
                })}
                type="number"
                metricUnit=""
                baseline={emissions?.pm10}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalPm10`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10",
              })}
              adornment="%"
              type="number"
              defaultValue={emissions?.pm10 ?? ""}
              disabled
            />
          )}
        </>
      ) : (
        <>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.methaneEntericFermentation`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.methaneEntericFermentation}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.methaneMms`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.methaneMms}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nmvoc`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.nmvoc}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitrousOxideDirect`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.nitrousOxideDirect}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitrousOxideLeaching`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.nitrousOxideLeaching}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitrogenOxidesStorage`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.nitrogenOxidesStorage}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitrousOxideVolatilization`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.nitrousOxideVolatilization}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.ammoniaHousing`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.ammoniaHousing}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.ammoniaStorage`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.ammoniaStorage}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.pm2_5`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.pm2_5}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.ammoniaYard`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.ammoniaYard}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.tsp`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.tsp}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.pm10`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10",
              })}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10",
              })}
              type="number"
              metricUnit=""
              baseline={emissions?.pm10}
              disabled={false}
              displayTypeSelect={true}
            />
            <Spacer />
          </div>
        </>
      )}
    </>
  )
}

export default DairyV2EmissionMitigationForm;
