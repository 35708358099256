/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from "react";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Box, Tooltip, Typography, withStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import {
  DSM_DARK_GREY,
  processAndStageStylesV2,
} from "../../../../../../_metronic/layout";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import {
  AnimalType,
  Farm,
  FarmBaseline,
  AnimalSystem,
  ProductionType,
  StageType,
  Stage,
} from "../../../../../../graphql/types";
import {
  FormType,
  getVersionOfAnimalSystem,
  isReadOnlyAnimalSystem,
} from "../../common";
import AnimalSystemForm from "./AnimalSystemForm";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { DsmButtonV2, useButtonStyles } from "../../../helpers/DsmButton";
import { footprintURL } from "../../../../utils/footprint-url-utils";
import useAdobeDataLayer from "../../../../analytics/adobeDataLayer";
import { DialogContainer } from "../DialogContainer2";
import { isDairy, isShrimp } from "../../../../helpers/animals";
import { FootprintCategoryBox } from "../../../footprints/FootprintCategoryBox";
import FadeMenu from "../../../pageparts/TableMenu";
import Can from "../../../../../modules/Auth/Can";
import { IntlShape } from "react-intl";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import {
  defaultUnits,
  explicitConvertValue,
} from "../../../../utils/unit-utils";

type AnimalSystemComponentProps = {
  processItem: AnimalSystem;
  farm: Farm;
  openHandler: (id: string) => void;
  deleteHandler: () => void;
  createBaselineHandler: (processID: string) => void;
  customerID: string;
};

export const inlineStyleComplete = {
  marginLeft: "20px",
  marginTop: "-3px",
  lineHeight: "2em",
  fontWeight: "500",
  padding: "0 15px",
  borderRadius: "1em",
  color: "var(--dsm-color-success",
  backgroundColor: "var(--dsm-color-success-lighter)",
  border: "1px solid var(--dsm-color-success",
};

export const inlineStyleIncomplete = {
  marginLeft: "20px",
  marginTop: "-3px",
  lineHeight: "2em",
  fontWeight: "500",
  borderRadius: "1em",
  padding: "0 10px",
  color: "var(--dsm-color-neutral-darker",
  backgroundColor: "var(--dsm-color-neutral-lighter)",
  border: "1px solid var(--dsm-color-neutral-darker",
};

export const inlineStyleError = {
  marginLeft: "20px",
  marginTop: "-3px",
  lineHeight: "2em",
  fontWeight: "500",
  borderRadius: "1em",
  padding: "0 10px",
  color: "var(--dsm-color-error)",
  backgroundColor: "var(--dsm-color-error-lightest)",
  border: "1px solid var(--dsm-color-error",
};

export const clickableText = {
  fontWeight: "500",
  "&:hover": {
    textDecoration: "underline !important",
  },
};

const AnimalSystemComponent = ({
  processItem,
  farm,
  openHandler,
  deleteHandler,
  customerID,
}: AnimalSystemComponentProps) => {
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const intl = useIntl();
  const [inlineFormOpen, setInlineFormOpened] = React.useState<boolean>(false);
  const [activeBaseline, setActiveBaseline] = React.useState<any>();
  const [openDuplicateDialog, setOpenDuplicateDialog] =
    useState<boolean>(false);
  const history = useHistory();
  const { ctaClickEvent } = useAdobeDataLayer();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const buttonClasses = useButtonStyles();
  const [duplicateBaseline, setDuplicateBaseline] = useState<boolean>(false);
  const [updatedProcessItem, setUpdatedProcessItem] = useState(processItem);

  const setProductionProcessData = () => {
    // fetch successeded only one matching PP has been retrieved
    const selProdProcess = processItem;

    if (selProdProcess.baselines?.items) {
      const extractedBaselines = [...(selProdProcess.baselines.items ?? [])];

      // Check if there's only one baseline
      if (extractedBaselines.length === 1) {
        setActiveBaseline(extractedBaselines[0]);
      } else {
        // Find the baseline with the most recent updatedAt date
        const mostRecentBaseline = extractedBaselines.reduce(
          (mostRecent, baseline) => {
            if (
              !mostRecent ||
              (baseline &&
                baseline.updatedAt &&
                mostRecent &&
                mostRecent.updatedAt &&
                baseline.updatedAt > mostRecent.updatedAt)
            ) {
              return baseline;
            }
            return mostRecent;
          },
          null
        );

        if (mostRecentBaseline) {
          setActiveBaseline(mostRecentBaseline);
        }
      }
    }
  };

  const StyledClickableParagraph = withStyles(() => ({
    root: {
      fontWeight: 500,
      fontSize: "14px",
      fontFamily: "Fira Sans,sans-serif",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  }))(Typography);

  const calculationCompleted = (item: any) => {
    if (!item.calculationInProgress && item.impactSummary) return true;
    return false;
  };

  const openFootprint = (
    baselineReference: string,
    dataSetName: string,
    openIfError = false
  ) => {
    let path = footprintURL({
      baselineCustomerID: customerID,
      baselineFarmID: farm.id,
      baselineProcessID: processItem.id,
      baselineType: "b",
      baselineReference,
    });
    if (openIfError) path = `${path}/?initialTabOpened=error`;
    const farmName = farm.name;
    const processName = processItem.name;
    ctaClickEvent(
      path,
      "link",
      `Open ${"b"} footprint`,
      "Baseline interventions",
      "Production processes"
    );
    history.push(path, {
      farmName,
      processName,
      dataSetName,
      animalType: updatedProcessItem.animalType,
    });
  };

  const onDuplicateBaselineClick = (dataset: { id: any }) => {
    ctaClickEvent(
      window.location.href,
      "link",
      `Duplicate baseline`,
      "Baseline interventions",
      "Production processes"
    );
    history.push(
      `/customers/${customerID}/farm/${farm.id}/pp/${processItem.id}/nds/NEW`,
      {
        farmName: farm.name || "Farm",
        processName: processItem.name || "Process",
        animalType: processItem?.animalType,
        formType: FormType.Add,
        duplicateDatasetID: dataset.id,
      }
    );
  };

  const addNewBaseline = () => {
    history.push(
      `/customers/${customerID}/farm/${farm.id}/pp/${processItem.id}/nds/NEW`,
      {
        formType: FormType.Add,
        animalType: processItem.animalType,
      }
    );
  };

  const viewNewBaseline = () => {
    history.push(
      `/customers/${customerID}/farm/${farm.id}/pp/${processItem.id}/nds/${activeBaseline.id}`,
      {
        formType: FormType.View,
        animalType: processItem.animalType,
        farmName: farm.name,
        processName: processItem.name,
      }
    );
  };

  const editNewBaseline = () => {
    history.push(
      `/customers/${customerID}/farm/${farm.id}/pp/${processItem.id}/nds/${activeBaseline.id}`,
      {
        formType: FormType.Edit,
        animalType: processItem.animalType,
        farmName: farm.name,
        processName: processItem.name,
      }
    );
  };

  const addOrDuplicateBaseline = () => {
    if (duplicateBaseline) onDuplicateBaselineClick(activeBaseline);
    else {
      addNewBaseline();
    }
  };

  const getDisplayProductionType = (value: string, intl: IntlShape) => {
    const keyValData = Object.entries(ProductionType).find(
      ([, name]) => value === name
    );
    if (keyValData && keyValData.length > 0) {
      return intl.formatMessage({
        id: `SUSTELL.PRODUCTION.TYPE.${keyValData[1]}`,
      });
    }
    return "";
  };

  const animalTypeNameFix = (animalType: string) => {
    let cleanedString = animalType
      .replace(/\s*[vV](?:1\.0|2\.0)|_V2\s*/g, "") // Remove specified patterns
      .replace(/_/g, " "); // Replace underscores with whitespace
    // Remove s from text
    if (cleanedString === "LAYING HENS") {
      cleanedString = "Laying Hen";
    }
    return (
      cleanedString.charAt(0).toUpperCase() +
      cleanedString.slice(1).toLowerCase()
    );
  };

  React.useEffect(() => {
    setProductionProcessData();
  }, [processItem]);

  const headingTitle = {
    fontSize: "20px",
    color: "black",
    fontWeight: "500",
    marginTop: "10px",
    marginBottom: "20px",
  };

  const headingRadioButtonTitle = {
    fontSize: "14px",
    marginLeft: "10px",
  };

  const openFootprintIfError = (item: any) => {
    if (!item.calculationInProgress && !item.impactSummary) {
      openFootprint(item.id, item.name, true);
    }
    if (calculationCompleted(item)) openFootprint(item.id, item.name, false);
  };

  const completionStyle = (item: FarmBaseline) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return inlineStyleIncomplete;
    if (!item.calculationInProgress && item.impactSummary)
      return inlineStyleComplete;
    if (!item.calculationInProgress && !item.impactSummary)
      return inlineStyleError;
    return inlineStyleError;
  };

  const completionMessage = (item: FarmBaseline) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return intl.formatMessage({ id: "SUSTELL.BASELINE.INCOMPLETE" });
    if (!item.calculationInProgress && item.impactSummary)
      return intl.formatMessage({ id: "SUSTELL.BASELINE.COMPLETE" });
    if (!item.calculationInProgress && !item.impactSummary)
      return intl.formatMessage({ id: "SUSTELL.BASELINE.ERROR" });
    return intl.formatMessage({ id: "SUSTELL.BASELINE.ERROR" });
  };

  const completionTooltip = (item: FarmBaseline) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return intl.formatMessage({ id: "SUSTELL.BASELINE.INCOMPLETE.TOOLTIP" });
    if (!item.calculationInProgress && item.impactSummary)
      return intl.formatMessage({ id: "SUSTELL.BASELINE.COMPLETE.TOOLTIP" });
    if (!item.calculationInProgress && !item.impactSummary)
      return intl.formatMessage({ id: "SUSTELL.BASELINE.ERROR.TOOLTIP" });
    return intl.formatMessage({ id: "SUSTELL.BASELINE.ERROR" });
  };

  const cancelInlineEditHandler = () => {
    setInlineFormOpened(false);
  };

  const postSaveInlineEditHandler = (savedData: AnimalSystem) => {
    setInlineFormOpened(false);
    setUpdatedProcessItem({ ...processItem, ...savedData, id: processItem.id });
  };

  const deleteAnimalSystem = () => {
    deleteHandler();
  };

  const duplicateDatasetHandler = () => {
    onDuplicateBaselineClick(activeBaseline);
  };

  const getFootprintConvRatioValue = (value: number) => {
    const convRatio =
      !userUOM ||
      (userUOM && userUOM.unitEnvImpactPer === defaultUnits.unitEnvImpactPer)
        ? 1
        : explicitConvertValue(
            1,
            defaultUnits.unitEnvImpactPer,
            userUOM.unitEnvImpactPer,
          ) || 1;
    if (convRatio !== 1) {
      return value * convRatio;
    }
    return value;
  };

  const getCategoryValue = () => {
    if (activeBaseline.impactSummary) {
      const impactSummary = JSON.parse(activeBaseline.impactSummary);
      return getFootprintConvRatioValue(impactSummary?.air?.value);
    }
    return null;
  };

  const shouldDisplayDairyUnitText = () => {
    if (isDairy(updatedProcessItem.animalType as AnimalType)) {
      return activeBaseline.stages?.some(
        (stage: Stage) => stage?.type === StageType.DairyCow
      );
    }
    return false;
  };

  return (
    <>
      <DialogContainer
        formVisible={openDuplicateDialog}
        handleClose={() => setOpenDuplicateDialog(false)}
        iconCode="general/edit-01"
        iconColor="#EE7203"
        variant="normal"
      >
        <Typography style={headingTitle}>
          {intl.formatMessage({
            id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.ADD.NEW",
          })}
        </Typography>
        <Box display="flex" flexDirection="column">
          <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
            <input
              type="radio"
              name="baselineOption"
              defaultChecked={!duplicateBaseline}
              onClick={() => setDuplicateBaseline(false)}
            />
            <span style={headingRadioButtonTitle}>
              {intl.formatMessage({
                id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.START.NEW",
              })}
            </span>
          </div>
          <div style={{ marginBottom: "var(--dsm-spacing-px-4)" }}>
            <input
              type="radio"
              name="baselineOption"
              defaultChecked={duplicateBaseline}
              onClick={() => setDuplicateBaseline(true)}
            />
            <span style={headingRadioButtonTitle}>
              {intl.formatMessage({
                id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.DUPLICATE",
              })}
            </span>
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ marginTop: "12px" }}
          >
            <DsmButtonV2
              className={buttonClasses.secondaryButton}
              onClick={() => setOpenDuplicateDialog(false)}
            >
              {intl.formatMessage({ id: "GENERAL.CANCEL" })}
            </DsmButtonV2>
            <DsmButtonV2 onClick={() => addOrDuplicateBaseline()}>
              {intl.formatMessage({ id: "GENERAL.CONFIRM" })}
            </DsmButtonV2>
          </Box>
        </Box>
      </DialogContainer>
      <Box
        style={{
          justifyContent: "flex-start",
          padding: "0",
          alignItems: "baseline",
          marginBottom: "24px",
        }}
      >
        {!inlineFormOpen && (
          <Box
            display="inline"
            sx={{
              marginTop: "20px",
            }}
          >
            <Box
              bgcolor="#F9FAFB"
              sx={{
                padding: "24px",
                paddingTop: "20px",
              }}
            >
              <Box display="flex">
                <Typography
                  style={{
                    fontSize: "20px",
                    lineHeight: "32px",
                    fontWeight: "bold",
                  }}
                  color="textPrimary"
                  className={classes.AnimalSystemFlowInfo}
                >
                  {intl.formatMessage({
                    id: "SUSTELL.ANIMAL.SYSTEM.INFORMATION.TITLE",
                  })}
                </Typography>
                {!farm.benchmark &&
                  !isReadOnlyAnimalSystem(processItem.animalType) &&
                  Can("update", "Farm") && (
                    <DsmButton
                      onClick={() => setInlineFormOpened(true)}
                      variant="text"
                      color="primary"
                    >
                      <DsmIcon
                        name="general/edit-01"
                        style={{
                          marginLeft: " 15px",
                          height: "18px",
                          width: "18px",
                        }}
                        color="--dsm-color-primary"
                      />
                    </DsmButton>
                  )}
                {!farm.benchmark &&
                  !isReadOnlyAnimalSystem(processItem.animalType) &&
                  Can("delete", "Farm") && (
                    <DsmButton
                      onClick={() => deleteAnimalSystem()}
                      variant="text"
                      color="primary"
                    >
                      <DsmIcon
                        name="general/trash-01"
                        style={{
                          marginLeft: " 15px",
                          height: "18px",
                          width: "18px",
                          color: "var(--dsm-color-error, #E51F22)",
                        }}
                      />
                    </DsmButton>
                  )}
              </Box>
              <DsmGrid
                className={classes.dsmGridthreeColumnRowSpacing}
                style={{ marginBotton: "10px" }}
              >
                <div>
                  <Typography style={{ fontWeight: "bold" }}>
                    {animalTypeNameFix(updatedProcessItem.animalType)}
                    {getVersionOfAnimalSystem(
                      updatedProcessItem.animalType as AnimalType
                    )}
                  </Typography>
                  <Typography>
                    {intl.formatMessage({ id: "SUSTELL.ANIMAL.SYSTEM.TYPE" })}{" "}
                  </Typography>
                  <Typography />
                </div>
                <div>
                  <Typography style={{ fontWeight: "bold" }}>
                    {updatedProcessItem.name}
                  </Typography>
                  <Typography>
                    {intl.formatMessage({ id: "SUSTELL.ANIMAL.SYSTEM.NAME" })}
                  </Typography>
                  <Typography />
                </div>
                {isShrimp(updatedProcessItem.animalType as AnimalType) && (
                  <div>
                    <Typography style={{ fontWeight: "bold" }}>
                      {getDisplayProductionType(
                        updatedProcessItem.productionType as string,
                        intl
                      )}
                    </Typography>
                    <Typography>
                      {intl.formatMessage({ id: "SUSTELL.PRODUCTION.TYPE" })}
                    </Typography>
                    <Typography />
                  </div>
                )}
              </DsmGrid>
              <Box
                display="flex"
                sx={{ marginTop: "15px" }}
                style={{ cursor: "pointer" }}
                onClick={() => openHandler(processItem.id)}
              >
                <StyledClickableParagraph>
                  {intl.formatMessage({
                    id: "SUSTELL.ANIMAL.SYSTEM.VIEW.ALL.BASELINES",
                  })}
                </StyledClickableParagraph>
                <DsmIcon
                  name="arrows/chevron-right"
                  style={{
                    marginLeft: " 15px",
                    height: "18px",
                    width: "18px",
                    alignSelf: "center",
                  }}
                  color="#1B1B1B"
                />
              </Box>
              <Typography>
                {intl.formatMessage({
                  id: "SUSTELL.ANIMAL.SYSTEM.VIEW.ALL.BASELINES.INFO",
                })}
              </Typography>
            </Box>
          </Box>
        )}
        {inlineFormOpen && (
          <AnimalSystemForm
            formType={FormType.Edit}
            formVisible={inlineFormOpen}
            formData={updatedProcessItem}
            farmID={farm.id}
            handleClose={cancelInlineEditHandler}
            handleFormSave={postSaveInlineEditHandler}
          />
        )}
        <Box
          sx={{
            paddingY: "20px",
            border: "1px solid #F0F0F0 !important",
          }}
        >
          {!activeBaseline && (
            <DsmGrid
              style={{
                grid: "auto/5fr 1fr",
                columnGap: "var(--dsm-spacing-px-2)",
              }}
            >
              <Box>
                <Typography style={{ fontWeight: "bold" }}>
                  {intl.formatMessage({ id: "SUSTELL.CURRENT.BASELINE" })}
                </Typography>
                <Typography style={{ color: DSM_DARK_GREY }}>
                  {intl.formatMessage({
                    id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.FIRST.DESCRIPTION",
                  })}
                </Typography>
              </Box>
              {!farm.benchmark &&
                !isReadOnlyAnimalSystem(processItem.animalType) &&
                Can("create", "Baseline") && (
                  <Box display="inline">
                    <DsmButtonV2
                      onClick={() => addNewBaseline()}
                      style={{
                        float: "right",
                        width: "100%",
                        marginBottom: "4px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <DsmIcon
                        name="general/plus"
                        style={{
                          paddingRight: "5px",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                      {intl.formatMessage({
                        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.ADD.NEW",
                      })}
                    </DsmButtonV2>
                    <Typography
                      style={{
                        color: DSM_DARK_GREY,
                        fontSize: "10px",
                        textAlign: "center",
                        lineHeight: "16px",
                      }}
                    >
                      {intl.formatMessage({
                        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.ADD.NEW.DESCRIPTION",
                      })}
                    </Typography>
                  </Box>
                )}
            </DsmGrid>
          )}
          {activeBaseline && (
            <DsmGrid
              style={{
                grid: "auto/auto 3fr 3fr auto",
                columnGap: "var(--dsm-spacing-px-2)",
              }}
            >
              <Box>
                <Box
                  display="flex"
                  sx={{ marginTop: "15px" }}
                  style={{ cursor: "pointer" }}
                  onClick={() => viewNewBaseline()}
                >
                  <StyledClickableParagraph>
                    {activeBaseline.name}
                  </StyledClickableParagraph>
                  <DsmIcon
                    name="arrows/chevron-right"
                    style={{
                      marginLeft: "13px",
                      height: "22px",
                      width: "22px",
                    }}
                    color="#1B1B1B"
                  />
                  <Tooltip title={completionTooltip(activeBaseline)}>
                    <Box
                      onClick={(e) => {
                        openFootprintIfError(activeBaseline);
                        e.stopPropagation();
                      }}
                      style={completionStyle(activeBaseline)}
                    >
                      {completionMessage(activeBaseline)}
                    </Box>
                  </Tooltip>
                </Box>
                <Typography style={{ color: DSM_DARK_GREY }}>
                  {intl.formatMessage({
                    id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.FIRST.DESCRIPTION",
                  })}
                </Typography>
                <Typography style={{ color: DSM_DARK_GREY }}>
                  {intl.formatMessage({
                    id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.SECOND.DESCRIPTION",
                  })}
                </Typography>

                <DsmGrid
                  className={
                    getCategoryValue() ? classes.dsmGridFourColumn : ""
                  }
                  style={{ marginTop: "15px", alignItems: "start" }}
                >
                  {getCategoryValue() && (
                    <>
                      <div>
                        <FootprintCategoryBox
                          categoryName={intl.formatMessage({
                            id: "SUSTELL.FOOTPRINT.CATEGORYBOX.TITLE",
                          })}
                          categoryValue={getCategoryValue() || 0}
                          categoryConversationText={intl.formatMessage({
                            id: shouldDisplayDairyUnitText()
                              ? "SUSTELL.FOOTPRINT.CATEGORYBOX.DAIRY.CONVERSATION.TEXT"
                              : "SUSTELL.FOOTPRINT.CATEGORYBOX.CONVERSATION.TEXT",
                          },{
                            unit: userUOM.unitEnvImpactPer || defaultUnits.unitEnvImpactPer,
                          })}
                          categoryUnitDescription={intl.formatMessage({
                            id: "SUSTELL.FOOTPRINT.CATEGORYBOX.UNIT.DESCRIPTION",
                          })}
                          isSmallBox={true}
                        />
                      </div>
                      <div>
                        <Typography style={{ fontWeight: "bold" }}>
                          {activeBaseline.year}
                        </Typography>
                        <Typography>
                          {intl.formatMessage({
                            id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.YEAR",
                          })}
                        </Typography>
                        <Typography />
                      </div>
                      <div>
                        <Typography style={{ fontWeight: "bold" }}>
                          {activeBaseline.lastUpdatedBy}
                        </Typography>
                        <Typography>
                          {intl.formatMessage({
                            id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.MODIFIED_BY",
                          })}
                        </Typography>
                        <Typography />
                      </div>
                      <div>
                        <Typography style={{ fontWeight: "bold" }}>
                          {new Date(
                            activeBaseline.updatedAt
                          ).toLocaleDateString()}
                        </Typography>
                        <Typography>
                          {intl.formatMessage({
                            id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.MODIFIED_DATE",
                          })}
                        </Typography>
                        <Typography />
                      </div>
                    </>
                  )}
                </DsmGrid>
              </Box>
              {!farm.benchmark &&
                !isReadOnlyAnimalSystem(processItem.animalType) &&
                Can("update", "Baseline") && (
                  <Box display="inline">
                    <DsmButtonV2
                      className={buttonClasses.secondaryButton}
                      onClick={() => editNewBaseline()}
                      style={{
                        float: "right",
                        width: "100%",
                        marginBottom: "4px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <DsmIcon
                        name="general/edit-01"
                        style={{
                          paddingRight: "5px",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                      {intl.formatMessage({
                        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.EDIT",
                      })}
                    </DsmButtonV2>
                    <Typography
                      style={{
                        color: DSM_DARK_GREY,
                        fontSize: "10px",
                        textAlign: "center",
                        lineHeight: "16px",
                      }}
                    >
                      {intl.formatMessage({
                        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.EDIT.DESCRIPTION",
                      })}
                    </Typography>
                  </Box>
                )}
              {!farm.benchmark &&
                !isReadOnlyAnimalSystem(processItem.animalType) &&
                Can("create", "Baseline") && (
                  <Box display="inline">
                    <DsmButtonV2
                      className={buttonClasses.secondaryButton}
                      onClick={() => setOpenDuplicateDialog(true)}
                      style={{
                        float: "right",
                        width: "100%",
                        marginBottom: "4px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <DsmIcon
                        name="general/plus"
                        style={{
                          paddingRight: "5px",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                      {intl.formatMessage({
                        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.ADD.NEW",
                      })}
                    </DsmButtonV2>
                    <Typography
                      style={{
                        color: DSM_DARK_GREY,
                        fontSize: "10px",
                        textAlign: "center",
                        lineHeight: "16px",
                      }}
                    >
                      {intl.formatMessage({
                        id: "SUSTELL.ANIMAL.SYSTEM.VIEW.CURRENT.BASELINES.ADD.NEW.DESCRIPTION",
                      })}
                    </Typography>
                  </Box>
                )}
              {!farm.benchmark &&
                !isReadOnlyAnimalSystem(processItem.animalType) &&
                Can("create", "Baseline") && (
                  <Box display="inline">
                    <FadeMenu
                      placeHolder="vertical"
                      duplicateHandler={
                        Can("create", "Baseline") && Can("read", "Baseline")
                          ? () => {
                              duplicateDatasetHandler();
                            }
                          : null
                      }
                      showDuplicate={true}
                    />
                  </Box>
                )}
            </DsmGrid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AnimalSystemComponent;
