import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CattleFeed, FreshGrass } from "../../../../models/Intervention/CattleIntervention";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DairyIntervention } from "../../../../models/Intervention";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { v4 as uuidv4 } from "uuid";
import * as BaselineDairyV2FreshGrassComponent from "../../Baseline/DairyV2/DairyV2FeedFreshGrassComponent";
import { FormType } from "../../common";


type FreshGrassInput = {
  id: string;
  type: string;
  amount: string;
  crudeProteinContent: string;
  dryMatterContent: string;
  digestibility: string;
  neutralDetergentFiber: string;
  grossEnergyContent: string;
  ashContent: string;
}

interface DairyV2FeedFreshGrassComponentProp {
  formType: FormType;
  fieldItemPrefix: string;
  currentStageData: CattleFeed;
  isContinuousGrowth: boolean;
  isFreshGrassEdit: boolean;
  setIsFreshGrassEdit: Dispatch<SetStateAction<boolean>>;
}

const DairyV2FeedFreshGrassComponent: FC<DairyV2FeedFreshGrassComponentProp> = ({
  formType,
  fieldItemPrefix,
  currentStageData,
  isContinuousGrowth,
  isFreshGrassEdit,
  setIsFreshGrassEdit,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const fc = useFormContext<DairyIntervention>();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);

  let adornment: string;
  switch (barnOutputMassUnit) {
    case "kg":
      adornment = "kg DM";
      break;
    case "lbs":
      adornment = "lbs DM"
      break;
    default:
      barnOutputMassUnit || "kg DM";
      break;
  };

  const {
    fields: freshGrass,
    append: appendFreshGrass,
    remove: removeFreshGrass,
  } = useFieldArray({
    name: `${fieldItemPrefix}.freshGrassAdditions`,
    control: fc.control,
    keyName: 'keyId',
  });

  const addFreshGrass = () => {
    const newFreshGrass: FreshGrassInput = {
      id: uuidv4(),
      type: "",
      amount: "",
      crudeProteinContent: "",
      dryMatterContent: "",
      digestibility: "",
      neutralDetergentFiber: "",
      grossEnergyContent: "",
      ashContent: "",
    };
    appendFreshGrass(newFreshGrass);
  };

  const resetEdit = () => {
    (currentStageData?.freshGrass || [])
      ?.filter((item) => !!item.type)
      ?.forEach((_, index) => {
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].amount`, "");
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].crudeProteinContent`, "");
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].dryMatterContent`, "");
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].digestibility`, "");
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].neutralDetergentFiber`, "");
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].grossEnergyContent`, "");
        fc.setValue(`${fieldItemPrefix}.freshGrass[${index}].ashContent`, "");
      });

    for (let i = freshGrass.length; i >= 0; i--) {
      removeFreshGrass(i);
    }
    setIsFreshGrassEdit(false);
  };

  const freshGrassUnit = (fg: FreshGrass, index: number) => {
    const fieldPrefix = `${fieldItemPrefix}.freshGrass[${index}]`;

    return <>
      <DsmGrid className={!isFreshGrassEdit ?
        classes.dsmGridFourColumnWithBorder :
        classes.dsmGridTwoColumnWithBorder}>
        {!isFreshGrassEdit ? (
          <>
            <ReactHookDsmInput
              name={`${fieldPrefix}.type`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.FRESH_GRASS_NAME',
              })}
              tooltip={intl.formatMessage({
                id: !isContinuousGrowth
                  ? 'SUSTELL.STAGE.DAIRY.FEED.FRESH_GRASS_NAME.TOOLTIP'
                  : 'SUSTELL.STAGE.DAIRY.FEED.CONTINUOUS_GROWING.FRESH_GRASS_NAME.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.FRESH_GRASS_NAME.ADORNMENT',
              })}
              type="text"
              defaultValue={fg.type ?? ''}
              disabled
            />
            {!!fc.getValues(`${fieldPrefix}.amount`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.amount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.AMOUNT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.AMOUNT.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.amount}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalAmount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.AMOUNT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.AMOUNT.TOOLTIP',
                })}
                adornment={adornment}
                type="number"
                defaultValue={fg.amount ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.crudeProteinContent`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.crudeProteinContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.crudeProteinContent}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalCrudeProteinContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT.PLACEHOLDER',
                })}
                type="number"
                defaultValue={fg.crudeProteinContent ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.dryMatterContent`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.dryMatterContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.dryMatterContent}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalDryMatterContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT.PLACEHOLDER',
                })}
                type="number"
                defaultValue={fg.dryMatterContent ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.digestibility`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.digestibility`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.digestibility}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalDigestibility`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY.PLACEHOLDER',
                })}
                type="number"
                defaultValue={fg.digestibility ?? ''}
                disabled
              />
            )}
            {!isContinuousGrowth &&
              (!!fc.getValues(`${fieldPrefix}.neutralDetergentFiber`) ? (
                <RowTextFieldWithMetrics
                  name={`${fieldPrefix}.neutralDetergentFiber`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER.TOOLTIP',
                  })}
                  type="number"
                  metricUnit=""
                  baseline={fg.neutralDetergentFiber}
                  displayTypeSelect={false}
                  disabled
                />
              ) : (
                <ReactHookDsmInput
                  name={`${fieldPrefix}.originalNeutralDetergentFiber`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER.TOOLTIP',
                  })}
                  adornment={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER.ADORNMENT',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER.PLACEHOLDER',
                  })}
                  type="number"
                  defaultValue={fg.neutralDetergentFiber ?? ''}
                  disabled
                />
              ))}
            {!!fc.getValues(`${fieldPrefix}.grossEnergyContent`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.grossEnergyContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.grossEnergyContent}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalGrossEnergyContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT.PLACEHOLDER',
                })}
                type="number"
                defaultValue={fg.grossEnergyContent ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.ashContent`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.ashContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.ashContent}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalAshContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT.PLACEHOLDER',
                })}
                type="number"
                defaultValue={fg.ashContent ?? ''}
                disabled
              />
            )}
          </>
        ) : (
          <>
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.amount`}
              label={fg.type as string}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.AMOUNT.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={fg.amount}
              disabled={false}
            />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.crudeProteinContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.CRUDE_PROTEIN_CONTENT.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={fg.crudeProteinContent}
              disabled={false}
            />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.dryMatterContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.DRY_MATTER_CONTENT.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={fg.dryMatterContent}
              disabled={false}
            />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.digestibility`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.DIGESTIBILITY.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={fg.digestibility}
              disabled={false}
            />
            {!isContinuousGrowth &&
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.neutralDetergentFiber`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.NEUTRAL_DETERGENT_FIBER.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={fg.neutralDetergentFiber}
                disabled={false}
              />}
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.grossEnergyContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.GROSS_ENERGY_CONTENT.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={fg.grossEnergyContent}
              disabled={false}
            />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.ashContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.ASH_CONTENT.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={fg.ashContent}
              disabled={false}
            />
          </>
        )}
      </DsmGrid>
    </>
  }

  return (
    <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
      {(currentStageData?.freshGrass || [])
        ?.filter((item) => !!item.type)
        ?.map((freshGrassItem, index: number) => (freshGrassUnit(freshGrassItem, index)))}
      {!!freshGrass.length && freshGrass.map((item, index) => (
        <BaselineDairyV2FreshGrassComponent.default
          key={item.keyId}
          fieldItemPrefix={`${fieldItemPrefix}.freshGrassAdditions`}
          formType={formType}
          itemIndex={index}
          removeHandler={(index) => removeFreshGrass(index)}
          isDairyCowStage={!isContinuousGrowth}
          isIntervention
        />))}
      {!isFreshGrassEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsFreshGrassEdit(true)}
          disabled={formType === FormType.View}
          style={{ width: "120px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <>
          <DsmButton
            variant="text"
            onClick={addFreshGrass}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({ id: 'GENERAL.ADD.ANOTHER' })}{' '}
            {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.FEED.GRASS' })}
          </DsmButton>
          <DsmButton
            variant="text"
            destructive
            onClick={() => resetEdit()}
            disabled={formType === FormType.View}
            style={{ width: "160px" }}
          >
            <DsmIcon slot="before" name="general/minus-circle" />
            <u>
              {intl.formatMessage({
                id: "GENERAL.REMOVE_CHANGE_TYPE",
              })}
            </u>
          </DsmButton>
        </>
      )}
    </DsmGrid>
  )
}

export default DairyV2FeedFreshGrassComponent;
